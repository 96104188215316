import Guid from "common/values/guid/guid";

export default class Topic {
  entityClass?: string;
  entityId?: Guid;
  context?: string;

  constructor(entityClass?: string, entityId?: Guid, context?: string) {
    this.entityClass = entityClass;
    this.entityId = entityId;
    this.context = context;
  }

  public isEqualTo(topic?: Topic): boolean {
    if (!topic) return false;
    return (
      this.entityClass === topic.entityClass && 
      this.entityId?.value === topic.entityId?.value && 
      this.context === topic.context
    );
  }
}
