import Individual from "marketplace/entities/individual/individual";
import CreateMarketplaceIndividualProfileAPIRequest from "marketplace/values/individual-profile/api/request-contracts/create-marketplace-individual-profile-api-request";

export default class MarketplaceIndividualAPIRequest extends CreateMarketplaceIndividualProfileAPIRequest {
    userId: string;
    companyId?: string;
    isVisible: boolean;

    constructor(individual: Individual) {
        if (!individual.userId) throw new Error('Individual must have a userId');
        if(!individual.profile) throw new Error('Individual must have a profile');
        super(individual.profile);
        this.userId = individual.userId.value;
        this.isVisible = individual.isVisible;
        this.companyId = individual.company?.id?.value;

    }
}
