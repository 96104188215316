import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}));

type FilterTextFieldProps = {
  filterName: string;
  placeholder: string;
  value: string;
  onChange: (filterName: string, value: string) => void;
};

export default function FilterTextField(props: Readonly<FilterTextFieldProps>) {
  const { filterName, placeholder, onChange } = props;

  const [value, setValue] = React.useState<string>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function getButtons() {
    if (value === props.value) return null;
    
    return (
      <ButtonsContainer>
        <IconButton 
          size="small" 
          disabled={value === ''}
          onClick={() => onChange(filterName, value)}
        >
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton 
          size="small" 
          onClick={() => setValue(props.value)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </ButtonsContainer>
    )
  }

  return (
    <TextField
      name={filterName}
      size="small"
      multiline
      value={value}
      placeholder={placeholder}
      variant="outlined"
      sx={{ width: '100%' }}
      InputProps={{
        endAdornment: getButtons()
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          onChange(filterName, value);
        }
        if (event.key === 'Escape') {
          event.preventDefault();
          setValue(props.value);
        }
      }}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  )
}
