import Guid from "common/values/guid/guid";
import moment from "moment";
import UserBookmarkInfo from "users/values/user-bookmark-info/user-bookmark-info";


export default class UserBookmarkInfoAPIResponse {
    id?: string;
    entityId?: string;
    createdDate?: string;

    deserialize(): UserBookmarkInfo {
        if(!this.id) throw new Error("id is required");
        if(!this.entityId) throw new Error("entityId is required");
        if(!this.createdDate) throw new Error("createdDate is required");
        return new UserBookmarkInfo(new Guid(this.id), new Guid(this.entityId), moment(this.createdDate));
    }
}
