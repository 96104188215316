import UserProfileAPIResponse from "users/entities/user/api/response-contracts/user-profile-api-response";
import moment from "moment";
import Guid from "common/values/guid/guid";
import UserNetworkConnectionProfile, { InvalidUserNetworkConnectionProfileError } from "users/entities/user-network-connection/user-network-connection-profile";


export default class UserNetworkInfoAPIResponse {
    id?: string;
    createdDate?: string;
    userConnection?: UserProfileAPIResponse;

    deserialize(): UserNetworkConnectionProfile {
        if(!this.id || !this.createdDate || !this.userConnection) 
            throw new InvalidUserNetworkConnectionProfileError(this);
        this.userConnection = Object.assign(new UserProfileAPIResponse(), this.userConnection);
        return new UserNetworkConnectionProfile(
            new Guid(this.id),
            this.userConnection.deserialize(),
            moment(this.createdDate)
        );
    }
}
