import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import Topic from "messaging/values/topic";
import moment from "moment";

export default class MarketplaceForumResultAPIResponse {
  id?: string;
  name?: string;
  entityClass?: string;
  entityId?: string;
  context?: string;
  createdDate?: string;
  subscribers?: string[];

  deserialize(): Forum {
    if (!this.id) throw new Error("id is required, was not provided by the API");
    if (!this.name) throw new Error("name is required, was not provided by the API");
    if (!this.createdDate) throw new Error("createdDate is required, was not provided by the API");
    if (!this.entityId) throw new Error("entityId is required, was not provided by the API");

    return new Forum(
      this.name,
      new Topic(this.entityClass, new Guid(this.entityId), this.context),
      this.subscribers?.map((subscriber) => new Guid(subscriber)),
      new Guid(this.id),
      undefined,
      moment(this.createdDate)
    );
  }
}
