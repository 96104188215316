import Guid from "common/values/guid/guid";
import _ from "lodash";
import ProposalReviewerAPIRequest from "work/entities/proposal/api/request-contracts/proposal-reviewer-api-request";
import ReplaceableDocumentAPIRequest from "work/entities/proposal/api/request-contracts/replaceable-document-api-request";
import WorkFeeScheduleCategoryAPIRequest from "work/entities/proposal/api/request-contracts/work-fee-schedule-category-api-request";
import Proposal from "work/entities/proposal/proposal";

export default class UpdateProposalAPIRequest {
  payload: Array<{ op: string, path: string, value: any }> = [];

  constructor(originalProposal: Proposal, updatedProposal: Proposal) {
    if (updatedProposal.name?.value !== originalProposal.name?.value)
      this.payload = [{ op: "replace", path: "/name", value: updatedProposal.name }];

    if (updatedProposal.description?.value !== originalProposal.description?.value) {
      const update = {
        op: "replace",
        path: "/description",
        value: updatedProposal.description
      };
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (
      !_.isEqual(
        updatedProposal.feeSchedule,
        originalProposal.feeSchedule
      )
    ) {
      const updatedFeeSchedule = updatedProposal.feeSchedule.map((feeSchedule) => {
        return new WorkFeeScheduleCategoryAPIRequest(feeSchedule);
      });
      const update = {
        op: "replace",
        path: "/feeSchedule",
        value: updatedFeeSchedule,
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (
      !_.isEqual(
        updatedProposal.team?.toJSON(),
        originalProposal.team?.toJSON()
      )
    ) {
      const update = {
        op: "replace",
        path: "/team",
        value: updatedProposal.team?.toJSON(),
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (updatedProposal.responseDueBy || originalProposal.responseDueBy) {
      if ((updatedProposal.responseDueBy?.value.isSame(originalProposal.responseDueBy?.value) ?? false) === false) {
        const update = {
          op: "replace",
          path: "/responseDueBy",
          value: updatedProposal.responseDueBy,
        }
        this.payload = this.payload?.length ? this.payload.concat(update) : [update];
      }
    }

    if (updatedProposal.startDate || originalProposal.startDate) {
      if ((updatedProposal.startDate?.isSame(originalProposal.startDate) ?? false) === false) {
        const update = {
          op: "replace",
          path: "/startDate",
          value: updatedProposal.startDate,
        }
        this.payload = this.payload?.length ? this.payload.concat(update) : [update];
      }
    }

    if (updatedProposal.endDate || originalProposal.endDate) {
      if ((updatedProposal.endDate?.isSame(originalProposal.endDate) ?? false) === false) {
        const update = {
          op: "replace",
          path: "/endDate",
          value: updatedProposal.endDate,
        }
        this.payload = this.payload?.length ? this.payload.concat(update) : [update];
      }
    }

    if (
      updatedProposal.discount &&
      updatedProposal.discount !==
      originalProposal.discount
    ) {
      const update = {
        op: "replace",
        path: "/discount",
        value: updatedProposal.discount,
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (updatedProposal.negotiable !== originalProposal.negotiable) {
      const update = {
        op: "replace",
        path: "/negotiable",
        value: updatedProposal.negotiable,
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (!_.isEqual(updatedProposal.clientReviewers, originalProposal.clientReviewers)) {
      const update = {
        op: "replace",
        path: "/clientReviewers",
        value: updatedProposal.clientReviewers.map((reviewer) => new ProposalReviewerAPIRequest(reviewer).payload),
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (!_.isEqual(updatedProposal.vendorReviewers, originalProposal.vendorReviewers)) {
      const update = {
        op: "replace",
        path: "/vendorReviewers",
        value: updatedProposal.vendorReviewers.map((reviewer) => new ProposalReviewerAPIRequest(reviewer).payload),
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (!_.isEqual(updatedProposal.clientPolicyDocuments, originalProposal.clientPolicyDocuments)) {
      const update = {
        op: "replace",
        path: "/clientPolicyDocuments",
        value: updatedProposal.clientPolicyDocuments.map((replaceAbleDocument) => 
          new ReplaceableDocumentAPIRequest(replaceAbleDocument).payload
        )
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
    if (!_.isEqual(updatedProposal.vendorPolicyDocuments, originalProposal.vendorPolicyDocuments)) {
      const update = {
        op: "replace",
        path: "/vendorPolicyDocuments",
        value: updatedProposal.vendorPolicyDocuments.map((replaceAbleDocument) => 
          new ReplaceableDocumentAPIRequest(replaceAbleDocument).payload
        )
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
    if (!_.isEqual(updatedProposal.conflictsDocuments, originalProposal.conflictsDocuments)) {
      const update = {
        op: "replace",
        path: "/conflictsDocuments",
        value: updatedProposal.conflictsDocuments.map((replaceAbleDocument) => 
          new ReplaceableDocumentAPIRequest(replaceAbleDocument).payload
        )
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }

    if (!_.isEqual(updatedProposal.clientTeamTemplateIds, originalProposal.clientTeamTemplateIds)) {
      const update = {
        op: "replace",
        path: "/clientTeamTemplateIds",
        value: updatedProposal.clientTeamTemplateIds.map((id: Guid) => id.value),
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
    if (!_.isEqual(updatedProposal.vendorTeamTemplateIds, originalProposal.vendorTeamTemplateIds)) {
      const update = {
        op: "replace",
        path: "/vendorTeamTemplateIds",
        value: updatedProposal.vendorTeamTemplateIds.map((id: Guid) => id.value),
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
    if (!_.isEqual(updatedProposal.clientFeeScheduleTemplateIds, originalProposal.clientFeeScheduleTemplateIds)) {
      const update = {
        op: "replace",
        path: "/clientFeeScheduleTemplateIds",
        value: updatedProposal.clientFeeScheduleTemplateIds.map((id: Guid) => id.value),
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
    if (!_.isEqual(updatedProposal.vendorFeeScheduleTemplateIds, originalProposal.vendorFeeScheduleTemplateIds)) {
      const update = {
        op: "replace",
        path: "/vendorFeeScheduleTemplateIds",
        value: updatedProposal.vendorFeeScheduleTemplateIds.map((id: Guid) => id.value),
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
    if (updatedProposal.conflictsCheckWaived !== originalProposal.conflictsCheckWaived) {
      const update = {
        op: "replace",
        path: "/conflictsWaived",
        value: updatedProposal.conflictsCheckWaived
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
    if (updatedProposal.teamRestricted !== originalProposal.teamRestricted) {
      const update = {
        op: "replace",
        path: "/teamRestricted",
        value: updatedProposal.teamRestricted
      }
      this.payload = this.payload?.length ? this.payload.concat(update) : [update];
    }
  }
}
