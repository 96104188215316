import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import SearchResults from "common/contracts/search-results";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import MarketplaceSearchInfoAPIResponse from "marketplace/api/response-contracts/marketplace-search-info-api-response";
import MarketplaceCompanySearch from "marketplace/entities/company/api/request-contracts/marketplace-company-search";
import MarketplaceCompanyAPIResponse from "marketplace/entities/company/api/response-contracts/marketplace-company-api-response";
import Company from "marketplace/entities/company/company";
import Session from "users/session/session";

import MarketplaceForumResultAPIResponse from "marketplace/api/response-contracts/marketplace-forum-result-api-response";
import MarketplaceCompanyAPIRequest from "marketplace/entities/company/api/request-contracts/marketplace-company-api-request";
import UpdateMarketplaceCompanyAPIRequest from "marketplace/entities/company/api/request-contracts/update-marketplace-company-api-request";
import MarketplaceCompanyContactAPIRequest from "marketplace/values/company-profile/api/request-contracts/marketplace-company-contact-api-request";
import CompanyProfile from "marketplace/values/company-profile/company-profile";
import Forum from "messaging/entities/forum/forum";

export default class CompanyAPIService {

  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create BookmarkAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getCompanyVendors(abortController?: AbortController): Promise<Company[]> {
    const url = new URL(`/marketplace/company-vendors`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const vendors: Array<Company> = [];
      response.data.forEach((element: any) => {
        let vendor = Object.assign(new MarketplaceCompanyAPIResponse(), element);
        vendors.push(vendor.deserialize());
      });
      return vendors;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new CompanyApiServiceError("getCompanyVendors", error);
    }
  }

  async searchCompanies(search: MarketplaceCompanySearch, abortController: AbortController):
    Promise<SearchResults<MarketplaceSearchInfoAPIResponse>> {
    let params = new URLSearchParams();
    for (const [key, value] of Object.entries(search)) {
      if (value) {
        params.append(key, value.toString());
      }
    }
    const url = new URL(`/marketplace/companies?${params.toString()}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return new SearchResults<MarketplaceSearchInfoAPIResponse>(response.data);
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new CompanyApiServiceError("searchCompanies", error);
    }
  }

  async getCompanyById(companyId: Guid, abortController: AbortController): Promise<Company | undefined> {
    const url = new URL(`/marketplace/companies/${companyId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return Object.assign(new MarketplaceCompanyAPIResponse(), response.data).deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error?.response?.status) {
        case 404: throw new CompanyNotFoundError("Requested Company was not found");
        case 403: throw new HiddenCompanyError("Requested company is not visible");
        default: throw new CompanyApiServiceError("getCompanyById", error);
      }
    }
  }

  async getCompanyAvatar(companyId: Guid, abortController: AbortController): Promise<string> {
    const url = new URL(`/marketplace/companies/${companyId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 404: throw new CompanyAvatarError(error.response.data);
        case 403: throw new HiddenCompanyError("Requested company is not visible");
        default: throw new CompanyApiServiceError("getCompanyAvatar", error);
      }
    }
  }

  async getCompanyContactForums(companyId: Guid, abortController: AbortController): Promise<Forum[]> {
    const url = new URL(`/marketplace/companies/${companyId.value}/contact`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      let forums: Array<Forum> = [];
      response.data?.forEach((element: any) => {
        let forum = Object.assign(new MarketplaceForumResultAPIResponse(), element);
        forums.push(forum.deserialize());
      });
      return forums;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      if (error.response?.status === 404)
        throw new CompanyNotFoundError("Requested Company was not found");
      throw new CompanyApiServiceError("getCompanyContactForums", error);
    }
  }

  async createCompany(company: Company): Promise<Company> {
    const url = new URL(`/marketplace/companies`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new MarketplaceCompanyAPIRequest(company),
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' }),       
        }
      );
      const result = Object.assign(new MarketplaceCompanyAPIResponse(), response.data);
      return result.deserialize();
    } catch (error: any) {
      switch (error.response?.status) {
        case 403: throw new ManagerNotFoundError("Proposed manager for new company was not found");
        case 401: throw new InvalidManagerError("Proposed manager for new company is not valid");
        default:
          throw new CompanyApiServiceError("createCompany", error);
      }
    }
  }

  async updateCompany(
    companyId: Guid,
    isVisible: boolean,
    contactId?: Guid,
    originalProfile?: CompanyProfile,
    updatedProfile?: CompanyProfile): Promise<Company> {
    const url = new URL(`/marketplace/companies/${companyId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.patch(
        url.toString(),
        new UpdateMarketplaceCompanyAPIRequest(isVisible, contactId, originalProfile, updatedProfile).payload,
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
      const result = Object.assign(new MarketplaceCompanyAPIResponse(), response.data);
      return result.deserialize();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new UpdateCompanyError(`Unable to update the company: ${error.response?.data}`);
        case 403: throw new InvalidManagerError("Manager for updated company is not valid");
        case 404: throw new ManagerNotFoundError("Manager for updated company was not found");
        default:
          throw new CompanyApiServiceError("updateCompany", error);
      }
    }
  }

  async joinCompany(companyId: Guid): Promise<void> {
    const url = new URL(`/marketplace/join-company/${companyId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      switch (error.response.status) {
        case 400:
        case 403: throw new UpdateCompanyError(`Unable to join company: ${error.response?.data}`);
        default:
          throw new CompanyApiServiceError("joinCompany", error);
      }
    }
  }

  async registerCompanyContact(companyId: Guid, contact: MarketplaceCompanyContactAPIRequest): Promise<void> {
    const url = new URL(`/marketplace/register-company-contact/${companyId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        contact,
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
    } catch (error: any) {
      switch (error.response.status) {
        case 400:
        case 403: throw new UpdateCompanyError(`Unable to register company contact: ${error.response?.data}`);
        default:
          throw new CompanyApiServiceError("registerCompanyContact", error);
      }
    }
  }

  async contactCompany(companyId: Guid): Promise<Forum> {
    const url = new URL(`/marketplace/companies/${companyId.value}/contact`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const result = Object.assign(new MarketplaceForumResultAPIResponse(), response.data);
      return result.deserialize();
    } catch (error: any) {
      switch (error.response.status) {
        case 400: throw new UpdateCompanyError(`Unable to contact company: ${error.response?.data}`);
        case 404: throw new CompanyNotFoundError("Requested Company was not found");
        default:
          throw new CompanyApiServiceError("contactCompany", error);
      }
    }
  }

  async createCompanyAvatar(companyId: Guid, file: File): Promise<void> {
    const url = new URL(`/marketplace/companies/${companyId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const formData = new FormData();
      formData.append("file", file);
      await axios.post(
        url.toString(),
        formData,
        {
          headers: this.headers.concat({ 'Content-Type': 'multipart/form-data' }),
        }
      );
      AttorneyHubAPIService.removeHeader("Content-Type");
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new CompanyNotFoundError(error.response?.data);
        case 403: throw new InvalidManagerError("Manager for updated company is not valid");
        case 422: throw new CompanyAvatarError(error.response?.data);
        default: throw new CompanyApiServiceError("createCompanyAvatar", error);
      }
    }
  }

  async deleteCompanyAvatar(companyId: Guid): Promise<void> {
    const url = new URL(`/marketplace/companies/${companyId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);

    try{
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      switch (error.response.status) {
        case 403: throw new InvalidManagerError("Manager for updated company is not valid");
        case 404: throw new CompanyNotFoundError("Requested Company was not found");
        default: throw new CompanyApiServiceError("deleteCompanyAvatar", error);
      }
    }
  }
}

export class CompanyApiServiceError extends Error {
  method: string;
  error: any;
  constructor(method: string, error: any) {
    super(`Error in CompanyAPIService.${method}: ${error.message}`);
    this.method = method;
    this.error = error;
  }
}
export class CompanyNotFoundError extends Error { }
export class HiddenCompanyError extends Error { }
export class CompanyAvatarError extends Error { }
export class ManagerNotFoundError extends Error { }
export class InvalidManagerError extends Error { }
export class UpdateCompanyError extends Error { }