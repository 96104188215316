export default class TopicCategory extends String{
  static readonly LegalEntities = {
    EntityMember: 'LegalEntities.EntityMember',
    LegalEntity: 'LegalEntities.LegalEntity'
  };
  static readonly Marketplace = {
    Team: 'Marketplace.Team',
    TeamInvitation: 'Marketplace.TeamInvitation'
  };
  static readonly Messaging = {
    Message: 'Messaging.Message',
    ForumInvitation: 'Messaging.ForumInvitation'
  };
  static readonly Users = {
    NetworkInvitation: 'Users.NetworkInvitation',
    NetworkConnection: 'Users.NetworkConnection'
  };
  static readonly Work = {
    Proposal: 'Work.Proposal'
  };
}
