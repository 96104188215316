import { ThemeProvider, useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Copyright = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  ...theme.applyStyles('light', {
    color: 'rgba(0, 0, 0, 0.54)'
  })
}));

const FooterLinks = styled('section')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '& ul': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      '&:last-child': {
        border: 'none'
      },
      borderRight: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#3f51b5' : '#fff',
      flexBasis: 'auto',
      padding: '0 1em',
      textAlign: 'center'
    }
  }
}));

const FooterContainer = styled('footer')(({ theme }) => ({
  display: 'block',
  flexShrink: 0,
  fontSize: '0.8em',
  margin: 'auto',
  padding: theme.spacing(2, 0),
  textAlign: "center",
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0, 1, 0)
  }
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#3f51b5' : 'white'
}));

type Props = {
  mode?: string;
};

export default function Footer(props: Readonly<Props>) {
  const isMediumDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={theme => ({
      ...theme,
      palette: {
        mode: props.mode ?? 'light'
      }
    })}>
      <FooterContainer id='siteFooter'>
        <Copyright variant="body2" color="textSecondary">
          {`Copyright \u00A9 Attorney Hub, LLC ${new Date().getFullYear()}`}
        </Copyright>
        {!isMediumDisplaySize && (
          <FooterLinks>
            <ul>
              <li><FooterLink to="/privacy">Privacy</FooterLink></li>
              <li><FooterLink to="/terms">Terms</FooterLink></li>
              <li><FooterLink to="/about">About</FooterLink></li>
              <li><FooterLink to="/contact">Contact</FooterLink></li>
            </ul>
          </FooterLinks>
        )}
      </FooterContainer>
    </ThemeProvider>
  );
}
