import { Moment } from "moment";

import Guid from "common/values/guid/guid";

import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";


export default class OpenEntityMemberInvitation {
    id?: Guid;
    entity: LegalEntity;
    expires?: Moment;

    constructor(entity: LegalEntity) {
        this.entity = entity;
    }
}
