import Guid from "common/values/guid/guid";
import { Change } from "diff";

export class TextChangeCollection {
  public changes: TextChange[] = [];

  public get allChangesResolved(): boolean {
    return this.changes.every((change) => change.isResolved);
  }

  public get diffResult(): { previous: string; current: string } {
    return {
      previous: this.changes
        .filter((change) => !change.diff.added)
        .map((change) => change.diff.value)
        .join(""),

      current: this.changes
        .filter((change) => !change.diff.removed)
        .map((change) => change.diff.value)
        .join(""),
    };
  }

  toJSON() : object {
    return {
      changes: this.changes.map((change) => change.toJSON()),
    };
  }

  static fromObjects(objects: Array<object>): TextChangeCollection {
    const collection = new TextChangeCollection();
    objects.forEach((object: any) => {
      collection.changes.push(TextChange.fromObject(object, collection));
    });
    return collection;
  }

  public clone(): TextChangeCollection {
    const collection = new TextChangeCollection();
    collection.changes = this.changes.map((change) => change.clone(collection));
    return collection;
  }
}

export default class TextChange {
  public readonly id = Guid.generate();
  public diff: Change;
  public parent: TextChangeCollection;

  constructor(
    parent: TextChangeCollection | undefined,
    diff: Change,
  ) {
    this.parent = parent ? parent : new TextChangeCollection();
    this.diff = diff;
  }

  public get index(): number {
    return this.parent.changes.indexOf(this);
  }
  public get isResolved(): boolean {
    return !this.diff.added && !this.diff.removed;
  }
  public get previousChange(): TextChange | undefined {
    return this.parent.changes[this.index - 1];
  }
  public get nextChange(): TextChange | undefined {
    return this.parent.changes[this.index + 1];
  }
  public get isModified(): boolean {
    return (
      ((this.diff.removed && this.nextChange?.diff.added) ||
        (this.previousChange?.diff.removed && this.diff?.added)) ??
      false
    );
  }
  public get isAdded(): boolean {
    return (
      (this.diff.added &&
        !this.previousChange?.diff.added &&
        !this.previousChange?.diff.removed) ??
      false
    );
  }
  public get isRemoved(): boolean {
    return (
      (this.diff.removed &&
        !this.previousChange?.diff.added &&
        !this.previousChange?.diff.removed) ??
      false
    );
  }

  public accept() {
    const updatedChanges = [...this.parent.changes];
    const previousIndex = this.index - 1;

    // resolve previous change
    if (this.isModified && this.previousChange?.isModified) {
      const newChange = new TextChange(
        this.parent,
        {
          added: false,
          removed: false,
          value: this.diff.value,
        }
      );
      updatedChanges.splice(previousIndex, 2, newChange);
    }

    // resolve next change
    if (this.isModified && this.nextChange?.isModified) {
      const nextChange = new TextChange(
        this.parent,
        {
          added: false,
          removed: false,
          value: this.nextChange.diff.value,
        }
      );
      updatedChanges.splice(this.index, 2, nextChange);
    }

    // resolve current change
    if (!this.isModified && this.isRemoved) {
      updatedChanges.splice(this.index, 1);
    }

    if (!this.isModified && this.isAdded) {
      const newChange = new TextChange(
        this.parent,
        {
          added: false,
          removed: false,
          value: this.diff.value,
        }
      );
      updatedChanges.splice(this.index, 1, newChange);
    }

    this.parent.changes = updatedChanges;
  }

  public reject() {
    const updatedChanges = [...this.parent.changes];
    const previousIndex = this.index - 1;

    // resolve previous change
    if (this.isModified && this.previousChange?.isModified) {
      const newChange = new TextChange(
        this.parent,
        {
          added: false,
          removed: false,
          value: this.previousChange?.diff.value,
        }
      );
      updatedChanges.splice(previousIndex, 2, newChange);
    }

    // resolve next change
    if (this.isModified && this.nextChange?.isModified) {
      const nextChange = new TextChange(
        this.parent,
        {
          added: false,
          removed: false,
          value: this.diff.value,
        }
      );
      updatedChanges.splice(this.index, 2, nextChange);
    }

    // resolve current change
    if (!this.isModified && this.isRemoved) {
      const newChange = new TextChange(
        this.parent,
        {
          added: false,
          removed: false,
          value: this.diff.value,
        }
      );
      updatedChanges.splice(this.index, 1, newChange);
    }

    if (!this.isModified && this.isAdded) {
      updatedChanges.splice(this.index, 1);
    }

    this.parent.changes = updatedChanges;
  }

  public editChange(newValue: string) {
    const updatedChanges = [...this.parent.changes];
    const previousIndex = this.index - 1;

    const newChange = new TextChange(
      this.parent,
      {
        added: false,
        removed: false,
        value: newValue,
      }
    );

    if (this.isModified && this.previousChange?.isModified) {
      updatedChanges.splice(previousIndex, 2, newChange);
    } else if (this.isModified && this.nextChange?.isModified) {
      updatedChanges.splice(this.index, 2, newChange);
    } else if (!this.isModified) {
      updatedChanges.splice(this.index, 1, newChange);
    }

    this.parent.changes = updatedChanges;
  }

  toJSON() : object {
    return {
      diff: this.diff,
      isResolved: this.isResolved
    };
  }

  static fromObject(object: any, parent?: TextChangeCollection): TextChange {
    const change = new TextChange(parent, object.diff);
    return change;
  }

  public clone(parent: TextChangeCollection): TextChange {
    return new TextChange(parent, this.diff);
  }
}
