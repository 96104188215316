import { ProposalField } from "work/entities/proposal/proposal";

export enum Audience {
    VendorReviewers = "vendorReviewers",
    ClientReviewers = "clientReviewers",
    AllReviewers = "allReviewers"
}
export class TopicContext {
    public readonly audience: Audience;
    public readonly field: ProposalField;
  
    constructor(audience: Audience, field: ProposalField) {
      this.audience = audience;
      this.field = field;
    }
  
    public isEqualTo(other: TopicContext): boolean {
      return this.audience === other.audience && this.field.isEqualTo(other.field);
    }
  
    toJSON(): object {
      return {
        audience: this.audience,
        field: this.field?.toJSON()
      };
    }
  
    static fromObject(object: any): TopicContext {
      return new TopicContext(
        object.audience,
        ProposalField.fromObject(object.field)
      );
    }
  };