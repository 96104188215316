import AHBoolean from "common/values/boolean/boolean";
import Date from "common/values/date/date";
import Guid from "common/values/guid/guid";
import Percent from "common/values/percent/percent";
import _ from "lodash";
import moment, { Moment } from "moment";
import Session from "users/session/session";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import Proposal, {
  CompleteProposalSpec,
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import Team from "work/values/team/team";
import WorkDocument from "work/values/work-document/work-document";
import ProposalFactory from "./proposal-factory";
import { ProposalFieldName } from "work/values/constants";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import User from "users/entities/user/user";

export class ProposalSpec {
  client?: EntityClientRepresentative;
  vendors: EntityVendorRepresentative[] = [];
  name?: ProjectName;
  description?: ProjectDescription;
  negotiable?: boolean;
  responseDueBy?: Date;
  startDate?: Date;
  endDate?: Date;
  supersededBy?: Proposal;
  supersedesId?: Guid;
  clientReviewers?: ProposalReviewer[];
  vendorReviewers?: ProposalReviewer[];
  discount: Percent = new Percent(0);
  feeSchedule: FeeScheduleCategory[] = [];
  conflictsDocuments: WorkDocument[] = [];
  clientPolicyDocuments: WorkDocument[] = [];
  vendorPolicyDocuments: WorkDocument[] = [];
  team?: Team;
  clientTeamTemplateIds: Guid[] = [];
  vendorTeamTemplateIds: Guid[] = [];
  clientFeeScheduleTemplateIds: Guid[] = [];
  vendorFeeScheduleTemplateIds: Guid[] = [];
  teamRestricted: AHBoolean = new AHBoolean(false);
  conflictsCheckWaived: AHBoolean = new AHBoolean(false);


  clone(): ProposalSpec {
    const clone = new ProposalSpec();
    clone.client = this.client?.clone();
    clone.name = this.name?.clone();
    clone.description = this.description?.clone();
    clone.negotiable = this.negotiable;
    clone.responseDueBy = this.responseDueBy?.clone();
    clone.startDate = this.startDate?.clone();
    clone.endDate = this.endDate?.clone();
    clone.supersededBy = this.supersededBy;
    clone.supersedesId = this.supersedesId?.clone();
    clone.clientReviewers = this.clientReviewers?.concat();
    clone.vendorReviewers = this.vendorReviewers?.concat();
    clone.feeSchedule = this.feeSchedule.map((category) => category.clone());
    clone.discount = this.discount.clone();
    clone.conflictsDocuments = this.conflictsDocuments.map((doc) => doc.clone());
    clone.clientPolicyDocuments = this.clientPolicyDocuments.map((doc) =>
      doc.clone()
    );
    clone.vendorPolicyDocuments = this.vendorPolicyDocuments.map((doc) =>
      doc.clone()
    );
    clone.team = this.team?.clone();
    clone.clientTeamTemplateIds = this.clientTeamTemplateIds.map((id) =>
      id.clone()
    );
    clone.vendorTeamTemplateIds = this.vendorTeamTemplateIds.map((id) =>
      id.clone()
    );
    clone.clientFeeScheduleTemplateIds = this.clientFeeScheduleTemplateIds.map(
      (id) => id.clone()
    );
    clone.vendorFeeScheduleTemplateIds = this.vendorFeeScheduleTemplateIds.map(
      (id) => id.clone()
    );
    clone.teamRestricted = this.teamRestricted.clone();
    clone.conflictsCheckWaived = this.conflictsCheckWaived.clone();
    return clone;
  }

  isEqualTo(other: ProposalSpec): boolean {
    if ((this.client && !this.client?.isEqualTo(other.client)) ||
        !this.client && other.client) return false;
    if ((this.name && !this.name?.isEqualTo(other.name)) ||
        !this.name && other.name) return false;
    if ((this.description && !this.description?.isEqualTo(other.description)) ||
        !this.description && other.description) return false;
    if (this.negotiable !== other.negotiable) return false;
    if ((this.responseDueBy && !this.responseDueBy?.isEqualTo(other.responseDueBy)) ||
        !this.responseDueBy && other.responseDueBy) return false;
    if ((this.startDate && !this.startDate?.isEqualTo(other.startDate)) ||
        !this.startDate && other.startDate) return false;
    if ((this.endDate && !this.endDate?.isEqualTo(other.endDate)) ||
        !this.endDate && other.endDate) return false;
    if ((this.discount && !this.discount.isEqualTo(other.discount)) ||
        !this.discount && other.discount) return false;
    if ((this.team && !this.team?.isEqualTo(other.team)) ||
        !this.team && other.team) return false;
    if (!this.teamRestricted.isEqualTo(other.teamRestricted)) return false;
    if (!this.conflictsCheckWaived.isEqualTo(other.conflictsCheckWaived)) return false;
    
    if(!this.feeSchedule && other.feeSchedule) return false;
    if(this.feeSchedule.length !== other.feeSchedule.length) return false;
    this.feeSchedule.forEach((category) => {
      if(!other.feeSchedule.some((otherCategory) => otherCategory.isEqualTo(category))) return false;
    })

    if(!this.conflictsDocuments && other.conflictsDocuments) return false;
    if(this.conflictsDocuments.length !== other.conflictsDocuments.length) return false;
    this.conflictsDocuments.forEach((doc) => {
      if(!other.conflictsDocuments.some((otherDoc) => otherDoc.isEqualTo(doc))) return false;
    })

    if(!this.clientPolicyDocuments && other.clientPolicyDocuments) return false;
    if(this.clientPolicyDocuments.length !== other.clientPolicyDocuments.length) return false;
    this.clientPolicyDocuments.forEach((doc) => {
      if(!other.clientPolicyDocuments.some((otherDoc) => otherDoc.isEqualTo(doc))) return false;
    })

    if(!this.vendorPolicyDocuments && other.vendorPolicyDocuments) return false;
    if(this.vendorPolicyDocuments.length !== other.vendorPolicyDocuments.length) return false;
    this.vendorPolicyDocuments.forEach((doc) => {
      if(!other.vendorPolicyDocuments.some((otherDoc) => otherDoc.isEqualTo(doc))) return false;
    })

    if(!this.clientTeamTemplateIds && other.clientTeamTemplateIds) return false;
    if(this.clientTeamTemplateIds.length !== other.clientTeamTemplateIds.length) return false;
    this.clientTeamTemplateIds.forEach((id) => {
      if(!other.clientTeamTemplateIds.some((otherId) => otherId.isEqualTo(id))) return false;
    })

    if(!this.vendorTeamTemplateIds && other.vendorTeamTemplateIds) return false;
    if(this.vendorTeamTemplateIds.length !== other.vendorTeamTemplateIds.length) return false;
    this.vendorTeamTemplateIds.forEach((id) => {
      if(!other.vendorTeamTemplateIds.some((otherId) => otherId.isEqualTo(id))) return false;
    })

    if(!this.clientFeeScheduleTemplateIds && other.clientFeeScheduleTemplateIds) return false;
    if(this.clientFeeScheduleTemplateIds.length !== other.clientFeeScheduleTemplateIds.length) return false;
    this.clientFeeScheduleTemplateIds.forEach((id) => {
      if(!other.clientFeeScheduleTemplateIds.some((otherId) => otherId.isEqualTo(id))) return false;
    })

    if(!this.vendorFeeScheduleTemplateIds && other.vendorFeeScheduleTemplateIds) return false;
    if(this.vendorFeeScheduleTemplateIds.length !== other.vendorFeeScheduleTemplateIds.length) return false;
    this.vendorFeeScheduleTemplateIds.forEach((id) => {
      if(!other.vendorFeeScheduleTemplateIds.some((otherId) => otherId.isEqualTo(id))) return false;
    })  

    if(!this.clientReviewers && other.clientReviewers) return false;
    if(this.clientReviewers?.length !== other.clientReviewers?.length) return false;
    this.clientReviewers?.forEach((reviewer) => {
      if(!other.clientReviewers?.some((otherReviewer) => otherReviewer.isEqualTo(reviewer))) return false;
    })


    return true;
  }
}

export class ProposalSpecChange {
  field: ProposalField | null;
  action: ProposalChangeAction;
  value: string;
  timeStamp: Moment = moment();
  autoCommentField?: ProposalField;

  constructor(
    field: ProposalField,
    action: ProposalChangeAction,
    value: string,
    autoCommentField?: ProposalField
  ) {
    this.field = field ?? null;
    this.action = action ?? ProposalChangeAction.Edit;
    this.value = value ?? "";
    this.autoCommentField = autoCommentField;
  }

  static fromObject(object: any): ProposalSpecChange {
    const field = ProposalField.fromObject(object.field);
    const action = ProposalChangeAction.fromObject(object.action);
    const value = object.value;
    return new ProposalSpecChange(field, action, value);
  }

  public toJSON(): object {
    return {
      field: this.field?.toJSON(),
      action: this.action.toString(),
      value: this.value,
    };
  }
}

export class ProposalChangeAction {
  // The present tense of the action MUST match the readonly property name
  static readonly Edit: ProposalChangeAction = new ProposalChangeAction(
    "Edit",
    "Edited"
  );
  static readonly ReEdit: ProposalChangeAction = new ProposalChangeAction(
    "ReEdit",
    "Re-edited"
  );
  static readonly Add: ProposalChangeAction = new ProposalChangeAction(
    "Add",
    "Added"
  );
  static readonly Remove: ProposalChangeAction = new ProposalChangeAction(
    "Remove",
    "Removed"
  );

  private constructor(
    private readonly presentTense: string,
    public readonly actionDescription: string
  ) {}

  toString() {
    return this.presentTense;
  }

  public static fromObject(
    obj: keyof typeof ProposalChangeAction
  ): ProposalChangeAction {
    const newAction = ProposalChangeAction[obj] as ProposalChangeAction;
    return newAction;
  }
}

export default class ProposalBuilder {
  private _specStack: ProposalSpec[] = [new ProposalSpec()];
  private _currentIndex: number = 0;
  private _sessionHistory: ProposalSpecChange[] = [];
  private readonly _currentUser: User;

  constructor(currentUser: User, startingSpec?: CompleteProposalSpec) {
    this._currentUser = currentUser;
    if (startingSpec) {
      const spec = new ProposalSpec();
      spec.client = startingSpec.client;
      spec.name = startingSpec.name;
      spec.description = startingSpec.description;
      spec.negotiable = startingSpec.negotiable;
      spec.responseDueBy = startingSpec.responseDueBy;
      spec.startDate = startingSpec.startDate;
      spec.endDate = startingSpec.endDate;
      spec.clientReviewers = startingSpec.clientReviewers;
      spec.vendorReviewers = startingSpec.vendorReviewers;
      spec.discount = startingSpec.discount;
      spec.feeSchedule = startingSpec.feeSchedule;
      spec.conflictsDocuments = startingSpec.conflictsDocuments;
      spec.clientPolicyDocuments = startingSpec.clientPolicyDocuments;
      spec.vendorPolicyDocuments = startingSpec.vendorPolicyDocuments;
      spec.team = startingSpec.team;
      spec.clientTeamTemplateIds = startingSpec.clientTeamTemplateIds;
      spec.vendorTeamTemplateIds = startingSpec.vendorTeamTemplateIds;
      spec.clientFeeScheduleTemplateIds = startingSpec.clientFeeScheduleTemplateIds;
      spec.vendorFeeScheduleTemplateIds = startingSpec.vendorFeeScheduleTemplateIds;
      spec.teamRestricted = startingSpec.teamRestricted;
      spec.conflictsCheckWaived = startingSpec.conflictsCheckWaived;
      this._specStack = [spec];
      this._currentIndex = 0;
    }
  }

  public get currentSpec(): ProposalSpec {
    if (this._currentIndex >= this._specStack.length || this._currentIndex < 0)
      throw new Error("Invalid index");
    return this._specStack[this._currentIndex];
  }

  public get sessionHistory(): ProposalSpecChange[] {
    return this._sessionHistory;
  }

  public get canUndo(): boolean {
    return this._currentIndex > 0;
  }

  public get canRedo(): boolean {
    return this._currentIndex < this._specStack.length - 1;
  }

  public get isModified(): boolean {
    if (this._currentIndex === 0) return false;
    return !this.currentSpec.isEqualTo(this._specStack[0]);
  }

  private resetStack() {
    if (this._currentIndex < this._specStack.length - 1) {
      this._specStack.push(this._specStack[this._currentIndex]);
    }
    this._currentIndex = this._specStack.length - 1;
  }

  public clearSessionHistory() {
    this._sessionHistory = [];
  }

  public undo() {
    if (!this.canUndo) return;
    this._currentIndex--;
  }

  public redo() {
    if (!this.canRedo) return;
    this._currentIndex++;
  }

  public loadSpec(spec: ProposalSpec) {
    this._specStack = [spec];
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClient(client?: EntityClientRepresentative) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.client = client;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setName(name: ProjectName) {
    if (this.currentSpec.name?.value === name.value) {
      return this;
    }
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.name = name;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Name)
    );
    if ((!this._specStack[0].name?.value && !name.value) ||
        this._specStack[0].name?.isEqualTo(name)) {
      return this;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = this._specStack[0].name?.value ?? "";
    if(!this._specStack[0].name && name) {
      action = ProposalChangeAction.Add;
      value = name.value;
    } else if(this._specStack[0].name?.value && !name.value) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.Name,
      action,
      value
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setDescription(description: ProjectDescription) {
    if ((!description && !this.currentSpec.description) ||
        this.currentSpec.description?.isEqualTo(description)) {
      return this;
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.description = description;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Description)
    );

    if ((!this._specStack[0].description?.value && !description?.value) ||
        this._specStack[0].description?.isEqualTo(description)) {
      return this;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = this._specStack[0].description?.value ?? "";
    if(!this._specStack[0].description?.value && description?.value) {
      action = ProposalChangeAction.Add;
      value = description.value;
    } else if(this._specStack[0].description?.value && !description?.value) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.Description,
      action,
      this._specStack[0].description?.value ?? ""
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setNegotiable(negotiable: boolean) {
    if (this.currentSpec.negotiable === negotiable) {
      return this;
    }

    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Negotiable)
    );

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.negotiable = negotiable;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    if (this._specStack[0].negotiable === negotiable) {
      return this;
    }
    const action = ProposalChangeAction.Edit;
    const specChange = new ProposalSpecChange(
      ProposalField.Negotiable,
      action,
      this._specStack[0].negotiable ? "Negotiable" : "Not Negotiable"
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setResponseDueBy(responseDueBy: Date | undefined) {
    if ((!this.currentSpec.responseDueBy && !responseDueBy)  ||
      this.currentSpec.responseDueBy?.isEqualTo(responseDueBy)) {
      return this;
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.responseDueBy = responseDueBy;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.ResponseDueBy)
    );

    if ((!this._specStack[0].responseDueBy && !responseDueBy) ||
        this._specStack[0].responseDueBy?.isEqualTo(responseDueBy)
    ) {
      return this;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = this._specStack[0].responseDueBy?.format("MM/DD/YY") ?? "";
    if(!this._specStack[0].responseDueBy && responseDueBy) {
      action = ProposalChangeAction.Add;
      value = responseDueBy.format("MM/DD/YY");
    } else if(this._specStack[0].responseDueBy && !responseDueBy) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.ResponseDueBy,
      action,
      value
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setStartDate(startDate: Date | undefined) {
    if ((!this.currentSpec.startDate && !startDate)  ||
      this.currentSpec.startDate?.isEqualTo(startDate)) {
      return this;
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.startDate = startDate;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.StartDate)
    );
    if ((!this._specStack[0].startDate && !startDate) ||
        this._specStack[0].startDate?.isEqualTo(startDate)
    ) {
      return this;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = this._specStack[0].startDate?.format("MM/DD/YY") ?? "";
    if(!this._specStack[0].startDate && startDate) {
      action = ProposalChangeAction.Add;
      value = startDate.format("MM/DD/YY");
    } else if(this._specStack[0].startDate && !startDate) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.StartDate,
      action,
      value
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setEndDate(endDate: Date | undefined) {
    if ((!this.currentSpec.endDate && !endDate)  ||
      this.currentSpec.endDate?.isEqualTo(endDate)) {
      return this;
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.endDate = endDate;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.EndDate)
    );

    if ((!this._specStack[0].endDate && !endDate) ||
        this._specStack[0].endDate?.isEqualTo(endDate)
    ) {
      return this;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = this._specStack[0].endDate?.format("MM/DD/YY") ?? "";
    if(!this._specStack[0].endDate && endDate) {
      action = ProposalChangeAction.Add;
      value = endDate.format("MM/DD/YY");
    } else if(this._specStack[0].endDate && !endDate) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.EndDate,
      action,
      value
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setTeam(team: Team | undefined) {
    if((!team && !this.currentSpec.team) ||
        this.currentSpec.team?.isEqualTo(team)) {
      return this;
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.team = team;

    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => change.field?.category !== ProposalFieldCategory.Team
    );

    const addedLeader =
      team?.leader?.userId.value &&
      !this._specStack[0].team?.leader?.userId.value;
    const removedLeader =
      !team?.leader?.userId.value &&
      this._specStack[0].team?.leader?.userId.value;

    if (
      addedLeader &&
      team.leader &&
      !team.leader?.userId.isEqualTo(
        this._specStack[0].team?.leader?.userId
      )
    ) {
      const specChange = new ProposalSpecChange(
        ProposalField.TeamLeader,
        ProposalChangeAction.Add,
        team?.leader?.userId.value.toString(),
        ProposalField.Team
      );
      this._sessionHistory.push(specChange);
    }
    if (
      removedLeader &&
      this._specStack[0].team?.leader
    ) {
      const specChange = new ProposalSpecChange(
        ProposalField.TeamLeader,
        ProposalChangeAction.Remove,
        this._specStack[0].team?.leader.userId.value.toString() ??
          "",
        ProposalField.Team
      );
      this._sessionHistory.push(specChange);
    }
    for (const memberUserId of team?.memberUserIds ?? []) {
      const existingMember =
        this._specStack[0].team?.memberUserIds.find((id) =>
          id.isEqualTo(memberUserId)
        );
      if (!existingMember) {
        const specChange = new ProposalSpecChange(
          ProposalField.TeamMember(memberUserId),
          ProposalChangeAction.Add,
          memberUserId.value.toString(),
          ProposalField.Team
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const memberUserId of this._specStack[0].team
      ?.memberUserIds ?? []) {
      const removedMember =
        !team?.memberUserIds.find((id) => id.isEqualTo(memberUserId))

      if (removedMember) {
        const specChange = new ProposalSpecChange(
          ProposalField.TeamMember(memberUserId),
          ProposalChangeAction.Remove,
          memberUserId.value.toString(),
          ProposalField.Team
        );
        this._sessionHistory.push(specChange);
      }
    }

    return this;
  }

  public setFeeSchedule(categories: FeeScheduleCategory[] | undefined) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.feeSchedule = categories ?? [];
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => change.field?.category !== ProposalFieldCategory.FeeSchedule
    );
    for (const category of categories ?? []) {
      const existingCategory =
        this._specStack[0].feeSchedule?.find((cat) =>
          cat.id.isEqualTo(category.id)
        );
      if (!existingCategory) {
        const specChange = new ProposalSpecChange(
          ProposalField.FeeScheduleCategory(category.id),
          ProposalChangeAction.Add,
          category.toString(),
          ProposalField.FeeSchedule
        );
        this._sessionHistory.push(specChange);
      } else if (!category.isEqualTo(existingCategory)) {
        const specChange = new ProposalSpecChange(
          ProposalField.FeeScheduleCategory(category.id),
          ProposalChangeAction.Edit,
          existingCategory.toString(),
          ProposalField.FeeSchedule
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const category of this._specStack[0].feeSchedule ?? []) {
      const removedCategory = !categories?.find((cat) =>
        cat.id.isEqualTo(category.id)
      );
      if (removedCategory) {
        const specChange = new ProposalSpecChange(
          ProposalField.FeeScheduleCategory(category.id),
          ProposalChangeAction.Remove,
          category.toString(),
          ProposalField.FeeSchedule
        );
        this._sessionHistory.push(specChange);
      }
    }

    return this;
  }

  public setDiscount(discount: Percent) {
    if ((!this.currentSpec.discount && !discount) ||
      this.currentSpec.discount.isEqualTo(discount)) {
      return this;
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.discount = discount ?? 0;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;


    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Discount)
    );

    if ((!this._specStack[0].discount && !discount) ||
        this._specStack[0].discount?.isEqualTo(discount)
    ) {
      return this;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = this._specStack[0].discount?.toString() ?? "";
    if(!this._specStack[0].discount.value && discount) {
      action = ProposalChangeAction.Add;
      value = discount.toString();
    } else if(this._specStack[0].discount.value && !discount.value) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.Discount,
      action,
      value
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setConflictsDocuments(documents: WorkDocument[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.conflictsDocuments = _.uniqBy(
      documents,
      (document) => document.id.value
    );
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    this._sessionHistory = this._sessionHistory.filter(
      (change) => change.field?.name !== ProposalFieldName.Conflicts);
    for (const document of documents) {
      const existingDocument =
        this._specStack[0].conflictsDocuments.find((doc) =>
          doc.id.isEqualTo(document.id)
        );
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ConflictsDocument(document.id),
          ProposalChangeAction.Add,
          document.name?.value ?? "",
          ProposalField.Conflicts
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const document of this._specStack[0]?.conflictsDocuments ??
      []) {
      const removedDocument = !documents.find((doc) =>
        doc.id.isEqualTo(document.id)
      );
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ConflictsDocument(document.id),
          ProposalChangeAction.Remove,
          document.name?.value ?? "",
          ProposalField.Conflicts
        );
        this._sessionHistory.push(specChange);
      }
    }

    return this;
  }

  public setClientPolicyDocuments(documents: WorkDocument[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.clientPolicyDocuments = _.uniqBy(
      documents,
      (document) => document.id.value
    );
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;


    this._sessionHistory = this._sessionHistory.filter(
      (change) => change.field?.name !== ProposalFieldName.ClientPolicies);
    for (const document of documents) {
      const existingDocument =
        this._specStack[0].clientPolicyDocuments.find((doc) =>
          doc.id.isEqualTo(document.id)
        );
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ClientPolicyDocument(document.id),
          ProposalChangeAction.Add,
          document.name?.value ?? "",
          ProposalField.ClientPolicies
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const document of this._specStack[0].clientPolicyDocuments ??
      []) {
      const removedDocument = !documents.find((doc) =>
        doc.id.isEqualTo(document.id)
      );
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ClientPolicyDocument(document.id),
          ProposalChangeAction.Remove,
          document.name?.value ?? "",
          ProposalField.ClientPolicies
        );
        this._sessionHistory.push(specChange);
      }
    }

    return this;
  }

  public setVendorPolicyDocuments(documents: WorkDocument[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.vendorPolicyDocuments = _.uniqBy(
      documents,
      (document) => document.id.value
    );
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;


    this._sessionHistory = this._sessionHistory.filter(
      (change) => change.field?.name !== ProposalFieldName.VendorPolicies);
    for (const document of documents) {
      const existingDocument =
        this._specStack[0]?.vendorPolicyDocuments.find((doc) =>
          doc.id.isEqualTo(document.id)
        );
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.VendorPolicyDocument(document.id),
          ProposalChangeAction.Add,
          document.name?.value ?? "",
          ProposalField.VendorPolicies
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const document of this._specStack[0]?.vendorPolicyDocuments ??
      []) {
      const removedDocument = !documents.find((doc) =>
        doc.id.isEqualTo(document.id)
      );
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.VendorPolicyDocument(document.id),
          ProposalChangeAction.Remove,
          document.name?.value ?? "",
          ProposalField.VendorPolicies
        );
        this._sessionHistory.push(specChange);
      }
    }
    return this;
  }

  public setClientTeamTemplateIds(templateIds: Guid[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.clientTeamTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }
  public setVendorTeamTemplateIds(templateIds: Guid[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.vendorTeamTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientFeeScheduleTemplateIds(templateIds: Guid[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.clientFeeScheduleTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }
  public setVendorFeeScheduleTemplateIds(templateIds: Guid[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.vendorFeeScheduleTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setConflictsCheckWaived(waived: AHBoolean) {
    if (
      this.currentSpec?.conflictsCheckWaived?.value ===
      waived?.value
    ) {
      return this;
    }
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.conflictsCheckWaived = waived;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;


    this._sessionHistory = this._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.WaiveConflictsCheck)
    );
    if(this._specStack[0]?.conflictsCheckWaived?.value === waived?.value) {
      return this
    }
    const specChange = new ProposalSpecChange(
      ProposalField.WaiveConflictsCheck,
      ProposalChangeAction.Edit,
      this.currentSpec?.conflictsCheckWaived?.value
        ? "Conflicts Check Waived"
        : "Conflicts Check Not Waived",
      ProposalField.Conflicts
    );
    this._sessionHistory.push(specChange);


    return this;
  }

  public setTeamRestricted(teamRestricted: AHBoolean) {
    if (
      this.currentSpec?.teamRestricted?.value ===
      teamRestricted?.value
    ) {
      return this;
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.teamRestricted = new AHBoolean(teamRestricted.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    const specChange = new ProposalSpecChange(
      ProposalField.Team,
      ProposalChangeAction.Edit,
      this.currentSpec?.teamRestricted?.value
        ? "Team Restricted"
        : "Team Not Restricted"
    );
    this._sessionHistory.push(specChange);

    return this;
  }

  public setClientReviewers(reviewers: ProposalReviewer[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.clientReviewers = reviewers;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setVendorReviewers(reviewers: ProposalReviewer[]) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.vendorReviewers = reviewers;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public buildDraft(session: Readonly<Session>): Proposal {
    return ProposalFactory.createProposal(this.completedSpec, this._currentUser, session);
  }

  public updateProposal(
    oldProposal: Proposal,
    session: Readonly<Session>
  ): Proposal {
    return ProposalFactory.updateProposal(
      oldProposal,
      this._currentUser,
      this.completedSpec,
      session
    );
  }

  public clone(): ProposalBuilder {
    const clone = new ProposalBuilder(this._currentUser);
    clone._specStack = this._specStack.map((spec) => spec.clone());
    clone._currentIndex = this._currentIndex;
    return clone;
  }

  private get completedSpec(): CompleteProposalSpec {
    const completedSpec: CompleteProposalSpec = {
      name: this.currentSpec.name,
      client: this.currentSpec.client,
      vendors: this.currentSpec.vendors,
      description: this.currentSpec.description,
      negotiable: this.currentSpec.negotiable ?? true,
      responseDueBy: this.currentSpec.responseDueBy,
      startDate: this.currentSpec.startDate,
      endDate: this.currentSpec.endDate,
      clientReviewers: this.currentSpec.clientReviewers ?? [],
      vendorReviewers: this.currentSpec.vendorReviewers ?? [],
      vendorFeeScheduleTemplateIds: this.currentSpec.vendorFeeScheduleTemplateIds ?? [],
      clientFeeScheduleTemplateIds: this.currentSpec.clientFeeScheduleTemplateIds ?? [],
      vendorTeamTemplateIds: this.currentSpec.vendorTeamTemplateIds ?? [],
      clientTeamTemplateIds: this.currentSpec.clientTeamTemplateIds ?? [],
      vendorPolicyDocuments: this.currentSpec.vendorPolicyDocuments ?? [],
      clientPolicyDocuments: this.currentSpec.clientPolicyDocuments ?? [],
      conflictsDocuments: this.currentSpec.conflictsDocuments ?? [],
      conflictsCheckWaived: this.currentSpec.conflictsCheckWaived ?? new AHBoolean(false),
      teamRestricted: this.currentSpec.teamRestricted ?? new AHBoolean(false),
      team: this.currentSpec.team,
      feeSchedule: this.currentSpec.feeSchedule ?? [],
      discount: this.currentSpec.discount ?? new Percent(0),
    };

    return completedSpec;
  }
}
