import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import UserOpenEntityAgreement from "legal-entities/entities/entity-agreement/user-open-entity-agreement";
import EntityName from "legal-entities/values/entity-name/entity-name";

export default class EntityAgreement {
  public readonly id: Guid;
  public readonly entityName: EntityName;
  public administratorName?: Name;
  public administratorEmail?: EmailAddress;
  public termsFileId?: Guid;

  constructor(id: Guid, entityName: EntityName) {
    this.id = id;
    this.entityName = entityName;
  }

  static fromUserOpenEntityAgreement(userOpenEntityAgreement: UserOpenEntityAgreement) {
    return new EntityAgreement(userOpenEntityAgreement.id, userOpenEntityAgreement.entityName);
  }

  static fromObject(object: any) {
    const { id, entityName, administratorName, administratorEmail, termsFileId } = object;
    const agreementId = Guid.fromObject(id);
    if(!agreementId) return undefined

    const agreement = new EntityAgreement(agreementId, EntityName.fromObject(entityName));
    agreement.administratorName = administratorName ? Name.fromObject(administratorName) : undefined;
    agreement.administratorEmail = administratorEmail ? EmailAddress.fromObject(administratorEmail) : undefined;
    agreement.termsFileId = termsFileId ? Guid.fromObject(termsFileId) : undefined;
    return agreement;
  }

  public toJSON() {
    return {
      id: this.id.toJSON(),
      entityName: this.entityName.toJSON(),
      administratorName: this.administratorName?.toJSON(),
      administratorEmail: this.administratorEmail?.toJSON(),
      termsFileId: this.termsFileId?.toJSON()
    };
  }
}

export class UnauthorizedAgreementAccessError extends Error {
  constructor(id: Guid) {
    super(`You are not permitted to view this agreement: ${id}`);
  }
}

export class EntityAgreementNotFoundError extends Error {
  constructor(id: Guid) {
    super(`Unable to find agreement by id: ${id}`);
  }
}

export class InvalidEntityAgreementError extends Error {}
