import Guid from "common/values/guid/guid";
import { Moment } from "moment";

import Bookmark from "marketplace/entities/bookmark/bookmark";
import { BookmarkType } from "marketplace/values/bookmark/bookmark";
import Individual from "marketplace/entities/individual/individual";


export default class BookmarkedIndividual implements Bookmark {
    vendor: Individual;
    id: Guid;
    createdDate: Moment;
    bookmarkId: Guid;
    type: BookmarkType;

    constructor(id: Guid, bookmarkId: Guid, createdDate: Moment, vendor: Individual) {
        this.vendor = vendor;
        this.bookmarkId = bookmarkId;
        this.id = id;
        this.createdDate = createdDate;
        this.type = BookmarkType.Individual;
    }
}
