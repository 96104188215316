import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";


export default class MarketplaceTeamAPIRequest {
    isVisible: boolean;
    name: string;
    description?: string;
    categories?: string[];

    constructor(team: MarketplaceTeam) {
        this.isVisible = team.isVisible;
        this.name = team.profile.name;
        this.description = team.profile.description;
        this.categories = team.profile.categories ?? [];
    }
}
