import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const Errors = styled('ul')(() => ({
  color: red[500],
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  '& li': {
    marginBottom: '0.5em'
  }
}));

type Props = {
  errors?: string[];
}

export default function ErrorList(props: Readonly<Props>) {
  const { errors } = props;

  return (
    <Errors>
      {errors?.map((error) => <li key={error}>{error}</li>)}
    </Errors>
  );
}
