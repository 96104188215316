import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import moment, { MomentInput } from 'moment';
import { useEffect, useState } from 'react';
import Proposal from 'work/entities/proposal/proposal';
import DetailedTeam from 'work/values/team/detailed-team';

const RevisionChangesPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPaper-root': {
    pointerEvents: 'auto'
  }
}));
const ContentContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2)
}));

type RedlinePopoverProps = {
  columnName: string;
  proposal: Proposal | undefined;
  anchorEl: HTMLElement | null;
  open: boolean;
}

export default function RedlinePopover(props: Readonly<RedlinePopoverProps>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [originalTeam, setOriginalTeam] = useState<DetailedTeam | undefined>();
  const [updatedTeam, setUpdatedTeam] = useState<DetailedTeam | undefined>();

  useEffect(() => {
    if (!props.anchorEl && hasFocus) return;
    setAnchorEl(props.anchorEl);
  }, [props.anchorEl, hasFocus]);

  useEffect(() => {
    if (!props.open && hasFocus) return;
    setOpen(props.open);
  }, [props.open, hasFocus]);

  useEffect(() => {
    if (!props.proposal?.team?.leader?.userId) return;
    setOriginalTeam(props.proposal.details?.team);
    setUpdatedTeam(props.proposal.supersedes?.details?.team);
  }, [props.proposal?.team?.leader?.userId, props.proposal]);

  /**
  * Gets the value for the given column in the revision comparison dialog
  * @param   {boolean} getSupersedes If the superseded proposal revision should be returned
  * @returns {string} The value for the given column in the revision comparison dialog
  */
  function getRevisionValue(getSupersedes: boolean = false): string {
    const { columnName, proposal } = props;
    const column = columnName;

    const rowData = getSupersedes ? proposal?.supersedes : proposal;
    const value = rowData ? rowData[column as keyof Proposal] : '';

    if (!column || !rowData || Object.values(rowData).includes(column)) return '';

    if (['name', 'description', 'discount'].includes(column) && rowData.hasOwnProperty(column)) {
      return value?.toString() ?? '';
    }

    if (['responseDueBy', 'startDate', 'endDate'].includes(column) && rowData.hasOwnProperty(column)) {
      return moment(value as MomentInput).isValid() ? moment(value as MomentInput).utc().format('M/D/YY') : 'None';
    }

    return ''
  }

  return (
    <RevisionChangesPopover
      id='redline-popover'
      open={
        Boolean(
          (open || hasFocus) &&
          props.columnName !== '' && props.proposal?.supersedes &&
          !_.isEqual(
            props.proposal[props.columnName as keyof Proposal],
            props.proposal?.supersedes[(props.columnName as keyof Proposal)]
          )
        )
      }
      anchorReference="anchorEl"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          onMouseEnter: () => setHasFocus(true),
          onMouseLeave: () => setHasFocus(false)
        }
      }}
      onClose={() => setAnchorEl(null)}>
      <ContentContainer>
        {!['team', 'feeSchedule'].includes(props.columnName ?? '') && (
          <section>
            <Typography><strong>Original Value:</strong></Typography>
            <Typography>
              {getRevisionValue(false)}
            </Typography>
            <br />
            <Typography><strong>Revised Value:</strong></Typography>
            <Typography>
              {getRevisionValue(true)}
            </Typography>
          </section>
        )}
        {props.columnName === 'team' && (
          // TODO: Implement redline team
          <p>TODO</p>
          // <RedlineEditTeam />
        )}
        {props.columnName === 'feeSchedule' && (
          // TODO: Implement redline fee schedule
          <p>TODO</p>
          // <RedlineEditTeam />
        )}
      </ContentContainer>
    </RevisionChangesPopover>
  )
}
