export type PasswordIssues = {
  password: string[],
  confirmPassword: string | null
}

export default class Password {
  static pattern = /\/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$\/i/;

  private _value: string;
  constructor(password: string, confirmPassword?: string) {
    Password.validate(password, confirmPassword);
    this._value = password;
  }

  get value(): string {
    return this._value;
  }

  static validate(password: string, confirmPassword?: string) {
    const issues: PasswordIssues = {
      password: [],
      confirmPassword: null
    }

    // If password is null or empty string, skip other validation and return invalid
    if (password === null || password.length < 1) {
      issues.password.push('Password required');
      throw new InvalidPasswordError(issues);
    }

    // Only check confirmpassword if it isn't null
    // (for forms that do not use a confirm password check)
    if (confirmPassword !== null && confirmPassword !== undefined) {
      if (confirmPassword.length < 1) {
        issues.confirmPassword = 'Confirm Password required';
      }
      else if (password !== confirmPassword) {
        issues.confirmPassword = 'Password and Confirm Password must match';
      }
    }

    // Check password rules individually, report error(s).
    const regexTotalChar = /^.{8,}$/gm;
    if (!regexTotalChar.test(password)) {
      issues.password.push('Password must contain at least 8 characters');
    }

    const regexUpper = /(?=.*?[A-Z])/g;
    if (!regexUpper.test(password)) {
      issues.password.push('Password must contain at least 1 uppercase letter');
    }

    const regexLower = /(?=.*?[a-z])/g;
    if (!regexLower.test(password)) {
      issues.password.push('Password must contain at least 1 lowercase letter');
    }

    const regexNum = /(?=.*?[\d])/g;
    if (!regexNum.test(password)) {
      issues.password.push('Password must contain at least 1 number');
    }

    const regexSymbol = /(?=.*?[#?!@$%^&*:;'"/.,<>()+=\\|\][}{-])/g;
    if (!regexSymbol.test(password)) {
      issues.password.push('Password must contain at least 1 symbol (#?!@$%^&*-)');
    }

    if (issues.password.length > 0 || issues.confirmPassword !== null) {
      throw new InvalidPasswordError(issues);
    }
  }

  public toString(): string {
    return this._value;
  }
}

export class InvalidPasswordError extends Error {
  issues: PasswordIssues;
  constructor(issues: PasswordIssues) {
    super(`Invalid password: ${issues.password.join(', ')}`);
    this.issues = issues;
  }
}