import axios, { AxiosHeaders, CanceledError } from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import NetworkConnectionAPIResponse from "users/entities/user-network-connection/api/response-contracts/network-connection-api-response";
import UserNetworkConnection from "users/entities/user-network-connection/user-network-connection";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import NetworkInvitationAPIRequest from "users/entities/user-network-invitation/api/request-contracts/network-invitation-api-request";
import NetworkInvitationInfoAPIResponse from "users/entities/user-network-invitation/api/response-contracts/network-invitation-info-api-response";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import Session from "users/session/session";

export default class NetworkInvitationAPIService {
  private readonly _authHeaders: AxiosHeaders = new AxiosHeaders();
  private readonly _session: Readonly<Session>;

  constructor(session: Readonly<Session>) {
    this._session = session;
    if (!session.authToken?.value)
      throw new Error(
        "Session must have an authToken to create a NetworkInvitationAPIService"
      );
    this._authHeaders.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getUserNetworkInvitations(
    abortController?: AbortController
  ): Promise<NetworkInvitationInfo[]> {
    const url = new URL(
      "/users/network-invitations",
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.get(url.toString(), {
        headers: this._authHeaders,
        signal: abortController?.signal,
      });

      const invitations: Array<NetworkInvitationInfo> = [];
      response.data.forEach((invitation: any) => {
        const invitationResponse = Object.assign(
          new NetworkInvitationInfoAPIResponse(),
          invitation
        );
        invitations.push(invitationResponse.deserialize());
      });
      return invitations;
    } catch (error: any) {
      if (error?.response?.status === 400)
        throw new NetworkInvitationBadRequestError(error.response.data);
      if (error instanceof CanceledError) throw error;
      throw new NetworkInvitationAPIServiceError(
        "getUserNetworkInvitations",
        error
      );
    }
  }

  async createNetworkInvitation(
    request: NetworkInvitationAPIRequest
  ): Promise<NetworkInvitationInfo> {
    const url = new URL(
      "/users/network-invitations",
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.post(url.toString(), request, {
        headers: this._authHeaders,
      });
      const invitationInfo = NetworkInvitationInfo.fromObject(response.data);
      this._session.addNetworkInvitation(invitationInfo);

      return invitationInfo;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        throw new NetworkInvitationBadRequestError(error.response.data);
      }
      throw new NetworkInvitationAPIServiceError(
        "createNetworkInvitation",
        error
      );
    }
  }

  async cancelNetworkInvitation(invitationId: Guid): Promise<void> {
    try {
      const url = new URL(
        `/users/network-invitations/${invitationId}/cancel`,
        AttorneyHubAPIService.apiBaseUrl
      );
      await axios.post(
        url.toString(),
        {},
        {
          headers: this._authHeaders,
        }
      );
      this._session.removeNetworkInvitationById(invitationId);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        throw new NetworkInvitationBadRequestError(error.response.data);
      }
      throw new NetworkInvitationAPIServiceError(
        "cancelNetworkInvitation",
        error
      );
    }
  }

  async acceptNetworkInvitation(
    invitationId: Guid
  ): Promise<UserNetworkConnection> {
    try {
      const url = new URL(
        `/users/network-invitations/${invitationId}/accept`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this._authHeaders,
        }
      );
      const connectionInfoData: NetworkConnectionAPIResponse = Object.assign(
        new NetworkConnectionAPIResponse(),
        response.data
      );
      const connectionInfo = connectionInfoData.deserialize();
      const profile = connectionInfo.userA.id.isEqualTo(this._session.user?.id) ? connectionInfo.userB : connectionInfo.userA;
      const connectionProfile = new UserNetworkConnectionProfile(connectionInfo.id, profile, connectionInfo.createdDate);
      this._session.addNetworkConnection(connectionProfile);
      this._session.removeNetworkInvitationById(invitationId);
      return connectionInfo;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        throw new NetworkInvitationBadRequestError(error.response.data);
      }
      throw new NetworkInvitationAPIServiceError(
        "acceptNetworkInvitation",
        error
      );
    }
  }

  async rejectNetworkInvitation(invitationId: Guid): Promise<void> {
    try {
      const url = new URL(
        `/users/network-invitations/${invitationId}/reject`,
        AttorneyHubAPIService.apiBaseUrl
      );
      await axios.post(
        url.toString(),
        {},
        {
          headers: this._authHeaders,
        }
      );
      this._session.removeNetworkInvitationById(invitationId);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        throw new NetworkInvitationBadRequestError(error.response.data);
      }
      throw new NetworkInvitationAPIServiceError(
        "rejectNetworkInvitation",
        error
      );
    }
  }
}

export class NetworkInvitationAPIServiceError extends Error {
  constructor(method: string, error: any) {
    super(`NetworkInvitationAPIService.${method} failed: ${error}`);
  }
}

export class NetworkInvitationBadRequestError extends Error {
  constructor(message: string) {
    super(`NetworkInvitation API service call failed: ${message}`);
  }
}
