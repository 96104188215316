
import EditCompanyProfile from 'marketplace/values/company-profile/view/edit-company-profile';
import ViewCompanyProfile from 'marketplace/values/company-profile/view/view-company-profile';
import EditIndividualProfile from 'marketplace/values/individual-profile/view/edit-individual-profile';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import EditTeamProfile from 'marketplace/values/team-profile/view/edit-team-profile';
import ViewTeamProfile from 'marketplace/values/team-profile/view/view-team-profile';
import { PageTab } from './tabs';

/** @description A map of avatar IDs to base64 encoded image data strings */
export type AvatarMap = { [avatarId: string]: string };

export const editComponentMap = {
  [PageTab.Company]: EditCompanyProfile,
  [PageTab.Teams]: EditTeamProfile,
  [PageTab.Individual]: EditIndividualProfile
};

export const viewComponentMap = {
  [PageTab.Company]: ViewCompanyProfile,
  [PageTab.Teams]: ViewTeamProfile,
  [PageTab.Individual]: ViewIndividualProfile
};
