import Guid from "common/values/guid/guid";

export default class DocumentTopicParameters {
  entityClass?: string;
  entityId?: string;
  context?: string;

  constructor(
    entityClass?: string,
    entityId?: Guid,
    context?: string
    ) {
    this.entityClass = entityClass;
    this.entityId = entityId?.toString();
    this.context = context;
  }
}
