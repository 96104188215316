import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const LoaderContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  "& > *": {
    color: "inherit",
    fontSize: "inherit",
    margin: theme.spacing(1)
  }
}));

type LoaderProps = {
  className?: string;
  size?: number;
  thickness?: number;
  text?: string;
  fontSize?: string;
};

export default function Loader(props: Readonly<LoaderProps>) {
  const { 
    className,
    size,
    thickness,
    text
  } = props;

  return (
    <LoaderContainer className={className}>
      <CircularProgress size={size ?? 32} thickness={thickness ?? 4} />
      <Typography>{text ?? "Loading..."}</Typography>
    </LoaderContainer>
  );
}
