import RegistrationInfo from "legal-entities/values/registration-info";
import LegalEntity from "../legal-entity/legal-entity";
import { InvitationStatus } from "legal-entities/values/invitation-status";
import InvitationMessage from "legal-entities/values/invitation-message/invitation-message";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";


export default class UserEntityMemberInvitation {
    id?: Guid;
    entity: LegalEntity;
    invitedEmail: EmailAddress;
    registrationInfo?: RegistrationInfo;
    status: InvitationStatus;
    message?: InvitationMessage;

    constructor(entity: LegalEntity, invitedEmail: EmailAddress, message?: InvitationMessage, registrationInfo?: RegistrationInfo) {
        this.entity = entity;
        this.invitedEmail = invitedEmail;
        this.registrationInfo = registrationInfo;
        this.status = InvitationStatus.Open;
        this.message = message;
    }

    async accept(): Promise<void> {
        if (this.status !== InvitationStatus.Open) {
            throw new InvalidUserEntityMemberInvitationStatusError("Cannot accept an invitation that is not open.");
        }
    }
}

export class UserMemberInvitationNotFoundError extends Error {
    constructor(id: Guid) {
        super(`Open member invitation with id ${id} not found.`);
    }
}

export class InvalidUserEntityMemberInvitationStatusError extends Error { }

export class InvalidUserEntityMemberInvitationError extends Error { }