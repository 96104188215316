import Guid from "common/values/guid/guid";
import Company from "marketplace/entities/company/company";
import Individual from "marketplace/entities/individual/individual";
import CompanyProfile from "marketplace/values/company-profile/company-profile";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";

export default class WorkMarketplaceInfoAPIResponse {
  individualId?: string;
  entityId?: string;
  companyId?: string;
  companyName?: string;
  firstName?: string;
  lastName?: string;
  description?: string;
  location?: string;
  phoneNumber?: string;
  school?: string;
  avatar?: string;
  emailAddress?: string;
  resume?: string;
  professionalRegistration?: string;
  technicalBackground?: string;
  numberOfApplicationsDraftedProsecuted: number = 0;
  numberOfAllowedApplications: number = 0;
  barAdmissionYear?: number;
  isOnCall?: boolean;
  insuredAmount?: number;
  billingRate?: string;
  potentialDiversityCandidate?: boolean;
  collaborates?: boolean;
  additionalInfo?: string;
  links: string[] = [];
  professionalPublications: string[] = [];
  sampleApplications: string[] = [];
  fluentLanguages?: string[];
  technicalLanguages?: string[];
  regionsRegistered?: string[];
  regionsLicensed?: string[];

  categories?: string[];
  userId?: string;
  isVisible: boolean = true;

  deserialize(): Individual {
    if (!this.individualId) throw new Error('Individual id not returned by api.');
    if (!this.userId) throw new Error('Individual user id not returned by api.');

    const userId = new Guid(this.userId);
    const individual = new Individual(new Guid(this.individualId), userId);

    if (this.firstName && this.lastName) {
      individual.profile = new IndividualProfile(userId, this.firstName, this.lastName);
      if (this.entityId) {
        individual.profile.entityId = new Guid(this.entityId);
      }
    }

    if (this.companyId && this.entityId) {
      individual.company = new Company(new Guid(this.companyId), new Guid(this.entityId));
      if (this.companyName && this.companyId) {
        individual.company.profile = new CompanyProfile(this.companyName);
      }

    }

    return individual;
  }
}
