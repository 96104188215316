import Guid from "common/values/guid/guid";
import EntityName from "legal-entities/values/entity-name/entity-name";

export default class UserOpenEntityAgreement {
    public readonly id: Guid;
    public readonly entityName: EntityName;

    constructor(id: Guid, entityName: EntityName) {
        this.id = id;
        this.entityName = entityName;
    }
}
