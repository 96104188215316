import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import IndividualAvatar from "marketplace/view/individual-avatar";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import UserNetworkConnectionAPIService from "users/entities/user-network-connection/api/user-network-connection-api-service";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import Guid from "common/values/guid/guid";
import { AccountType } from "common/values/account-type/account-type";
import { CanceledError } from "axios";

const PopoverContainer = styled("div")(({ theme }) => ({
  minWidth: "22rem",
  padding: theme.spacing(2),
  textAlign: "center",
}));
const SearchField = styled(TextField)(({ theme }) => ({
  width: "100%",
})) as typeof TextField;
const LoaderProgress = styled(CircularProgress)(({ theme }) => ({
  display: "inline-block",
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
}));
const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: "20rem",
  overflowX: "hidden",
  overflowY: "auto",
}));
const SelectedIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

type ClientSelectorProps = {
  clientUserId?: Guid;
  popoverAnchor?: HTMLDivElement;
  onClientUpdated?: (userInfo?: NetworkedUserInfo) => void;
  onPopoverClose?: Function;
};

export default function ClientSelector(props: Readonly<ClientSelectorProps>) {
  const { clientUserId, popoverAnchor, onClientUpdated, onPopoverClose } =
    props;

  const session = useSession();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedClient, setSelectedClient] = React.useState<
    NetworkedUserInfo | undefined
  >();
  const [networkedClients, setNetworkedClients] = React.useState<
    NetworkedUserInfo[]
  >([]);
  const [clientFilterResults, setClientFilterResults] = React.useState<
    NetworkedUserInfo[]
  >([]);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  useEffect(() => {
    let abortController = new AbortController();
    getClientList(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  useEffect(() => {
    const userProfileInfo = networkedClients.find((c) =>
      c.userId?.isEqualTo(clientUserId)
    );
    setSelectedClient(userProfileInfo);
  }, [clientUserId, networkedClients]);

  async function getClientList(abortController: AbortController) {
    if (!session.user) return;

    setIsLoading(true);

    try {
      const service = new UserNetworkConnectionAPIService(session);
      const apiNetworkConnections = await service.getUserConnectionsInfo(
        abortController
      );
      session.updateNetworkConnections(apiNetworkConnections);
      const clientApiNetworkConnections = apiNetworkConnections.filter(
        (connection) =>
          connection.isClientRep &&
          connection.userId &&
          connection.companyEntityId
      );
      if (
        session.context?.isClientRepresentative &&
        session.accountType === AccountType.Client &&
        session.user.id &&
        session.user.name
      ) {
        const userProfileInfo = new NetworkedUserInfo({
          connectionId: Guid.generate(),
          userId: session.user?.id,
          individualId: session.user?.individualId ?? Guid.generate(),
          name: session.user.name,
          isClientRep: true,
          isVendorRep: false,
        });
        userProfileInfo.name = session.user?.name;
        userProfileInfo.email = session.user?.email;
        userProfileInfo.companyId = session.user?.companyId;
        userProfileInfo.companyEntityId = session.user?.companyEntityId;
        userProfileInfo.companyName = session.user?.companyName;
        userProfileInfo.isClientRep = session.context.isClientRepresentative;
        userProfileInfo.isVendorRep = session.context.isVendorRepresentative;
        clientApiNetworkConnections.push(userProfileInfo);
      }
      setNetworkedClients(clientApiNetworkConnections);
      setClientFilterResults(clientApiNetworkConnections);
    } catch (err) {
      if (err instanceof CanceledError) return;
      console.error(err);
    }
    setIsLoading(false);
  }

  const debouncedSearch = debounce(
    (value) =>
      setClientFilterResults(
        networkedClients.filter(
          (r) =>
            r.name?.toString().toLowerCase().includes(value.toLowerCase()) ||
            r.email?.toString().toLowerCase().includes(value.toLowerCase())
        )
      ),
    100
  );

  function search(value: string) {
    setSearchTerm(value);

    if (value === "") {
      setClientFilterResults(networkedClients);
      return;
    }

    debouncedSearch(value);
  }

  function saveChanges(client?: NetworkedUserInfo) {
    onClientUpdated?.(client);
    onPopoverClose?.();
  }

  return (
    <Popover
      anchorEl={popoverAnchor}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={popoverAnchor !== undefined}
      onClose={() => onPopoverClose?.()}
    >
      <PopoverContainer>
        <SearchField
          placeholder="Networked Clients"
          slotProps={{
            input: {
              startAdornment: <SearchIcon />,
              endAdornment: (
                <>
                  {searchTerm.length > 0 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchTerm("");
                        search("");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </>
              ),
            },
          }}
          value={searchTerm}
          onChange={(event) => search(event.target.value)}
        />
        {isLoading && <LoaderProgress size={50} />}
        {!isLoading && (
          <ListContainer dense>
            {clientFilterResults.length < 1 && (
              <ListItem>
                <ListItemText>
                  {`No networked clients matching: ${searchTerm}`}
                </ListItemText>
              </ListItem>
            )}
            {clientFilterResults.length > 0 &&
              clientFilterResults.map((client: NetworkedUserInfo) => {
                const isSelected = selectedClient?.userId?.isEqualTo(
                  client.userId
                );

                return (
                  <ListItemButton
                    disableGutters
                    key={client.userId?.value}
                    selected={isSelected}
                    onClick={(event) => {
                      if (!client.userId || !client.companyEntityId) {
                        throw new Error(
                          "Client or Company Entity ID is missing"
                        );
                      }
                      saveChanges(client);
                    }}
                  >
                    <ListItemAvatar>
                      {isSelected ? (
                        <SelectedIcon>
                          <DoneIcon />
                        </SelectedIcon>
                      ) : (
                        <IndividualAvatar
                          avatarId={client.avatarId}
                          individualId={client.individualId}
                          session={session}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={client.name?.toString()}
                      secondary={<>{client.email?.toString()}</>}
                    />
                  </ListItemButton>
                );
              })}
          </ListContainer>
        )}
      </PopoverContainer>
    </Popover>
  );
}
