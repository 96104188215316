import UserEntityInfo from "users/values/user-entity-info/user-entity-info";
import UserOpenEntityAgreement from "legal-entities/entities/entity-agreement/user-open-entity-agreement";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import UserBookmarkInfo from "users/values/user-bookmark-info/user-bookmark-info";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";

export interface IUserInformation {
  id?: Guid;
  isVendor: boolean;
  isClient: boolean;
  isVendorRepresentative: boolean;
  isClientRepresentative: boolean;
  viewingAsVendor?: boolean;
  emailAddress: EmailAddress;
  fullName: Name;
  timestamp: Date | string;
  legalEntities: UserEntityInfo[];
  openEntityAgreements: UserOpenEntityAgreement[];
  hasOpenEntityAgreements: boolean;
  individualId?: Guid;
  networkConnections: UserNetworkConnectionProfile[];
  networkInvitations: NetworkInvitationInfo[];
  bookmarkedIndividuals: UserBookmarkInfo[];
  bookmarkedCompanies: UserBookmarkInfo[];
  bookmarkedTeams: UserBookmarkInfo[];
}

export default class UserInformation {
  public readonly id: Guid;
  public readonly isVendor: boolean;
  public readonly isClient: boolean;
  public readonly isVendorRepresentative: boolean;
  public readonly isClientRepresentative: boolean;
  public readonly viewingAsVendor: boolean;
  public readonly emailAddress: EmailAddress;
  public readonly fullName: Name;
  public readonly timestamp: Date | string;
  public readonly legalEntities: UserEntityInfo[];
  public readonly openEntityAgreements: UserOpenEntityAgreement[];
  public readonly hasOpenEntityAgreements: boolean;
  public readonly individualId?: Guid;
  public readonly networkConnections: UserNetworkConnectionProfile[];
  public readonly networkInvitations: NetworkInvitationInfo[];
  public readonly bookmarkedIndividuals: UserBookmarkInfo[];
  public readonly bookmarkedCompanies: UserBookmarkInfo[];
  public readonly bookmarkedTeams: UserBookmarkInfo[];
  public readonly isSuperUser: boolean;

  constructor(
    id: Guid,
    emailAddress: EmailAddress,
    fullName: Name,
    timestamp: Date | string,
    legalEntities: UserEntityInfo[],
    openEntityAgreements: UserOpenEntityAgreement[],
    hasOpenEntityAgreements: boolean,
    networkConnections: UserNetworkConnectionProfile[],
    networkInvitations: NetworkInvitationInfo[],
    bookmarkedIndividuals: UserBookmarkInfo[],
    bookmarkedCompanies: UserBookmarkInfo[],
    bookmarkedTeams: UserBookmarkInfo[],
    isVendor = false,
    isClient = false,
    isVendorRepresentative = false,
    isClientRepresentative = false,
    viewingAsVendor = false,
    individualId?: Guid,
    isSuperUser = false
  ) {
    this.id = id;
    this.isVendor = isVendor;
    this.isClient = isClient;
    this.isVendorRepresentative = isVendorRepresentative;
    this.isClientRepresentative = isClientRepresentative;
    this.viewingAsVendor = viewingAsVendor;
    this.emailAddress = emailAddress;
    this.fullName = fullName;
    this.timestamp = timestamp;
    this.legalEntities = legalEntities;
    this.openEntityAgreements = openEntityAgreements;
    this.hasOpenEntityAgreements = hasOpenEntityAgreements;
    this.individualId = individualId;
    this.networkConnections = networkConnections;
    this.networkInvitations = networkInvitations;
    this.bookmarkedIndividuals = bookmarkedIndividuals;
    this.bookmarkedCompanies = bookmarkedCompanies;
    this.bookmarkedTeams = bookmarkedTeams;
    this.isSuperUser = isSuperUser;
  }
}

export class InvalidUserInfoError extends Error {
  issues: any;

  constructor(issues: any) {
    super(`Invalid user info.`);
    this.issues = issues;
  }
}
