import UserOpenEntityAgreementAPIResponse from "users/api/response-contracts/user-open-entity-agreement-api-response";
import UserNetworkInfoAPIResponse from "users/entities/user-network-connection/api/response-contracts/user-network-connection-profile-api-response";
import NetworkInvitationInfoAPIResponse from "users/entities/user-network-invitation/api/response-contracts/network-invitation-info-api-response";
import UserBookmarkInfoAPIResponse from "users/values/user-bookmark-info/api/response-contracts/user-bookmark-info-api-response";
import UserEntityInfoAPIResponse from "users/values/user-entity-info/api/response-contracts/user-entity-info-api-response";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import EmailAddress from "common/values/email-address/email-address";
import UserInformation from "users/values/user-information/user-information";

export default class UserInformationAPIResponse {
  id?: string;
  isSuperUser?: boolean;
  isVendor?: boolean;
  isClient?: boolean;
  viewingAsVendor?: boolean;
  isVendorRepresentative?: boolean;
  isClientRepresentative?: boolean;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  timestamp?: Date | string;
  legalEntities?: UserEntityInfoAPIResponse[];
  openEntityAgreements?: UserOpenEntityAgreementAPIResponse[];
  hasOpenEntityAgreements?: boolean;
  individualId?: string;
  networkConnections?: UserNetworkInfoAPIResponse[];
  networkInvitations?: NetworkInvitationInfoAPIResponse[];
  bookmarkedIndividuals?: UserBookmarkInfoAPIResponse[];
  bookmarkedCompanies?: UserBookmarkInfoAPIResponse[];
  bookmarkedTeams?: UserBookmarkInfoAPIResponse[];

  deserialize(): UserInformation {
    if (!this.id)
      throw new Error("Id is required, was not returned by the API");
    if (this.isVendor === undefined)
      throw new Error("isVendor is required, was not returned by the API");
    if (this.isClient === undefined)
      throw new Error("isClient is required, was not returned by the API");
    if (!this.firstName)
      throw new Error("First name is required, was not returned by the API");
    if (!this.lastName)
      throw new Error("Last name is required, was not returned by the API");
    if (!this.emailAddress)
      throw new Error("Email address is required, was not returned by the API");
    if (!this.timestamp)
      throw new Error("Timestamp is required, was not returned by the API");

    const deserializedLegalEntities =
      this.legalEntities?.map((legalEntity) => {
        const entity = Object.assign(
          new UserEntityInfoAPIResponse(),
          legalEntity
        );
        return entity.deserialize();
      }) ?? [];

    const deserializedNetworkConnections =
      this.networkConnections?.map((connection) => {
        const entity = Object.assign(
          new UserNetworkInfoAPIResponse(),
          connection
        );
        return entity.deserialize();
      }) ?? [];

    const deserializedNetworkInvitations =
      this.networkInvitations?.map((invitation) => {
        const entity = Object.assign(
          new NetworkInvitationInfoAPIResponse(),
          invitation
        );
        return entity.deserialize();
      }) ?? [];

    const deserializedOpenEntityAgreements =
      this.openEntityAgreements?.map((agreement) => {
        const entity = Object.assign(
          new UserOpenEntityAgreementAPIResponse(),
          agreement
        );
        return entity.deserialize();
      }) ?? [];

    const deserializedBookmarkedIndividuals =
      this.bookmarkedIndividuals?.map((bookmark) => {
        const entity = Object.assign(
          new UserBookmarkInfoAPIResponse(),
          bookmark
        );
        return entity.deserialize();
      }) ?? [];

    const deserializedBookmarkedCompanies =
      this.bookmarkedCompanies?.map((bookmark) => {
        const entity = Object.assign(
          new UserBookmarkInfoAPIResponse(),
          bookmark
        );
        return entity.deserialize();
      }) ?? [];

    const deserializedBookmarkedTeams =
      this.bookmarkedTeams?.map((bookmark) => {
        const entity = Object.assign(
          new UserBookmarkInfoAPIResponse(),
          bookmark
        );
        return entity.deserialize();
      }) ?? [];

    const id = new Guid(this.id);
    const isVendor = Boolean(this.isVendor);
    const isClient = Boolean(this.isClient);
    const isSuperUser = Boolean(this.isSuperUser);
    const viewingAsVendor = Boolean(
      this.viewingAsVendor == null
        ? this.isVendor && !this.isClient
        : this.viewingAsVendor
    );
    const isVendorRepresentative = Boolean(
      this.isVendorRepresentative ?? false
    );
    const isClientRepresentative = Boolean(
      this.isClientRepresentative ?? false
    );
    const fullName = new Name(this.firstName, this.lastName);
    const emailAddress = new EmailAddress(this.emailAddress);
    const timestamp = this.timestamp;
    const hasOpenEntityAgreements = this.hasOpenEntityAgreements ?? false;
    const legalEntities = deserializedLegalEntities;
    const openEntityAgreements = deserializedOpenEntityAgreements;
    const individualId = this.individualId
      ? new Guid(this.individualId)
      : undefined;
    const networkConnections = deserializedNetworkConnections;
    const networkInvitations = deserializedNetworkInvitations;
    const bookmarkedIndividuals = deserializedBookmarkedIndividuals;
    const bookmarkedCompanies = deserializedBookmarkedCompanies;
    const bookmarkedTeams = deserializedBookmarkedTeams;

    return new UserInformation(
      id,
      emailAddress,
      fullName,
      timestamp,
      legalEntities,
      openEntityAgreements,
      hasOpenEntityAgreements,
      networkConnections,
      networkInvitations,
      bookmarkedIndividuals,
      bookmarkedCompanies,
      bookmarkedTeams,
      isVendor,
      isClient,
      isVendorRepresentative,
      isClientRepresentative,
      viewingAsVendor,
      individualId,
      isSuperUser
    );
  }
}
