import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import UpdateEntityMemberAPIRequest from "legal-entities/entities/entity-member/api/request-contracts/update-entity-member-api-request";
import EntityMembershipsUserInfoAPIResponse from "legal-entities/entities/entity-member/api/response-contracts/entity-membership-user-info-api-response";
import EntityMember, { EntityMemberNotFoundException, UnauthorizedEntityMemberAccessError } from "legal-entities/entities/entity-member/entity-member";
import LegalEntity, { LegalEntityNotFoundError } from "legal-entities/entities/legal-entity/legal-entity";
import CreateUserEntityMemberInvitationAPIRequest from "legal-entities/entities/user-entity-member-invitation/api/request-contracts/create-user-entity-member-invitation-api-request";
import UserEntityMemberInvitation from "legal-entities/entities/user-entity-member-invitation/user-entity-member-invitation";
import MemberUserInfoAPIResponse from "./response-contracts/member-user-info-api-response";
import Session from "users/session/session";


export default class EntityMemberAPIService {
  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create EntityMemberAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async createUserEntityMemberInvitation(invitation: UserEntityMemberInvitation): Promise<UserEntityMemberInvitation> {
    const url = new URL("/legal-entities/user-entity-member-invitations", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new CreateUserEntityMemberInvitationAPIRequest(invitation),
        {
          headers: this.headers.concat({ "Content-Type": "application/json" })
        }
      );
      const invitationId = new Guid(response.data);
      invitation.id = invitationId;
      return invitation;
    } catch (error: any) {
      if (error.response?.status === 400)
        throw new InvalidInvitationError(error.response.data);
      throw new EntityMemberAPIServiceError("createUserEntityMemberInvitation", error);
    }
  }

  async getLegalEntityMembersUserInfo(legalEntity: LegalEntity, abortController?: AbortController): Promise<EntityMember[]> {
    const url = new URL(`/legal-entities/${legalEntity.id.value}/members/`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const entityMembers: EntityMember[] = [];
      const responseData = Object.assign(new EntityMembershipsUserInfoAPIResponse(), response.data);
      responseData.membershipUsers.forEach((userData: any) => {
        const entityMember = Object.assign(new MemberUserInfoAPIResponse(), userData).deserialize();
        entityMembers.push(entityMember);
      });
      return entityMembers;
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      if (error?.response?.status === 403)
        throw new UnauthorizedEntityMemberAccessError(legalEntity.id);
      if (error?.response?.status === 404)
        throw new LegalEntityNotFoundError(legalEntity.id);
      throw new EntityMemberAPIServiceError("getLegalEntityMembersUserInfo", error);
    }
  }

  async updateEntityMember(originalMember: EntityMember, updatedMember: EntityMember):  Promise<void> {
    const url = new URL(`/legal-entities/${originalMember.entityId.value}/members/${originalMember.memberId.value}`,
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.patch(
        url.toString(),
        new UpdateEntityMemberAPIRequest(originalMember, updatedMember).payload,
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      switch (error.response.status) {
        case 400:
        case 403:
          throw new InvalidMemberUpdateException(error.response.data);
        case 404:
          throw new EntityMemberNotFoundException(originalMember.entityId);
        default:
          throw new EntityMemberAPIServiceError("updateEntityMember", error);
      }
    }
  }
}

export class InvalidInvitationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InvalidInvitationError";
  }
}

export class InvalidMemberUpdateException extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InvalidMemberUpdateException";
  }
}

export class EntityMemberAPIServiceError extends Error {
  methodName: string;
  error: any;
  constructor(methodName: string, error: any) {
    super(`Error in EntityMemberAPIService.${methodName}: ${error.message}`);
    this.methodName = methodName;
    this.error = error;
  }
}