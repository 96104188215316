import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";

export default class MarketplaceIndividualPendingInvitation {
    id: Guid;
    userId: Guid;
    name: Name;
    avatarId?: Guid;
    invitationId: Guid;
    message: string;

    constructor(id: Guid, userId: Guid, name: Name, invitationId: Guid, message: string, avatarId?: Guid) {
        this.id = id;
        this.userId = userId;
        this.name = name;
        this.invitationId = invitationId;
        this.message = message;
        this.avatarId = avatarId;
    }
}
