import SaveIcon from '@mui/icons-material/Save';
import { Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from 'common/components/loading-button';
import Guid from 'common/values/guid/guid';
import Individual from 'marketplace/entities/individual/individual';
import MarketplaceIndividualPendingInvitation from 'marketplace/entities/marketplace-individual-pending-invitation/marketplace-individual-pending-invitation';
import MarketplaceTeamAPIService from 'marketplace/entities/marketplace-team/api/marketplace-team-api-service';
import MarketplaceTeam from 'marketplace/entities/marketplace-team/marketplace-team';
import IndividualProfile from 'marketplace/values/individual-profile/individual-profile';
import TeamProfile from 'marketplace/values/team-profile/team-profile';
import EditTeam, { TeamMemberInvite } from 'marketplace/values/team-profile/view/edit-team';
import TeamProfileCategoriesSelect from 'marketplace/values/team-profile/view/team-profile-categories-select';
import TeamProfileNameInput from 'marketplace/values/team-profile/view/team-profile-name-input';
import AvatarUpload from 'marketplace/view/avatar-upload';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router';
import { SetURLSearchParams } from 'react-router-dom';
import { useSession } from 'users/session/session-context';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(5, auto)',
  width: '100%'
}));
const HeaderContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '& > *': {
      margin: 0,
      padding: theme.spacing(0, 0, 3, 0),
      width: '100%',
    },
    '&:not(last-child)': {
      paddingBottom: theme.spacing(0.5)
    }
  },
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'row',
  gridRow: '1',
  justifyContent: 'center',
  padding: theme.spacing(3, 0, 4, 0),
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 10,
}));
const AboutYou = styled('section')(({ theme }) => ({
  gridRow: '3',
  marginTop: theme.spacing(1)
}));
const AboutYouTextField = styled(TextField)(({ theme }) => ({
  width: '100%'
}));
const AvatarNameContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}));
const RegisterToMarketplaceCheckbox = styled(FormControlLabel)(({ theme }) => ({
  whiteSpace: 'nowrap'
}));
const TeamDetails = styled('section')(({ theme }) => ({
  gridRow: '4',
  marginTop: theme.spacing(1)
}));
const EditTeamMembers = styled(EditTeam)(({ theme }) => ({
  gridRow: '2'
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  bottom: 0,
  gridRow: '5',
  justifySelf: 'flex-start',
  padding: theme.spacing(2, 0),
  position: 'sticky',
  width: '100%',
  zIndex: 10
}));
const LoadingContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%'
}));
const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: theme.spacing(2)
}));

export type EditTeamProfileProps = {
  isVisible?: boolean;
  teamId?: Guid;
  profile?: TeamProfile;
  navigate: NavigateFunction;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  onFormDirty?: (isDirty?: boolean) => void;
  onSave?: (team: MarketplaceTeam) => void;
}

export default function EditTeamProfile(props: Readonly<EditTeamProfileProps>) {
  const { 
    profile,
    navigate,
    searchParams,
    setSearchParams,
    onFormDirty,
    onSave
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [profileValidated, setProfileValidated] = useState<boolean>(false);
  
  const [teamId, setTeamId] = useState<Guid | undefined>(props.teamId);
  const [team, setTeam] = useState<MarketplaceTeam | undefined>();
  const [originalTeamProfile, setOriginalTeamProfile] = useState<TeamProfile | undefined>(profile);
  const [updatedTeamProfile, setUpdatedTeamProfile] = useState<TeamProfile | undefined>(profile);
  const [avatar, setAvatar] = useState<File | string>();

  const [isVisible, setIsVisible] = useState<boolean>(props.isVisible ?? false);
  const [formTeamName, setFormTeamName] = useState<string>(profile?.name ?? 'New Profile');
  const [formCategories, setFormCategories] = useState<string[]>(profile?.categories ?? []);
  const [formDescription, setFormDescription] = useState<string>(profile?.description ?? '');
  const [formRegisterToMarketplace, setFormRegisterToMarketplace] = useState<boolean>(isVisible ?? true);
  const [originalRegisterToMarketplace, setOriginalRegisterToMarketplace] = useState<boolean>(isVisible ?? true);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState<Individual>();
  const [leaderPromoted, setLeaderPromoted] = useState<boolean>(false);
  const [originalMembers, setOriginalMembers] = useState<Individual[]>([]);
  const [existingTeamMembers, setExistingTeamMembers] = useState<Individual[]>([]);
  const [invitedMembers, setInvitedMembers] = useState<TeamMemberInvite[]>([]);
  const [pendingInvites, setPendingInvites] = useState<MarketplaceIndividualPendingInvitation[]>([]);
  const [removedMemberUserIds, setRemovedMemberUserIds] = useState<Guid[]>([]);
  const [profileHash, setProfileHash] = useState<string>("");

  const session = useSession();

  const validateProfile = (profile: TeamProfile) => {
    if (!profile) return;
    const hasCategories: boolean = profile.categories !== undefined && profile.categories.length > 0;
    const hasName: boolean = profile.name.length > 0 && profile.name !== 'New Profile';
    setProfileValidated(hasName && hasCategories);
  }

  const dirty = (): boolean =>{
    if (!updatedTeamProfile) {
      onFormDirty?.(false);
      return false;
    }

    //compare original register to marketplace to updated register to marketplace
    if(formRegisterToMarketplace !== originalRegisterToMarketplace) {
      onFormDirty?.(true);
      return true;
    }

    if(removedMemberUserIds.length > 0) {
      onFormDirty?.(true);
      return true;    
    }

    if(invitedMembers.length > 0) {
      onFormDirty?.(true);
      return true;
    } 

    //compare profile hash to updated team profile hash
    const hash = JSON.stringify(updatedTeamProfile.toJSON());
    const isDirty = hash !== profileHash;
    onFormDirty?.(isDirty);
    return isDirty;
  }

  const canSave = (): boolean => (
    !loading &&
    !saving &&
    dirty() &&
    profileValidated &&
    selectedTeamLeader !== undefined
  );

  const loadProfile = useCallback(async () => {
    try {
      setLoading(true);

      if (!teamId) throw new Error('Team ID not found');

      const teamService = new MarketplaceTeamAPIService(session);
      const abortController = new AbortController();
      const returnedTeam = await teamService.getTeamById(teamId, abortController);

      if (!returnedTeam) throw new Error('Team not found');
      if (!returnedTeam.profile) throw new Error('Team profile not found');

      setTeam(returnedTeam);
      setOriginalTeamProfile(returnedTeam.profile);
      setUpdatedTeamProfile(returnedTeam.profile);
      setIsVisible(returnedTeam.isVisible);
      setFormTeamName(returnedTeam.profile.name);
      setSelectedTeamLeader(returnedTeam.leader);
      setExistingTeamMembers(returnedTeam.memberships);
      setOriginalMembers(returnedTeam.memberships);
      setFormCategories(returnedTeam.profile.categories ?? []);
      setFormDescription(returnedTeam.profile.description ?? '');

      validateProfile(returnedTeam.profile);

      const hash = JSON.stringify(returnedTeam.profile?.toJSON());
      setProfileHash(hash);

      if (returnedTeam.isVisible !== undefined) {
        setFormRegisterToMarketplace(returnedTeam.isVisible);
        setOriginalRegisterToMarketplace(returnedTeam.isVisible);
      }

      if (returnedTeam.profile.avatarId) {
        const returnedAvatar = await teamService.getTeamAvatar(teamId, abortController);
        setAvatar(returnedAvatar);
      }

      const pendingInvites = await teamService.getTeamInvitationsByTeamId(teamId, abortController);
      if (pendingInvites.length > 0) {
        setPendingInvites(pendingInvites);
      }
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Unable to load profile', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [teamId]);

  useEffect(() => {
    if (!teamId) {
      return constructNewTeamProfile();
    }
    loadProfile();
  }, [loadProfile, teamId]);

  function constructNewTeamProfile() {
    if (!session.user?.id) throw new Error("User not found");
    if (session.user?.individualId === undefined) throw new Error("Individual not found");

    setOriginalTeamProfile(new TeamProfile("New Profile"));

    let individualProfile: IndividualProfile | undefined;
    if (session.user.name?.firstName && session.user.name?.lastName) {
      individualProfile = new IndividualProfile(session.user.id, session.user.name.firstName, session.user.name.lastName);
    }
    const leader = new Individual(session.user.individualId, session.user.id, individualProfile);
    setSelectedTeamLeader(leader);
  }

  function constructTeam(): MarketplaceTeam {
    if (!updatedTeamProfile) throw new Error("Updated Team profile not found");
    if (!selectedTeamLeader) throw new Error("Team leader not found");

    return new MarketplaceTeam(selectedTeamLeader, updatedTeamProfile, formRegisterToMarketplace, []);
  }

  async function handleSaveButtonClick() {
    let updatedTeam: MarketplaceTeam | undefined;

    try {
      setSaving(true);

      if (teamId)
        updatedTeam = await updateProfile();
      else
        updatedTeam = await createProfile();

      if (!updatedTeam.id) throw new Error('Failed to save profile');

      await removeMembers(updatedTeam.id);
      await inviteMembers(updatedTeam.id);
      
      if (leaderPromoted) {
        await promoteMemberToLeader(updatedTeam.id);
      }

      const teamService = new MarketplaceTeamAPIService(session);
      const abortController = new AbortController();
      const invites = await teamService.getTeamInvitationsByTeamId(updatedTeam.id, abortController);
      
      setInvitedMembers([]);
      setPendingInvites(invites ?? []);
      setRemovedMemberUserIds([]);

      setTeamId(updatedTeam.id);
      setFormRegisterToMarketplace(updatedTeam.isVisible);
      setOriginalRegisterToMarketplace(updatedTeam.isVisible);
      setOriginalMembers(updatedTeam.memberships);
      setProfileHash(JSON.stringify(updatedTeam.profile.toJSON()));

      enqueueSnackbar('Profile changes saved', { variant: 'success' });
      onSave?.(updatedTeam);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to save profile', { variant: 'error' });
    } finally {
      setSaving(false);
    }
  }

  async function updateProfile(): Promise<MarketplaceTeam> {
    try {
      if (!team) throw new Error('Team not found');
      if (!updatedTeamProfile) throw new Error('Updated team profile not found');

      const updatedTeam = constructTeam();
      const teamService = new MarketplaceTeamAPIService(session);
      const teamResponse = await teamService.updateTeam(team, updatedTeam);
      setTeam(teamResponse);
      setOriginalTeamProfile(teamResponse.profile);
      setUpdatedTeamProfile(teamResponse.profile);
      
      return teamResponse;
    } catch (error: any) {
      console.error(error);
      throw new Error('Unable to update profile');
    }
  }

  async function createProfile(): Promise<MarketplaceTeam> {
    try {
      const teamToCreate = constructTeam();
      const teamService = new MarketplaceTeamAPIService(session);
      const team = await teamService.createTeam(teamToCreate);

      if (!team.id) throw new Error('Unable to create team');

      setTeam(team);
      setOriginalTeamProfile(team.profile);
      setUpdatedTeamProfile(team.profile);
      if (avatar) {
        await createAvatar(team.id, avatar as File);
      }

      return team;
    } catch (error: any) {
      console.error(error);
      throw new Error('Unable to create profile');
    }
  }

  async function createAvatar(teamId: Guid, avatar: File) {
    if (!teamId || !avatar) return;

    try {
      const teamService = new MarketplaceTeamAPIService(session);
      await teamService.createTeamAvatar(teamId, avatar);
    } catch (error: any) {
      console.error(error);
      throw new Error('Unable to create avatar');
    }
  }

  async function removeMembers(teamId: Guid) {
    for (const userId of removedMemberUserIds) {
      try {
        const pendingInvite = pendingInvites.find(invite => invite.userId.isEqualTo(userId));
        const teamService = new MarketplaceTeamAPIService(session);
        if (pendingInvite) {
          await teamService.cancelTeamInvitation(pendingInvite.invitationId);
          continue;
        }
        await teamService.removeTeamMember(teamId, userId);
      } catch (error: any) {
        console.error(error);
        throw new Error(`Unable to remove member with userId ${userId}`);
      }
    }
  }

  async function inviteMembers(teamId: Guid) {
    const teamService = new MarketplaceTeamAPIService(session);
    for (const member of invitedMembers) {
      await teamService.createTeamInvitation(member.id, teamId, member.message);
    }
  }

  async function promoteMemberToLeader(teamId: Guid) {
    const memberToPromote = team?.memberships.find(member => member.userId?.isEqualTo(team.leader.userId));
    if (memberToPromote?.userId) {
      const teamService = new MarketplaceTeamAPIService(session);
      await teamService.promoteTeamMember(teamId, memberToPromote.userId);
    }
    setLeaderPromoted(false);
  }

  function handleMemberAdded(newMember: TeamMemberInvite) {
    if (invitedMembers.find(member => member.userId?.isEqualTo(newMember.userId))) return;
    setInvitedMembers([...invitedMembers, newMember]);
  }

  function handleMemberUpdated(newMember: TeamMemberInvite) {
    const updatedMembers = invitedMembers.map(member => {
      if (member.userId?.isEqualTo(newMember.userId)) return newMember;
      return member;
    });
    setInvitedMembers(updatedMembers);
  }

  function handleMemberRemoved(userId: Guid) {
    setRemovedMemberUserIds([...removedMemberUserIds, userId]);
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <AvatarNameContainer>
          <AvatarUpload
            accept="image/jpeg, image/png"
            id={teamId}
            type="team"
            avatar={avatar}
            onUpload={(updatedAvatar) => setAvatar(updatedAvatar)}
            session={session}
          />
          <TeamProfileNameInput
            name={loading ? 'Loading' : formTeamName}
            error={formTeamName === 'New Profile' && !loading}
            onNameChange={(name: string) => {
              setFormTeamName(name);
              if (!originalTeamProfile) return;
              let updatedProfile = originalTeamProfile.clone();
              updatedProfile = updatedProfile.updateName(name);
              setUpdatedTeamProfile(updatedProfile);
              validateProfile(updatedProfile);
            }}
          />
        </AvatarNameContainer>
        <TeamProfileCategoriesSelect
          categories={formCategories}
          onChange={async (updatedCategories: string[]) => {
            setFormCategories(updatedCategories);
            if (!originalTeamProfile) return;
            let updatedProfile = originalTeamProfile.clone();
            updatedProfile = updatedProfile.updateCategories(updatedCategories);
            setUpdatedTeamProfile(updatedProfile);
            validateProfile(updatedProfile);
          }}
        />
      </HeaderContainer>
      {loading && (
        <LoadingContainer>
          <LoadingSpinner />
          <Typography variant="h6">Loading...</Typography>
        </LoadingContainer>
      )}
      {!loading && (
        <>
          <EditTeamMembers
            navigate={navigate}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            leader={selectedTeamLeader}
            members={existingTeamMembers}
            invited={invitedMembers}
            pending={pendingInvites}
            removedUserIds={removedMemberUserIds}
            onMemberAdded={handleMemberAdded}
            onMemberUpdated={handleMemberUpdated}
            onMemberRemoved={handleMemberRemoved}
          />
          <AboutYou>
            <Typography variant="h6">
              About Your Team
            </Typography>
            <AboutYouTextField
              value={formDescription}
              onChange={(event) => {
                setFormDescription(event.target.value);
                if (!originalTeamProfile) return;
                let updatedProfile = originalTeamProfile.clone();
                updatedProfile = updatedProfile.updateDescription(event.target.value);
                setUpdatedTeamProfile(updatedProfile);
                validateProfile(updatedProfile);
              }}
              placeholder='Additional info displayed on your profile'
              variant="outlined"
              multiline
            />
          </AboutYou>
          <TeamDetails>
            <RegisterToMarketplaceCheckbox
              label="Show Team On Marketplace"
              labelPlacement="end"
              control={
                <Checkbox
                  color="primary"
                  checked={formRegisterToMarketplace}
                  onChange={(event) => {
                    setFormRegisterToMarketplace(event.target.checked);
                    onFormDirty?.(true);
                  }}
                />
              }
            />
          </TeamDetails>
        </>
      )}
      <ButtonContainer>
        <LoadingButton
          id="saveButton"
          variant="contained"
          startIcon={<SaveIcon />}
          loading={saving}
          color="primary"
          disabled={!canSave()}
          onClick={handleSaveButtonClick}>
          {teamId ? 'Save Changes' : 'Create Profile'}
        </LoadingButton>
      </ButtonContainer>
    </MainContainer >
  );
}
