import { Change } from "diff";

export enum ChangeType {
  added,
  removed,
  unchanged
}

export type DiffResult = {
  originalText: string;
  updatedText: string;
  updatedChanges?: Change[];
};

export default class DiffChanges {
  public static allChangesResolved(changes: Change[]): boolean {
    return changes.every(change => !change.added && !change.removed);
  }

  public static getChangeType(change: Change): ChangeType {
    if (change?.removed) return ChangeType.removed;
    if (change?.added) return ChangeType.added;

    return ChangeType.unchanged;
  }

  public static isModified(changes: Change[], index: number): boolean {
    const previousChange = changes[index - 1];
    const currentChange = changes[index];
    const nextChange = changes[index + 1];

    return (
      ((currentChange?.removed ?? false) && (nextChange?.added ?? false)) ||
      ((previousChange?.removed ?? false) && (currentChange?.added ?? false))
    );
  }

  public static isAdded(changes: Change[], index: number): boolean {
    const previousChange = changes[index - 1];
    const currentChange = changes[index];

    return (
      (currentChange?.added ?? false)
      && !previousChange?.added
      && !previousChange?.removed
    );
  }

  public static isRemoved(changes: Change[], index: number): boolean {
    const previousChange = changes[index - 1];
    const currentChange = changes[index];

    return (
      (currentChange?.removed ?? false)
      && !previousChange?.added
      && !previousChange?.removed
    );
  }

  public static acceptChange(changes: Change[], index: number): DiffResult {
    const updatedChanges = [...changes];
    const previousIndex = index - 1;
    const nextIndex = index + 1;

    if (DiffChanges.isModified(changes, index)) {
      // The previous group is also modified, merge the change
      if (DiffChanges.isModified(changes, previousIndex)) {
        const newChange: Change = {
          added: false,
          removed: false,
          value: changes[index].value
        };
        updatedChanges.splice(previousIndex, 2, newChange);
      }
      // The next group is also modified, merge the change
      if (DiffChanges.isModified(changes, nextIndex)) {
        const newChange: Change = {
          added: false,
          removed: false,
          value: changes[nextIndex].value
        };
        updatedChanges.splice(index, 2, newChange);
      }
    } else {
      if (DiffChanges.isRemoved(changes, index)) {
        updatedChanges.splice(index, 1);
      }
      if (DiffChanges.isAdded(changes, index)) {
        const newChange = {
          added: false,
          removed: false,
          value: changes[index].value
        };
        updatedChanges.splice(index, 1, newChange);
      }
    }

    const { originalText, updatedText } = this.getDiffResult(updatedChanges);

    return { originalText, updatedText, updatedChanges };
  }

  public static rejectChange(changes: Change[], index: number): DiffResult {
    const updatedChanges = [...changes];
    const previousIndex = index - 1;
    const nextIndex = index + 1;

    if (DiffChanges.isModified(changes, index)) {
      // The previous group is also modified, merge the original
      if (DiffChanges.isModified(changes, previousIndex)) {
        const newChange: Change = {
          added: false,
          removed: false,
          value: changes[previousIndex].value
        };
        updatedChanges.splice(previousIndex, 2, newChange);
      }
      // The next group is also modified, merge the original
      if (DiffChanges.isModified(changes, nextIndex)) {
        const newChange: Change = {
          added: false,
          removed: false,
          value: changes[index].value
        };
        updatedChanges.splice(index, 2, newChange);
      }
    } else {
      if (DiffChanges.isRemoved(changes, index)) {
        const newChange = {
          added: false,
          removed: false,
          value: changes[index].value
        };
        updatedChanges.splice(index, 1, newChange);
      }
      if (DiffChanges.isAdded(changes, index)) {
        updatedChanges.splice(index, 1);
      }
    }

    const { originalText, updatedText } = this.getDiffResult(updatedChanges);

    return { originalText, updatedText, updatedChanges };
  }

  public static editChange(changes: Change[], index: number, newValue: string): DiffResult {
    const updatedChanges: Change[] = [...changes];
    const newChange: Change = {
      added: false,
      removed: false,
      value: newValue
    };

    if (DiffChanges.isModified(changes, index)) {
      const currentIndex = index;
      const previousIndex = index - 1;
      const nextIndex = index + 1;

      // The previous group is also modified, merge the change
      if (DiffChanges.isModified(changes, previousIndex)) {
        updatedChanges.splice(previousIndex, 2, newChange);
      }
      // The next group is also modified, merge the change
      if (DiffChanges.isModified(changes, nextIndex)) {
        updatedChanges.splice(currentIndex, 2, newChange);
      }
    } else {
      updatedChanges.splice(index, 1, newChange);
    }

    const {originalText, updatedText} = this.getDiffResult(updatedChanges);

    return { originalText, updatedText, updatedChanges };
  }

  private static getDiffResult(updatedChanges: Change[]): DiffResult {
    const originalText = updatedChanges
      .filter(change => !change.added)
      .map(change => change.value)
      .join('');
    const updatedText = updatedChanges
      .filter(change => !change.removed)
      .map(change => change.value)
      .join('');

    return { originalText, updatedText };
  }
}
