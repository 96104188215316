import { SetURLSearchParams, useSearchParams } from "react-router-dom";

type UseSearchParamsProps = {
  onSearchParams: (searchParams: URLSearchParams, setSearchParams: SetURLSearchParams) => void
  onError?: (error: any) => void
}

export const UseSearchParams = (props: UseSearchParamsProps) => {
  try {
    const [searchParams, setSearchParams] = useSearchParams();
    props.onSearchParams(searchParams, setSearchParams);
  } catch (error: any) {
    props.onError?.(error);
  }
  return null;
}
