import SortIcon from '@mui/icons-material/Sort';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';


export interface SortButtonProps {
  sortBy: string;
  onSort: (value: string) => void;
}

export default function SortButton(props: Readonly<SortButtonProps>) {

  const [sortByAnchor, setSortByAnchor] = useState<null | HTMLElement>(null);

  const sortBy = (event: React.MouseEvent<HTMLElement>) => {
    setSortByAnchor(null);
    props.onSort(event.currentTarget.getAttribute('value') ?? '');
  };

  return (
    <React.Fragment>
      <Button
        size="large"
        color="primary"
        startIcon={<SortIcon />}
        onClick={(event) => setSortByAnchor(event.currentTarget)}>
        Sort
      </Button>
      <Menu
        anchorEl={sortByAnchor}
        open={Boolean(sortByAnchor)}
        onClose={() => setSortByAnchor(null)}
        keepMounted={true}>
        <MenuItem key="relevance" value="relevance">
          Relevance
        </MenuItem>
        <MenuItem key="ratingDesc" value="Rating desc" onClick={sortBy}>
          Rating: High To Low
        </MenuItem>
        <MenuItem key="ratingAsc" value="Rating asc" onClick={sortBy}>
          Rating: Low To High
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="rateAsc" value="BillingRate asc" onClick={sortBy}>
          Billing Rate: Low To High
        </MenuItem>
        <MenuItem key="rateDesc" value="BillingRate desc" onClick={sortBy}>
          Billing Rate: High To Low
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="applicationsAsc" value="ApplicationsDrafted asc" onClick={sortBy}>
          Applications Drafted: Low To High
        </MenuItem>
        <MenuItem key="applicationsDesc" value="ApplicationsDrafted desc" onClick={sortBy}>
          Applications Drafted: High To Low
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="experienceAsc" value="YearsExperience asc" onClick={sortBy}>
          Years Experience: Low To High
        </MenuItem>
        <MenuItem key="experienceDesc" value="YearsExperience desc" onClick={sortBy}>
          Years Experience: High To Low
        </MenuItem>
        <Divider light={true} variant="middle" />
        <MenuItem key="distanceAsc" value="Distance asc" onClick={sortBy}>
          Distance: Near To Far
        </MenuItem>
        <MenuItem key="distanceDesc" value="Distance desc" onClick={sortBy}>
          Distance: Far To Near
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
