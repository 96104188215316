import EntityOfficerInvitation from "legal-entities/entities/entity-officer-invitation/entity-officer-invitation";
import EntityName from "legal-entities/values/entity-name/entity-name";
import InvitationMessage from "legal-entities/values/invitation-message/invitation-message";
import RegistrationInfo from "legal-entities/values/registration-info";
import { InvitationStatus } from "legal-entities/values/invitation-status";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";


export default class OpenEntityOfficerInvitationAPIResponse {
    id?: string;
    entityName?: string;
    emailAddress?: string;
    firstName?: string;
    lastName?: string;
    message?: string;
    userId?: string;
    status?: string;

    deserialize(): EntityOfficerInvitation {
        if(!this.id) throw new Error("Invalid OpenEntityOfficerInvitationAPIResponse: id is required");
        if(!this.entityName) throw new Error("Invalid OpenEntityOfficerInvitationAPIResponse: entityName is required");
        if(!this.emailAddress) throw new Error("Invalid OpenEntityOfficerInvitationAPIResponse: emailAddress is required");
        if(!this.firstName || !this.lastName) throw new Error("Invalid OpenEntityOfficerInvitationAPIResponse: firstName and lastName are required");
        if(!this.status) throw new Error("Invalid OpenEntityOfficerInvitationAPIResponse: status is required");
        
        const invitation = new EntityOfficerInvitation(
            new EntityName(this.entityName),
            new RegistrationInfo(new Name(this.firstName, this.lastName), new EmailAddress(this.emailAddress)),
            this.message ? new InvitationMessage(this.message) : undefined,
            InvitationStatus[this.status as keyof typeof InvitationStatus]
        )
        invitation.id = new Guid(this.id);
        return invitation;
    }
}
