import MarketplaceCompanyInfoAPIResponse from 'marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response';
import MarketplaceIndividualInfoAPIResponse from 'marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response';
import MarketplaceResponseAPIResponse from 'marketplace/api/response-contracts/marketplace-response-api-response';


export default class MarketplaceSearchInfoAPIResponse extends MarketplaceResponseAPIResponse {
    name?: string;
    yearEstablished?: number;
    numberOfEmployees?: number;
    contact?: MarketplaceIndividualInfoAPIResponse;
    description?: string;
    location?: string;
    website?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    school?: string;
    avatar?: string;
    resume?: string;
    professionalRegistration?: string;
    technicalBackground?: string;
    numberOfApplicationsDraftedProsecuted?: number;
    numberOfAllowedApplications?: number;
    barAdmissionYear?: number;
    isOnCall?: boolean;
    insuredAmount?: number;
    billingRate?: string;
    potentialDiversityCandidate?: boolean;
    collaborates?: boolean;
    additionalInfo?: string;
    links?: string[];
    professionalPublications?: string[];
    sampleApplications?: string[];
    fluentLanguages?: string[];
    technicalLanguages?: string[];
    regionsRegistered?: string[];
    leader?: MarketplaceIndividualInfoAPIResponse;
    members?: MarketplaceIndividualInfoAPIResponse[];
    totalMembers?: number;
    associatedFirms?: MarketplaceCompanyInfoAPIResponse[];
    totalFirms?: number;
    companyId?: string;
    companyEntityId?: string;
    companyName?: string;
    userId?: string;
    isVendorRep?: boolean;
}
