import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const Main = styled(Container)(() => ({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  verticalAlign: 'middle'
}));

export type RouteError = {
  status: number;
  statusText: string;
};

type Props = {
  error?: RouteError;
};

export default function ErrorPage(props: Readonly<Props>) {
  let error = useRouteError();

  if (props.error) 
    error = props.error;

  function getErrorText(error: any): string {
    if (props.error || isRouteErrorResponse(error)) {
      return `${error.status} ${error.statusText}`;
    }
    return `${error?.message ?? 'Unknown Error'}`;
  }

  return (
    <Main>
      <Typography variant="h2">{getErrorText(error)}</Typography>
    </Main>
  );
}
