import { TextField, TextFieldVariants } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef, useState, useEffect } from "react";

import { InvalidURLError } from "common/errors";

const InputField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    width: '100%'
  },
  marginBottom: theme.spacing(1.5),
  minWidth: theme.spacing(40),
  ":first-of-type": {
    marginRight: theme.spacing(1.5)
  }
}));

export interface URLInputError extends String { };

type URLInputProps = {
  className?: string;
  variant?: TextFieldVariants;
  required?: boolean;
  url?: string;
  onChange?: (URL: string, error?: URLInputError) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

const URLInput = forwardRef((props: URLInputProps, ref) => {
  const { className, variant, required, url, onChange, onKeyDown } = props;

  const [URLFieldText, setURLFieldText] = useState<string>(url ?? '');
  const [URLFieldError, setURLFieldError] = useState<URLInputError>('');

  useEffect(() => {
    setURLFieldText(url ?? '');
  }, [url]);

  function buildURL(inputText?: string): URL | undefined {
    try {
      if (!inputText && required) throw new InvalidURLError("URL address is required");
      else if (!inputText) return;

      const validUrlTextRegEx: RegExp = /^https?:\/\//;
      const urlValid: boolean = validUrlTextRegEx.test(inputText);

      if (!urlValid) throw new InvalidURLError("URL must start with http:// or https://");

      return new URL(inputText);
    } catch (error: any) {
      if (error instanceof InvalidURLError) {
        throw error;
      }
      if (error instanceof TypeError) {
        throw new InvalidURLError("Invalid URL address");
      }
    }
  }

  function handleURLChanged(urlText: string) {
    try {
      const url = buildURL(urlText);
      if (url) {
        setURLFieldError('');
        onChange?.(urlText);
      }
    } catch (error: any) {
      setURLFieldError(error.message);
      onChange?.(urlText, error.message);
    }
  }

  return (
    <InputField
      className={className}
      label="Website URL"
      required={required}
      onChange={(event) => {
        setURLFieldText(event.target.value);
        handleURLChanged(event.target.value);
      }}
      value={URLFieldText}
      error={!!URLFieldError}
      helperText={URLFieldError}
      variant={variant}
      onKeyDown={onKeyDown}
    />
  );
});

export default URLInput;
