import { BoxType } from "notifications/values/box-type/box-type";

export default class NotificationParameters {
  boxType: BoxType;
  filterByActionRequired: boolean;
  filterByUnread: boolean;
  filterByMessageNotifications: boolean;
  topicId?: string;

  constructor(
    boxType: BoxType, 
    filterByActionRequired: boolean,
    filterByUnread: boolean,
    filterByMessageNotifications: boolean,
    topicId?: string
    ) {
    this.boxType = boxType;
    this.filterByActionRequired = filterByActionRequired;
    this.filterByUnread = filterByUnread;
    this.filterByMessageNotifications = filterByMessageNotifications;
    this.topicId = topicId;
  }
}
