import { Slider } from '@mui/material';
import React, { useEffect } from 'react';

type FilterSliderProps = {
  filterName: string;
  value: number | number[];
  min?: number;
  max?: number;
  step?: number;
  onChange: (filterName: string, value: number | number[]) => void;
};

export default function FilterSlider(props: Readonly<FilterSliderProps>) {
  const {
    filterName,
    min,
    max,
    step,
    onChange
  } = props;

  const [value, setValue] = React.useState<number | number[]>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Slider
      name={filterName}
      size="small"
      valueLabelDisplay="auto"
      value={value}
      onChange={(_event, value) => {
        setValue(value);
      }}
      onChangeCommitted={(event, value) => {
        onChange(filterName, value)
      }}
      min={min}
      max={max}
      step={step}
      sx={{ width: '100%' }}
    />
  )
}
