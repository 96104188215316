import CancelIcon from '@mui/icons-material/Cancel';
import {
  Badge, IconButton, styled, Tooltip
} from '@mui/material';
import { defaultStyles, FileIcon } from 'react-file-icon';

const AttachmentContainer = styled('figure')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    maxHeight: theme.spacing(10),
    maxWidth: theme.spacing(10)
  },
  margin: theme.spacing(1),
  maxHeight: theme.spacing(15),
  maxWidth: theme.spacing(15),
  textAlign: 'center'
}));
const AttachmentPreview = styled('img')(({ theme }) => ({
  margin: 0,
  maxHeight: '100px',
  width: '100%'
}));
const NonImgAttachmentPreview = styled('div')(({ theme }) => ({
  height: 'auto',
  margin: 0,
  width: theme.spacing(8)
}));
const AttachmentName = styled('figcaption')(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  overflow: 'hidden',
  textAlign: 'center',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%'
}));
const RemoveButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.error.main,
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.common.white
  }
}));

export type FileAttachment = {
  url: string;
  file: File;
  fileType: string;
};

type AttachmentProps = {
  attachment: FileAttachment;
  onAttachmentRemoved: () => void;
};

export default function Attachment(props: AttachmentProps) {
  const { attachment, onAttachmentRemoved } = props;

  return (
    <AttachmentContainer>
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        overlap='rectangular'
        badgeContent={
          <Tooltip title='Remove Attachment'>
            <span>
              <RemoveButton
                size='small'
                onClick={() => onAttachmentRemoved()}>
                <CancelIcon fontSize='small' />
              </RemoveButton>
            </span>
          </Tooltip>
        }>
        {attachment.file.type.startsWith('image/') && (
          <AttachmentPreview alt="attachment" src={attachment.url} />
        )}
        {!attachment.file.type.startsWith('image/') && (
          <NonImgAttachmentPreview>
            <FileIcon
              extension={attachment.file.name.split('.')[1]}
              {...defaultStyles[attachment.file.name.split('.')[1] as keyof typeof defaultStyles]}
            />
          </NonImgAttachmentPreview>
        )}
      </Badge>
      <AttachmentName>
        {attachment.file.name}
      </AttachmentName>
    </AttachmentContainer>
  )
}
