import { Currency } from "common/values/currency/currency";

export default class InsuranceInfo {
    insuredAmount: number;
    currency?: Currency;

    constructor(amount: number, currency?: Currency) {
        this.insuredAmount = amount;
        this.currency = currency ?? Currency.USD;
    }

    public static fromObject(object: any) {
        if (!object) return;
        const { insuredAmount, currency } = object;
        return new InsuranceInfo(
            insuredAmount, 
            Currency[currency as keyof typeof Currency]
        );
    }

    public toJSON() {
        return {
            insuredAmount: this.insuredAmount,
            currency: this.currency
        };
    }
}
