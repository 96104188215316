export default class ForumName {
    private _value: string;
    
    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: ForumName): boolean {
        return this.value === other?.value;
    }
    
    public get value(): string {
        return this._value;
    }
    
    public static get Prototype(): ForumName {
        return new ForumName("");
    }
    
    public fromObject(obj: any): ForumName {
        return new ForumName(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): ForumName {
        return new ForumName(this.value);
    }
}