import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";

export default class VendorRepresentativeAPIRequest {
    userId?: string;
    entityId?: string;

    constructor(rep: EntityVendorRepresentative){
        this.userId = rep.userId.value;
        this.entityId = rep.entityId.value;
    }

    public get payload(): object {
        return {
            userId: this.userId,
            entityId: this.entityId
        };
    }
}
