import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import Topic from "messaging/values/topic";
import moment from "moment";

export default class ForumAPIResponse {
    id?: string;
    name?: string; 
    entityClass?: string; 
    entityId?: string; 
    context?: string; 
    createdDate?: string; 
    subscribers?: string[];
    creator?: string; 

    deserialize(): Forum {
        if(!this.id) 
            throw new Error("Forum id not returned.");

        const topic = new Topic(this.entityClass,this.entityId ? new Guid(this.entityId) : undefined, this.context);
        return new Forum(
            this.name,
            topic,
            this.subscribers?.map((s) => new Guid(s)),
            new Guid(this.id),
            this.creator ? new Guid(this.creator) : undefined,
            moment(this.createdDate)
        );
    }
}
