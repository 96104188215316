import Individual from 'marketplace/entities/individual/individual';
import TeamProfile from "marketplace/values/team-profile/team-profile";
import CompanyProfile from 'marketplace/values/company-profile/company-profile';
import Guid from 'common/values/guid/guid';

export default class MarketplaceTeam {
  id?: Guid;
  leader: Individual;
  profile: TeamProfile;
  isVisible: boolean;
  memberships: Individual[];
  associatedFirms: CompanyProfile[];

  constructor(
    leader: Individual,
    profile: TeamProfile,
    isVisible: boolean,
    memberships: Individual[],
    associatedFirms?: CompanyProfile[],
  ) {
    this.leader = leader;
    this.profile = profile;
    this.isVisible = isVisible;
    this.memberships = memberships;
    this.associatedFirms = associatedFirms ?? [];
  }
}
