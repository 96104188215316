import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import EntityMember from "legal-entities/entities/entity-member/entity-member";


export default class UpdateEntityMemberAPIRequest {
    payload: JsonPatchOperation[] = []

    constructor(originalMember: EntityMember, updatedMember: EntityMember) {
        if(originalMember.isActive !== updatedMember.isActive) {
            this.payload.push({
                op: 'replace',
                path: '/IsActive',
                value: updatedMember.isActive
            })
        }
        if(originalMember.isAdmin !== updatedMember.isAdmin) {
            this.payload.push({
                op: 'replace',
                path: '/IsEntityAdmin',
                value: updatedMember.isAdmin
            })
        }
    }
}
