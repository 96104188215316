import React, { useEffect, useState } from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const AddSubjectButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));
const SubjectTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  margin: theme.spacing(0.5, 0),
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderRadius: 0
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
}));

type SubjectComponentProps = {
  forceReset?: boolean;
  onSubjectChanged: (value: string) => void;
}

export default function SubjectButton(props: SubjectComponentProps) {
  const { forceReset, onSubjectChanged } = props;

  const [addingSubject, setAddingSubject] = useState(false);
  const [subject, setSubject] = useState('');

  useEffect(() => {
    if (forceReset) {
      setAddingSubject(false);
      setSubject('');
      onSubjectChanged('');
    }
  }, [forceReset, onSubjectChanged]);

  return (
    <React.Fragment>
      {!addingSubject && (
        <AddSubjectButton
          size='small'
          onClick={() => setAddingSubject(true)}>
          Add Subject
        </AddSubjectButton>
      )}
      {addingSubject && (
        <SubjectTextField
          placeholder='Subject'
          value={subject}
          size='small'
          onChange={(event) => {
            setSubject(event.target.value);
            onSubjectChanged(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                size='small'
                onClick={() => {
                  setAddingSubject(false);
                  setSubject('');
                }}>
                <CloseIcon />
              </IconButton>
            )
          }}
        />
      )}
    </React.Fragment>
  );
}
