import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Popover, TextField } from '@mui/material';
import lightblue from '@mui/material/colors/lightBlue';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DateField } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect } from 'react';
import { NumberFormatValues, NumericFormat, SourceInfo } from 'react-number-format';
import TextChange from 'work/entities/proposal/redlining/_diff/text-change';

const PopoverContainer = styled('div')(({ theme }) => ({
  minWidth: '10rem',
  padding: theme.spacing(1.5)
}));
const ChangesPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPaper-root': {
    pointerEvents: 'auto'
  }
}));
const ComparisonContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));
const ChangedText = styled(Typography)(({ theme }) => ({
  color: lightblue[500],
  fontSize: '0.9em',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1em',
  textAlign: 'center',
  width: '100%'
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  width: '100%'
}));
const EditContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

type DiffChangePopoverProps = {
  className?: string;
  anchorElement: Element | null;
  change: TextChange;
  valueType?: 'text' | 'date' | 'percent';
  disableEdit?: boolean;
  onClosePopover: () => void;
  onEdit?: (change: TextChange, newValue: string) => void;
  onAccept?: (change: TextChange) => void;
  onReject?: (change: TextChange) => void;
}

export default function DiffChangePopover(props: Readonly<DiffChangePopoverProps>) {
  const {
    className,
    change,
    valueType,
    disableEdit,
    onClosePopover,
    onAccept,
    onEdit,
    onReject
  } = props;

  const [anchorElement, setAnchorElement] = React.useState<Element | null | undefined>(props.anchorElement);
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [currentText, setCurrentText] = React.useState<string>(
    (change.isModified ? change?.nextChange?.diff.value : change?.diff.value) ?? '');
  const [currentNumber, setCurrentNumber] = React.useState<number>();

  function handleDiffPopoverClose() {
    setAnchorElement(null);
    setHasFocus(false);
    setIsEditing(false);
    setCurrentText((change.isModified ? change?.nextChange?.diff.value : change?.diff.value) ?? '');
    if (valueType === 'percent') setCurrentNumber(Number((change.isModified ? change?.nextChange?.diff.value : change?.diff.value)) * 100);
  }

  useEffect(() => {
    setCurrentText((change.isModified ? change?.nextChange?.diff.value : change?.diff.value) ?? '');
    if (valueType === 'percent') setCurrentNumber(Number((change.isModified ? change?.nextChange?.diff.value : change?.diff.value)) * 100);
  }, [change]);

  useEffect(() => {
    if (isEditing) return;

    if (!props.anchorElement) {
      if (!hasFocus) handleDiffPopoverClose();
      return;
    }
    setAnchorElement(props.anchorElement ?? undefined);
  }, [props.anchorElement, hasFocus, isEditing]);

  function handleEdit(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();

    if (!change) {
      console.error('change is null');
      return;
    }
    if (isEditing) {
      handleDiffPopoverClose();
      onEdit?.(change, valueType === 'percent' ? currentNumber?.toString() ?? '' : currentText);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }

  return (
    change &&
    <ChangesPopover
      id='changes-popover'
      className={className}
      open={Boolean(anchorElement)}
      anchorReference="anchorEl"
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      slotProps={{
        paper: {
          onMouseEnter: () => setHasFocus(true),
          onMouseLeave: onClosePopover
        }
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <PopoverContainer>
        <ComparisonContainer>
          {change.isModified && (
            <>
              <div>
                <Typography variant='overline'>was:</Typography>
                <ChangedText>
                  {valueType === 'percent' ?
                    `${Number(Number.parseFloat(change.diff.value) * 100).toFixed(2)}%` :
                    change.diff.value}
                </ChangedText>
              </div>
              <div>
                <Typography variant='overline'>now:</Typography>
                <ChangedText>
                  {valueType === 'percent' ?
                    `${Number(Number.parseFloat(change.nextChange?.diff.value ?? '0') * 100).toFixed(2)}%` :
                    change.nextChange?.diff.value}
                </ChangedText>
              </div>
            </>
          )}
          {!change.isModified && change.isAdded && (
            <div>
              <Typography variant='overline'>added:</Typography>
              <ChangedText>{change.diff.value}</ChangedText>
            </div>
          )}
          {!change.isModified && change.isRemoved && (
            <div>
              <Typography variant='overline'>removed:</Typography>
              <ChangedText>{change.diff.value}</ChangedText>
            </div>
          )}
        </ComparisonContainer>
        {isEditing && (
          <EditContainer>
            {(!valueType || valueType === 'text') && (
              <TextField
                autoFocus={true}
                variant='standard'
                size='small'
                label='New text'
                value={currentText}
                onChange={(event) => setCurrentText(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && currentText !== '') {
                    handleEdit(event);
                  }
                  if (event.key === 'Escape') {
                    setIsEditing(false);
                  }
                }}
              />
            )}
            {valueType === 'date' && (
              <DateField
                autoFocus={true}
                variant='standard'
                size='small'
                label='New date'
                value={moment(currentText)}
                onChange={(date) => setCurrentText(date?.format('MM/DD/YY') ?? '')}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && currentText !== '') {
                    handleEdit(event);
                  }
                  if (event.key === 'Escape') {
                    setIsEditing(false);
                  }
                }}
              />
            )}
            {valueType === 'percent' && (
              <NumericFormat
                placeholder="New percentage"
                variant="standard"
                size='small'
                customInput={TextField}
                value={currentNumber}
                suffix='%'
                type='text'
                thousandSeparator={false}
                allowNegative={false}
                decimalScale={2}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue == null || (floatValue >= 0 && floatValue <= 100);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && currentText !== '') {
                    handleEdit(event);
                  }
                  if (event.key === 'Escape') {
                    setIsEditing(false);
                  }
                }}
                onValueChange={(values: NumberFormatValues, sourceInfo: SourceInfo) => {
                  if (sourceInfo.source === 'prop') return;
                  setCurrentNumber(values.floatValue);
                }}
              />
            )}
          </EditContainer>
        )}
        <ButtonContainer>
          {!disableEdit && (
            <Button
              startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
              size='small'
              disabled={currentText === ''}
              onClick={(event) => handleEdit(event)}
            >
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          )}
        </ButtonContainer>
        {isEditing && (
          <ButtonContainer>
            <Button
              startIcon={<CloseIcon />}
              size='small'
              onClick={(event) => {
                event.stopPropagation();
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          </ButtonContainer>
        )}
        {!isEditing && (
          <>
            <ButtonContainer>
              <Button
                startIcon={<CheckIcon />}
                size='small'
                onClick={(event) => {
                  event.stopPropagation();
                  handleDiffPopoverClose();
                  if (!change) {
                    console.error('changes is null');
                    return;
                  }
                  onAccept?.(change);
                }}
              >
                Accept
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                startIcon={<CloseIcon />}
                size='small'
                onClick={(event) => {
                  event.stopPropagation();
                  handleDiffPopoverClose();
                  if (!change) {
                    console.error('change is null');
                    return;
                  }
                  onReject?.(change);
                }}
              >
                Reject
              </Button>
            </ButtonContainer>
          </>
        )}
      </PopoverContainer>
    </ChangesPopover>
  )
}
