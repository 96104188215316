import { Container, Typography } from '@mui/material';
import { useDialog } from 'app/providers/dialog';
import IndividualBookmarks from 'app/routes/marketplace/bookmarks/individual';
import TeamBookmarks from 'app/routes/marketplace/bookmarks/team';
import React, { useEffect } from 'react';
import { useLoaderData, useLocation, useSearchParams } from 'react-router-dom';
import { useSession } from 'users/session/session-context';
import CompanyBookmarks from './company';
import { PageTab } from './tabs';

type BookmarksProps = {};

export default function Bookmarks(props: Readonly<BookmarksProps>) {
  const [activeTab, setActiveTab] = React.useState(PageTab.Individual);

  const tabFromUrl = useLoaderData() as string;
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { closeAllDialogs } = useDialog();
  const session = useSession();

  // Handle URL changes
  useEffect(() => {
    if (tabFromUrl) setActiveTab(tabFromUrl as PageTab);
  }, [searchParams, tabFromUrl]);

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <>
      {activeTab === PageTab.Individual && (
        <IndividualBookmarks activeTab={activeTab} />
      )}
      {activeTab === PageTab.Team && (
        <TeamBookmarks activeTab={activeTab} />
      )}
      {activeTab === PageTab.Company && (
        <CompanyBookmarks activeTab={activeTab} />
      )}
    </>
  );
}
