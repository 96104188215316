import AddBoxIcon from '@mui/icons-material/AddBox';
import WorkIcon from '@mui/icons-material/Work';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Guid from 'common/values/guid/guid';
import Name from 'common/values/name/name';
import * as React from 'react';
import Session from 'users/session/session';
import SelectedRepresentative from 'work/values/selected-representative/selected-representative';
import SelectedTeam from 'work/values/selected-team/selected-team';

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30)
}));

export type DirectProposalButtonProps = {
  session: Readonly<Session>;
  action: "create" | "request";
  targetTeamId?: Guid;
  targetUserId?: Guid;
  targetEntityId?: Guid;
  targetName?: Name;
  useStyledButton?: boolean;
  targetTeamName?: string;
  onClick: (targetUrl: URL) => void;
};

export default function DirectProposalButton(props: Readonly<DirectProposalButtonProps>) {
  const {
    action,
    targetTeamId,
    targetUserId,
    targetEntityId,
    targetName,
    session,
    useStyledButton,
    targetTeamName,
    onClick
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (targetTeamId) {
      const team = new SelectedTeam(targetTeamId, targetTeamName);
      const result = team.generateTargetUrl(action, window.location);
      onClick(result);
      return;
    } else if (targetUserId) {
      const viewingAsVendor = session?.context?.viewingAsVendor ?? false;
      const rep = new SelectedRepresentative(targetUserId, targetEntityId!, viewingAsVendor, targetName!);
      const result = rep.generateTargetUrl(action, window.location);
      onClick(result);
    }
  };

  return (
    <>
      {useStyledButton &&
        <StyledButton
          variant="outlined"
          startIcon={action === "create" ? <AddBoxIcon /> : <WorkIcon />}
          onClick={handleClick}>
          {action === "create" ? "Create Proposal" : "Request Proposal"}
        </StyledButton>
      }
      {!useStyledButton &&
        <Button
          color="primary"
          startIcon={action === "create" ? <AddBoxIcon /> : <WorkIcon />}
          onClick={handleClick}>
          {action === "create" ? "Create Proposal" : "Request Proposal"}
        </Button>
      }
    </>
  );
}
