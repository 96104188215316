import Guid from "common/values/guid/guid";

import TeamProfile from "marketplace/values/team-profile/team-profile";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import MarketplaceTeamInfoAPIResponse from "marketplace/values/team-profile/api/response-contracts/marketplace-team-info-api-response";
import MarketplaceLeaderInfoAPIResponse from "marketplace/entities/marketplace-leader/api/response-contracts/marketplace-leader-info-api-response";
import MarketplaceCompanyInfoAPIResponse from "marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";

export default class MarketplaceTeamAPIResponse extends MarketplaceTeamInfoAPIResponse {
  deserializeToTeam(): MarketplaceTeam {
    if (!this.id)
      throw new Error("Id is required, was not returned by the API");
    if (!this.name)
      throw new Error("Name is required, was not returned by the API");
    if (!this.leader)
      throw new Error(
        "Marketplace Team Leader is required, was not returned by the API"
      );

    const memberArray: MarketplaceIndividualInfoAPIResponse[] = [];
    if (this.members) {
      for (const element of this.members) {
        memberArray.push(
          Object.assign(
            new MarketplaceIndividualInfoAPIResponse(),
            element
          ));
      }
    }
    
    const leader = Object.assign(
      new MarketplaceLeaderInfoAPIResponse(),
      this.leader
    ).deserialize();
    const teamProfile = new TeamProfile(
      this.name,
      this.description,
      this.categories,
      this.avatar ? new Guid(this.avatar) : undefined,
      leader.userId
    );
    const team = new MarketplaceTeam(
      leader,
      teamProfile,
      this.isVisible ?? false,
      memberArray.map((m) => m.deserialize()) ?? [],
      this.associatedFirms?.map((f) =>
        Object.assign(
          new MarketplaceCompanyInfoAPIResponse(),
          f
        ).deserializeToProfile()
      ) ?? []
    );
    team.id = new Guid(this.id);

    return team;
  }
}
