import MarketplaceCompanyInfoAPIResponse from "marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";
import MarketplaceLeaderInfoAPIResponse from "marketplace/entities/marketplace-leader/api/response-contracts/marketplace-leader-info-api-response";
import MarketplaceResponseAPIResponse from "marketplace/api/response-contracts/marketplace-response-api-response";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import TeamProfile from "marketplace/values/team-profile/team-profile";
import Guid from "common/values/guid/guid";

export default class MarketplaceTeamInfoAPIResponse extends MarketplaceResponseAPIResponse {
  name?: string;
  description?: string;
  leader?: MarketplaceLeaderInfoAPIResponse;
  members?: MarketplaceIndividualInfoAPIResponse[];
  associatedFirms?: MarketplaceCompanyInfoAPIResponse[];
  categories?: string[];
  avatar?: string;

  deserializeToTeam(): MarketplaceTeam {
    if (!this.leader)
      throw new Error(
        "Marketplace Team Leader is required, was not returned by the API"
      );
    if (!this.name)
      throw new Error("Name is required, was not returned by the API");

    const leader = Object.assign(
      new MarketplaceLeaderInfoAPIResponse(),
      this.leader
    ).deserialize();

    const profile = new TeamProfile(
      this.name,
      this.description,
      this.categories,
      this.avatar ? new Guid(this.avatar) : undefined,
      leader.userId
    );

    const memberArray: MarketplaceIndividualInfoAPIResponse[] = [];
    if (this.members) {
      for (const element of this.members) {
        memberArray.push(
          Object.assign(
            new MarketplaceIndividualInfoAPIResponse(),
            element
          ));
      }
    }

    return new MarketplaceTeam(
      leader,
      profile,
      this.isVisible ?? false,
      memberArray.map((m) => m.deserialize()) ?? [],
      this.associatedFirms?.map((f) =>
        Object.assign(
          new MarketplaceCompanyInfoAPIResponse(),
          f
        ).deserializeToProfile()
      ) ?? []
    );
  }
}
