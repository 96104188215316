import { Moment } from 'moment';
import Guid from "common/values/guid/guid";

export default class ReadReceipt {
  userId: Guid;
  seenAt: Moment;

  constructor(userId: Guid, seenAt: Moment) {
    this.userId = userId;
    this.seenAt = seenAt;
  }

}