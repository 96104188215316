import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { KEY_ENTER_COMMAND, COMMAND_PRIORITY_LOW, LexicalCommand, createCommand, LexicalEditor } from "lexical";
import Message from "messaging/entities/message/message";
import react from "react";

type SubmitMessagePluginProps = {
  message?: Message;
  listenForEnterKey?: boolean;
  listenForSubmitButton?: boolean;
  onSend: (message: Message, editor: LexicalEditor) => void;
};

export const SEND_MESSAGE_COMMAND: LexicalCommand<Message> = createCommand();

export function SubmitMessagePlugin(props: Readonly<SubmitMessagePluginProps>) {
  const { message, listenForEnterKey, listenForSubmitButton, onSend } = props;

  const [editor] = useLexicalComposerContext();

  function sendMessage(message: Message): boolean {
    onSend(message, editor);
    return true;
  }

  react.useEffect(() => {
    return editor.registerCommand(KEY_ENTER_COMMAND, (event: KeyboardEvent | null): boolean => {
      if (!listenForEnterKey || !message || !event || event.shiftKey) return false;
      event.preventDefault();
      return sendMessage(message);
    }, COMMAND_PRIORITY_LOW);
  }, [editor, listenForEnterKey, message]);

  react.useEffect(() => {
    return editor.registerCommand(SEND_MESSAGE_COMMAND, (message: Message | null): boolean => {
      if (!listenForSubmitButton || !message) return false;
      return sendMessage(message);
    }, COMMAND_PRIORITY_LOW);
  }, [editor, listenForSubmitButton]);

  return null;
}
