import { Currency } from "common/values/currency/currency";

export default class Money {
    amount: number;
    currency: Currency;

    constructor(amount: number, currency: Currency) {
        this.amount = amount;
        this.currency = currency;
    }

    public isEqualTo(other: Money | undefined): boolean {
        if (!other) return false;
        return (
            this.amount === other.amount &&
            this.currency === other.currency
        );
    }

    public fromObject(obj: any): Money {
        if (!obj) throw new Error('Cannot create Money from null object');
        return new Money(
            obj.amount,
            obj.currency
        );
    }

    public static get Prototype(): Money {
        return new Money(0, Currency.ALL);
    }

    public clone(): Money {
        return new Money(this.amount, this.currency);
    }

    public toJSON(): object {
        return {
            amount: this.amount,
            currency: this.currency
        };
    }
}