import { v4 as uuidv4 } from 'uuid';

const PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const EMPTY = '00000000-0000-0000-0000-000000000000';

export default class Guid {
    private readonly _value: string;

    constructor(value: string) {
        Guid.validate(value);
        this._value = value;
    }

    public get value(): string {
        return this._value;
    }

    static generate() : Guid {
        // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
        return new Guid(uuidv4());
    }

    public static validate(value?: string, allowEmpty: boolean = false) {
        if (!value) {
            throw new InvalidGuidError('cannot be empty');
        }
        if (allowEmpty && value === EMPTY) return;
        const valid = PATTERN.test(value);
        if (!valid) {
            throw new InvalidGuidError(value);
        }
    }

    public static empty() {
        return new Guid(EMPTY);
    }

    public static isEmpty(value?: string) {
        return !value || value === EMPTY;
    }

    public isEqualTo(other: Guid | null | undefined): boolean {
        if (!other) return false;
        return this._value === other._value;
    }

    toString(){     
        return this._value;
    }

    public clone(): Guid {
        return new Guid(this._value);
    }

    public static fromJSON(value: string): Guid {
        return new Guid(value);
    }

    public static fromObject(object: any): Guid | undefined {
        if (!object) return undefined;
        return new Guid(object);
    }

    public toJSON(): string {
        return this._value;
    }
}


export class InvalidGuidError extends Error {
    constructor(value: string) {
        super(`Invalid guid: ${value}`);
    }
}