import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";

export default class IndividualSummary {
    id: Guid;
    userId: Guid;
    firstName: string;
    lastName: string;
    avatarId?: Guid;
    emailAddress?: EmailAddress;

    constructor(id: Guid, userId: Guid, firstName: string, lastName: string, avatarId?: Guid, emailAddress?: EmailAddress) {
        this.id = id;
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.avatarId = avatarId;
        this.emailAddress = emailAddress;
    }
}