import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CanceledError } from "axios";
import Guid from "common/values/guid/guid";
import EntityMemberAPIService from "legal-entities/entities/entity-member/api/entity-member-api-service";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import { debounce } from "lodash";
import IndividualAvatar from "marketplace/view/individual-avatar";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";

const PopoverContainer = styled("div")(({ theme }) => ({
  minWidth: "22rem",
  padding: theme.spacing(2),
  textAlign: "center",
}));
const SearchField = styled(TextField)(({ theme }) => ({
  width: "100%",
})) as typeof TextField;
const LoaderProgress = styled(CircularProgress)(({ theme }) => ({
  display: "inline-block",
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
}));
const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: "20rem",
  overflowX: "hidden",
  overflowY: "auto",
}));
const SelectedIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

type TeamLeaderSelectorProps = {
  teamLeaderUserId?: Guid;
  popoverAnchor?: HTMLDivElement;
  onTeamLeaderUpdated?: (userInfo?: EntityMember) => void;
  onPopoverClose?: Function;
};

export default function TeamLeaderSelector(
  props: Readonly<TeamLeaderSelectorProps>
) {
  const {
    teamLeaderUserId,
    popoverAnchor,
    onTeamLeaderUpdated,
    onPopoverClose,
  } = props;

  const session = useSession();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTeamLeader, setSelectedTeamLeader] = React.useState<
    EntityMember | undefined
  >();
  const [coworkers, setCoworkers] = React.useState<EntityMember[]>([]);
  const [teamLeaderFilterResults, setTeamLeaderFilterResults] = React.useState<
    EntityMember[]
  >([]);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  useEffect(() => {
    let abortController = new AbortController();
    getCoworkerList(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  useEffect(() => {
    const userProfileInfo = coworkers.find((c) =>
      c.userId?.isEqualTo(teamLeaderUserId)
    );
    setSelectedTeamLeader(userProfileInfo);
  }, [teamLeaderUserId, coworkers]);

  async function getCoworkerList(abortController: AbortController) {
    if (!session.user) return;

    setIsLoading(true);

    try {
      const entityInfo = session.currentEntity;
      const entity = new LegalEntity(
        entityInfo.entityId,
        entityInfo.entityName,
        entityInfo.entityClientFlag,
        entityInfo.entityVendorFlag
      );
      const entityMemberService = new EntityMemberAPIService(session);
      const coworkers = await entityMemberService.getLegalEntityMembersUserInfo(
        entity,
        abortController
      );
      const vendorReps = coworkers.filter(
        (coworker) =>
          (coworker.isAdmin || coworker.isOfficer) && coworker.userId
      );
      setCoworkers(vendorReps);
      setTeamLeaderFilterResults(vendorReps);
    } catch (err) {
      if (err instanceof CanceledError) return;
      console.error(err);
    }
    setIsLoading(false);
  }

  const debouncedSearch = debounce(
    (value) =>
      setTeamLeaderFilterResults(
        coworkers.filter(
          (r) =>
            r.name?.toString().toLowerCase().includes(value.toLowerCase()) ||
            r.email?.toString().toLowerCase().includes(value.toLowerCase())
        )
      ),
    100
  );

  function search(value: string) {
    setSearchTerm(value);

    if (value === "") {
      setTeamLeaderFilterResults(coworkers);
      return;
    }

    debouncedSearch(value);
  }

  function saveChanges(vendorRep?: EntityMember) {
    onTeamLeaderUpdated?.(vendorRep);
    onPopoverClose?.();
  }

  return (
    <Popover
      anchorEl={popoverAnchor}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={popoverAnchor !== undefined}
      onClose={() => onPopoverClose?.()}
    >
      <PopoverContainer>
        <SearchField
          placeholder="Coworkers"
          slotProps={{
            input: {
              startAdornment: <SearchIcon />,
              endAdornment: (
                <>
                  {searchTerm.length > 0 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchTerm("");
                        search("");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </>
              ),
            },
          }}
          value={searchTerm}
          onChange={(event) => search(event.target.value)}
        />
        {isLoading && <LoaderProgress size={50} />}
        {!isLoading && (
          <ListContainer dense>
            {teamLeaderFilterResults.length < 1 && (
              <ListItem>
                <ListItemText>
                  {`No coworkers matching: ${searchTerm}`}
                </ListItemText>
              </ListItem>
            )}
            {teamLeaderFilterResults.length > 0 &&
              teamLeaderFilterResults.map((vendorRep: EntityMember) => {
                const isSelected = selectedTeamLeader?.userId?.isEqualTo(
                  vendorRep.userId
                );

                return (
                  <ListItemButton
                    disableGutters
                    key={vendorRep.userId?.value}
                    selected={isSelected}
                    onClick={() => saveChanges(vendorRep)}
                  >
                    <ListItemAvatar>
                      {isSelected ? (
                        <SelectedIcon>
                          <DoneIcon />
                        </SelectedIcon>
                      ) : (
                        <IndividualAvatar
                          avatarId={vendorRep.avatarId}
                          individualId={vendorRep.individualId}
                          session={session}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={vendorRep.name?.toString()}
                      secondary={<>{vendorRep.email?.toString()}</>}
                    />
                  </ListItemButton>
                );
              })}
          </ListContainer>
        )}
      </PopoverContainer>
    </Popover>
  );
}
