import Guid from "common/values/guid/guid";
import ForumEntityClass from "notifications/values/forum-entity-class/forum-entity-class";
import ForumName from "notifications/values/forum-name/forum-name";

export default class ForumInfo {
  id: Guid;
  name: ForumName;
  entityClass: ForumEntityClass;
  entityId: Guid | null;

  constructor(id: Guid, name: ForumName, entityClass: ForumEntityClass, entityId: Guid | null) {
    this.id = id;
    this.name = name;
    this.entityClass = entityClass;
    this.entityId = entityId;
  }
}
