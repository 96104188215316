import MarketplaceIndividualInfoAPIResponse  from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";
import Individual from "../../individual";

/**
 * MarketplaceIndividualInfoAPIResponse and MarketplaceIndividualAPIResponse are equivalent
 */
export default class MarketplaceIndividualAPIResponse extends MarketplaceIndividualInfoAPIResponse {
    deserialize(): Individual {
        if (!this.id) throw new Error("Individual id is required, was not returned by the API");
        let individual = super.deserialize();
        individual.isVisible = Boolean(this.isVisible);
        return individual;
    }       
}
