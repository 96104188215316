import Guid from "common/values/guid/guid";

import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import EntityRepresentativeAPIResponse from "work/entities/entity-representative/api/response-contracts/entity-representative-api-response";

export default class WorkVendorRepresentativeAPIResponse extends EntityRepresentativeAPIResponse {

    deserialize() : EntityVendorRepresentative {
        if (!this.userId) throw new Error("User id not returned.");
        if (!this.entityId) throw new Error("Entity id not returned.");

        return new EntityVendorRepresentative(new Guid(this.userId), new Guid(this.entityId));
    }
}
