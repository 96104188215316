import {
  Card,
  CardContent,
  Theme,
  useMediaQuery
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

const ResultContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  overflow: 'hidden',
  '&:first-of-type': {
    marginTop: 0
  }
}));
const AvatarSkeleton = styled(Skeleton)(({ theme }) => ({
  height: 'auto',
  width: '250px'
}));
const AvatarCircleSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '80px',
  width: '80px',
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(2)
}));
const Description = styled(CardContent)(({ theme }) => ({
  flex: '1 0 auto',
  padding: theme.spacing(3),
  paddingLeft: '3rem'
}));
const Details = styled(Grid)(({ theme }) => ({
  marginRight: '5rem',
  marginTop: theme.spacing(2),
  width: '100%'
}));
const TitleSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '35px',
  maxWidth: '250px'
}));
const TextSkeleton = styled(Skeleton)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

type ResultSkeletonProps = {};

export default function ResultSkeleton(props: Readonly<ResultSkeletonProps>) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <ResultContainer>
      {mdDown && <AvatarCircleSkeleton height={80} width={80} variant="circular" />}
      {lgUp && <AvatarSkeleton variant="rectangular" />}
      <Description>
        <TitleSkeleton variant="text" />
        <Grid container direction="row">
          <Details>
            <TextSkeleton variant="text" />
            <TextSkeleton variant="text" />
            <TextSkeleton variant="text" />
            <TextSkeleton variant="text" />
          </Details>
        </Grid>
      </Description>
    </ResultContainer>
  );
}
