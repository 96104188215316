import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GradingIcon from '@mui/icons-material/Grading';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import PageviewIcon from '@mui/icons-material/Pageview';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import WorkIcon from '@mui/icons-material/Work';
import {
  Badge, Button,
  Divider,
  IconButton, List,
  ListItemButton, ListItemIcon, ListItemText, Popover
} from '@mui/material/';
import { styled } from '@mui/material/styles';
import { useDialog } from 'app/providers/dialog';
import { useRealtime, HubName } from 'app/providers/realtime';
import { CanceledError } from 'axios';
import { DialogProps } from 'common/components/dialog';
import PaginationParameters from 'common/contracts/pagination-parameters';
import * as Constants from 'common/helpers/constants';
import ChatDialog from 'messaging/entities/forum/view/components/chat-dialog';
import MessageNotification from 'notifications/entities/message-notification/message-notification';
import NotificationParameters from 'notifications/entities/notification/api/request-contracts/notification-parameters';
import Notification from 'notifications/entities/notification/notification';
import NotificationsAPIService from 'notifications/services/notifications-api-service';
import Action from 'notifications/values/action/action';
import { BoxType } from 'notifications/values/box-type/box-type';
import TopicCategory from 'notifications/values/topic-category/topic-category';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSession } from 'users/session/session-context';

const BellIconButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  margin: theme.spacing(0.5)
}));
const NotificationMenu = styled(Popover)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1
}));
const MarkAllReadButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.4),
  marginLeft: theme.spacing(1.6)
}));
const ViewAllButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  width: '100%'
}));
const NotificationList = styled(List)(({ theme }) => ({
  maxHeight: theme.spacing(40),
  overflow: 'auto',
  padding: 0,
  width: theme.spacing(44)
}));
const NotificationItem = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'seen' })<{
  seen?: boolean;
}>(({ theme, seen }) => ({
  backgroundColor: seen ? '#f3f3f3' : '#fff',
  '&:first-of-type': {
    borderTop: 'none'
  },
  '&:last-child': {
    borderBottom: 'none'
  },
  '&:hover': {
    backgroundColor: seen ? '#f3f3f3' : '#fff',
  }
}));

type NotificationBellProps = {};

export default function NotificationBell(props: Readonly<NotificationBellProps>) {
  const [numUnread, setNumUnread] = React.useState(0);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  const session = useSession();
  const { openDialog } = useDialog();
  const location = useLocation();
  const navigate = useNavigate();
  const { connection, startConnection } = useRealtime(HubName.Notification);

  useEffect(() => {
    let abortController = new AbortController();

    getNotifications(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  useEffect(() => {
    if (!connection) return;

    startConnection()
      .then(() => {
        connection.on('notification-received', handleNotificationReceived);
        connection.on('notification-updated', handleNotificationUpdated);
        connection.on('notification-deleted', handleNotificationDeleted);
      })
      .catch(err => console.error('Realtime notifications hub connection failed: ', err));
  }, [connection]);

  async function getNotifications(abortController: AbortController) {
    if (!session.isLoggedIn) return;
    try {
      const paginationParams = new PaginationParameters(1, 25);
      const notificationParams = new NotificationParameters(BoxType.Inbox, false, false, false);
      const notificationService = new NotificationsAPIService(session);
      const notifications = await notificationService.getNotificationsForUser(
        paginationParams,
        notificationParams,
        abortController
      );
      setNumUnread(notifications.filter(n => !n.isSeen).length);
      setNotifications(notifications);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
  }

  async function handleNotificationReceived(notification: Notification | MessageNotification) {
    if (notification.recipientUserId?.value !== session?.user?.id?.value) return;

    if (notification.isCanceled) {
      setNumUnread(prev => prev - 1);
      setNotifications(prev => prev.filter(n => n?.id?.isEqualTo(notification.id) === false));
      return;
    }

    setNumUnread(prev => prev + 1);
    setNotifications(prev => [notification, ...prev]);
  }

  async function handleNotificationUpdated(notification: Notification | MessageNotification) {
    if (notification.recipientUserId?.value !== session?.user?.id?.value) return;

    if (notification.isCanceled) {
      setNumUnread(prev => prev - 1);
      setNotifications(prev => prev.filter(n => n?.id?.isEqualTo(notification?.id) === false));
      return;
    }

    setNotifications(function (prev) {
      const isInNotificationList = prev.some(n => n.id.value === notification.id.value);
      if (!isInNotificationList) {
        setNumUnread(prev => prev + 1);
        return [...prev, notification];
      }
      const updatedNotifications = prev
        .map(n => n.id.value === notification.id.value ? notification : n)
        .filter(n => !n.isSeen);
      setNumUnread(updatedNotifications.length);
      return updatedNotifications;
    });
  }

  function handleNotificationDeleted(notification: Notification | MessageNotification) {
    setNumUnread(prev => prev - 1);
    if (notifications.length < 1) return;
    setNotifications(prev => prev.filter(n => n?.id?.isEqualTo(notification.id) === false));
  }

  function handleBellClick(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  }

  async function handleNotificationClick(_event: React.MouseEvent<HTMLElement>, notification: Notification) {
    try {
      const seenNotification = await new NotificationsAPIService(session)
        .markNotificationAsSeen(notification);

      if (!seenNotification.isSeen) {
        console.error(`Failed to mark notification ${notification.id} as seen.`);
      } else {
        setNumUnread(prev => prev - 1);
        setNotifications(prev => prev.filter(n => n?.id?.isEqualTo(notification.id) === false));
      }

      if (
        notification?.topic?.id &&
        notification.topic?.category?.startsWith('Messaging') &&
        (notification as MessageNotification)?.messageInfo?.forum.entityClass.value !== 'Work.Proposal'
      ) {
        const dialogProps: DialogProps = {
          title: notification.topic.category.toString() ?? 'Chat',
          component: <ChatDialog forumId={notification.topic.id} />,
          contentSxProps: {
            display: 'flex',
            overflowX: 'hidden',
            paddingBottom: 0
          },
          MuiProps: {
            fullWidth: true,
            maxWidth: 'md'
          }
        };
        openDialog(dialogProps);
        return;
      }

      const redirectUrl = getRedirectUrl(notification);
      if (redirectUrl === window.location.pathname) return;
      navigate(redirectUrl);
    } catch (error: any) {
      console.error(error);
    } finally {
      setMenuAnchor(null);
    }
  }

  async function handleMarkAllClick() {
    try {
      if (numUnread < 1) return;
      await new NotificationsAPIService(session).markAllNotificationsAsSeen();
      setNumUnread(0);
      setNotifications([]);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't mark all notifications as read. Please try again.", { variant: 'error' });
    }
  }

  function handleViewAllClick() {
    setMenuAnchor(null);
    if (location.pathname.startsWith('/communications')) return;
    navigate('/communications');
  }

  function getRedirectUrl(notification: Notification) {
    const id = notification.topic?.id;
    const category = notification.topic?.category ?? '';
    const isProposalMessageNotification =
      (notification as MessageNotification).messageInfo?.forum.entityClass.value === 'Work.Proposal';
    const action = notification.actionRequired;
    let actionType: 'view' | 'edit' | 'revise' | 'review' = 'view';
    let pageTab = 'active';
    let tab = '';
    let url = '';

    if (!isProposalMessageNotification) {
      url = Constants.notificationLinks[category as keyof typeof Constants.notificationLinks];
    } else {
      const proposalId = (notification as MessageNotification).messageInfo?.forum.entityId;
      return `/proposals/active/revise/${proposalId}/chat`;
    }

    if (!id) return url;

    switch (action) {
      case Action.DraftProposalReview:
        pageTab = 'drafts';
        actionType = 'review';
        break;
      case Action.DraftProposalReviewApproved:
        pageTab = 'drafts';
        actionType = 'edit';
        break;
      case Action.ProposalReview:
        actionType = 'review';
        break;
      case Action.Review:
      case Action.ProposalRevisionReview:
      case Action.ProposalReviewApproved:
      case Action.HireOrCancel:
        actionType = 'revise';
        break;
    }

    url = url.replace('{pageTab}', pageTab);
    url = url.replace('{action}', actionType);
    url = url.replace('{topicId}', id.value);
    return url.replace('{tab}', tab);
  }

  function getNotificationText(notification: Notification & MessageNotification) {
    if (notification.topic?.category === TopicCategory.Messaging.Message) {
      return `Message from ${notification.messageInfo?.senderName?.value ?? 'unknown sender'}`;
    }
    return Constants.notificationTypes[(notification.topic?.category ?? '') as keyof typeof Constants.notificationTypes] ?? 'Notification';
  }

  return (
    <React.Fragment>
      <BellIconButton
        aria-label="open notifications menu"
        onClick={handleBellClick}
        size="large">
        <Badge
          overlap="rectangular"
          badgeContent={numUnread > 0 ? numUnread : null}
          color="error">
          <NotificationsIcon />
        </Badge>
      </BellIconButton>
      <NotificationMenu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}>
        <MarkAllReadButton
          color="primary"
          startIcon={<PlaylistAddCheckIcon />}
          disabled={numUnread < 1}
          onClick={handleMarkAllClick}>
          Mark All As Read
        </MarkAllReadButton>
        <Divider />
        <NotificationList>
          {notifications.length < 1 && (
            <NotificationItem seen={true} disabled>
              <ListItemIcon>
                <NotificationsOffOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                No Unread Notifications
              </ListItemText>
            </NotificationItem>
          )}
          {notifications.map((notification: Notification & MessageNotification) => (
            <NotificationItem
              key={notification.id.value}
              seen={Boolean(notification.isSeen)}
              divider
              onClick={(event) => handleNotificationClick(event, notification)}>
              <ListItemIcon>
                {[TopicCategory.Messaging.Message, TopicCategory.Messaging.Message].includes(notification.topic?.category ?? '') && <MessageIcon />}
                {notification.topic?.category === TopicCategory.Users.NetworkInvitation && <PersonAddIcon />}
                {notification.topic?.category === TopicCategory.Users.NetworkConnection && <AccountTreeIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.AcceptOrReject && <ThumbsUpDownIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.Review && <PageviewIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.DraftProposalReview && <PageviewIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.DraftProposalReviewApproved && <GradingIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.ProposalReview && <PageviewIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.ProposalReviewApproved && <GradingIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.ReviewOrReject && <PageviewIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.ProposalRevisionReview && <AssignmentIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.HireOrCancel && <WorkIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired === Action.Hired && <AssignmentIcon />}
                {notification.topic?.category === TopicCategory.Work.Proposal && !notification.actionRequired && <InfoIcon />}
                {notification.topic?.category === TopicCategory.Marketplace.Team && <GroupIcon />}
                {notification.topic?.category === TopicCategory.LegalEntities.EntityMember && <PersonIcon />}
                {notification.topic?.category === TopicCategory.Marketplace.TeamInvitation && <GroupAddIcon />}
                {notification.topic?.category === TopicCategory.LegalEntities.LegalEntity && <SupervisorAccountIcon />}
              </ListItemIcon>
              <ListItemText
                primary={getNotificationText(notification)}
                secondary={notification.message?.value}
              />
            </NotificationItem>
          ))}
        </NotificationList>
        <Divider />
        <ViewAllButton
          color="primary"
          onClick={handleViewAllClick}>
          View All
        </ViewAllButton>
      </NotificationMenu>
    </React.Fragment>
  );
}
