import {
  Button,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Navigate } from 'react-router-dom';

const ActionButton = styled(Button)(() => ({
  backgroundColor: '#e0e9ff',
  marginTop: '1rem',
  '&:hover': {
    backgroundColor: '#fff',
  }
}));

type Props = {
  redirectUrl: string,
  buttonText: string,
  title: string,
  backgroundImageUrl: string
};

export default function CallToAction(props: Readonly<Props>) {
  const [redirect, setRedirect] = React.useState<boolean>(false);
  const { redirectUrl, buttonText, title, backgroundImageUrl } = props;
  
  const CTAContainer = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      backgroundSize: '250% auto'
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '200% auto'
    },
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    height: '100%',
    minHeight: '25rem',
    minWidth: '25rem',
    padding: '2rem 2.5rem'
  }));

  if (redirect) {
    return <Navigate to={redirectUrl} />
  }

  return (
    <CTAContainer>
      <Typography variant="h4">
        {title}
      </Typography>
      <ActionButton
        variant="outlined"
        onClick={() => setRedirect(true)}>
        {buttonText}
      </ActionButton>
    </CTAContainer>
  )
}
