import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "common/components/loading-button";
import React, { forwardRef, useImperativeHandle } from "react";

const MenuPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1
}));
const PrimaryButton = styled(LoadingButton)(({ theme }) => ({
  flex: 1,
  whiteSpace: "nowrap"
}));

export type MenuButtonRef = {
  closeMenu: () => void;
}

type MenuButtonProps = {
  className?: string;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
  saving?: boolean;
  startIcon?: React.ReactNode;
  menuOptions: string[];
  popperContent?: React.ReactNode;
  defaultMenuIndex?: number;
  menuIsOpen?: boolean;
  onClick?: (selectedMenuIndex: string) => void;
}

export const MenuButton = forwardRef<MenuButtonRef, MenuButtonProps>((props: Readonly<MenuButtonProps>, ref) => {
  useImperativeHandle(ref, () => ({
    closeMenu() {
      setMenuOpen(false)
    }
  }), []);

  const {
    className,
    variant,
    color,
    disabled,
    saving,
    startIcon,
    menuOptions,
    popperContent,
    defaultMenuIndex,
    onClick
  } = props;

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = React.useState<number>(defaultMenuIndex ?? 0);

  function getButtonLabel() {
    return menuOptions[selectedMenuIndex];
  }

  function handleButtonClicked() {
    if (menuOptions.length === 1 && popperContent !== undefined) {
      handleMenuToggle();
      return;
    }
    onClick?.(menuOptions[selectedMenuIndex]);
    setMenuOpen(false);
  }

  function handleMenuToggle() {
    setMenuOpen((prevOpen) => !prevOpen);
  }

  function handleMenuClose(event: Event) {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;
    setMenuOpen(false);
  };

  function handleMenuItemClick(
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) {
    setSelectedMenuIndex(index);
    setMenuOpen(false);
  }

  return (
    <React.Fragment>
      <ButtonGroup
        className={className}
        variant={variant}
        color={color}
        ref={anchorRef}
      >
        <PrimaryButton
          variant={variant}
          color={color}
          disabled={saving || disabled}
          loading={saving ?? false}
          startIcon={startIcon}
          disableElevation
          onClick={handleButtonClicked}
        >
          {getButtonLabel()}
        </PrimaryButton>
        {(menuOptions.length > 1) && (
          <Button
            size="small"
            color={color}
            onClick={handleMenuToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <MenuPopper
        open={menuOpen}
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <span>
                  {popperContent}
                  {!popperContent && (
                    <MenuList id="split-button-menu" autoFocusItem>
                      {menuOptions?.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedMenuIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  )}
                </span>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MenuPopper>
    </React.Fragment>
  );
});
