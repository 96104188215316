import IndividualProfile from "marketplace/values/individual-profile/individual-profile";

export default class CreateMarketplaceIndividualProfileAPIRequest {
    firstName: string;
    lastName: string;
    description?: string;
    location?: string;
    phoneNumber?: string;
    email?: string;
    school?: string;
    degree?: string;
    avatar?: string;
    resume?: string;
    professionalRegistration?: string;
    technicalBackground?: string;
    numberOfApplicationsDraftedProsecuted?: number;
    numberOfAllowedApplications?: number;
    barAdmissionYear?: number;
    isOnCall?: boolean;
    insuredAmount?: number;
    billingRate?: string;
    billingUnit?: string;
    potentialDiversityCandidate?: boolean;
    collaborates?: boolean;
    additionalInfo?: string;
    links?: string[];
    professionalPublications?: string[];
    sampleApplications?: string[];
    fluentLanguages?: string[];
    technicalLanguages?: string[];
    regionsRegistered?: string[];
    regionsLicensed?: string[];
    categories?: string[];

    constructor(profile: IndividualProfile) {
        if(!profile) throw new Error('Individual must have a profile');
        this.firstName = profile.firstName;
        this.lastName = profile.lastName;
        this.description = profile.description;
        this.location = profile.location;
        this.phoneNumber = profile.phoneNumber?.value;
        this.email = profile.email?.value;
        this.school = profile.school;
        this.degree = profile.degree;
        this.avatar = profile.avatarId?.value;
        this.resume = profile.resumeId?.value;
        this.professionalRegistration = profile.professionalRegistration?.value;
        this.technicalBackground = profile.technicalBackground?.value;
        this.numberOfApplicationsDraftedProsecuted = profile.numberOfApplicationsDraftedProsecuted;
        this.numberOfAllowedApplications = profile.numberOfAllowedApplications;
        this.barAdmissionYear = profile.barAdmissionYear;
        this.isOnCall = profile.isOnCall;
        this.billingRate = profile.billingRate?.billingRate;
        this.billingUnit = profile.billingRate?.billingUnit;
        this.potentialDiversityCandidate = profile.potentialDiversityCandidate;
        this.collaborates = profile.collaborates;
        this.additionalInfo = profile.additionalInfo;
        this.links = profile.links;
        this.professionalPublications = profile.professionalPublications;
        this.sampleApplications = profile.sampleApplications;
        this.fluentLanguages = profile.fluentLanguages;
        this.technicalLanguages = profile.technicalLanguages;
        this.regionsRegistered = profile.regionsRegistered;
        this.regionsLicensed = profile.regionsLicensed;
        this.categories = profile.categories;
    }
}
