import { Autocomplete, Chip, TextField } from '@mui/material';
import React, { useEffect } from 'react';

type FilterAutocompleteProps = {
  filterName: string;
  placeholder: string;
  value: string | string[] | null;
  options?: string[];
  freeSolo?: boolean;
  multiple?: boolean;
  onChange: (filterName: string, value: string | string[] | null) => void;
};

export default function FilterAutocomplete(props: Readonly<FilterAutocompleteProps>) {
  const { 
    filterName, 
    placeholder, 
    freeSolo, 
    multiple, 
    options, 
    onChange 
  } = props;

  const [value, setValue] = React.useState<string | string[] | null>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Autocomplete
      size="small"
      freeSolo={freeSolo}
      multiple={multiple}
      value={value}
      options={options ?? []}
      sx={{ width: '100%' }}
      onChange={(_event, val, _reason) => {
        onChange(
          filterName, 
          val
        );
      }}
      renderTags={(val, getTagProps) =>
        val.map((option, index) => (
          <Chip size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params}
          size="small"
          variant="outlined"
          placeholder={placeholder} />
      )}
    />
  )
}
