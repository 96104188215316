import {
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  styled,
  Typography,
} from "@mui/material";
import { useConfirmDialog } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import { useSession } from "users/session/session-context";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(1),
}));
const TeamContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  margin: theme.spacing(1, 0),
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const TeamList = styled(List)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    "&:not(:last-of-type)": {
      marginRight: theme.spacing(5),
    },
  },
  margin: 0,
  marginTop: 0,
  minWidth: theme.spacing(40),
  paddingBottom: theme.spacing(3),
}));
const TeamListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: "1.4em",
  padding: 0,
}));
const NoRowsPlaceholder = styled(Typography)(({ theme }) => ({
  fontSize: "1.3em",
  paddingTop: theme.spacing(1),
}));
const TeamMemberLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  cursor: "pointer",
  textDecoration: "underline",
}));

type ViewTeamProps = {
  className?: string;
  team?: MarketplaceTeam;
};

export default function ViewTeam(props: Readonly<ViewTeamProps>) {
  const { className, team } = props;

  const { openDialog } = useDialog();
  const confirm = useConfirmDialog();
  const session = useSession();

  return (
    <MainContainer className={className}>
      <TeamContainer>
        <TeamList>
          <TeamListSubheader>Team Leader</TeamListSubheader>
          <ListItem disableGutters>
            {!team?.leader && <NoRowsPlaceholder>None</NoRowsPlaceholder>}
            {team?.leader && (
              <>
                <ListItemAvatar>
                  <IndividualAvatar
                    avatarId={team.leader.profile?.avatarId}
                    individualId={team.leader.id}
                    session={session}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography>
                    <TeamMemberLink
                      onClick={() =>
                        openDialog({
                          component: (
                            <ViewIndividualProfile
                              individualId={team.leader.id}
                            />
                          ),
                          titleStyle: {
                            position: "absolute",
                            right: 0,
                            top: 0,
                          },
                          contentSxProps: {
                            display: "flex",
                            overflowX: "hidden",
                          },
                          MuiProps: {
                            maxWidth: "lg",
                            fullWidth: true,
                          },
                        })
                      }
                    >
                      {team.leader.getFullName()}
                    </TeamMemberLink>
                  </Typography>
                </ListItemText>
              </>
            )}
          </ListItem>
        </TeamList>
        <TeamList>
          <TeamListSubheader>Team Members</TeamListSubheader>
          {(!team?.memberships || team?.memberships.length < 1) && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {team?.memberships?.map((member: Individual) => (
            <ListItem key={member.userId?.value} disableGutters>
              <ListItemAvatar>
                <IndividualAvatar
                  avatarId={member.profile?.avatarId}
                  individualId={member.id}
                  session={session}
                />
              </ListItemAvatar>
              <ListItemText>
                <Typography>
                  <TeamMemberLink
                    onClick={() =>
                      openDialog({
                        component: (
                          <ViewIndividualProfile
                            individualId={member.id}
                          />
                        ),
                        titleStyle: {
                          position: "absolute",
                          right: 0,
                          top: 0,
                        },
                        contentSxProps: {
                          display: "flex",
                          overflowX: "hidden",
                        },
                        MuiProps: {
                          maxWidth: "lg",
                          fullWidth: true,
                        },
                      })
                    }
                  >
                    {member.getFullName()}
                  </TeamMemberLink>
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </TeamList>
      </TeamContainer>
    </MainContainer>
  );
}
