import Guid from "common/values/guid/guid";

export default class SystemNotification {
    id: Guid;
    content: string;

    public get message(): string {
        if(!this.content.includes(':')) return this.content;
        return this.content.split(':').filter(i => i)[1];
    }

    public get type(): string {
        return this.content.split(':').filter(i => i)[0];
    }

    constructor(id: Guid, content: string) {
        this.id = id;
        this.content = content;
    }
}