import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import WorkFeeScheduleCategoryAPIRequest from "work/entities/proposal/api/request-contracts/work-fee-schedule-category-api-request";


export default class UpdateFeeScheduleTemplateAPIRequest {
    payload: JsonPatchOperation[] = [];
    
    constructor(originalTemplate: FeeScheduleTemplate, updatedTemplate: FeeScheduleTemplate) {
        if (updatedTemplate.name !== originalTemplate?.name)
            this.payload.push({
                op: "replace",
                path: "/name",
                value: updatedTemplate.name,
            });
        if (updatedTemplate.tags !== originalTemplate?.tags)
            this.payload.push({
                op: "replace",
                path: "/tags",
                value: updatedTemplate.tags,
            });
        if (updatedTemplate.categories !== originalTemplate?.categories) {
            this.payload.push({
                op: "replace",
                path: "/categories",
                value: updatedTemplate.categories.map((category) => new WorkFeeScheduleCategoryAPIRequest(category)),
            });
        }
    }
}
