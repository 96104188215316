import Guid from "common/values/guid/guid";
import { Moment } from "moment";

export default class TeamInvitation {
    id: Guid;
    inviteeId: Guid;
    teamId: Guid;
    message?: string;
    acceptedAt?: Moment;
    closed: boolean = false;

    constructor(id: Guid, inviteeId: Guid, teamId: Guid, message?: string, acceptedAt?: Moment, closed: boolean = false) {
        this.id = id;
        this.inviteeId = inviteeId;
        this.teamId = teamId;
        this.message = message;
        this.acceptedAt = acceptedAt;
        this.closed = closed;
    }
}