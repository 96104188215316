import Guid from "common/values/guid/guid";
import Document from "documents/entities/document/document";
import DocumentName from "documents/values/document-name";
import DocumentTopic from "documents/values/document-topic";
import { DocumentType } from "documents/values/document-type";import moment from "moment";
import DocumentTopicInfoAPIResponse from "work/entities/proposal/api/response-contracts/document-topic-info-api-response";


export default class DocumentInfoAPIResponse {
    id?: string;
    creatorId?: string;
    name?: string;
    fileSize?: number;
    fileType?: string;
    created?: string;
    topics?: DocumentTopicInfoAPIResponse[];
    templateIds?: string[];

    deserialize(): Document {
        if (!this.id) {
            throw new Error("DocumentInfoAPIResponse is missing Id.");
          }

          const documentTopics: DocumentTopic[] = [];
          if (this.topics) {
            this.topics.forEach((topic) => {
              documentTopics.push(Object.assign(new DocumentTopicInfoAPIResponse(), topic).deserialize());
            });
          }
          let documentType: DocumentType = DocumentType.UNKNOWN;
          const documentTypes = Object.values(DocumentType);
          const documentTypeIndex = documentTypes.indexOf(this.fileType as DocumentType);
      
          if (documentTypeIndex > -1) {
            const documentTypeKeys = Object.keys(DocumentType);
            documentType = documentTypeKeys[documentTypeIndex] as DocumentType;
          }
      
          return new Document(
            new Guid(this.id),
            this.name ? new DocumentName(this.name) : undefined,
            this.creatorId ? new Guid(this.creatorId) : null,
            this.fileSize as number,
            documentType,
            moment(this.created),
            [],
            [],
            documentTopics,
            this.templateIds?.map((id) => new Guid(id)) ?? []
          );
        
    }
}