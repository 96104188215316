import Guid from "common/values/guid/guid";


export default class UnavailableDocument {
    id: Guid;
    issue: string;
    constructor(id: Guid, issue: string) {
        this.id = id;
        this.issue = issue;
    }
}