import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import WorkFeeScheduleCategoryAPIRequest from "work/entities/proposal/api/request-contracts/work-fee-schedule-category-api-request";


export default class CreateFeeScheduleTemplateAPIRequest {
  entityId: string;
  name: string;
  tags: string[] = [];
  categories: WorkFeeScheduleCategoryAPIRequest[] = [];
  creatorType?: string;

  constructor(template: FeeScheduleTemplate, creatorType: string = 'client') {
    if (!template.creator?.entityId)
      throw new Error(
        "Template must indicate entity fee schedule is being created for."
      );
    this.entityId = template.creator.entityId.value;
    this.name = template.name;
    this.tags = template.tags.map((tag) => tag.value);
    this.categories = template.categories.map(
      (category) => new WorkFeeScheduleCategoryAPIRequest(category)
    );
    this.creatorType = creatorType;
  }
}
