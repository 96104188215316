import Guid from "common/values/guid/guid";
import Password from "common/values/password/password";

export default class ResetPasswordConfirmationAPIRequest {
    password: string;
    confirmPassword: string;
    userId: string;
    token: string;

    constructor(password: Password, userId: Guid, token: string) {
        this.password = password.value;
        this.confirmPassword = password.value;
        this.userId = userId.value;
        this.token = token;
    }
}
