export interface Hit<T> {
  data: T;
  score: number | null;
  highlight: { [key: string]: string[]; };
}


export default class SearchResults<T> {
    hits?: Hit<T>[];
    from?: number;
    size?: number;
    total?: number;

    constructor(values: Partial<SearchResults<T>>) {
        Object.assign(this, values);
    }
}