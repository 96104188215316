import CreateForumAPIRequest from "messaging/entities/forum/api/request-contracts/create-forum-api-request";
import Message from "messaging/entities/message/message";

type BulkMessageRequest = {
    id: string;
    existingForumId?: string;
    newForum?: CreateForumAPIRequest;
    subject: string;
    contentPlainText: string;
    markDeleted: boolean;
    markRead: boolean;
    markUnread: boolean;
}

export default class BulkMessageAPIRequest {
    public payload: Array<BulkMessageRequest>;

    constructor(messages: Message[]) {
        this.payload = [];
        messages.forEach((message) => {
            if(!message.id) throw new Error("Message must have an id");
            this.payload.push({
                id: message.id.value,
                existingForumId: message.forum.id?.value,
                newForum: !message.forum.id ? new CreateForumAPIRequest(message.forum) : undefined,
                subject: message.subject ?? "",
                contentPlainText: message.content,
                markDeleted: message.markedForDeletion ?? false,
                markRead: message.markedForReadReceipt ?? false,
                markUnread: message.markedForUnread ?? false,
            });
        });
    }
}
