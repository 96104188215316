import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import {
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CanceledError } from "axios";
import LoadingButton from "common/components/loading-button";
import PaginationParameters from "common/contracts/pagination-parameters";
import { downloadFile } from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import DocumentFilterParameters from "documents/entities/document/api/request-contracts/document-filter-parameters";
import DocumentOrderParameters from "documents/entities/document/api/request-contracts/document-order-parameters";
import DocumentTopicParameters from "documents/entities/document/api/request-contracts/document-topic-parameters";
import Document from "documents/entities/document/document";
import DocumentTopic from "documents/values/document-topic";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  useMaterialReactTable
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { defaultStyles, FileIcon } from "react-file-icon";
import { useSession } from "users/session/session-context";

const FileName = styled(Typography)(() => ({
  fontSize: "1em",
  fontWeight: "500",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
const NameContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  columnGap: theme.spacing(1),
  display: "flex",
  maxWidth: "20rem",
  paddingLeft: theme.spacing(2),
  svg: {
    width: "24px",
  },
}));
const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap"
}));
const RowActions = styled("div")(({ theme }) => ({
  display: "flex",
}));

type DocumentSelectorProps = {
  isLoading?: boolean;
  documentType: "conflicts" | "policy";
  selectedTab?: "templates" | "documents";
  selectedDocumentIds: Guid[];
  selectedTemplateIds?: Guid[];
  onDocumentSelectionChanged: (selectedDocs: Document[], deselectedDocs: Document[]) => void;
  onCreateDocumentFromTemplate: (templateId: Guid) => void;
};

export default function DocumentSelector(
  props: Readonly<DocumentSelectorProps>
) {
  const {
    documentType,
    selectedTab,
    selectedDocumentIds,
    selectedTemplateIds,
    onDocumentSelectionChanged,
    onCreateDocumentFromTemplate,
  } = props;

  const documentFileUploaderRef = React.useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
  const [activeTab, setActiveTab] = React.useState<"templates" | "documents">(
    selectedTab ?? "templates"
  );
  const [availableDocuments, setAvailableDocuments] = React.useState<
    Document[]
  >([]);

  const rowSelectionFromSelectedDocumentIds: Record<string, boolean> = {};
  for(const id of selectedDocumentIds){
    rowSelectionFromSelectedDocumentIds[id.toString()] = true;
  }
  const [rowSelection, setRowSelection] = React.useState<Record<string, boolean>>(rowSelectionFromSelectedDocumentIds);

  const theme = useTheme();
  const session = useSession();

  const columnHelper = createMRTColumnHelper<Document>();

  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
      id: "name",
    }),
    columnHelper.accessor((document) => document.created?.format("MM/DD/YY hh:mm A"), {
      header: "Created",
      id: "created",
      filterVariant: 'date-range',
    }),
  ];

  useEffect(() => {
    let abortController = new AbortController();
    fetchData(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    }
  }, [
    activeTab, 
    session,
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting
  ]);

  useEffect(() => {
    const selectedIds = new Array<Guid>();
    for(const [idString, isSelected] of Object.entries(rowSelection)){
      if(isSelected){
        const id = Guid.fromObject(idString);
        if(id) {
          selectedIds.push(id);
        }
      }
    }

    const selectedDocs = availableDocuments.filter((doc) =>
      selectedIds.some((id) => doc.id.isEqualTo(id))
    );
    const deselectedDocs = availableDocuments.filter((doc) =>
      !selectedIds.some((id) => doc.id.isEqualTo(id))
    );

    if(!selectedDocs.length && !deselectedDocs.length) return;
    if(selectedDocs.some((doc) => !selectedDocumentIds.some((id) => doc.id.isEqualTo(id))) ||
    deselectedDocs.some((doc) => selectedDocumentIds.some((id) => doc.id.isEqualTo(id)))
    ){
      onDocumentSelectionChanged(selectedDocs, deselectedDocs);
    }
  }, [rowSelection]);

  // useEffect(() => {
  //   const updatedRowSelection: Record<string, boolean> = {};

  //   for(const id of selectedDocumentIds){
  //     updatedRowSelection[id.toString()] = true;
  //   }

  //   setRowSelection(updatedRowSelection); // May include entries not in table data.
  // }, [selectedDocumentIds]);
  
  const fetchData = async (abortController?: AbortController) => {
    if (!availableDocuments.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const apiService = new DocumentAPIService(session);
      const paginationParams = new PaginationParameters(
        pagination.pageIndex + 1,
        pagination.pageSize
      );
      const order = sorting.find(
        (sort) => sort !== undefined
      );
      const orderParams = new DocumentOrderParameters(
        order?.id === "name" ? "name" : "created",
        order?.desc ? "desc" : "asc"
      );
      const topicParams = new DocumentTopicParameters(
        activeTab === "documents" ? "Work.Proposal" : undefined,
        undefined,
        documentType + (activeTab === "templates" ? "template" : "")
      );
      const filterParams = new DocumentFilterParameters(
        columnFilters.find((filter) => filter.id === "name")?.value?.toString() ?? globalFilter,
      );
      const paginatedResponse = await apiService.getDocumentsInfo(
        abortController,
        paginationParams,
        topicParams,
        orderParams,
        filterParams
      );

      setAvailableDocuments(paginatedResponse.data);
      setRowCount(paginatedResponse.totalElements);
    } catch (error) {
      setErrorLoading(true);
      if (error instanceof CanceledError) return;
      console.error(error);
      return;
    }
    setErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  
  function renderNameColumn(document: Document): React.ReactNode {
    return (
      <NameContainer>
        <FileIcon
          extension={document.fileType}
          {...defaultStyles[
            document.fileType?.toLowerCase() as keyof typeof defaultStyles
          ]}
        />
        <FileName>{document.name?.value}</FileName>
      </NameContainer>
    );
  }

  async function handleDownload(id: Guid): Promise<void> {
    try {
      if (!id) Promise.reject(new Error("No document id provided"));
      const service = new DocumentAPIService(session);
      const response = await service.downloadDocument(id);
      downloadFile(response);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to download file", { variant: "error" });
    }
  }

  async function handleDocumentFileUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!event.currentTarget.files) return;

    const file: File = event.currentTarget.files[0];

    try {
      setIsUploading(true);

      let context: string | undefined;
      context = documentType === "conflicts" ? "Conflicts" : "Policy";
      if (activeTab === "templates") {
        context = context.concat("Template");
      }
      const service = new DocumentAPIService(session);
      await service.createDocument(file, file.name, [
        new DocumentTopic(undefined, undefined, context),
      ]);
      enqueueSnackbar("Uploaded file", { variant: "success" });

      fetchData();
    } catch (error: any) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(error.response.data, { variant: "error" });
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar("Unsupported file type", { variant: "error" });
      } else {
        enqueueSnackbar("Unknown error occurred during upload", {
          variant: "error",
        });
      }
    } finally {
      event.target.value = "";
      setIsUploading(false);
    }
  }
  function getRowStyle(rowData: Document): React.CSSProperties {
    if(rowData.id === undefined) return {};
    const color = selectedDocumentIds.some((id) => rowData.id.isEqualTo(id))
      ? "rgba(0, 0, 0, 0.5)"
      : "inherit";
    let bgColor = selectedTemplateIds?.some((id) => rowData.id.isEqualTo(id))
      ? "rgba(0, 200, 0, 0.1)"
      : "inherit";
    if (selectedDocumentIds.some((id) => rowData.id.isEqualTo(id))) {
      bgColor = theme.palette.primary.light;
    }

    return {
      backgroundColor: bgColor,
      color: color,
    };
  }

  const table = useMaterialReactTable({
    columns,
    data: availableDocuments,
    enableSorting: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: true,
    enableTableHead: true,
    initialState: {
      showGlobalFilter: true,
      density: "compact",
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    positionToolbarAlertBanner: "none",
    muiTopToolbarProps: {
      sx: {
        '.MuiBox-root': {
          alignItems: "end"
        }
      }
    },
    renderToolbarInternalActions: ({ table }) => (
      <ToolbarButtonsContainer>
        <ToolbarButtons>
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </ToolbarButtons>
        <LoadingButton
          color="primary"
          loading={isUploading}
          startIcon={<UploadIcon />}
          onClick={() => documentFileUploaderRef.current?.click()}
        >
          Upload Template
        </LoadingButton>
      </ToolbarButtonsContainer>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <Tabs
          value={activeTab}
          onChange={(_event, value) => {
            setActiveTab(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Templates" value="templates" />
          <Tab label="Recently Used" value="documents" />
        </Tabs>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    renderRowActions: ({ row, table }) => (
      <RowActions>
        <Tooltip title="Create Document from Template">
          <span>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onCreateDocumentFromTemplate(row.original.id);
              }}
            >
              <ContentPasteGoIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Download">
          <span>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleDownload(row.original.id);
              }}
            >
              <DownloadIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
      </RowActions>
    ),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: errorLoading
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => setRowSelection((prev) => ({
        ...prev,
        [row.id]: !prev[row.id]
      })),
      selected: rowSelection[row.id],
      sx: {
        backgroundColor: getRowStyle(row.original).backgroundColor,
        color: getRowStyle(row.original).color,
        cursor: "pointer"
      }
    }),
    muiSelectCheckboxProps: ({ row }) => ({
      checked: rowSelection[row.id],
      indeterminate: !selectedDocumentIds?.some((id) => row.original.id.isEqualTo(id)) &&
        selectedTemplateIds?.some((id) => row.original.id.isEqualTo(id)),
    }),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: errorLoading,
      showProgressBars: isRefetching,
      sorting,
      rowSelection
    },
  });

  return (
    <>
      <input
        type="file"
        hidden={true}
        ref={documentFileUploaderRef}
        onChange={handleDocumentFileUpload}
      />
      <MaterialReactTable table={table} />
    </>
  );
}
