import { useState } from 'react';
import {
  TextField,
  Tooltip,
  Typography,
  IconButton,
  styled
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const ComponentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  margin: theme.spacing(0, 6, 0, 3)
}));
const NameFields = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr auto auto'
}));
const CheckButton = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.main
}));
const CancelButton = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.error.main
}));

export type CompanyProfileNameInputProps = {
  className?: string;
  name: string;
  onNameChange: (name: string) => void;
}

export default function CompanyProfileNameInput(props: Readonly<CompanyProfileNameInputProps>) {
  const { className, onNameChange } = props;

  const [editingName, setEditingName] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name);

  return (
    <ComponentContainer className={className}>
      {!editingName && (
        <Typography variant="h4">
          {props.name}
        </Typography>
      )}
      {editingName && (
        <NameFields>
          <TextField
            id="name"
            variant="outlined"
            slotProps={{
              inputLabel: {
                shrink: Boolean(name)
              }
            }}
            label="Company Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setEditingName(false);
                onNameChange(name);
              }
            }}
          />
          <Tooltip title="Accept Change">
            <span>
              <IconButton onClick={() => {
                setEditingName(false);
                onNameChange(name);
              }}
                size="medium">
                <CheckButton />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <span>
              <IconButton onClick={() => {
                setName(props.name);
                setEditingName(false);
              }}
                size="medium"
                edge="end">
                <CancelButton />
              </IconButton>
            </span>
          </Tooltip>
        </NameFields>
      )}
      {!editingName && (
        <Tooltip title="Edit Name">
          <span>
            <IconButton
              onClick={() => setEditingName(true)}
              size="medium"
              edge="end">
              <EditIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </ComponentContainer>
  );
}
