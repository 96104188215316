import { Dictionary } from 'lodash';

export default class MarketplaceSearchAPIRequest {
  query?: string;
  hideCompanies?: boolean;
  hideTeams?: boolean;
  hideIndividuals?: boolean;
  category?: string;
  sortBy?: string;
  name?: string;
  yearEstablished?: number | null;
  numberOfEmployees?: number | null;
  contactPersonUserId?: string;
  description?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  website?: string;
  firstName?: string;
  lastName?: string;
  school?: string;
  professionalRegistration?: string;
  technicalBackground?: string;
  applicationsDrafted?: number | null;
  allowedApplications?: number | null;
  barAdmissionYear?: number | null;
  isOnCall?: boolean;
  insuredAmount?: number | null;
  billingRate?: string;
  potentialDiversityCandidate?: boolean;
  collaborates?: boolean;
  additionalInfo?: string;
  fluentLanguages?: string[];
  technicalLanguages?: string[];
  regionsRegistered?: string[];
  regionsLicensed?: string[];
  limitToVendorReps?: boolean;
  from: number = 0;
  size: number = 10;

  constructor(filters?: Dictionary<string>){
    if(!filters) return;

    this.query = filters['query'];
    this.category = filters['category'];
    this.hideCompanies = filters['hideCompanies'] === 'true';
    this.hideTeams = filters['hideTeams'] === 'true';
    this.hideIndividuals = filters['hideIndividuals'] === 'true';
    this.sortBy = filters['sortBy'];
    this.name = filters['name'];
    this.yearEstablished = filters['yearEstablished'] ? parseInt(filters['yearEstablished']) : null;
    this.numberOfEmployees = filters['numberOfEmployees'] ? parseInt(filters['numberOfEmployees']) : null;
    this.contactPersonUserId = filters['contactPersonUserId'];
    this.description = filters['description'];
    this.address1 = filters['address1'];
    this.address2 = filters['address2'];
    this.city = filters['city'];
    this.state = filters['state'];
    this.postalCode = filters['postalCode'];
    this.website = filters['website'];
    this.firstName = filters['firstName'];
    this.lastName = filters['lastName'];
    this.school = filters['school'];
    this.professionalRegistration = filters['professionalRegistration'];
    this.technicalBackground = filters['technicalBackground'];
    this.applicationsDrafted = filters['applicationsDrafted'] ? parseInt(filters['applicationsDrafted']) : null;
    this.allowedApplications = filters['allowedApplications'] ? parseInt(filters['allowedApplications']) : null;
    this.barAdmissionYear = filters['barAdmissionYear'] ? parseInt(filters['barAdmissionYear']) : null;
    this.isOnCall = filters['isOnCall'] === 'true';
    this.insuredAmount = filters['insuredAmount'] ? parseInt(filters['insuredAmount']) : null;
    this.billingRate = filters['billingRate'];
    this.potentialDiversityCandidate = filters['potentialDiversityCandidate'] === 'true';
    this.collaborates = filters['collaborates'] === 'true';
    this.additionalInfo = filters['additionalInfo'];
    this.fluentLanguages = filters['fluentLanguages'] ? filters['fluentLanguages'].split(',') : [];
    this.technicalLanguages = filters['technicalLanguages'] ? filters['technicalLanguages'].split(',') : [];
    this.regionsRegistered = filters['regionsRegistered'] ? filters['regionsRegistered'].split(',') : [];
    this.regionsLicensed = filters['regionsLicensed'] ? filters['regionsLicensed'].split(',') : [];  
  }
}