import { Container, Icon, Tab, Tabs, Theme, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDialog } from "app/providers/dialog";
import { PageTab } from "app/routes/work/tabs";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import React, { useEffect } from "react";
import { Params, useLoaderData, useLocation, useNavigate } from "react-router";
import { useSession } from "users/session/session-context";
import WorkAgreementAPIService from "work/entities/work-agreement/api/work-agreement-api-service";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";

const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap",
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

type WorkProps = {};

export default function Work(_props: Readonly<WorkProps>) {
  const columnHelper = createMRTColumnHelper<WorkAgreement>();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [data, setData] = React.useState<WorkAgreement[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(PageTab.Active);
  const [rowSelection, setRowSelection] = React.useState<Record<string, boolean>>({});

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const { closeAllDialogs } = useDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (!session.user?.companyEntityId) return;
    fetchData();
  }, [activeTab, session]);

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle session account type change
  useEffect(() => {
    if (!session.user?.companyEntityId) return;
    fetchData();
  }, [session.accountType]);

  // Handle URL changes
  useEffect(() => {
    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/work/${PageTab.Active}`);
    }
  }, [routeParams]);

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const workService = new WorkAgreementAPIService(session);
      const work = await workService.getWorkAsync({
        entityId: session.entities[0].entityId,
        viewingAs: session.context?.viewingAsVendor
          ? AccountType.Vendor
          : AccountType.Client,
        context: activeTab === PageTab.Active ? "allactive" : "archive",
      });

      setData(work);
      setRowCount(work.length);
    } catch (error) {
      setErrorLoading(true);
      console.error(error);
      return;
    }
    setErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  function renderNameColumn(work: WorkAgreement) {
    if (!work.name) return <Icon>remove</Icon>
    return <RowTitle>{work.name.value}</RowTitle>;
  }

  function renderDescriptionColumn(work: WorkAgreement) {
    if (!work.description) return <Icon>remove</Icon>
    return work.description.value;
  }

  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
    }),
    columnHelper.accessor(renderDescriptionColumn, {
      header: "Description",
      id: "description",
    }),
    columnHelper.accessor((work) => work.hireDate?.format('MM/DD/YYYY') ?? <Icon>remove</Icon>, {
      header: "Hire Date",
      id: "hireDate",
    }),
    columnHelper.accessor((work) => work.startDate?.format('MM/DD/YYYY') ?? <Icon>remove</Icon>, {
      header: "Start Date",
      id: "startDate",
    }),
    columnHelper.accessor((work) => work.endDate?.format('MM/DD/YYYY') ?? <Icon>remove</Icon>, {
      header: "End Date",
      id: "endDate",
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
          </ToolbarButtonsContainer>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Work
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/work/${newValue}`)}
            >
              <Tab value={PageTab.Active} label="Active" />
              <Tab value={PageTab.Archived} label="Archived" />
            </Tabs>
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: errorLoading ? {
      color: "error",
      children: "Error loading data",
    } : undefined,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: errorLoading,
      showProgressBars: isRefetching,
      // sorting,
      rowSelection
    },
  });

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <MaterialReactTable table={table} />
  );
}
