import Guid from "common/values/guid/guid";
import moment from "moment";

import BookmarkedTeam from "marketplace/entities/bookmarked-team/bookmarked-team";
import MarketplaceTeamInfoAPIResponse from "marketplace/values/team-profile/api/response-contracts/marketplace-team-info-api-response";


export default class MarketplaceTeamBookmarkAPIResponse extends MarketplaceTeamInfoAPIResponse {
    bookmarkId?: string;
    createdDate?: string;

    deserializeToBookmark(): BookmarkedTeam {
        if (!this.id) throw new Error('Id is required, was not returned from API');
        if (!this.bookmarkId) throw new Error('BookmarkId is required, was not returned from API');
        if (!this.createdDate) throw new Error('CreatedDate is required, was not returned from API');
        if (!this.name) throw new Error("Team name is required, was not returned from API");
        if (!this.leader) throw new Error("Team leader is required, was not returned from API");

        const vendorProfile = super.deserializeToTeam();

        return new BookmarkedTeam(
            new Guid(this.id),
            new Guid(this.bookmarkId),
            moment(this.createdDate),
            vendorProfile
        );
    }
}
