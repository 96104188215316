export default class FeeScheduleTag {
    private _value: string;
  
    constructor(value: string) {
      this._value = value;
    }
    
    isEqualTo(other: FeeScheduleTag): boolean {
      return this.value === other?.value;
    }
  
    public get value(): string {
      return this._value;
    }
  
    public static get Prototype(): FeeScheduleTag {
      return new FeeScheduleTag("");
    }
  
    public fromObject(obj: any): FeeScheduleTag {
      return new FeeScheduleTag(obj);
    }
    public toJSON(): any {
      return this.value;
    }
    public clone(): FeeScheduleTag {
      return new FeeScheduleTag(this.value);
    }
}