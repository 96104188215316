import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  IconButton, Toolbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ipahLogo from 'app/assets/ipah_logo.svg';
import ipahLogoAlt from 'app/assets/ipah_logo_alt.svg';
import { AccountType } from 'common/values/account-type/account-type';
import NotificationBell from 'notifications/entities/notification/view/notification-bell';
import { useNavigate } from 'react-router';
import UserMenu from 'users/entities/user/view/user-menu';
import { useSession } from 'users/session/session-context';

const HeaderBar = styled(AppBar)<{ accounttype: AccountType }>(({ accounttype, theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxHeight: theme.spacing(7)
  },
  backgroundColor: (accounttype === AccountType.Client) ? '#404558' : '#E19941',
  flexShrink: 0,
  maxHeight: theme.spacing(8),
  zIndex: theme.zIndex.drawer + 1
}));
const Logo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: theme.spacing(3),
    marginLeft: 0
  },
  [theme.breakpoints.up("sm")]: {
    height: '36px',
    marginLeft: theme.spacing(2)
  },
  cursor: 'pointer',
  margin: 'auto'
}));
const AccountTypeButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: '1.2em',
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));
const NavMenuButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}));

type HeaderProps = {
  toggleNavMenuClicked?: () => void;
  hideControls?: boolean;
}

export default function Header(props: Readonly<HeaderProps>) {
  const { hideControls, toggleNavMenuClicked } = props;

  const session  = useSession();
  const navigate = useNavigate();

  return (
    <HeaderBar position='fixed' accounttype={session.accountType}>
      <Toolbar>
        <Logo
          src={session.accountType === AccountType.Client ? ipahLogo : ipahLogoAlt}
          alt="AttorneyHub Logo"
          onClick={() => navigate('/dashboard')}
        />
        {!hideControls && (
          <>
            {session.canSwitchContext && (
              <AccountTypeButton
                onClick={() => {
                  session.setAccountViewType(session.accountType === AccountType.Client ? AccountType.Vendor : AccountType.Client);
                }}>
                Viewing as {session.accountType}
              </AccountTypeButton>
            )}
            <NotificationBell />
            <UserMenu />
            <NavMenuButton
              color="inherit"
              aria-label="open navigation drawer"
              edge="end"
              onClick={toggleNavMenuClicked}
              size="large">
              <MenuIcon />
            </NavMenuButton>
          </>
        )}
      </Toolbar>
    </HeaderBar>
  );
}
