import Guid from "common/values/guid/guid";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import UserProfileAPIResponse from "users/entities/user/api/response-contracts/user-profile-api-response";

export default class NetworkInvitationInfoAPIResponse {
  id?: string;
  status?: string;
  inviter?: boolean;
  individualId?: string;
  avatarId?: string;
  user?: UserProfileAPIResponse;
  created?: string;
  message?: string;

  deserialize(): NetworkInvitationInfo {
    if(!this.id) 
      throw new Error("id is required");
    if(this.inviter === undefined) 
      throw new Error("inviter is required");
    if(!this.individualId) 
      throw new Error("individualId is required");
    if(!this.user) 
      throw new Error("user is required");
    if(!this.created) 
      throw new Error("created is required");

    const user = Object.assign(new UserProfileAPIResponse(), this.user).deserialize();
    return new NetworkInvitationInfo(
      new Guid(this.id),
      this.status ?? "",
      this.inviter ?? false,
      new Guid(this.individualId),
      this.avatarId ? new Guid(this.avatarId) : undefined,
      user,
      new Date(this.created),
      this.message ?? ""
    );
  }
}
