import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Button, Container, IconButton, Link, ListItemText, styled, Theme, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { PageTab } from "app/routes/entity-administration/tabs";
import { CanceledError } from 'axios';
import Guid from "common/values/guid/guid";
import AdministratorInvitation from "legal-entities/entities/administrator-invitation/administrator-invitation";
import AdministratorInvitationAPIService from "legal-entities/entities/administrator-invitation/api/administrator-invitation-api-service";
import EntityMemberAPIService, {
  InvalidMemberUpdateException,
} from "legal-entities/entities/entity-member/api/entity-member-api-service";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import EntityMemberInviteManager from "legal-entities/entities/user-entity-member-invitation/view/components/entity-member-invite-manager";
import { InvitationStatus } from 'legal-entities/values/invitation-status';
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import {
  Params,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AuthEmailAPIService from "users/entities/user/api/auth-email-api-service";
import { useSession } from "users/session/session-context";

const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap",
}));
const NameCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const NameText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
const NewButton = styled(IconButton)(({ theme }) => ({
  padding: 0
}));

export type EntityAdministrationTableData = AdministratorInvitation | EntityMember;
type EntityAdministrationProps = {};

export default function EntityAdministration(
  props: Readonly<EntityAdministrationProps>
) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [data, setData] = React.useState<EntityAdministrationTableData[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [wasErrorLoading, setWasErrorLoading] = React.useState(false);
  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [activeTab, setActiveTab] = React.useState<PageTab>(PageTab.Users);
  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    if (!session.user?.companyEntityId || !session.user?.isCompanyManager) return;

    let abortController = new AbortController();

    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/administration/${PageTab.Users}`);
    }

    if (routeParams.action === "invite") {
      if (routeParams.tab === PageTab.Users) handleInviteUsers();
      if (routeParams.tab === PageTab.Invitations) {
        enqueueSnackbar("Cannot yet invite administrators", {
          variant: "info",
        });
      }
      return;
    }

    if (routeParams.action === "view" && routeParams.id) {
      handleViewProfile(new Guid(routeParams.id));
    }

    fetchData(routeParams.tab as PageTab, abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    }
  }, [routeParams]);
  
  const fetchData = async (tab?: PageTab, abortController?: AbortController) => {
    if (!session?.hasEntity) {
      setData([]);
      setRowCount(0);
      setIsLoading(false);
      setIsRefetching(false);
      setWasErrorLoading(true);
      return;
    }

    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      if ((tab ?? activeTab) === PageTab.Users) {
        const firstEntity = new LegalEntity(
          session.entities[0].entityId,
          session.entities[0].entityName
        );
        const entityMemberService = new EntityMemberAPIService(session);
        const members =
          await entityMemberService.getLegalEntityMembersUserInfo(
            firstEntity,
            abortController
          );
        setData(members);
        setRowCount(members.length);
        setIsLoading(false);
        setIsRefetching(false);
        setWasErrorLoading(false);
      }
      if ((tab ?? activeTab) === PageTab.Invitations) {
        if (!session?.isLoggedInAsAdmin) {
          setData([]);
          setRowCount(0);
          setIsLoading(false);
          setIsRefetching(false);
          setWasErrorLoading(true);
          return;
        }

        const firstUserEntityInfo = session.entities[0];
        const legalEntity = new LegalEntity(
          firstUserEntityInfo.entityId,
          firstUserEntityInfo.entityName
        );
        const adminService = new AdministratorInvitationAPIService(session);
        const invitations = await adminService.getAdminInvitationsForLegalEntity(
          legalEntity, 
          abortController
        );
        setData(invitations);
        setRowCount(invitations.length);
        setIsLoading(false);
        setIsRefetching(false);
        setWasErrorLoading(false);
      }
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
      setData([]);
      setRowCount(0);
      setWasErrorLoading(true);
      setIsLoading(false);
      setIsRefetching(false);
    }

    setWasErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  function handleViewProfile(individualId?: Guid): void {
    if (!individualId) return;

    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        titleStyle: {
          position: "absolute",
          right: 0,
          top: 0,
        },
        contentSxProps: {
          display: "flex",
          overflowX: "hidden",
        },
        MuiProps: {
          maxWidth: "lg",
          fullWidth: false,
        },
        component: (
          <ViewIndividualProfile
            individualId={individualId}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  function handleInviteUsers() {
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "User Invitations",
        MuiProps: {
          maxWidth: "md",
        },
        component: <EntityMemberInviteManager confirmDialog={confirm} />,
      },
      () => navigate(parentPath.pathname)
    );
  }

  async function handleCancelInvite(invitation?: AdministratorInvitation) {
    if (!invitation) return;

    const adminName = `${invitation.registrationInfo.name.firstName} ${invitation.registrationInfo.name.lastName}`;

    const response = await confirm({
      title: "Cancel Administrator Invitation?",
      message: `Cancel ${adminName}'s administrator invitation to your orginization?`,
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const service = new AdministratorInvitationAPIService(session);
      await service.cancelAdministratorInvitation(invitation);
      enqueueSnackbar("Canceled invitation", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to cancel invitation", { variant: "error" });
    }
  }

  async function handleToggleAdminRole(entityMember: EntityMember) {
    const name = `${entityMember.name?.firstName} ${entityMember.name?.lastName}`;

    const response = await confirm({
      title: !entityMember.isAdmin
        ? "Make an Administrator?"
        : "Remove Administrative Role?",
      message: !entityMember.isAdmin
        ? `This change will grant ${name} administrative powers for your legal entity`
        : `This change will remove ${name}'s administrative powers from your legal entity`,
      okButtonText: "Change Role",
    });

    if (response === ConfirmResponse.Cancel) return;

    const updatedMember = new EntityMember({
      ...entityMember,
      isAdmin: !entityMember.isAdmin,
    });

    try {
      const service = new EntityMemberAPIService(session);
      await service.updateEntityMember(entityMember, updatedMember);
      enqueueSnackbar("Role updated", { variant: "success" });
    } catch (error) {
      console.error(error);
      if (error instanceof InvalidMemberUpdateException) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Failed to update member administration role", {
          variant: "error",
        });
      }
    }
  }

  async function handleResetPassword(entityMember: EntityMember) {
    const response = await confirm({
      title: "Reset Password?",
      message: "Send password reset email?",
      okButtonText: "Send",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      await AuthEmailAPIService.requestPasswordReset(entityMember.email);
      enqueueSnackbar("Password reset email sent", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to send password reset email", {
        variant: "error",
      });
    }
  }

  async function handleRemoveUser(entityMember: EntityMember) {
    const response = await confirm({
      title: "Remove User?",
      message: `Remove ${entityMember.name}'s user account from your organization?`,
      okButtonText: "Remove",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      // TODO: Implement
      enqueueSnackbar("Removed user from organization", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to remove user from organization", {
        variant: "error",
      });
    }
  }

  function renderNameColumn(data: EntityAdministrationTableData): React.ReactNode {
    const member = data as EntityMember;
    const invitiation = data as AdministratorInvitation;

    let name: string = "";
    if (activeTab === PageTab.Users)
      name = `${member.name?.firstName} ${member.name?.lastName}`;
    else
      name = `${invitiation.registrationInfo?.name?.firstName} ${invitiation.registrationInfo?.name?.lastName}`;

    let email: string = "";
    if (activeTab === PageTab.Users)
      email = member.email.value;
    else
      email = invitiation.registrationInfo?.email?.value;

    const contactLink = (
      <RowTitle>{name}</RowTitle>
    );

    return (
      <NameCellContainer>
        <IndividualAvatar
          avatarId={member.avatarId}
          individualId={member.individualId}
          session={session}
        />
        <NameText
          primary={contactLink}
          secondary={email}
        />
      </NameCellContainer >
    );
  }

  function renderRoleColumn(data: EntityAdministrationTableData) {
    const entityMember = data as EntityMember;
    if (entityMember.isOfficer) return 'Officer';
    if (entityMember.isAdmin) return 'Admin';
    return 'Member';
  }

  function renderEmailColumn(data: EntityAdministrationTableData): React.ReactNode {
    const invitation = data as AdministratorInvitation;
    const emailAddress = invitation?.registrationInfo?.email?.value ?? "Unknown";
    return <Link href={`mailto:${emailAddress}`}>{emailAddress}</Link>;
  }
  
  function renderStatusColumn(data: EntityAdministrationTableData): React.ReactNode {
    const invitation = data as AdministratorInvitation;
    return invitation?.status?.valueOf() ?? "Unknown";
  }

  const membersColumnHelper = createMRTColumnHelper<EntityAdministrationTableData>();
  let columns: (MRT_ColumnDef<EntityAdministrationTableData, React.ReactNode> | MRT_ColumnDef<EntityAdministrationTableData, "Officer" | "Admin" | "Member"> | MRT_ColumnDef<EntityAdministrationTableData, string | undefined>)[] = [];
  if (activeTab === PageTab.Users) {
    columns = [
      membersColumnHelper.accessor(renderNameColumn, {
        header: "Name",
        id: "name",
      }),
      membersColumnHelper.accessor(renderRoleColumn, {
        header: "Role",
        id: "isAdmin",
      }),
      membersColumnHelper.accessor((member) => (member as EntityMember).created?.toDateString(), {
        header: "Registered",
        id: "registered",
      })
    ];
  }
  if (activeTab === PageTab.Invitations) {
    columns = [
      membersColumnHelper.accessor(renderNameColumn, {
        header: "Name",
        id: "name",
      }),
      membersColumnHelper.accessor(renderEmailColumn, {
        header: "Email",
        id: "email",
      }),
      membersColumnHelper.accessor(renderStatusColumn, {
        header: "Status",
        id: "status",
      })
    ];
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    enableRowActions: activeTab === PageTab.Invitations,
    enableColumnPinning: true,
    initialState: {
      columnPinning: { right: ["mrt-row-actions"] },
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilters: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setQuery,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (activeTab === PageTab.Users) {
          const individualId = (row.original as EntityMember).individualId;
          handleViewProfile(individualId);
        }
      },
      sx: { cursor: "pointer" },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
            <Button
              startIcon={<PersonAddIcon />}
              onClick={() => navigate(`/administration/${activeTab}/invite`)}>
              {activeTab === PageTab.Users ? 'Invite Users' : 'Invite Administrators'}
            </Button>
          </ToolbarButtonsContainer>
        )}
        {isMediumDisplaySize && (
          <NewButton
            color="primary"
            onClick={() => navigate(`/administration/${activeTab}/invite`)}
          >
            <PersonAddIcon fontSize='large' />
          </NewButton>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Administration
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/administration/${newValue}`)}
            >
              <Tab value={PageTab.Users} label="Users" />
              <Tab value={PageTab.Invitations} label="Invitations" />
            </Tabs>
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    renderRowActions: ({ row, table }) => {
      const data = row.original as EntityAdministrationTableData;
      return (
        <ToolbarButtons>
          {(activeTab === PageTab.Invitations && (data as AdministratorInvitation).status === InvitationStatus.Open) && (
            <Tooltip title="Cancel Invitation">
              <span>
            <IconButton
              onClick={() => handleCancelInvite(data as AdministratorInvitation)}
            >
              <DeleteIcon color="error" />
            </IconButton>
            </span>
            </Tooltip>
          )}
        </ToolbarButtons>
      );
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    rowCount,
    state: {
      columnFilters,
      globalFilter: query,
      isLoading,
      pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  if (!session.user?.companyEntityId || !session.user?.isCompanyManager) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return <MaterialReactTable table={table} />;
}
