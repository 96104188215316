import Team from "work/values/team/team";
import Guid from "common/values/guid/guid";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import DetailedTeam from "work/values/team/detailed-team";
import WorkMarketplaceInfoAPIResponse from "work/contracts/responses/work-marketplace-info-api-response";



export default class DetailedWorkTeamAPIResponse {
    description?: string;
    leader?: WorkMarketplaceInfoAPIResponse;
    members: WorkMarketplaceInfoAPIResponse[] = [];
    marketplaceId?: string;

    deserialize(): Team {
        if (!this.leader?.userId || !this.leader?.entityId) {
            throw new InvalidDetailedWorkTeamAPIResponseError('Team leader entity/user id not returned by api');
        }

        const marketplaceId = this.marketplaceId ? new Guid(this.marketplaceId) : undefined;
        const members = this.members.map((member) => {
            if (!member.userId) throw new Error("Member user id not returned.");
            return new Guid(member.userId);
        });
        const team = new Team(
            new EntityVendorRepresentative(new Guid(this.leader.userId), new Guid(this.leader.entityId)),
            members,
            marketplaceId
        );

        return team;
    }

    deserializeDetailed(): DetailedTeam {
        if (!this.leader?.userId || !this.leader?.entityId) {
            throw new InvalidDetailedWorkTeamAPIResponseError('Team leader entity/user id not returned by api');
        }

        const team = new DetailedTeam(
            Object.assign(new WorkMarketplaceInfoAPIResponse(), this.leader).deserialize(),
            this.members.map(member => Object.assign(new WorkMarketplaceInfoAPIResponse(), member).deserialize()),
            this.marketplaceId ? new Guid(this.marketplaceId) : undefined,
            this.description,
        );

        return team;
    }
}

export class InvalidDetailedWorkTeamAPIResponseError extends Error { }
