import Proposal, { ProposalFieldCategory } from 'work/entities/proposal/proposal';
import FeeScheduleCategories from 'work/values/fee-schedule-category/view/fee-schedule-categories';

type FeeScheduleTabProps = {
  activeTab: ProposalFieldCategory,
  proposal?: Proposal
};

export default function FeeScheduleTab(props: Readonly<FeeScheduleTabProps>) {
  const {
    activeTab,
    proposal
  } = props;

  if (activeTab !== ProposalFieldCategory.FeeSchedule) return null;

  return (
    <FeeScheduleCategories
      categories={proposal?.feeSchedule ?? []}
      hideFromTemplateButton={true}
      viewOnly
    />
  );
}
