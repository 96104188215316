import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import EntityAgreement from "legal-entities/entities/entity-agreement/entity-agreement";
import EntityName from "legal-entities/values/entity-name/entity-name";

export default class EntityAgreementAPIResponse {
    id?: string;
    entityName?: string;
    administratorFirstName?: string;
    administratorLastName?: string;
    administratorEmail?: string;
    termsFileId?: string;

    deserialize(): EntityAgreement {
        if (!this.id || !this.entityName) throw new Error("Invalid EntityAgreementAPIResponse");
        const agreement = new EntityAgreement(
            new Guid(this.id),
            new EntityName(this.entityName),
        );
        if (this.administratorFirstName && this.administratorLastName) {
            agreement.administratorName = new Name(this.administratorFirstName, this.administratorLastName);
        }
        if (this.administratorEmail) {
            agreement.administratorEmail = new EmailAddress(this.administratorEmail);
        }
        if (this.termsFileId) {
            agreement.termsFileId = new Guid(this.termsFileId);
        }
        return agreement;
    }
}
