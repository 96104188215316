import { Currency } from "common/values/currency/currency";
import Money from "common/values/money/money";
import Fee from "work/values/fee/fee";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import Guid from "common/values/guid/guid";

export default class WorkFeeScheduleCategoryAPIResponse {
  id?: string;
  name?: string;
  description?: string;
  billingCode?: string;
  feeRate?: number;
  feeCurrency?: string;
  feeUnit?: string;

  deserialize(): FeeScheduleCategory {
    if (this.name === undefined)
      throw new Error("Name not provided by API for fee schedule category.");
    if (this.description === undefined)
      throw new Error(
        "Description not provided by API for fee schedule category."
      );
    if (this.billingCode === undefined)
      throw new Error(
        "Billing code not provided by API for fee schedule category."
      );
    const fee = this.feeRate
      ? new Fee(
          new Money(
            this.feeRate,
            Currency[this.feeCurrency as keyof typeof Currency]
          ),
          this.feeUnit ?? null
        )
      : null;
    return new FeeScheduleCategory(
      new FeeScheduleCategoryName(this.name),
      new FeeScheduleCategoryDescription(this.description),
      fee,
      this.billingCode ? new FeeScheduleBillingCode(this.billingCode) : null,
      this.id && !Guid.isEmpty(this.id) ? new Guid(this.id) : Guid.generate()
    );
  }
}
