import Guid from "common/values/guid/guid";
import Bookmark from "marketplace/entities/bookmark/bookmark";
import { BookmarkType } from "marketplace/values/bookmark/bookmark";
import { Moment } from "moment";
import MarketplaceTeam from "../marketplace-team/marketplace-team";

export default class BookmarkedTeam implements Bookmark {
    vendor: MarketplaceTeam;
    id: Guid;
    createdDate: Moment;
    bookmarkId: Guid;
    type: BookmarkType;

    constructor(id: Guid, bookmarkId: Guid, createdDate: Moment, vendor: MarketplaceTeam) {
        this.vendor = vendor;
        this.id = id;
        this.bookmarkId = bookmarkId;
        this.createdDate = createdDate;
        this.type = BookmarkType.Team;
    }
}
