import Guid from "common/values/guid/guid";

export default class TeamProfile {
  name: string;
  description?: string;
  contactUserId?: Guid;
  categories?: string[];
  avatarId?: Guid;

  constructor(
    name: string,
    description?: string,
    categories?: string[],
    avatarId?: Guid,
    contactUserId?: Guid
  ) {
    this.name = name;
    this.description = description;
    this.categories = categories ?? [];
    this.avatarId = avatarId;
    this.contactUserId = contactUserId;
  }

  clone(): TeamProfile {
    const newProfile = new TeamProfile(this.name);
    Object.assign(newProfile, this);
    return newProfile;
  }

  updateName(name: string): TeamProfile {
    return new TeamProfile(
      name,
      this.description,
      this.categories,
      this.avatarId,
      this.contactUserId
    );
  }

  updateDescription(description: string): TeamProfile {
    return new TeamProfile(
      this.name,
      description,
      this.categories,
      this.avatarId,
      this.contactUserId
    );
  }

  updateCategories(categories: string[]): TeamProfile {
    return new TeamProfile(
      this.name,
      this.description,
      categories,
      this.avatarId,
      this.contactUserId
    );
  }

  updateContactId(contactUserId: Guid): TeamProfile {
    return new TeamProfile(
      this.name,
      this.description,
      this.categories,
      this.avatarId,
      contactUserId
    );
  }

  public toJSON(): any { 
    return {
      name: this.name,
      description: this.description,
      categories: this.categories,
      avatarId: this.avatarId?.toJSON(),
      contactUserId: this.contactUserId?.toJSON()
    };
  }
}
