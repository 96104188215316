import UserInfoAPIResponse from "users/entities/user/api/response-contracts/user-info-api-response";
import UserEntityMemberInvitation from "../../user-entity-member-invitation";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import EntityName from "legal-entities/values/entity-name/entity-name";
import { InvitationStatus } from "legal-entities/values/invitation-status";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import RegistrationInfo from "legal-entities/values/registration-info";
import Name from "common/values/name/name";


export default class UserMemberInvitationAPIResponse {
    inviteId?: string;
    entityName?: string;
    entityId?: string;
    user?: UserInfoAPIResponse;
    status?: string;

    deserialize(): UserEntityMemberInvitation {
        if (!this.inviteId || !this.entityName || !this.entityId || !this.user || !this.status || !this.user.emailAddress) throw new Error("Invalid UserMemberInvitationAPIResponse");
        const entity = new LegalEntity(new Guid(this.entityId), new EntityName(this.entityName));
        const invitedEmail = new EmailAddress(this.user.emailAddress);
        const invitation = new UserEntityMemberInvitation(entity, invitedEmail);
        invitation.status = InvitationStatus[this.status as keyof typeof InvitationStatus];
        invitation.id = new Guid(this.inviteId);

        if (this.user.firstName && this.user.lastName) {
            invitation.registrationInfo = new RegistrationInfo(
                new Name(this.user.firstName, this.user.lastName),
                new EmailAddress(this.user.emailAddress)
            );
        }
            
        return invitation;
    }
}
