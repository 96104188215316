
import { formatCurrency } from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import Fee from "work/values/fee/fee";

export default class FeeScheduleCategory {
  id: Guid;
  name: FeeScheduleCategoryName;
  description: FeeScheduleCategoryDescription | null;
  fee: Fee | null;
  billingCode: FeeScheduleBillingCode | null;

  constructor(
    name: FeeScheduleCategoryName,
    description: FeeScheduleCategoryDescription | null,
    fee: Fee | null,
    billingCode: FeeScheduleBillingCode | null,
    id: Guid
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.fee = fee;
    this.billingCode = billingCode;
  }

  public isEqualTo(other: FeeScheduleCategory | null): boolean {
    if (!other) return false;
    return (
      this.name?.isEqualTo(other.name) &&
      this.description?.isEqualTo(other.description) &&
      ((!this.fee && !other.fee) || this.fee?.isEqualTo(other.fee)) &&
      this.billingCode ? this.billingCode.isEqualTo(other.billingCode) : !other.billingCode
    );
  }

  public clone(): FeeScheduleCategory {
    return new FeeScheduleCategory(
      this.name,
      this.description,
      this.fee,
      this.billingCode,
      this.id
    );
  }

  public fromObject(obj: any): FeeScheduleCategory | null {
    if (!obj) return null;
    if (!obj.id) throw new Error('FeeScheduleCategory.fromObject: id is required');
    if (!obj.name) throw new Error('FeeScheduleCategory.fromObject: name is required');
    return new FeeScheduleCategory(
      new FeeScheduleCategoryName(obj.name),
      new FeeScheduleCategoryDescription(obj.description ?? ""),
      obj.fee ? Fee.Prototype.fromObject(obj.fee) : null,
      obj.billingCode ? new FeeScheduleBillingCode(obj.billingCode) : null,
      Guid.fromObject(obj.id) ?? Guid.empty()
    );
  }

  toString(): string {
    if (!this.fee)
      return (this.description ? `${this.name.value}: ${this.description.toString()}\n` : "") + '(Deferred Fee)';
    return `${this.name.value}: ${this.description?.toString()}\n
      ${!this.fee?.unit ? 'Fee' : 'Rate'}: ${formatCurrency(this.fee?.rate)} ${this?.fee?.rate?.currency.toString() ?? ''} ${this.fee?.unit ? '/ ' + this.fee.unit : ''}`;
  }

  public toJSON(): object {
    return {
      id: this.id.toJSON(),
      name: this.name.toJSON(),
      description: this.description?.toJSON() ?? "",
      fee: this.fee ? this.fee.toJSON() : null,
      billingCode: this.billingCode?.toJSON() ?? ""
    };
  }

  get feeString(): string {
    if (!this.fee)
      return `${'(Deferred Fee)'}`;
    return `${!this.fee?.unit ? 'Fee' : 'Rate'}: ${formatCurrency(this.fee?.rate)} ${this?.fee?.rate?.currency.toString() ?? ''} ${this.fee?.unit ? '/ ' + this.fee?.unit : ''}`;
  }
  
  public static get Prototype() {
    return new FeeScheduleCategory(
      FeeScheduleCategoryName.Prototype,
      FeeScheduleCategoryDescription.Prototype,
      null,
      null,
      Guid.generate()
    );
  }
}
