import Proposal, { ProposalFieldCategory } from 'work/entities/proposal/proposal';
import { TeamMember } from 'work/values/team/team-member';
import ViewTeam from 'work/values/team/view/view-team';

type TeamTabProps = {
  activeTab: ProposalFieldCategory,
  proposal?: Proposal
};

export default function TeamTab(props: Readonly<TeamTabProps>) {
  const {
    activeTab,
    proposal
  } = props;
  
  if (activeTab !== ProposalFieldCategory.Team) return null;

  return (
    <ViewTeam 
      leader={
        proposal?.details?.team?.leader?.userId ?
        {
          avatarId: proposal?.details?.team?.leader.profile?.avatarId,
          individualId: proposal?.details?.team?.leader.id,
          userId: proposal?.details?.team?.leader?.userId,
          entityId: proposal?.details?.team?.leader.profile?.entityId,
          firstName: proposal?.details?.team?.leader.profile?.firstName ?? 'Unknown',
          lastName: proposal?.details?.team?.leader.profile?.lastName ?? 'Unknown'
        } : undefined
      }
      members={
        proposal?.details?.team?.members.map(member => {
          if (!member.userId) return undefined;
          return {
            avatarId: member.profile?.avatarId,
            individualId: member.id,
            userId: member.profile?.userId,
            entityId: member.profile?.entityId,
            firstName: member.profile?.firstName ?? 'Unknown',
            lastName: member.profile?.lastName ?? 'Unknown'
          } as TeamMember;
        }).filter(member => member !== undefined)
      }
    />
  );
}
