export default class DocumentFilterParameters {
  name?: string;
  minCreatedDate?: string;
  maxCreatedDate?: string;

  constructor(
    name?: string,
    minCreatedDate?: string,
    maxCreatedDate?: string
  ) {
    this.name = name;
    this.minCreatedDate = minCreatedDate;
    this.maxCreatedDate = maxCreatedDate;
  }
}
