import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import Session from 'users/session/session';
import * as Constants from 'common/helpers/constants';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

interface RealtimeContextType {
  connection: HubConnection | null;
  startConnection: () => Promise<void>;
}

interface RealtimeProviderProps {
  children: ReactNode;
  hubName: HubName;
}

export enum HubName {
  Notification = 'notificationHub',
  Proposal = 'proposalHub',
  Message = 'messageHub'
}

const RealtimeNotificationContext = createContext<RealtimeContextType | undefined>(undefined);
const RealtimeProposalContext = createContext<RealtimeContextType | undefined>(undefined);
const RealtimeMessageContext = createContext<RealtimeContextType | undefined>(undefined);

export const RealtimeProvider: React.FC<RealtimeProviderProps> = ({ children, hubName }) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${Constants.apiBaseUrl}/${hubName}`,
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => Session.loadFromStorage(() => { }).authToken?.value ?? ''
        }
      )
      .withAutomaticReconnect()
      .build();

    newConnection.onreconnecting((error) => {
      console.log(`Reconnecting to ${hubName}...`, error);
    });

    newConnection.onreconnected((connectionId) => {
      console.log('Reconnected. Connection ID:', connectionId);
    });

    newConnection.onclose(async () => {
      console.log(`${hubName} connection closed. Attempting to reconnect...`);
      await startConnection();
    });

    setConnection(newConnection);

    return () => {
      if (newConnection && newConnection.state !== HubConnectionState.Disconnected) {
        newConnection.stop();
      }
    };
  }, []);

  const startConnection = async () => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      try {
        await connection.start();
        console.log(`Connected to ${hubName}`);
      } catch (err) {
        console.error(`${hubName} connection connection failed: `, err);
      }
    }
  };

  if (hubName === HubName.Notification) {
    return (
      <RealtimeNotificationContext.Provider value={{ connection, startConnection }}>
        {children}
      </RealtimeNotificationContext.Provider>
    );
  }
  if (hubName === HubName.Proposal) {
    return (
      <RealtimeProposalContext.Provider value={{ connection, startConnection }}>
        {children}
      </RealtimeProposalContext.Provider>
    );
  }
  if (hubName === HubName.Message) {
    return (
      <RealtimeMessageContext.Provider value={{ connection, startConnection }}>
        {children}
      </RealtimeMessageContext.Provider>
    );
  }
};

export const useRealtime = (hubName: HubName): RealtimeContextType => {
  let context;
  if (hubName === HubName.Notification) {
    context = RealtimeNotificationContext;
  }
  if (hubName === HubName.Proposal) {
    context = RealtimeProposalContext;
  }
  if (hubName === HubName.Message) {
    context = RealtimeMessageContext;
  }
  if (!context) {
    throw new Error(`Invalid hub name: ${hubName}`);
  }
  const contextType = useContext(context);
  if (!contextType) {
    throw new Error('useRealtime must be used within a RealtimeProvider');
  }
  return contextType;
};
