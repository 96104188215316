import Guid from "common/values/guid/guid";
import MessageFileInfo from "messaging/values/attachment/message-file-info";

import moment from "moment";

export default class MessageFileInfoAPIResponse {
    id?: string;
    name?: string;
    fileSize?: number;
    mimeType?: string;
    created?: string;

    deserialize(): MessageFileInfo {
        if (!this.id) throw new Error("Message file id not returned.");
        return new MessageFileInfo(
            new Guid(this.id),
            this.name ?? "",
            this.fileSize ?? 0,
            this.mimeType ?? "",
            moment(this.created) ?? moment()
        );
    }
}
