import { TextField, TextFieldVariants } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef, useState, useEffect } from "react";

import EntityName, { InvalidEntityNameError } from "legal-entities/values/entity-name/entity-name";

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  minWidth: theme.spacing(40)
}));

export interface EntityNameInputError extends String { };

type EntityNameInputProps = {
  idPrefix?: string;
  className?: string;
  entityName?: EntityName;
  label?: string;
  variant?: TextFieldVariants;
  onChange?: (entityName?: EntityName, error?: EntityNameInputError) => void;
};

const EntityNameInput = forwardRef((props: EntityNameInputProps, ref) => {
  const { idPrefix, className, label, variant, entityName, onChange } = props;
  const [entityNameFieldText, setEntityNameFieldText] = useState<string>(entityName?.value ?? '');
  const [entityNameFieldError, setEntityNameFieldError] = useState<EntityNameInputError>('');

  useEffect(() => {
    setEntityNameFieldText(entityName?.value ?? '');
  }, [entityName]);

  function buildEntityName(inputText?: string): EntityName | undefined {
    try {
      if (!inputText) throw new InvalidEntityNameError("Entity name is required");
      return new EntityName(inputText);
    } catch (error: any) {
      if (error instanceof InvalidEntityNameError) {
        throw error;
      }
    }
  }

  function handleEntityNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setEntityNameFieldText(event.target.value);

    let newEntityName: EntityName | undefined;
    let newErrors: EntityNameInputError = '';

    try {
      newEntityName = buildEntityName(event.target.value);
      setEntityNameFieldError('');
    } catch (error: any) {
      newErrors = error.message;
      setEntityNameFieldError(newErrors);
    } finally {
      onChange?.(newEntityName, newErrors);
    }
  }

  return (
    <InputField
      id={idPrefix ? idPrefix + "-entity-name-input" : "entity-name-input"}
      className={className}
      label={label ?? "Entity Name"}
      onChange={handleEntityNameChanged}
      required
      value={entityNameFieldText}
      error={!!entityNameFieldError}
      helperText={entityNameFieldError}
      variant={variant ?? "outlined"}
      autoComplete="organization"
    />
  );
});

export default EntityNameInput;
