import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { styled } from '@mui/material/styles';
import LoadingButton from 'common/components/loading-button';
import Guid from 'common/values/guid/guid';
import BookmarkAPIService from 'marketplace/entities/bookmark/api/bookmark-api-service';
import BookmarkedCompany from 'marketplace/entities/bookmarked-company/bookmarked-company';
import BookmarkedIndividual from 'marketplace/entities/bookmarked-individual/bookmarked-individual';
import BookmarkedTeam from 'marketplace/entities/bookmarked-team/bookmarked-team';
import { BookmarkType } from 'marketplace/values/bookmark/bookmark';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSession } from 'users/session/session-context';

const StyledBookmarkButton = styled(LoadingButton)<{ selected: boolean }>(({ selected, theme }) => ({
  backgroundColor: selected ? theme.palette.primary.main : undefined,
  color: selected ? theme.palette.primary.contrastText : undefined,
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(24),
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: selected ? theme.palette.primary.main : undefined,
    color: selected ? theme.palette.primary.contrastText : undefined
  }
}));

export interface BookmarkButtonProps {
  className?: string;
  type: BookmarkType;
  vendorId: Guid | undefined;
}

export default function BookmarkButton(props: Readonly<BookmarkButtonProps>) {
  const { className, type, vendorId } = props;

  const [loading, setLoading] = React.useState(Boolean(false));
  const [existingBookmark, setExistingBookmark] = React.useState<BookmarkedIndividual | BookmarkedCompany | BookmarkedTeam | undefined>();

  const { enqueueSnackbar } = useSnackbar();
  const session = useSession();

  async function createBookmark(type: BookmarkType, vendorId: Guid):
    Promise<BookmarkedIndividual | BookmarkedCompany | BookmarkedTeam | undefined> {
    const bookmarkService = new BookmarkAPIService(session);
    if (type === BookmarkType.Company) {
      return await bookmarkService.createCompanyBookmark(vendorId);
    } else if (type === BookmarkType.Team) {
      return await bookmarkService.createTeamBookmark(vendorId);
    } else if (type === BookmarkType.Individual) {
      return await bookmarkService.createIndividualBookmark(vendorId);
    }
    return undefined;
  };

  async function deleteBookmark(type: BookmarkType, bookmarkId: Guid): Promise<void> {
    const bookmarkService = new BookmarkAPIService(session);
    if (type === BookmarkType.Company) {
      await bookmarkService.deleteCompanyBookmark(bookmarkId);
    } else if (type === BookmarkType.Team) {
      await bookmarkService.deleteTeamBookmark(bookmarkId);
    } else if (type === BookmarkType.Individual) {
      await bookmarkService.deleteIndividualBookmark(bookmarkId);
    }
  }

  useEffect(() => {
    async function getBookmarks() {
      try {
        let bookmarks: BookmarkedCompany[] | BookmarkedTeam[] | BookmarkedIndividual[] = [];
        const bookmarkService = new BookmarkAPIService(session);
        switch (type) {
          case BookmarkType.Company:
            bookmarks = await bookmarkService.getAllCompanyBookmarksForUser(new AbortController());
            setExistingBookmark(bookmarks.find((bookmark: BookmarkedCompany) => {
              return bookmark.id.isEqualTo(vendorId);
            }));
            break;
          case BookmarkType.Team:
            bookmarks = await bookmarkService.getAllTeamBookmarksForUser(new AbortController());
            setExistingBookmark(bookmarks.find((bookmark: BookmarkedTeam) => {
              return bookmark.id.isEqualTo(vendorId);
            }));
            break;
          case BookmarkType.Individual:
            bookmarks = await bookmarkService.getAllIndividualBookmarksForUser(new AbortController());
            setExistingBookmark(bookmarks.find((bookmark: BookmarkedIndividual) => {
              return bookmark.id.isEqualTo(vendorId);
            }));
            break;
        }
      } catch (error: any) {
        console.error(error);
        setExistingBookmark(undefined);
      }
    }
    getBookmarks();
  }, []);

  const handleBookmarkClick = async () => {
    try {
      setLoading(true);
      if (existingBookmark) {
        await deleteBookmark(type, existingBookmark.bookmarkId);
        setExistingBookmark(undefined);
        enqueueSnackbar("Removed bookmark", { variant: 'info' });
      } else {
        if (!vendorId) throw new Error("Vendor ID is undefined");
        const bookmark = await createBookmark(type, vendorId);
        setExistingBookmark(bookmark);
        enqueueSnackbar("Added bookmark", { variant: 'info' });
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Couldn't save bookmark", { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledBookmarkButton
      className={className}
      selected={existingBookmark !== undefined}
      variant="outlined"
      aria-label="Bookmark"
      loading={loading}
      startIcon={<BookmarkBorderIcon />}
      disableElevation
      onClick={handleBookmarkClick}>
      {existingBookmark ? 'Bookmarked' : 'Bookmark'}
    </StyledBookmarkButton>
  );
}
