import EmailAddress from "common/values/email-address/email-address";
import Password from "common/values/password/password";

export default class UserCredentials {
    email: EmailAddress;
    password: Password;

    constructor (email: EmailAddress, password: Password) {
        this.email = email;
        this.password = password;
    }
}

export class InvalidCredentialsError extends Error {
    constructor() {
        super("Invalid credentials");
    }
}