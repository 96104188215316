import { Icon } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import DiffChangePopover from "work/entities/proposal/redlining/_diff/view/diff-change-popover";
import { green, lightBlue } from "@mui/material/colors";
import TextChange from "work/entities/proposal/redlining/_diff/text-change";

const DiffResult = styled("span")<{
  change?: TextChange;
  allResolved?: boolean;
}>(({ change, allResolved, theme }) => ({
  backgroundColor: (function () {
    if (allResolved) return "unset";
    if (change?.isAdded) return green[300];
  })(),
  color: (function () {
    if (change?.isAdded && allResolved) return lightBlue[700];
    if (change?.isAdded) return theme.palette.text.primary;
    return theme.palette.text.primary;
  })(),
  textDecoration: (function () {
    if (change?.isRemoved) return "line-through";
  })(),
  textDecorationColor: (function () {
    if (change?.isRemoved && allResolved) return lightBlue[700];
    if (change?.isRemoved) return theme.palette.error.main;
    return "initial";
  })(),
}));
const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  paddingRight: theme.spacing(2),
  width: "100%",
}));
const ChangeGroup = styled("span")(({ theme }) => ({
  whiteSpace: "nowrap"
}));

export interface DiffTextProps {
  changes: TextChange[];
  disablePopover: boolean;
  allResolved?: boolean;
  onAcceptChange?: (change: TextChange) => void;
  onRejectChange?: (change: TextChange) => void;
  onEditChange?: (change: TextChange, newValue: string) => void;
  onPopoverChange?: (isOpen: boolean) => void;
  onClick?: (event: any) => void;
}

export default function DiffText(props: Readonly<DiffTextProps>) {
  const {
    changes,
    disablePopover,
    allResolved,
    onAcceptChange,
    onRejectChange,
    onEditChange,
    onPopoverChange,
    onClick
  } = props;

  const [currentChange, setCurrentChange] = React.useState<TextChange>(
    changes[0]
  );
  const [popoverAnchorElement, setPopoverAnchorElement] =
    React.useState<Element | null>(null);

  useEffect(() => {
    if (!disablePopover && (!onAcceptChange || !onRejectChange || !onEditChange)) {
      throw new Error("onAcceptChange, onRejectChange, onEditChange are required when disablePopover is false");
    }
  }, []);

  const getChangeKey = (change: TextChange): string => {
    let changeType: string;
    if (change.isAdded) {
      changeType = "added";
    } else if (change.isRemoved) {
      changeType = "removed";
    } else {
      changeType = "unchanged";
    }
    return `${change.id}_${change?.diff.value}_${changeType}`;
  };

  function handleDiffPopoverOpen(event: React.MouseEvent, change: TextChange) {
    if (
      disablePopover ||
      change?.parent?.allChangesResolved ||
      (!change.isModified && !change.isAdded && !change.isRemoved)
    )
      return;

    setPopoverAnchorElement(event.currentTarget);
    setCurrentChange(change);
    onPopoverChange?.(true);
  }

  function handleDiffPopoverClose() {
    setPopoverAnchorElement(null);
    onPopoverChange?.(false);
  }

  function renderChanges(changes: TextChange[]) {
    let nodes = [];

    for (let changeIndex = 0; changeIndex < changes.length; changeIndex++) {
      if (
        changes?.[changeIndex - 1]?.isModified &&
        changes[changeIndex].isModified
      ) {
        continue;
      }
      if (
        changes[changeIndex].isModified &&
        changes[changeIndex + 1]?.isModified
      ) {
        const node = (
          <ChangeGroup
            key={getChangeKey(changes[changeIndex + 1])}
            onMouseEnter={(event) => {
              handleDiffPopoverOpen(event, changes[changeIndex]);
            }}
          >
            <DiffResult
              change={changes[changeIndex]}
              allResolved={allResolved}
            >
              {changes[changeIndex]?.diff.value}
            </DiffResult>
            <DiffResult
              change={changes[changeIndex + 1]}
              allResolved={allResolved}
            >
              {changes[changeIndex + 1]?.diff.value}
            </DiffResult>
          </ChangeGroup>
        );
        nodes.push(node);
        continue;
      }

      const node = (
        <DiffResult
          key={getChangeKey(changes[changeIndex])}
          change={changes[changeIndex]}
          allResolved={allResolved}
          onMouseEnter={(event) => {
            handleDiffPopoverOpen(event, changes[changeIndex]);
          }}
          onMouseLeave={handleDiffPopoverClose}
        >
          {changes[changeIndex]?.diff.value}
        </DiffResult>
      );
      nodes.push(node);
    }

    return <span>{nodes.map((node) => node)}</span>;
  }

  return (
    <TextContainer onMouseLeave={handleDiffPopoverClose} onClick={onClick}>
      {renderChanges(changes)}
      {!disablePopover && popoverAnchorElement && document.contains(popoverAnchorElement) && (
        <DiffChangePopover
          valueType="text"
          anchorElement={popoverAnchorElement}
          change={currentChange}
          onClosePopover={handleDiffPopoverClose}
          onAccept={onAcceptChange}
          onReject={onRejectChange}
          onEdit={onEditChange}
        />
      )}
    </TextContainer>
  );
}
