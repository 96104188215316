
import Guid from "common/values/guid/guid";
import WorkVendorRepresentativeAPIResponse from "work/entities/entity-vendor-representative/api/response-contracts/work-vendor-representative-api-response";
import Team from "work/values/team/team";

export default class SimpleWorkTeamAPIResponse {
    leader?: WorkVendorRepresentativeAPIResponse;
    members?: string[];
    marketplaceId?: string;

    deserialize(): Team {
        if (!this.leader) throw new Error('Leader is required');
        const team = new Team(
            Object.assign(new WorkVendorRepresentativeAPIResponse(), this.leader).deserialize(), 
            this.members?.map(member => new Guid(member)) ?? [], 
            this.marketplaceId ? new Guid(this.marketplaceId) : undefined
        );
        return team;
    }
}
