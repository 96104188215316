import Guid from "common/values/guid/guid";
import User from "users/entities/user/user";

export default class UnregisterAPIRequest {
    userId: Guid;

    constructor(user: User) {
        if(!user.id) throw new Error("User must have an id to register");
        this.userId = user.id;
    }
}