import Guid from "common/values/guid/guid";
import ForumInfo from "notifications/entities/forum-info/forum-info";
import MessageContent from "notifications/values/message-content/message-content";
import MessageSubject from "notifications/values/message-subject/message-subject";
import RecipientName from "notifications/values/recipient-name/recipient-name";
import SenderName from "notifications/values/sender-name/sender-name";
export default class MessageInfo {
  id: Guid;
  senderId?: Guid | null;
  senderName?: SenderName;
  recipientId?: Guid | null;
  recipientName: RecipientName;
  forum: ForumInfo;
  content: MessageContent;
  subject?: MessageSubject;

  constructor(
    id: Guid,
    recipientName: RecipientName,
    forum: ForumInfo,
    content: MessageContent,
    subject?: MessageSubject,
    senderId?: Guid | null,
    senderName?: SenderName,
    recipientId?: Guid | null
  ) {
    this.id = id;
    this.recipientId = recipientId;
    this.forum = forum;
    this.content = content;
    this.subject = subject;
    this.senderId = senderId;
    this.senderName = senderName;
    this.recipientName = recipientName;
  }
}
