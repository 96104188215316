import { Box, Chip, FormControl, ListItemText, MenuItem, TextField, Theme, useTheme } from "@mui/material";
import * as Constants from "common/helpers/constants";
import { styled } from "@mui/material/styles";

const Container = styled(FormControl)(({ theme }) => ({
  maxWidth: '100%',
  minWidth: '33%'
}));

type TeamProfileCategoriesSelectProps = {
  className?: string;
  categories?: string[];
  onChange: (updatedCategories: string[]) => void;
};

export default function TeamProfileCategoriesSelect(props: Readonly<TeamProfileCategoriesSelectProps>) {
  const { className } = props;

  const theme = useTheme();

  const getStyle = (category: string, categories: readonly string[], theme: Theme) => {
    return {
      fontWeight: categories.includes(category)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular
    }
  }

  function renderChips(selected: any): React.ReactNode {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map((option: any) => (
          <Chip key={option} label={option} />
        ))}
      </Box>
    )
  }

  return (
    <Container className={className}>
      <TextField
        select
        label="Profile Type(s)"
        required
        variant="outlined"
        slotProps={{
          select: {
            multiple: true,
            renderValue: renderChips
          }
        }}
        size='small'
        error={props.categories?.length === 0}
        value={props.categories ?? []}
        onChange={(event) => props.onChange(Array.from(event.target.value))}>
        {Constants.CompanyProfileTypes.map((category) => (
          <MenuItem key={category} value={category}>
            <ListItemText primary={category} style={getStyle(category, props.categories ?? [], theme)} />
          </MenuItem>
        ))}
      </TextField>
    </Container>
  );
}
