import Guid from "common/values/guid/guid";
import DocumentTopic from "documents/values/document-topic";

export default class DocumentTopicApiResponse {
    entityClass?: string;
    entityId?: string;
    context?: string;

    deserialize(): DocumentTopic {
        return new DocumentTopic(
            this.entityClass,
            this.entityId ? new Guid(this.entityId) : undefined,
            this.context
        );
    }
}