export type DocumentOrderField = 'name' | 'created';

export default class DocumentOrderParameters {
  field: DocumentOrderField;
  direction: 'asc' | 'desc';

  constructor(
    field: DocumentOrderField,
    direction: 'asc' | 'desc'
  ) {
    this.field = field;
    this.direction = direction;
  }
}
