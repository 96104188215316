import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import Session from "users/session/session";
import Guid from "common/values/guid/guid";
import MarketplaceCompanyBookmarkAPIResponse from "marketplace/entities/bookmarked-company/api/response-contracts/marketplace-company-bookmark-api-response";
import BookmarkedCompany from "marketplace/entities/bookmarked-company/bookmarked-company";
import MarketplaceIndividualBookmarkAPIResponse from "marketplace/entities/bookmarked-individual/api/response-contracts/marketplace-individual-bookmark-api-response";
import BookmarkedIndividual from "marketplace/entities/bookmarked-individual/bookmarked-individual";
import MarketplaceTeamBookmarkAPIResponse from "marketplace/entities/bookmarked-team/api/response-contracts/marketplace-team-bookmark-api-response";
import BookmarkedTeam from "marketplace/entities/bookmarked-team/bookmarked-team";
import Bookmarks from "marketplace/entities/bookmark/bookmarks";
import MarketplaceBookmarksAPIResponse from "marketplace/entities/bookmark/api/response-contracts/marketplace-bookmarks-api-response";


export default class BookmarkAPIService {
  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create BookmarkAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getAllBookmarksForUser(abortController: AbortController): Promise<Bookmarks> {
    const url = new URL(`/marketplace/bookmarks`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return Object.assign(
        new MarketplaceBookmarksAPIResponse(),
        response.data
      ).deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new BookmarksRequestError("getAllBookmarksForUser", error);
    }
  }

  async getAllCompanyBookmarksForUser(abortController?: AbortController): Promise<BookmarkedCompany[]> {
    const url = new URL(`/marketplace/company-bookmarks`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const bookmarks: Array<BookmarkedCompany> = [];
      response.data.forEach((element: any) => {
        let companyBookmark = Object.assign(
          new MarketplaceCompanyBookmarkAPIResponse(),
          element
        );
        bookmarks.push(companyBookmark.deserialize());
      });
      return bookmarks;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new BookmarksRequestError("getAllCompanyBookmarksForUser", error);
    }
  }

  async getAllIndividualBookmarksForUser(abortController?: AbortController): Promise<BookmarkedIndividual[]> {
    const url = new URL(`/marketplace/individual-bookmarks`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const bookmarks: Array<BookmarkedIndividual> = [];
      response.data.forEach((element: any) => {
        let individualBookmark = Object.assign(
          new MarketplaceIndividualBookmarkAPIResponse(),
          element
        );
        bookmarks.push(individualBookmark.deserializeToBookmark());
      });
      return bookmarks;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new BookmarksRequestError("getAllIndividualBookmarksForUser", error);
    }
  }

  async getAllTeamBookmarksForUser(abortController?: AbortController): Promise<BookmarkedTeam[]> {
    const url = new URL(`/marketplace/team-bookmarks`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const bookmarks: Array<BookmarkedTeam> = [];
      response.data.forEach((element: any) => {
        let teamBookmark = Object.assign(
          new MarketplaceTeamBookmarkAPIResponse(),
          element
        );
        bookmarks.push(teamBookmark.deserializeToBookmark());
      });
      return bookmarks;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new BookmarksRequestError("getAllTeamBookmarksForUser", error);
    }
  }

  async createCompanyBookmark(companyId: Guid): Promise<BookmarkedCompany> {
    const url = new URL(`/marketplace/company-bookmark/${companyId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const companyBookmark = Object.assign(
        new MarketplaceCompanyBookmarkAPIResponse(),
        response.data
      );
      return companyBookmark.deserialize();
    } catch (error: any) {
      if (error.response.status === 404)
        throw new BookmarkedCompanyNotFoundException(
          "Unable to create company bookmark: company not found."
        );
      else
        throw error;
    }
  }

  async createIndividualBookmark(individualId: Guid): Promise<BookmarkedIndividual> {
    const url = new URL(`/marketplace/individual-bookmark/${individualId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const individualBookmark = Object.assign(
        new MarketplaceIndividualBookmarkAPIResponse(),
        response.data
      );
      return individualBookmark.deserializeToBookmark();
    } catch (error: any) {
      if (error.response.status === 404)
        throw new BookmarkedIndividualNotFoundException(
          "Unable to create individual bookmark: individual not found."
        );
      else
        throw error;
    }
  }

  async createTeamBookmark(teamId: Guid): Promise<BookmarkedTeam> {
    const url = new URL(`/marketplace/team-bookmark/${teamId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const teamBookmark = Object.assign(
        new MarketplaceTeamBookmarkAPIResponse(),
        response.data
      );
      return teamBookmark.deserializeToBookmark();
    } catch (error: any) {
      if (error.response.status === 404)
        throw new BookmarkedTeamNotFoundException(
          "Unable to create team bookmark: team not found."
        );
      else
        throw error;
    }
  }

  async deleteCompanyBookmark(bookmarkId: Guid): Promise<void> {
    const url = new URL(`/marketplace/company-bookmark/${bookmarkId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error.response.status === 404)
        throw new BookmarkedCompanyNotFoundException(
          "Unable to delete company bookmark: bookmark not found."
        );
      else
        throw error;
    }
  }

  async deleteIndividualBookmark(bookmarkId: Guid): Promise<void> {
    const url = new URL(`/marketplace/individual-bookmark/${bookmarkId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error.response.status === 404)
        throw new BookmarkedIndividualNotFoundException(
          "Unable to delete individual bookmark: bookmark not found."
        );
      else
        throw error;
    }
  }

  async deleteTeamBookmark(bookmarkId: Guid): Promise<void> {
    const url = new URL(`/marketplace/team-bookmark/${bookmarkId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error.response.status === 404)
        throw new BookmarkedTeamNotFoundException(
          "Unable to delete team bookmark: bookmark not found."
        );
      else
        throw error;
    }
  }
}

export class BookmarkedCompanyNotFoundException extends Error { }
export class BookmarkedIndividualNotFoundException extends Error { }
export class BookmarkedTeamNotFoundException extends Error { }

export class BookmarksRequestError extends Error {
  endpoint: string;
  error: any;
  constructor(endpoint: string, error: any) {
    super(`Unable to get bookmarks from ${endpoint}.`);
    this.endpoint = endpoint;
    this.error = error;
  }
}