import Guid from "common/values/guid/guid";
import moment, { Moment } from "moment";

export default class MessageFileInfo {
  id: Guid;
  name: string;
  fileSize: number;
  mimeType: string;
  created: Moment;
  rawData?: Blob;

  constructor(id: Guid, name: string, fileSize: number, mimeType: string, created: Moment, rawData?: Blob) {
    this.id = id;
    this.name = name;
    this.fileSize = fileSize;
    this.mimeType = mimeType;
    this.created = created;
    this.rawData = rawData;
  }

  static fromFile(file: File): MessageFileInfo {
    return new MessageFileInfo(
      Guid.generate(), file.name, file.size, file.type, moment(), file);
  }

  static toFile(messageFileInfo: MessageFileInfo): File {
    return new File([messageFileInfo.rawData!], messageFileInfo.name, {
      type: messageFileInfo.mimeType,
    });
  }
}
