import { Box, Chip, Icon, styled, Typography } from "@mui/material";
import BookmarkAPIService from "marketplace/entities/bookmark/api/bookmark-api-service";
import BookmarkedIndividual from "marketplace/entities/bookmarked-individual/bookmarked-individual";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Guid from "common/values/guid/guid";
import { CanceledError } from "axios";
import IndividualAvatar from "marketplace/view/individual-avatar";

const NameCellContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  marginLeft: theme.spacing(1.5),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export interface BookmarkedIndividualsProps {
  selectedTeamMemberUserIds?: Guid[];
  onSelected: (bookmarkedIndividual: BookmarkedIndividual) => void;
}

export function BookmarkedIndividuals(
  props: Readonly<BookmarkedIndividualsProps>
) {
  const { 
    selectedTeamMemberUserIds,
    onSelected
  } = props;

  const columnHelper = createMRTColumnHelper<BookmarkedIndividual>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [data, setData] = React.useState<BookmarkedIndividual[]>([]);
  const [rowCount, setRowCount] = React.useState(0);

  const session = useSession();

  function renderNameColumn(bookmark: BookmarkedIndividual) {
    if (!bookmark.vendor.profile) return <Icon>remove</Icon>;
    return (
      <NameCellContainer>
        <IndividualAvatar
          avatarId={bookmark.vendor.profile.avatarId}
          individualId={bookmark.vendor.id}
          session={session}
        />
        <RowTitle>{bookmark.vendor.getFullName()}</RowTitle>
      </NameCellContainer>
    );
  }

  function renderCategoriesColumn(bookmark: BookmarkedIndividual) {
    if (!bookmark.vendor.profile?.categories) return <Icon>remove</Icon>;
    return bookmark.vendor.profile.categories.map((category) => (
      <Chip key={category} label={category} />
    ));
  }

  function renderDescriptionColumn(bookmark: BookmarkedIndividual) {
    if (!bookmark.vendor.profile) return <Icon>remove</Icon>;
    return bookmark.vendor.profile.description;
  }

  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
      id: "name",
    }),
    columnHelper.accessor(renderCategoriesColumn, {
      header: "Categories",
      id: "categories",
    }),
    columnHelper.accessor(renderDescriptionColumn, {
      header: "Description",
      id: "description",
    }),
  ];

  useEffect(() => {
    let abortController = new AbortController();
    fetchData(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  const fetchData = async (abortController: AbortController) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const bookmarkService = new BookmarkAPIService(session);
      const bookmarks = await bookmarkService.getAllIndividualBookmarksForUser(
        abortController
      );

      setData(bookmarks);
      setRowCount(bookmarks.length);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setErrorLoading(true);
      console.error(error);
      return;
    }
    setErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onSelected(row.original),
      sx: { 
        pointerEvents: selectedTeamMemberUserIds?.some(id => row.original?.vendor?.userId && id.isEqualTo(row.original.vendor.userId)) ? "none" : "auto",
        cursor: "pointer" 
      },
    }),
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    enableColumnPinning: true,
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: errorLoading
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: errorLoading,
      showProgressBars: isRefetching,
      // sorting,
    },
  });

  return <MaterialReactTable table={table} />;
}
