import moment from "moment";

import Guid from "common/values/guid/guid";
import BookmarkedIndividual from "marketplace/entities/bookmarked-individual/bookmarked-individual";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";


export default class MarketplaceIndividualBookmarkAPIResponse extends MarketplaceIndividualInfoAPIResponse {
    bookmarkId?: string;
    createdDate?: string;

    deserializeToIndividual(): Individual {
        return super.deserialize();
    }

    deserializeToBookmark(): BookmarkedIndividual {
        if (!this.bookmarkId) throw new Error('BookmarkId is required');
        if (!this.createdDate) throw new Error('CreatedDate is required');
        if (!this.id) throw new Error('Id is required');
        if (!this.userId) throw new Error('UserId is required');
        if (!this.firstName) throw new Error('First name is required');
        if (!this.lastName) throw new Error('Last name is required');

        const individual = super.deserialize();

        return new BookmarkedIndividual(
            new Guid(this.id),
            new Guid(this.bookmarkId),
            moment(this.createdDate),
            individual
        );
    }
}
