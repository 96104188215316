import IndividualSummaryAPIResponse from 'marketplace/values/individual-summary/api/response-contracts/individual-summary-api-response';
import IndividualSummary from 'marketplace/values/individual-summary/individual-summary';

export default class IndividualSummariesAPIResponse {
    individuals?: IndividualSummaryAPIResponse[];

    deserialize(): IndividualSummary[] {
        if (!Array.isArray(this.individuals)) throw new Error("Individual Summaries not returned from API");
        return this.individuals.map(individual => individual.deserialize());
    }
}
