import Guid from "common/values/guid/guid";

import moment from "moment";

import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import EntityName from "legal-entities/values/entity-name/entity-name";
import OpenEntityMemberInvitation from "legal-entities/entities/open-entity-member-invitation/open-entity-member-invitation";


export default class OpenMemberInvitationAPIResponse {
    id?: string;
    entityId?: string;
    entityName?: string;
    expires?: string;

    deserialize(): OpenEntityMemberInvitation {
        if (!this.id || !this.entityId || !this.entityName) throw new Error("Invalid OpenMemberInvitationAPIResponse");
        const invitation = new OpenEntityMemberInvitation(new LegalEntity(new Guid(this.entityId), new EntityName(this.entityName)));
        invitation.id = new Guid(this.id);
        invitation.expires = moment(this.expires);
        return invitation;
    }
}
