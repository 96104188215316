import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import User from "../../user";
import UserAuthToken from "users/values/user-auth-token/user-auth-token";
import moment, { Moment } from "moment";
import UserNetworkInfoAPIResponse from "users/entities/user-network-connection/api/response-contracts/user-network-connection-profile-api-response";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import UserEntityInfo from "users/values/user-entity-info/user-entity-info";
import UserEntityInfoAPIResponse from "users/values/user-entity-info/api/response-contracts/user-entity-info-api-response";
import UserBookmarkInfoAPIResponse from "users/values/user-bookmark-info/api/response-contracts/user-bookmark-info-api-response";
import UserBookmarkInfo from "users/values/user-bookmark-info/user-bookmark-info";
import UserOpenEntityAgreement from "legal-entities/entities/entity-agreement/user-open-entity-agreement";
import UserOpenEntityAgreementAPIResponse from "users/api/response-contracts/user-open-entity-agreement-api-response";
import { AuthResponse } from "users/entities/user/api/auth-email-api-service";
import NetworkInvitationInfoAPIResponse from "users/entities/user-network-invitation/api/response-contracts/network-invitation-info-api-response";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import UserInformationAPIResponse from "users/values/user-information/api/response-contract/user-information-api-response";

type AuthToken = {
  value: string;
  tokenExp: string;
};

export class AuthContext {
  public isVendor: boolean;
  public isClient: boolean;
  public viewingAsVendor: boolean | null;
  public isVendorRepresentative: boolean;
  public isClientRepresentative: boolean;
  public timestamp: Moment;

  constructor(
    isVendor: boolean,
    isClient: boolean,
    viewingAsVendor: boolean | null,
    isVendorRepresentative: boolean,
    isClientRepresentative: boolean,
    timestamp: Moment
  ) {
    this.isVendor = isVendor;
    this.isClient = isClient;
    this.viewingAsVendor = viewingAsVendor;
    this.isVendorRepresentative = isVendorRepresentative;
    this.isClientRepresentative = isClientRepresentative;
    this.timestamp = timestamp;
  }

  public static fromObject(obj: any): AuthContext {
    return new AuthContext(
      obj.isVendor,
      obj.isClient,
      obj.viewingAsVendor,
      obj.isVendorRepresentative,
      obj.isClientRepresentative,
      moment(obj.timestamp)
    );
  }
  public toJSON(): object {
    return {
      isVendor: this.isVendor,
      isClient: this.isClient,
      viewingAsVendor: this.viewingAsVendor,
      isVendorRepresentative: this.isVendorRepresentative,
      isClientRepresentative: this.isClientRepresentative,
      timestamp: this.timestamp.toISOString(),
    };
  }
};

export default class AuthInfoAPIResponse {
  authToken?: AuthToken;
  userInformation?: UserInformationAPIResponse;

  deserialize(): AuthResponse {
    const authResponse: AuthResponse = {
      user: this.deserializeUser(),
      authToken: this.deserializeAuthToken(),
      context: this.deserializeContext(),
      legalEntities: this.deserializeUserEntityInfo(),
      openEntityAgreements: this.deserializeOpenEntityAgreements(),
      networkConnections: this.deserializeNetworkConnections(),
      networkInvitations: this.deserializeNetworkInvitations(),
      bookmarkedIndividuals: this.deserializeBookmarkedIndividuals(),
      bookmarkedCompanies: this.deserializeBookmarkedCompanies(),
      bookmarkedTeams: this.deserializeBookmarkedTeams(),
    };
    return authResponse;
  }

  deserializeUser(): User {
    if (
      !this.userInformation?.id ||
      !this.userInformation?.firstName ||
      !this.userInformation?.lastName ||
      !this.userInformation?.emailAddress
    )
      throw new Error("Invalid user response.");
    const user = new User(
      new Name(this.userInformation.firstName, this.userInformation.lastName),
      new EmailAddress(this.userInformation.emailAddress),
      new Guid(this.userInformation.id),
      Boolean(this.userInformation.isSuperUser)
    );
    user.id = new Guid(this.userInformation.id);
    const firstlegalEntity = this.userInformation.legalEntities?.[0];
    if (firstlegalEntity && firstlegalEntity.entityId) {
      user.companyEntityId = new Guid(firstlegalEntity.entityId);
      user.companyName = firstlegalEntity.entityName;
    }
    if (this.userInformation.individualId) user.individualId = new Guid(this.userInformation.individualId);
    user.isCompanyManager = (
      Boolean(this.userInformation.viewingAsVendor && this.userInformation.isVendorRepresentative) ||
      Boolean(!this.userInformation.viewingAsVendor && this.userInformation.isClientRepresentative)
    );
    return user;
  }

  deserializeContext(): AuthContext {
    if (!this.userInformation) 
      throw new Error("Invalid user response.");
    return new AuthContext(
      this.userInformation.isVendor ?? false,
      this.userInformation.isClient ?? false,
      this.userInformation.viewingAsVendor ?? null,
      this.userInformation.isVendorRepresentative ?? false,
      this.userInformation.isClientRepresentative ?? false,
      moment(this.userInformation.timestamp)
    );
  }

  deserializeAuthToken(): UserAuthToken {
    if (!this.authToken?.value || !this.authToken?.tokenExp)
      throw new Error("Invalid auth token response.");
    return new UserAuthToken(
      this.authToken.value,
      moment(this.authToken.tokenExp)
    );
  }

  deserializeUserEntityInfo(): UserEntityInfo[] {
    if (!this.userInformation?.legalEntities)
      throw new Error("Invalid entities response.");
    return this.userInformation.legalEntities.map((data) => {
      const legalEntityResponse = Object.assign(
        new UserEntityInfoAPIResponse(),
        data
      );
      return legalEntityResponse.deserialize();
    });
  }

  deserializeOpenEntityAgreements(): UserOpenEntityAgreement[] {
    if (!this.userInformation?.openEntityAgreements)
      throw new Error("Invalid open entity agreements response.");
    return this.userInformation.openEntityAgreements.map((data) => {
      const agreementResponse = Object.assign(
        new UserOpenEntityAgreementAPIResponse(),
        data
      );
      return agreementResponse.deserialize();
    });
  }

  deserializeNetworkConnections(): UserNetworkConnectionProfile[] {
    if (!this.userInformation?.networkConnections)
      throw new Error("Invalid network connections response.");
    return this.userInformation.networkConnections.map((data) => {
      const connectionResponse = Object.assign(
        new UserNetworkInfoAPIResponse(),
        data
      );
      return connectionResponse.deserialize();
    });
  }

  deserializeNetworkInvitations(): NetworkInvitationInfo[] {
    if (!this.userInformation?.networkInvitations)
      throw new Error("Invalid network invitations response.");
    return this.userInformation.networkInvitations.map((data) => {
      const invitationResponse = Object.assign(
        new NetworkInvitationInfoAPIResponse(),
        data
      );
      return invitationResponse.deserialize();
    });
  }

  deserializeBookmarkedIndividuals(): UserBookmarkInfo[] {
    if (!this.userInformation?.bookmarkedIndividuals)
      throw new Error("Invalid bookmarked individuals response.");
    return this.userInformation.bookmarkedIndividuals.map((data) => {
      const bookmarkedIndividualResponse = Object.assign(
        new UserBookmarkInfoAPIResponse(),
        data
      );
      return bookmarkedIndividualResponse.deserialize();
    });
  }

  deserializeBookmarkedCompanies(): UserBookmarkInfo[] {
    if (!this.userInformation?.bookmarkedCompanies)
      throw new Error("Invalid bookmarked companies response.");
    return this.userInformation.bookmarkedCompanies.map((data) => {
      const bookmarkedCompanyResponse = Object.assign(
        new UserBookmarkInfoAPIResponse(),
        data
      );
      return bookmarkedCompanyResponse.deserialize();
    });
  }

  deserializeBookmarkedTeams(): UserBookmarkInfo[] {
    if (!this.userInformation?.bookmarkedTeams)
      throw new Error("Invalid bookmarked teams response.");
    return this.userInformation.bookmarkedTeams.map((data) => {
      const bookmarkedTeamResponse = Object.assign(
        new UserBookmarkInfoAPIResponse(),
        data
      );
      return bookmarkedTeamResponse.deserialize();
    });
  }
}
