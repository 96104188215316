import Guid from "common/values/guid/guid";
import Company from "marketplace/entities/company/company";
import Individual from "marketplace/entities/individual/individual";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";


export default class DetailedWorkClientRepresentativeAPIResponse {
    id?: string;
    entityId?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    companyId?: string;
    companyName?: string;
    marketplaceId?: string;
    isVisible?: boolean;
    avatar?: string;

    deserialize(): Individual {
        if(!this.id) 
            throw new Error('Client representative id not returned by api.');
        if(!this.userId) 
            throw new Error('Client representative user id not returned by api.');
        if(!this.entityId) 
            throw new Error('Client representative entity id not returned by api.');
        if(!this.companyId) 
            throw new Error('Client representative company id not returned by api.');
        const individual = new Individual(new Guid(this.id), new Guid(this.userId));
        individual.entityId = new Guid(this.entityId);
        const userId = new Guid(this.userId);
        if(this.firstName && this.lastName) {
            individual.profile = new IndividualProfile(userId, this.firstName, this.lastName)
            individual.company = new Company(new Guid(this.companyId), new Guid(this.entityId));
        }
        return individual;
    }
}
