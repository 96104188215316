import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  styled,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { CanceledError } from "axios";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSession } from "users/session/session-context";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import Proposal, { ProposalField, ProposalFieldCategory } from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategories from "work/values/fee-schedule-category/view/fee-schedule-categories";
import FeeScheduleTag from "work/values/fee-schedule-tag/fee-schedule-tag";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";

const ListContainer = styled(Grid)(({ theme }) => ({
  minHeight: "20rem",
}));
const TemplateContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  }
}));
const TemplateList = styled(List)(({ theme }) => ({
  marginRight: theme.spacing(2),
  maxHeight: "25rem",
  minWidth: "20rem",
  overflowY: "auto",
}));
const TemplateLoader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(2),
}));
const FeeScheduleTagChip = styled(Chip)(({ theme }) => ({
  color: "#777",
  marginRight: theme.spacing(0.25),
}));
const CategorySelection = styled(FeeScheduleCategories)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
  height: '100%',
  marginLeft: theme.spacing(2),
}));

type SelectFeeScheduleProps = {
  entityId: Guid;
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  proposal?: Proposal;
  disableCommenting: boolean;
  commentForums?: Forum[];
  disableEditing?: boolean;
  onCommentsClicked: (field: ProposalField, name?: string) => void;
  onChange: () => void;
};

export default function FeeScheduleTab(
  props: Readonly<SelectFeeScheduleProps>
) {
  const {
    entityId,
    activeTab,
    proposalBuilder,
    proposal,
    disableCommenting,
    commentForums,
    disableEditing,
    onCommentsClicked,
    onChange,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateFeeSchedules, setTemplateFeeSchedules] = useState<
    FeeScheduleTemplate[]
  >([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<Guid[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    FeeScheduleCategory[]
  >([]);

  const isLargeDownDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const session = useSession();

  useEffect(() => {
    if (activeTab !== ProposalFieldCategory.FeeSchedule) 
      return;

    initializeFeeSchedule();
    let abortController = new AbortController();
    getTemplateFeeSchedules(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [proposal, proposalBuilder, activeTab]);

  function initializeFeeSchedule() {
    setSelectedCategories(
      proposalBuilder.currentSpec.feeSchedule ?? []
    );
    setSelectedTemplateIds(
      (session.context?.viewingAsVendor
        ? proposalBuilder?.currentSpec.vendorFeeScheduleTemplateIds
        : proposalBuilder?.currentSpec.clientFeeScheduleTemplateIds) ?? []
    );
  }

  async function getTemplateFeeSchedules(abortController: AbortController): Promise<void> {
    if (!entityId || !session.user?.isCompanyManager) return;

    try {
      setIsLoading(true);

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const templates =
        await feeScheduleService.getFeeScheduleTemplates(
          entityId,
          session.context?.viewingAsVendor
            ? AccountType.Vendor
            : AccountType.Client,
          abortController
        );
      setTemplateFeeSchedules(templates);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  function handleTemplateExpanded(template: FeeScheduleTemplate) {
    let templates = [...templateFeeSchedules];
    let selectedTemplate = templates.find((feeSchedule) =>
      feeSchedule.id?.isEqualTo(template.id)
    );
    template.isExpanded = !selectedTemplate?.isExpanded;

    setTemplateFeeSchedules(templates);
  }

  function handleTemplateSelectionToggled(
    template: FeeScheduleTemplate
  ) {
    try {
      if (!template.id) throw new Error("No template selected");

      const isAlreadySelected = selectedTemplateIds.some((id) =>
        id.isEqualTo(template.id)
      );

      let updatedSelectedTemplateIds = [...selectedTemplateIds];
      if (isAlreadySelected) {
        updatedSelectedTemplateIds = updatedSelectedTemplateIds.filter((id) => !id.isEqualTo(template.id));
      }
      else {
        const categories = _.uniqWith(
          [...selectedCategories, ...template.categories],
          (a, b) => a.name?.isEqualTo(b.name) ?? false
        );
        setSelectedCategories(categories);
        proposalBuilder.setFeeSchedule(categories);
        updatedSelectedTemplateIds.push(template.id);
      }

      setSelectedTemplateIds(updatedSelectedTemplateIds);

      if (session.context?.viewingAsVendor) {
        proposalBuilder.setVendorFeeScheduleTemplateIds(updatedSelectedTemplateIds);
      } else {
        proposalBuilder.setClientFeeScheduleTemplateIds(updatedSelectedTemplateIds);
      }

      onChange();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to apply template. Please try again", {
        variant: "error",
      });
    }
  }


  function handleTemplateCategoryToggled(
    event: React.MouseEvent,
    selectedCategory: FeeScheduleCategory
  ) {
    event.stopPropagation();
    let updatedCategories = [...selectedCategories];
    if (selectedCategories.some((category) => category.id.isEqualTo(selectedCategory.id))) {
      updatedCategories = updatedCategories.filter((category) => !category.id.isEqualTo(selectedCategory.id));
    }
    else {
      updatedCategories.push(selectedCategory);
    }

    setSelectedCategories(updatedCategories);
    proposalBuilder.setFeeSchedule(updatedCategories);
    onChange();
  }

  function handleCategoryAdded(
    category: FeeScheduleCategory 
  ) {
    if (selectedCategories.some((c) => c.id?.isEqualTo(category.id))) {
      enqueueSnackbar(
        "Fee category with the same name is already in the fee schedule",
        { variant: "warning" }
      );
      return;
    }

    const updatedCategories = [
      ...selectedCategories,
      new FeeScheduleCategory(
        category.name,
        category.description ?? null,
        category.fee,
        category.billingCode ?? null,
        Guid.generate()
      ),
    ];

    setSelectedCategories(updatedCategories);
    proposalBuilder.setFeeSchedule(updatedCategories);

    onChange();
  }

  function handleCategoryRemoved(categoryName: FeeScheduleCategoryName) {
    const updatedCategories = selectedCategories.filter(
      (category) => !category.name?.isEqualTo(categoryName)
    );

    setSelectedCategories(updatedCategories);
    proposalBuilder.setFeeSchedule(updatedCategories);

    onChange();
  }

  function handleCategoryUpdated(
    originalName: FeeScheduleCategoryName | null,
    updatedCategory: FeeScheduleCategory 
  ) {
    if (originalName === null) return;

    if (
      selectedCategories.some((c) => c.id?.isEqualTo(updatedCategory.id)) &&
      originalName?.toString() !== updatedCategory.name?.toString()
    ) {
      enqueueSnackbar(
        "Fee category with the same name is already in the fee schedule",
        { variant: "warning" }
      );
      return;
    }

    const updatedCategories = selectedCategories.map((category) => {
      if (category.name?.isEqualTo(originalName)) return updatedCategory;
      return category;
    });

    setSelectedCategories(updatedCategories);
    proposalBuilder.setFeeSchedule(updatedCategories);

    onChange();
  }

  function renderTemplates() {
    return (
      <TemplateList subheader={<ListSubheader>Templates</ListSubheader>}>
        {templateFeeSchedules.length === 0 && (
          <>
            {isLoading ? (
              <TemplateLoader size={32} thickness={4} />
            ) : (
              <ListItem key="noTemplates">
                <ListItemText>No Templates</ListItemText>
              </ListItem>
            )}
          </>
        )}
        {templateFeeSchedules.map((template: FeeScheduleTemplate) => {
          const templateIsSelected = selectedTemplateIds.some((id) =>
            id.isEqualTo(template.id)
          );

          return (
            <div key={template.id?.toString()}>
              <ListItem>
                <ListItemIcon>
                  <Tooltip title={!templateIsSelected ? "Select Template" : "Deselect Template"}>
                    <span>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={templateIsSelected}
                        indeterminate={!templateIsSelected && template.isUsing(selectedCategories)}
                        tabIndex={-1}
                        disableRipple
                        onChange={() => handleTemplateSelectionToggled(template)}
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={template.name}
                  secondary={template.tags.map((tag: FeeScheduleTag) => (
                    <FeeScheduleTagChip
                      key={tag.value}
                      size="small"
                      label={tag.value}
                    />
                  ))}
                />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={`${
                      template.isExpanded ? "Hide" : "Show"
                    } Categories`}
                  >
                    <span>
                      <IconButton
                        edge="end"
                        onClick={() => handleTemplateExpanded(template)}
                        size="medium"
                      >
                        {template.isExpanded ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse
                in={template.isExpanded}
                timeout="auto"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <Divider />
                <List disablePadding dense>
                  {renderTemplateCategories(template)}
                </List>
                <Divider />
              </Collapse>
            </div>
          );
        })}
      </TemplateList>
    );
  }

  function renderTemplateCategories(template: FeeScheduleTemplate) {
    return template.categories.map((category: FeeScheduleCategory) => {
      const categoryIsSelected = selectedCategories.some((selectedCategory) => selectedCategory.id.isEqualTo(category.id));
      return (
      <ListItemButton
        key={category.name.value}
        onClick={(event) => handleTemplateCategoryToggled(event, category)}
      >
        <ListItemText
          primary={category.name.value}
          secondary={
            <>
              {category.description?.value}<br/>
              {category.feeString}
            </>
          }
        />
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            color="primary"
            checked={categoryIsSelected}
            tabIndex={-1}
            disableRipple
            onClick={(event) => handleTemplateCategoryToggled(event, category)}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
    )
  });
  }

  if (activeTab !== ProposalFieldCategory.FeeSchedule) return null;

  return (
    <ListContainer container direction="row">
      {!disableEditing && (
        <>
          <TemplateContainer>
            {renderTemplates()}
            {isLargeDownDisplay && <Divider />}
          </TemplateContainer>
          {!isLargeDownDisplay && (
            <Grid>
              <Divider orientation="vertical" />
            </Grid>
          )}
        </>
      )}
      <Grid size="grow">
        <CategorySelection
          categories={selectedCategories}
          hideFromTemplateButton={true}
          disableCommenting={disableCommenting}
          commentForums={commentForums}
          viewOnly={disableEditing}
          onCommentsClicked={(categoryId?: Guid, name?: string) => {
            if(!categoryId){
              onCommentsClicked(ProposalField.FeeSchedule, name);
            } else {
              onCommentsClicked(ProposalField.FeeScheduleCategory(categoryId), name);
            }
          }}
          onCategoryAdded={handleCategoryAdded}
          onCategoryRemoved={handleCategoryRemoved}
          onCategoryUpdated={handleCategoryUpdated}
        />
      </Grid>
    </ListContainer>
  );
}
