import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import moment from "moment";
import NetworkedUserInfo, { INetworkedUserInfoData } from "../../networked-user-info";

export default class NetworkedUserInfoAPIResponse {
    connectionId?: string;
    createdDate?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    individualId?: string;
    companyId?: string;
    companyEntityId?: string;
    companyName?: string;
    companyProfileVisible?: boolean;
    avatarId?: string;
    teamsOn?: number;
    teamsLeading?: number;
    myComments?: string;
    isClientRep?: boolean;
    isVendorRep?: boolean;

    deserialize(): NetworkedUserInfo {
        if (!this.connectionId) {
            throw new Error('connectionId is required');
        }
        if (!this.userId) {
            throw new Error('userId is required');
        }
        if (!this.individualId) {
            throw new Error('individualId is required');
        }
        if (!this.companyId) {
            throw new Error('companyId is required');
        }
        if (!this.companyEntityId) {
            throw new Error('companyEntityId is required');
        }
        if (this.isClientRep === undefined) {
            throw new Error('isClientRep is required');
        }
        if (this.isVendorRep === undefined) {
            throw new Error('isVendorRep is required');
        }
        const networkUserData: INetworkedUserInfoData = {
            connectionId: new Guid(this.connectionId),
            createdDate: moment(this.createdDate),
            userId: new Guid(this.userId),
            name: new Name(this.firstName, this.lastName),
            email: new EmailAddress(this.email ? this.email : 'unknown@example.com'),
            individualId: new Guid(this.individualId),
            companyId: new Guid(this.companyId),
            companyEntityId: new Guid(this.companyEntityId),
            companyName: this.companyName,
            companyProfileVisible: this.companyProfileVisible,
            avatarId: this.avatarId ? new Guid(this.avatarId) : undefined,
            teamsOn: this.teamsOn,
            teamsLeading: this.teamsLeading,
            myComments: this.myComments,
            isClientRep: this.isClientRep,
            isVendorRep: this.isVendorRep
        };
        return new NetworkedUserInfo(networkUserData);
    }
}
