export default class Name {
    readonly firstName?: string;
    readonly lastName?: string;

    constructor(firstName?: string, lastName?: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        Name.validate(firstName, lastName);
    }

    /**
     * Updates the name
     * @param firstName The new first name 
     * @param lastName The new last name
     * @throws InvalidNameError if the name is invalid
     * @returns A new Name instance
     */
    public update(firstName: string, lastName: string): Name {
        Name.validate(firstName, lastName);
        return new Name(firstName, lastName);
    }

    /**
     * Validates the name
     * @param firstName The first name
     * @param lastName The last name
     * @throws InvalidNameError if the name is invalid
     * @returns void
     */
    static validate(firstName?: string, lastName?: string): void {
        if ((!firstName || firstName === '') || (!lastName || lastName === '')) {
            throw new InvalidNameError(
                "Must have a value for first and last name.",
                firstName ?? '{empty}', lastName ?? '{empty}'
            );
        }
    }

    /**
     * Checks if the name is equal to another name
     * @param other The other name to compare to
     * @returns true if the names are equal, false otherwise
     */
    public isEqualTo(other: Name): boolean {
        return this.firstName === other.firstName && this.lastName === other.lastName;
    }

    public toString(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public toInitials(): string {
        return `${this.firstName?.charAt(0)}${this.lastName?.charAt(0)}`;
    }

    public static fromObject(object: any): Name {
        return new Name(object.firstName, object.lastName);
    }
    public toJSON() : object {
        return {
            firstName: this.firstName,
            lastName: this.lastName
        };
    }
}

export class InvalidNameError extends Error {
    invalidFirstName: string;
    invalidLastName: string;
    constructor(message: string, firstName: string, lastName: string) {
        super(message);
        this.invalidFirstName = firstName;
        this.invalidLastName = lastName;
    }
}
