export default class EntityName {
    private _value: string;

    constructor(value: string) {
        EntityName.validate(value);
        this._value = value;
    }

    public get value(): string {
        return this._value;
    }

    static validate(data: string) {
        if (!data) {
            throw new InvalidEntityNameError("must have a value.");
        }
    }

    public isEqualTo(other: EntityName) : boolean {
        return this._value === other.value;
    }

    public toString() : string {
        return this._value;
    }
    public static fromObject(data: any): EntityName {
        if(!data) throw new InvalidEntityNameError("Entity name must have a value.");
        return new EntityName(data);
    }
    public toJSON() {
        return this._value;
    }
}

export class InvalidEntityNameError extends Error { };
