import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import React from "react";
import Marketplace from "work/values/team/view/marketplace";

const PageContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: "auto 1fr",
  width: "100%"
}));
const TabsContainer = styled("div")(({ theme }) => ({
  gridRowStart: 1
}));
const TabContent = styled("div")(({ theme }) => ({
  gridRowStart: 2,
  gridRowEnd: 'none'
}));

type TeamSelectionProps = {
  onNewSelection: (team: MarketplaceTeam) => void;
  closeDialog: () => void;
};

export default function TeamSelection(props: Readonly<TeamSelectionProps>) {
  const { onNewSelection } = props;

  const [activeTab, setActiveTab] = React.useState("marketplace");

  return (
    <PageContainer>
      <TabsContainer>
        <Tabs
          indicatorColor="primary"
          variant="scrollable"
          value={activeTab}
          onChange={(event, tab) => setActiveTab(tab)}
          aria-label="Team selection nav">
          <Tab value="marketplace" label="Marketplace" />
        </Tabs>
      </TabsContainer>
      {activeTab === "marketplace" && (
        <TabContent>
          <Marketplace
            selectingTeam={true}
            onTeamSelected={onNewSelection}
          />
        </TabContent>
      )}
    </PageContainer>
  );
}
