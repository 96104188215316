import CheckIcon from '@mui/icons-material/Check';
import { Container, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import EmailAddress from 'common/values/email-address/email-address';
import EmailAddressInput, { EmailAddressInputError } from 'common/values/email-address/view/email-address-input';
import Name from 'common/values/name/name';
import NameInput, { NameInputError } from 'common/values/name/view/name-input';
import Password, { PasswordIssues } from 'common/values/password/password';
import { InvitationStatus } from 'legal-entities/values/invitation-status';
import RegistrationInfo, { InvalidRegistrationInfoError } from 'legal-entities/values/registration-info';
import PasswordInput from 'users/entities/user/view/password-input';

import LoadingButton from 'common/components/loading-button';
import UserEntityMemberInvitationAPIService from 'legal-entities/entities/user-entity-member-invitation/api/user-entity-member-invitation-api-service';
import UserEntityMemberInvitation, { InvalidUserEntityMemberInvitationError } from 'legal-entities/entities/user-entity-member-invitation/user-entity-member-invitation';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useSession } from 'users/session/session-context';

const InvitationForm = styled(Container)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  position: 'relative',
  width: '100%'
}));
const InvitationFormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));
const InvitationFormInstructions = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),
  "& a": {
    color: theme.palette.common.white,
    textDecoration: 'underline'
  }
}));
const InvitationEmailInput = styled(EmailAddressInput)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: theme.palette.common.white
  }
}));
const InvitationNameInput = styled(NameInput)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: theme.palette.common.white
  }
}));
const InvitationPasswordInput = styled(PasswordInput)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: theme.palette.common.white
  }
}));
const InvitationAcceptButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: '#E19941',
  color: theme.palette.common.white,
  marginTop: theme.spacing(2),
  "&.MuiButton-root": {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FFB949',
    },
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.3)",
      boxShadow: "none",
      backgroundColor: "rgba(255, 255, 255, 0.12)"
    }
  }
}));
const FormElements = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

interface GeneralFormError extends String { };
type InvitationAcceptanceFormError = GeneralFormError | EmailAddressInputError | NameInputError | PasswordIssues;

type InvitationAcceptanceFormErrors = {
  general?: GeneralFormError;
  email?: EmailAddressInputError;
  name?: NameInputError;
  password?: PasswordIssues;
}

type UserEntityMemberInvitationAcceptanceFormProps = {
  className?: string;
  invitation?: UserEntityMemberInvitation;
}

export default function UserEntityMemberInvitationAcceptanceForm(props: Readonly<UserEntityMemberInvitationAcceptanceFormProps>) {
  const { className, invitation } = props;

  const [invitationName, setInvitationName] = useState<Name>();
  const [invitationEmail, setInvitationEmail] = useState<EmailAddress>();
  const [invitationPassword, setInvitationPassword] = useState<Password>();
  const [formErrors, setFormErrors] = useState<InvitationAcceptanceFormErrors>();
  const [saving, setSaving] = useState<boolean>(false);

  const session = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (!invitation) return;
    setInvitationEmail(invitation.invitedEmail);
  }, [invitation]);

  function rebuildInvitation(
    emailAddress?: EmailAddress,
    name?: Name,
    password?: Password,
    errors?: InvitationAcceptanceFormErrors
  ): UserEntityMemberInvitation | undefined {
    if (!invitation || !formValid(errors) || !emailAddress || !name || !password) return;

    try {
      const invitationRegistrationInfo = new RegistrationInfo(name, emailAddress, password);
      const updatedInvitation = new UserEntityMemberInvitation(
        invitation.entity,
        invitation.invitedEmail,
        invitation.message,
        invitationRegistrationInfo
      );
      updatedInvitation.id = invitation.id;
      return updatedInvitation;
    } catch (error: any) {
      if (error instanceof InvalidUserEntityMemberInvitationError ||
        error instanceof InvalidRegistrationInfoError) {
        let errors: InvitationAcceptanceFormErrors = {};
        errors.general = error.message;
        return;
      }
      throw error;
    }
  }

  async function handleAcceptInvitation() {
    setSaving(true);

    const builtInvite = rebuildInvitation(invitationEmail, invitationName, invitationPassword);

    try {
      if (!builtInvite) 
        throw new InvalidUserEntityMemberInvitationError("Invitation is invalid");

      const service = new UserEntityMemberInvitationAPIService(session);
      await service.acceptUserEntityMemberInvitation(builtInvite, session);
      navigate('/dashboard');
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setSaving(false);
    }
  }

  function formValid(errors?: InvitationAcceptanceFormErrors): boolean {
    if (errors === undefined) errors = formErrors;

    return (
      invitationEmail !== undefined &&
      invitationName !== undefined &&
      invitationPassword !== undefined &&
      !errors?.general &&
      !errors?.email &&
      !errors?.name &&
      !errors?.password
    )
  }

  function updateFormErrors(type: keyof InvitationAcceptanceFormErrors, error?: InvitationAcceptanceFormError) {
    let errors: InvitationAcceptanceFormErrors = { ...formErrors };

    if (type === 'password') {
      errors.password = error as PasswordIssues;
    }
    else if (type === 'email') {
      errors.email = error as EmailAddressInputError;
    }
    else if (type === 'name') {
      errors.name = error as NameInputError;
    } 
    else {
      errors.general = error as GeneralFormError;
    }

    setFormErrors(errors);
    return errors;
  }

  function invitationAlreadyAcceptedMessage() {
    return (
      <InvitationForm className={className}>
        <InvitationFormTitle variant='h4'>
          Invitation Already Accepted
        </InvitationFormTitle>
        <InvitationFormInstructions>
          The invitation has already been accepted.<br />
          If you believe this is in error please contact <a href="mailto:support@attorneyhub.com" target="_blank" rel="noreferrer">support@attorneyhub</a>.<br />
          You can close this tab
        </InvitationFormInstructions>
      </InvitationForm>
    )
  }

  if (!invitation) return null;
  if (invitation.status === InvitationStatus.Accepted) return invitationAlreadyAcceptedMessage();

  return (
    <InvitationForm className={className}>
      <InvitationFormTitle variant='h4'>
        Accept Invitation
      </InvitationFormTitle>
      <InvitationFormInstructions>
        Enter the following to register as an administrator on Attorney Hub account for {invitation.entity.name?.value}:
      </InvitationFormInstructions>
      <form>
        <InvitationEmailInput
          variant='filled'
          disabled
          emailAddress={invitationEmail}
          onChange={(updatedEmailAddress?: EmailAddress, error?: EmailAddressInputError) => {
            if (updatedEmailAddress) setInvitationEmail(updatedEmailAddress);
            const errors = updateFormErrors('email', error);
            rebuildInvitation(updatedEmailAddress, invitationName, invitationPassword, errors);
          }}
        />
        <FormElements>
          <InvitationNameInput
            variant='filled'
            name={invitationName}
            onChange={(updatedName?: Name, error?: NameInputError) => {
              if (updatedName) setInvitationName(updatedName);
              const errors = updateFormErrors('name', error);
              rebuildInvitation(invitationEmail, updatedName, invitationPassword, errors);
            }}
          />
          <InvitationPasswordInput
            variant='filled'
            confirm={true}
            password={invitationPassword}
            onChange={(updatedPassword?: Password, error?: PasswordIssues) => {
              if (updatedPassword) setInvitationPassword(updatedPassword);
              const errors = updateFormErrors('password', error);
              rebuildInvitation(invitationEmail, invitationName, updatedPassword, errors);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Enter' && formValid()) {
                handleAcceptInvitation();
              }
            }}
          />
        </FormElements>
        <InvitationAcceptButton
          variant="contained"
          color="primary"
          disabled={saving || !formValid()}
          loading={saving}
          startIcon={<CheckIcon />}
          onClick={handleAcceptInvitation}>
          Accept Invitation
        </InvitationAcceptButton>
      </form>
    </InvitationForm>
  )
}
