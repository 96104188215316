import DeleteIcon from "@mui/icons-material/DeleteOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Button, Container, Icon, IconButton, Link, styled, Tab, Tabs, Theme, Tooltip, Typography, useMediaQuery } from "@mui/material";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import Company from "marketplace/entities/company/company";
import Individual from "marketplace/entities/individual/individual";
import ViewCompanyProfile from "marketplace/values/company-profile/view/view-company-profile";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import {
  Params,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSession } from "users/session/session-context";
import TeamTemplateAPIService from "work/entities/team-template/api/team-template-service";
import TeamTemplate from "work/entities/team-template/team-template";
import TeamTemplateForm from "work/entities/team-template/view/team-template-form";
import { TeamMember } from "work/values/team/team-member";
import { PageTab } from "./tabs";

const TeamColumnLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  display: "block",
  textDecorationColor: theme.palette.common.black,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));
const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap"
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
const NewButton = styled(IconButton)(({ theme }) => ({
  padding: 0
}));

type TeamProps = {};

export default function Teams(props: Readonly<TeamProps>) {
  const isDirtyRef = React.useRef<boolean>(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [data, setData] = React.useState<TeamTemplate[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [wasErrorLoading, setWasErrorLoading] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(PageTab.Templates);

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const { openDialog, closeDialog, closeAllDialogs } = useDialog();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    if (!session.user?.companyEntityId || !session.user?.isCompanyManager) return;

    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/teams/${PageTab.Templates}`);
    }

    if (routeParams.action === "create") {
      beginCreateTeamTemplate();
      return;
    }

    if (routeParams.tab === PageTab.Templates && routeParams.id) {
      handleEditTeamTemplate(new Guid(routeParams.id));
    }

    fetchData(routeParams.tab as PageTab);
  }, [routeParams]);

  const fetchData = async (tab?: PageTab) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      if (session.hasEntity) {
        let teams: TeamTemplate[] = [];
        const teamTemplateService = new TeamTemplateAPIService(session);
        if ((tab ?? activeTab) === PageTab.Templates) {
          teams = await teamTemplateService.getTeamTemplates(
            session.entities[0].entityId,
            session.accountType
          );
        }
        setData(teams);
        setRowCount(teams.length);
      }
    } catch (error) {
      setWasErrorLoading(true);
      console.error(error);
      return;
    }
    setWasErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  function beginCreateTeamTemplate() {
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Create Template Team",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { display: "flex" },
        component: (
          <TeamTemplateForm
            leader={null}
            members={[]}
            navigate={navigate}
            onFormDirty={() => {
              isDirtyRef.current = true;
            }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              fetchData();
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  function handleEditTeamTemplate(id: Guid) {
    const template = data.find((template) => template.id?.isEqualTo(id));

    if (!template) {
      enqueueSnackbar("Team template not found", { variant: "error" });
      return;
    }

    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Edit Template Team",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { display: "flex" },
        component: (
          <TeamTemplateForm
            template={template}
            name={template.name.value}
            leader={constructTeamMemberFromIndividual(template.leader) ?? null}
            members={constructTeamMembersFromIndividuals(template.members)}
            navigate={navigate}
            onFormDirty={() => {
              isDirtyRef.current = true;
            }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              fetchData();
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  async function handleDeleteTeamTemplate(id: Guid) {
    try {
      const response = await confirm({
        title: "Delete?",
        message: `Delete team template?`,
        okButtonText: "Delete",
        cancelButtonText: "Cancel",
      });

      if (response === ConfirmResponse.Cancel) return;

      const teamTemplateService = new TeamTemplateAPIService(session);
      await teamTemplateService.deleteTeamTemplate(id);
      enqueueSnackbar("Deleted team template", { variant: "success" });
      fetchData();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete team template. Please try again", {
        variant: "error",
      });
    }
  }

  function handleViewCompanyProfile(company: Company) {
    openDialog({
      component: (
        <ViewCompanyProfile companyId={company?.id} confirmDialog={confirm} />
      ),
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      MuiProps: {
        maxWidth: "md",
        fullWidth: true,
        style: {
          overflowX: "hidden",
          paddingBottom: 0,
        },
      },
      onClose: closeDialog,
    });
  }

  function handleViewIndividualProfile(individual: Individual) {
    openDialog({
      component: <ViewIndividualProfile individualId={individual.id} />,
      contentSxProps: {
        display: "flex",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      onClose: closeDialog,
    });
  }

  function constructTeamMemberFromIndividual(
    individual: Individual
  ): TeamMember | undefined {
    if (!individual.profile) return;

    return {
      individualId: individual.id,
      userId: individual.userId,
      firstName: individual.profile.firstName,
      lastName: individual.profile.lastName,
      entityId: individual.company?.entityId,
    } as TeamMember;
  }

  function constructTeamMembersFromIndividuals(
    members: Individual[]
  ): TeamMember[] {
    const teamMembers: TeamMember[] = [];

    for (let member of members) {
      const teamMember = constructTeamMemberFromIndividual(member);
      if (teamMember) teamMembers.push(teamMember);
    }

    return teamMembers;
  }

  const renderLeaderColumn = (team: TeamTemplate) => {
    if (!team.leader) return null;
    return (
      <TeamColumnLink
        onClick={(event) => {
          event.stopPropagation();
          openDialog({
            component: <ViewIndividualProfile individualId={team.leader.id} />,
            contentSxProps: {
              display: "flex",
            },
            MuiProps: {
              fullWidth: true,
              maxWidth: "lg",
            },
            titleStyle: {
              position: "absolute",
              right: 0,
              top: 0,
            },
            onClose: closeDialog,
          });
        }}
      >
        {team.leader.getFullName()}
      </TeamColumnLink>
    );
  };

  const renderMembersColumn = (team: TeamTemplate) => {
    if (team.members.length === 0) {
      return <Icon>remove</Icon>;
    }
    return (
      <div>
        {team.members.map((member) => (
          <TeamColumnLink
            key={member.id.value}
            onClick={(event) => {
              event.stopPropagation();
              openDialog({
                component: <ViewIndividualProfile individualId={member.id} />,
                contentSxProps: {
                  display: "flex",
                },
                MuiProps: {
                  fullWidth: true,
                  maxWidth: "lg",
                },
                titleStyle: {
                  position: "absolute",
                  right: 0,
                  top: 0,
                },
                onClose: closeDialog,
              });
            }}
          >
            {member.getFullName()}
          </TeamColumnLink>
        ))}
      </div>
    );
  };

  const renderCompanyColumn = (team: TeamTemplate) => {
    return (
      <TeamColumnLink
        key={team.leader.company?.profile?.name}
        onClick={(event) => {
          event.stopPropagation();
          openDialog({
            component: <ViewCompanyProfile companyId={team.leader.company?.id} />,
            contentSxProps: {
              display: "flex",
            },
            MuiProps: {
              fullWidth: true,
              maxWidth: "lg",
            },
            titleStyle: {
              position: "absolute",
              right: 0,
              top: 0,
            },
            onClose: closeDialog,
          });
        }}
      >
        {team.leader.company?.profile?.name}
      </TeamColumnLink>
    );
  };

  const columnHelper = createMRTColumnHelper<TeamTemplate>();
  const columns = [
    columnHelper.accessor((team) => team.name.value, {
      header: "Template Name",
      id: "name",
      Cell: ({ row }) => (
        <RowTitle>{row.original.name.value}</RowTitle>
      )
    }),
    columnHelper.accessor(renderCompanyColumn, {
      header: "Company",
      id: "company",
    }),
    columnHelper.accessor(renderLeaderColumn, {
      header: "Leader",
      id: "leader",
    }),
    columnHelper.accessor(renderMembersColumn, {
      header: "Members",
      id: "members",
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const template = row.original;
        const parentPath = new URL(
          ".",
          window.location.origin + window.location.pathname
        );

        openDialog(
          {
            title: "Edit Template Team",
            MuiProps: { fullWidth: false, maxWidth: "lg" },
            contentSxProps: { display: "flex" },
            component: (
              <TeamTemplateForm
                template={template}
                name={template.name.value}
                leader={
                  constructTeamMemberFromIndividual(template.leader) ?? null
                }
                members={constructTeamMembersFromIndividuals(template.members)}
                navigate={navigate}
                onFormDirty={() => {
                  isDirtyRef.current = true;
                }}
                onSave={() => {
                  closeDialog();
                  navigate(parentPath.pathname);
                  fetchData();
                }}
              />
            ),
          },
          () => navigate(parentPath.pathname),
          () => isDirtyRef.current
        );
      },
      sx: { cursor: "pointer" },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
            <Button
              startIcon={<GroupAddIcon />}
              onClick={() => navigate("/teams/templates/create")}>
              Create Team
            </Button>
          </ToolbarButtonsContainer>
        )}
        {isMediumDisplaySize && (
          <NewButton
            color="primary"
            onClick={() => navigate("/teams/templates/create")}>
            <GroupAddIcon fontSize="large" />
          </NewButton>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Teams
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/teams/${newValue}/`)}
            >
              <Tab value='templates' label='Templates' />
              <Tab value='teams' label={session.accountType === AccountType.Client ? "Teams Working For Me" : "Teams I'm On"} />
              <Tab value='archived' label="Archived" />
            </Tabs>
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    enableRowActions: true,
    enableColumnPinning: true,
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    renderRowActions: ({ row }) => {
      if (activeTab !== PageTab.Templates) return null;
      return (
        <Tooltip title="Delete Template">
          <span>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                if (!row.original.id) return;
                handleDeleteTeamTemplate(row.original.id);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </span>
        </Tooltip>
      );
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      // sorting,
    },
  });

  if (!session.user?.companyEntityId || !session.user?.isCompanyManager) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <MaterialReactTable table={table} />
  );
}
