import Guid from "common/values/guid/guid";

import EntityName from "legal-entities/values/entity-name/entity-name";
import InvitationMessage from "legal-entities/values/invitation-message/invitation-message";
import { InvitationStatus } from "legal-entities/values/invitation-status";
import RegistrationInfo from "legal-entities/values/registration-info";

export default class EntityOfficerInvitation {
    id?: Guid;
    entityName: EntityName;
    registrationInfo: RegistrationInfo;
    message?: InvitationMessage;
    status: InvitationStatus;

    constructor(entityName: EntityName, registrationInfo: RegistrationInfo, message?: InvitationMessage, status?: InvitationStatus) {
        this.entityName = entityName;
        this.registrationInfo = registrationInfo;
        this.message = message;
        this.status = status ?? InvitationStatus.Open;
    }
}

export class EntityOfficerInvitationNotFoundError extends Error {
    constructor(id: Guid) {
        super(`Unable to find invitation by id: ${id}`);
    }
}
export class UnauthorizedEntityOfficerInvitationAccessError extends Error {
    constructor() {
        super("You are not permitted to view all open officer invitations.");
    }
}
export class InvalidEntityOfficerInvitationError extends Error {}
export class InvalidEntityOfficerInvitationStatusError extends Error {}
