import { useState } from 'react';
import {
  TextField,
  Tooltip,
  Typography,
  IconButton,
  styled
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const ComponentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  margin: theme.spacing(0, 6, 0, 3)
}));
const NameFields = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr auto',
  '& .MuiTextField-root:first-of-type': {
    marginRight: theme.spacing(1)
  }
}));

export interface IndividualProfileEditNameProps {
  firstName: string;
  lastName: string;
  onFirstNameChange: (firstName: string) => void;
  onLastNameChange: (lastName: string) => void;
}

export default function IndividualProfileEditName(props: Readonly<IndividualProfileEditNameProps>) {
  const [editingName, setEditingName] = useState(false);

  return (
    <ComponentContainer>
      {!editingName && (
        <Typography variant="h4">
          {props.firstName} {props.lastName}
        </Typography>
      )}
      {editingName && (
        <NameFields>
          <TextField
            id="firstName"
            variant="outlined"
            size="small"
            slotProps={{
              inputLabel: {
                shrink: Boolean(props.firstName)
              }
            }}
            label="First Name"
            autoComplete='given-name'
            value={props.firstName}
            onChange={(event) => props.onFirstNameChange(event.target.value)}
          />
          <TextField
            id="lastName"
            variant="outlined"
            size="small"
            slotProps={{
              inputLabel: {
                shrink: Boolean(props.lastName)
              }
            }}
            label="Last Name"
            autoComplete='family-name'
            value={props.lastName}
            onChange={(event) => props.onLastNameChange(event.target.value)}
          />
          <Tooltip title="Cancel">
            <span>
              <IconButton onClick={() => setEditingName(false)} size="medium" edge="end">
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </NameFields>
      )}
      {!editingName && (
        <Tooltip title="Edit Name">
          <span>
            <IconButton onClick={() => setEditingName(true)} size="medium" edge="end">
              <EditIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </ComponentContainer>
  );
}
