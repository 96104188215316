import { Dictionary } from "lodash";
import { ArrayChip, BoolChip, NumericChip, RangeChip, RatingChip, TextChip } from "marketplace/view/filter-chip";

export type FilterChipType = TextChip | BoolChip | ArrayChip | RangeChip | RatingChip | NumericChip;

export class FilterChips {
  private _chips: FilterChipType[];

  constructor(filterChips: FilterChipType[] = []) {
    this._chips = filterChips;
  }

  get chips() {
    return this._chips;
  }

  findByName(filterName: string): FilterChipType | undefined {
    return this._chips.find(chip => chip.filterName === filterName);
  }

  add(chip: FilterChipType) {
    this._chips.push(chip);
  }

  addRange(chips: FilterChipType[]) {
    this._chips = chips;
  }

  remove(filterName: string): boolean {
    let index = this._chips.findIndex(chip => chip.filterName === filterName);

    if (index === -1) return false;
    this._chips.splice(index, 1);

    return true;
  }

  enable(filterName: string): boolean {
    let currentChip = this.findByName(filterName);

    if (!currentChip) return false;

    currentChip.enable();

    return true;
  }

  disable(filterName: string): boolean {
    let currentChip = this.findByName(filterName);

    if (!currentChip) return false;

    currentChip.disable();

    return true;
  }

  disableAll(): void {
    this._chips.forEach((chip: FilterChipType) => chip.disable());
  }

  updateValue(filterName: string, value: string | string[]): boolean {
    let currentChip = this.findByName(filterName);

    if (!currentChip) return false;
    
    if (currentChip instanceof ArrayChip || currentChip instanceof RangeChip) {
      if (!Array.isArray(value)) value = [value];
      currentChip.updateValue(value);
    } else {
      currentChip.updateValue(value.toString());
    }

    return true;
  }

  toDictionary() : Dictionary<string> {
    let dictionary: Dictionary<string> = {};

    this._chips.forEach((chip: FilterChipType) => {
      dictionary[chip.filterName] = chip.value?.toString() ?? '';
    });

    return dictionary;
  }
}
