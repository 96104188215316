import GetAppIcon from "@mui/icons-material/GetApp";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { downloadFile } from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useSession } from "users/session/session-context";
import Proposal, { ProposalFieldCategory } from "work/entities/proposal/proposal";
import WorkDocument from "work/values/work-document/work-document";

const ListContainer = styled("div")(({ theme }) => ({
  display: "grid",
  columnGap: theme.spacing(2),
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
}));
const PolicySubheader = styled(ListSubheader)(({ theme }) => ({
  fontSize: "1.4em",
}));

type PolicyTabProps = {
  activeTab: ProposalFieldCategory;
  proposal?: Proposal;
};

export default function PolicyTab(props: Readonly<PolicyTabProps>) {
  const { activeTab, proposal } = props;

  const [downloadingFileId, setDownloadingFileId] = React.useState<Guid>();

  const session = useSession();

  /**
   * Handles when a policy document is downloaded
   * @param   {WorkDocument} document The document to download {@link WorkDocument}
   * @returns {Promise<void>}
   */
  async function handleDownloadPolicy(
    document: WorkDocument
  ): Promise<void> {
    if (downloadingFileId) return;
    try {
      if (!proposal) throw new Error("No proposal was found");

      setDownloadingFileId(document.id);
      const documentApiService = new DocumentAPIService(session);
      const policyTemplate = await documentApiService.downloadDocument(
        document.id
      );
      downloadFile(policyTemplate);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download policy document. Please try again",
        { variant: "error" }
      );
    } finally {
      setDownloadingFileId(undefined);
    }
  }

  if (activeTab !== ProposalFieldCategory.Policies) return null;

  return proposal && (
    <ListContainer>
      <List>
        <PolicySubheader>Client Policies</PolicySubheader>
        {proposal.clientPolicyDocuments.length === 0 && (
          <ListItem>
            <ListItemText primary="None" />
          </ListItem>
        )}
        {(proposal.clientPolicyDocuments.length ?? 0) > 0 && (
          <>
            {proposal.clientPolicyDocuments.map((document) => (
              <ListItem key={document.id.value}>
                <ListItemText primary={document.name?.value} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleDownloadPolicy(document)}
                  >
                    <GetAppIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </>
        )}
      </List>
      <List>
        <PolicySubheader>Vendor Policies</PolicySubheader>
        {proposal.vendorPolicyDocuments.length === 0 && (
          <ListItem>
            <ListItemText primary="None" />
          </ListItem>
        )}
        {(proposal.vendorPolicyDocuments.length ?? 0) > 0 && (
          <>
            {proposal.vendorPolicyDocuments.map((document) => (
              <ListItem key={document.id.value}>
                <ListItemText primary={document.name?.value} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleDownloadPolicy(document)}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </ListContainer>
  );
}
