import Message from "messaging/entities/message/message";
import MessageAPIResponse from "./message-api-response";

export default class MessageCollectionAPIResponse {
    messages: MessageAPIResponse[] = [];

    deserialize(): Message[] {
        return this.messages.map((message: Partial<MessageAPIResponse>) => {
            return Object.assign(new MessageAPIResponse(), message).deserialize();
        });
    }
}
