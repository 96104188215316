import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import Individual from "marketplace/entities/individual/individual";

export default class UpdateMarketplaceIndividualAPIRequest {
    payload: JsonPatchOperation[] = [];

    constructor(originalIndividual: Individual, updatedIndividual: Individual) {
        if (originalIndividual.isVisible !== updatedIndividual.isVisible){
            this.payload.push({
                op: "replace",
                path: "/isVisible",
                value: updatedIndividual.isVisible,
              });
        }
    }
}
