import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import User from "users/entities/user/user";

export default class UserAPIResponse {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    isSuperUser: boolean = false;
    userIsDisabled: boolean = false;
    individualId?: string;
    companyName?: string;
    companyEntityId?: string;
    isCompanyManager: boolean = false;
    companyId?: string;

    deserialize() : User {
        if(!this.id || !this.firstName || !this.lastName || !this.email){
            throw new Error("Invalid UserAPIResponse, missing required fields");
        }
        const name = new Name(this.firstName, this.lastName);
        const email = new EmailAddress(this.email);
        const id = new Guid(this.id);
        const user = new User(name, email, id, Boolean(this.isSuperUser));
        user.userIsDisabled = this.userIsDisabled;
        if(this.individualId){
            user.individualId = new Guid(this.individualId)
        }
        if(this.companyName){
            user.companyName = this.companyName;
        }
        if(this.companyEntityId){
            user.companyEntityId = new Guid(this.companyEntityId);
        }
        if(this.isCompanyManager){
            user.isCompanyManager = this.isCompanyManager;
        }
        if(this.companyId){
            user.companyId = new Guid(this.companyId);
        }
        return user;
    }
}