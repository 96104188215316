import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import IndividualSummary from "marketplace/values/individual-summary/individual-summary";

export default class IndividualSummaryAPIResponse {
    id?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    emailAddress?: string;

    deserialize(): IndividualSummary {
        if (!this.id) throw new Error("Individual Summary ID not returned from API");
        if (!this.userId) throw new Error("Individual Summary User ID not returned from API");
        
        if (!this.firstName) throw new Error("Individual Summary First Name not returned from API");
        if (!this.lastName) throw new Error("Individual Summary Last Name not returned from API");

        return new IndividualSummary(
            new Guid(this.id),
            new Guid(this.userId),
            this.firstName,
            this.lastName,
            this.avatar ? new Guid(this.avatar) : undefined,
            this.emailAddress ? new EmailAddress(this.emailAddress) : undefined
        );
    }
}
