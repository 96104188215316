export default class RecipientName {
    private _value: string;

    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: RecipientName): boolean {
        return this.value === other?.value;
    }

    public get value(): string {
        return this._value;
    }

    public static get Prototype(): RecipientName {
        return new RecipientName("");
    }

    public fromObject(obj: any): RecipientName {
        return new RecipientName(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): RecipientName {
        return new RecipientName(this.value);
    }
}