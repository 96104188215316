import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import AuthEmailAPIService from "users/entities/user/api/auth-email-api-service";
import { styled } from '@mui/material/styles';
import EmailAddress from 'common/values/email-address/email-address';

const EmailField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(255, 255, 255, 0.09)",
    color: 'white'
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.13)",
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: "rgba(255, 255, 255, 0.09)"
    }
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: "rgba(255, 255, 255, 0.09)"
  },
  "& .MuiFilledInput-input:-webkit-autofill": {
    caretColor: "#fff",
    "-webkitBoxShadow": "0 0 0 100px #266798 inset",
    "-webkitTextFillColor": "#fff",
  },
  "& .MuiFilledInput-underline:before": {
    borderColor: "white"
  },
  "& .MuiFilledInput-underline:after": {
    borderColor: "white"
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)"
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "white"
  },
  "& .MuiFormHelperText-root": {
    color: "rgba(255, 255, 255, 0.7)"
  }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#E19941',
  margin: '1.5em 0',
  '&:hover': {
    backgroundColor: '#FFB949',
  },
  "&.MuiButton-root": {
    color: 'black'
  },
  "&.Mui-disabled": {
    color: "rgba(255, 255, 255, 0.3)",
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.12)"
  }
}));

interface Props {
  emailValue?: string | null;
 };

export default function PasswordResetRequestForm(props: Readonly<Props>) {
  const [emailValue, setEmailValue] = React.useState(props.emailValue ?? '');
  const [emailValid, setEmailValid] = React.useState(false);
  const [resetting, setResetting] = React.useState(false);

  useEffect(() => {
    handleEmailValueChanged(props.emailValue ?? '');
  }, [props.emailValue]);

  function handleEmailValueChanged(newValue: string) {
    try {
      setEmailValue(newValue);
      EmailAddress.validate(newValue);
      setEmailValid(true);
    } catch (error) {
      setEmailValid(false)
      return;
    }
    setEmailValid(true);
  }

  async function handleRequestClicked() {
    setResetting(true);
    try {
      const emailAddress = new EmailAddress(emailValue);
      await AuthEmailAPIService.requestPasswordReset(emailAddress);
      enqueueSnackbar("Password reset email sent", { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Password reset request failed. Please verify the email address is correct.", { variant: 'error' });
    } finally {
      setResetting(false);
      setEmailValue('');
      setEmailValid(false);
    }
  }

  return (
    <Box>
      <EmailField
        variant="filled"
        size="small"
        margin="normal"
        required
        fullWidth
        name="email"
        label="Email"
        type="email"
        id="email"
        onChange={(event) => handleEmailValueChanged(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && emailValid) {
            handleRequestClicked();
          }
        }}
        value={emailValue}
        autoComplete="account-email"
        helperText={!emailValid && "Must be a valid email address to receive a password reset link"}
      />
      <SubmitButton
        fullWidth
        variant="contained"
        color="primary"
        disabled={!emailValid || resetting}
        startIcon={
          resetting ?
            <CircularProgress size={20} thickness={5} style={{ color: '#999' }} /> :
            null
        }
        onClick={handleRequestClicked}>
        {resetting ? 'Sending...' : 'Send Reset Link'}
      </SubmitButton>
    </Box>
  )
}
