import { IS_DEV } from "common/helpers/meta";

export const apiBaseUrl = (() => {
  if (IS_DEV) {
    return "http://localhost:5000";
  } else {
    return "https://api.attorneyhub.com";
  }
})();

export const siteBaseUrl = (() => {
  if (IS_DEV) {
    return "http://localhost:3000";
  } else {
    return "https://www.attorneyhub.com";
  }
})();

export const entityMemberInvitationLink = `${siteBaseUrl}/entity-member-invitation`;

export const maxPolicyFiles = 100;

export const maxPolicyFileSize = 52428800; // 50 MB

export const acceptedPolicyFormats = [
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/*'
];

export const maxConflictsFiles = 100;

export const maxConflictsFileSize = 52428800; // 50 MB

export const acceptedConflictsFormats = [
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/*'
];

export const acceptedAttachmentFormats = [
  "text/plain",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/heic",
  "image/heif",
  "image/svg+xml",
  "image/webp",
  "application/pdf",
  "application/zip",
  "application/msword",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
];

export const AllCategories = [
  { id: 'all', name: 'All Categories', description: 'Search Entire Marketplace' }
];

export const PostCategories = [
  { id: 'workAvailable', name: 'Work Available', description: 'Search For Available Work' },
  { id: 'seekingEmployment', name: 'Seeking Employment', description: 'Search Resumes' },
  { id: 'educationSeminars', name: 'Education/Seminars', description: 'Search For Learning Opportunies' },
  { id: 'upcomingEvents', name: 'Upcoming Events', description: 'Search For Upcoming Events' }
];

export const MarketplaceCategories = [
  { id: 'search', name: 'Search Software', description: 'Search For Search Software' },
  { id: 'docketing', name: 'IAM/Docketing Software or Service', description: 'Search For IAM / Docketing Software Or Services' },
  { id: 'annuity', name: 'Annuity/Renewal Services', description: 'Search For Annuity / Renewal Services' },
  { id: 'ebilling', name: 'E-Billing', description: 'Search For E-Billing Solutions' },
  { id: 'costMgmt', name: 'Cost Management', description: 'Search For Cost Management' },
  { id: 'validation', name: 'Validation', description: 'Search For Validation' },
  { id: 'filing', name: 'Filing', description: 'Search For Filing' },
  { id: 'translation', name: 'Translation', description: 'Search For Translation' },
  { id: 'patentIntel', name: 'Patent Intelligence/Analytics', description: 'Search For Patent Intelligence / Analytics' },
  { id: 'contractMgmt', name: 'Contract Management', description: 'Search For Contract Management' },
  { id: 'patentRiskMgmt', name: 'Patent Risk Management/Licensing/Insurance', description: 'Search For Patent Risk Management / Licensing / Insurance' },
  { id: 'litigation', name: 'Litigation/Post-Grant Proceeding Analytics', description: 'Search For Litigation / Post-Grant Proceeding Analytics' },
  { id: 'offshoreDrafting', name: 'Offshoring Drafting/Docketing/Searching', description: 'Search For Offshoring Drafting / Docketing / Searching' },
  { id: 'aiDrafting', name: 'AI-Assisted Patent Drafting', description: 'Search For AI-Assisted Patent Drafting' },
  { id: 'trademarkSearch', name: 'Trademark Search', description: 'Search For Trademark Search' },
  { id: 'trademarkWatch', name: 'Trademark Watch/Monitoring', description: 'Search For Trademark Watch / Monitoring' },
  { id: 'domainWatch', name: 'Domain Management/Watch', description: 'Search For Domain Management / Watch' },
  { id: 'industryTools', name: 'Industry-Specific Tools or Services', description: 'Search For Industry-Specific Tools or Services' }
];

export enum MarketplaceResultType {
  Company = 'Company',
  Team = 'Team',
  Individual = 'Individual'
};

export const IndividualProfileTypes = [
  'Administration',
  'IPAdmin',
  'IPAttorney',
  'Management',
  'Sales',
  'Technical',
];

export const CompanyProfileTypes = [
  'AIDrafting',
  'Annuity',
  'ContractMgmt',
  'CostMgmt',
  'Docketing',
  'DomainWatch',
  'EBilling',
  'Filing',
  'IndustryTools',
  'Litigation',
  'OffshoreDrafting',
  'PatentIntel',
  'PatentRiskMgmt',
  'Search',
  'TrademarkSearch',
  'TrademarkWatch',
  'Translation',
  'Validation'
];

export const notificationTypes = {
  'Users.NetworkInvitation': 'Network Invitation',
  'Users.NetworkConnection': 'Network Connection',
  'Marketplace.TeamInvitation': 'Marketplace Team Invitation',
  'Marketplace.Team': 'Marketplace Team',
  'Work.Proposal': 'Work Proposal',
  'LegalEntities.LegalEntity': 'New Administrator',
  'LegalEntities.EntityMember': 'New Member',
  'Messaging.Message': 'New Message',
  'Messaging.ForumInvitation': 'Forum Invitation',
};

export const notificationLinks = {
  'Users.NetworkInvitation': '/network/pending/view/{topicId}',
  'Users.NetworkConnection': '/network/contacts/view/{topicId}',
  'Marketplace.TeamInvitation': '/marketplace/team-invitation/{topicId}',
  'Marketplace.Team': '/marketplace/profiles/teams/view/{topicId}',
  'Work.Proposal': '/proposals/{pageTab}/{action}/{topicId}/{tab}',
  'LegalEntities.LegalEntity': '/administration/users/view/{topicId}',
  'LegalEntities.EntityMember': '/administration/users/view/{topicId}',
  'Messaging.Message': '/communications/all/view/{topicId}',
  'Messaging.ForumInvitation': '/communications/all/view/{topicId}'
};

export const notificationActionTypes = {
  Review: 'Review',
  ProposalRevisionReview: 'ProposalRevisionReview',
  ProposalReview: 'ProposalReview',
  ReviewOrReject: 'ReviewOrReject',
  ReviewOrDismiss: 'ReviewOrDismiss',
  HireOrCancel: 'HireOrCancel',
  Hired: 'Hired',
  AcceptOrReject: 'AcceptOrReject'
};

export const eventType = {
  Unspecified: 0,
  UpcomingEvent: 1,
  EducationSeminar: 2
};

export const hireType = {
  Unspecified: 0,
  Hiring: 1,
  ForHire: 2
};

export const userRole = {
  Root: 0,
  Company: 1,
  Attorney: 2,
  Marketplace: 3,
  Corporate: 4,
  Demo: 5,
  Unknown: 6
};

export const userType = {
  CorpClient: 0,
  SubscribedCorpClient: 1,
  Attorney: 2,
  SubscribedAttorney: 3,
  SubscribedVendor: 4,
  Unspecified: 5
};

export enum TeamMemberStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected"
};


export const defaultDiscount = 0.03;

export const states = [
  {
    "State": "Alabama",
    "Abbrev": "Ala.",
    "Code": "AL"
  },
  {
    "State": "Alaska",
    "Abbrev": "Alaska",
    "Code": "AK"
  },
  {
    "State": "Arizona",
    "Abbrev": "Ariz.",
    "Code": "AZ"
  },
  {
    "State": "Arkansas",
    "Abbrev": "Ark.",
    "Code": "AR"
  },
  {
    "State": "California",
    "Abbrev": "Calif.",
    "Code": "CA"
  },
  {
    "State": "Colorado",
    "Abbrev": "Colo.",
    "Code": "CO"
  },
  {
    "State": "Connecticut",
    "Abbrev": "Conn.",
    "Code": "CT"
  },
  {
    "State": "Delaware",
    "Abbrev": "Del.",
    "Code": "DE"
  },
  {
    "State": "District of Columbia",
    "Abbrev": "D.C.",
    "Code": "DC"
  },
  {
    "State": "Florida",
    "Abbrev": "Fla.",
    "Code": "FL"
  },
  {
    "State": "Georgia",
    "Abbrev": "Ga.",
    "Code": "GA"
  },
  {
    "State": "Hawaii",
    "Abbrev": "Hawaii",
    "Code": "HI"
  },
  {
    "State": "Idaho",
    "Abbrev": "Idaho",
    "Code": "ID"
  },
  {
    "State": "Illinois",
    "Abbrev": "Ill.",
    "Code": "IL"
  },
  {
    "State": "Indiana",
    "Abbrev": "Ind.",
    "Code": "IN"
  },
  {
    "State": "Iowa",
    "Abbrev": "Iowa",
    "Code": "IA"
  },
  {
    "State": "Kansas",
    "Abbrev": "Kans.",
    "Code": "KS"
  },
  {
    "State": "Kentucky",
    "Abbrev": "Ky.",
    "Code": "KY"
  },
  {
    "State": "Louisiana",
    "Abbrev": "La.",
    "Code": "LA"
  },
  {
    "State": "Maine",
    "Abbrev": "Maine",
    "Code": "ME"
  },
  {
    "State": "Maryland",
    "Abbrev": "Md.",
    "Code": "MD"
  },
  {
    "State": "Massachusetts",
    "Abbrev": "Mass.",
    "Code": "MA"
  },
  {
    "State": "Michigan",
    "Abbrev": "Mich.",
    "Code": "MI"
  },
  {
    "State": "Minnesota",
    "Abbrev": "Minn.",
    "Code": "MN"
  },
  {
    "State": "Mississippi",
    "Abbrev": "Miss.",
    "Code": "MS"
  },
  {
    "State": "Missouri",
    "Abbrev": "Mo.",
    "Code": "MO"
  },
  {
    "State": "Montana",
    "Abbrev": "Mont.",
    "Code": "MT"
  },
  {
    "State": "Nebraska",
    "Abbrev": "Nebr.",
    "Code": "NE"
  },
  {
    "State": "Nevada",
    "Abbrev": "Nev.",
    "Code": "NV"
  },
  {
    "State": "New Hampshire",
    "Abbrev": "N.H.",
    "Code": "NH"
  },
  {
    "State": "New Jersey",
    "Abbrev": "N.J.",
    "Code": "NJ"
  },
  {
    "State": "New Mexico",
    "Abbrev": "N.M.",
    "Code": "NM"
  },
  {
    "State": "New York",
    "Abbrev": "N.Y.",
    "Code": "NY"
  },
  {
    "State": "North Carolina",
    "Abbrev": "N.C.",
    "Code": "NC"
  },
  {
    "State": "North Dakota",
    "Abbrev": "N.D.",
    "Code": "ND"
  },
  {
    "State": "Ohio",
    "Abbrev": "Ohio",
    "Code": "OH"
  },
  {
    "State": "Oklahoma",
    "Abbrev": "Okla.",
    "Code": "OK"
  },
  {
    "State": "Oregon",
    "Abbrev": "Ore.",
    "Code": "OR"
  },
  {
    "State": "Pennsylvania",
    "Abbrev": "Pa.",
    "Code": "PA"
  },
  {
    "State": "Rhode Island",
    "Abbrev": "R.I.",
    "Code": "RI"
  },
  {
    "State": "South Carolina",
    "Abbrev": "S.C.",
    "Code": "SC"
  },
  {
    "State": "South Dakota",
    "Abbrev": "S.D.",
    "Code": "SD"
  },
  {
    "State": "Tennessee",
    "Abbrev": "Tenn.",
    "Code": "TN"
  },
  {
    "State": "Texas",
    "Abbrev": "Tex.",
    "Code": "TX"
  },
  {
    "State": "Utah",
    "Abbrev": "Utah",
    "Code": "UT"
  },
  {
    "State": "Vermont",
    "Abbrev": "Vt.",
    "Code": "VT"
  },
  {
    "State": "Virginia",
    "Abbrev": "Va.",
    "Code": "VA"
  },
  {
    "State": "Washington",
    "Abbrev": "Wash.",
    "Code": "WA"
  },
  {
    "State": "West Virginia",
    "Abbrev": "W.Va.",
    "Code": "WV"
  },
  {
    "State": "Wisconsin",
    "Abbrev": "Wis.",
    "Code": "WI"
  },
  {
    "State": "Wyoming",
    "Abbrev": "Wyo.",
    "Code": "WY"
  }
];

export const languages = [
  { "code": "ab", "name": "Abkhaz", "nativeName": "аҧсуа" },
  { "code": "aa", "name": "Afar", "nativeName": "Afaraf" },
  { "code": "af", "name": "Afrikaans", "nativeName": "Afrikaans" },
  { "code": "ak", "name": "Akan", "nativeName": "Akan" },
  { "code": "sq", "name": "Albanian", "nativeName": "Shqip" },
  { "code": "am", "name": "Amharic", "nativeName": "አማርኛ" },
  { "code": "ar", "name": "Arabic", "nativeName": "العربية" },
  { "code": "an", "name": "Aragonese", "nativeName": "Aragonés" },
  { "code": "hy", "name": "Armenian", "nativeName": "Հայերեն" },
  { "code": "as", "name": "Assamese", "nativeName": "অসমীয়া" },
  { "code": "av", "name": "Avaric", "nativeName": "авар мацӀ, магӀарул мацӀ" },
  { "code": "ae", "name": "Avestan", "nativeName": "avesta" },
  { "code": "ay", "name": "Aymara", "nativeName": "aymar aru" },
  { "code": "az", "name": "Azerbaijani", "nativeName": "azərbaycan dili" },
  { "code": "bm", "name": "Bambara", "nativeName": "bamanankan" },
  { "code": "ba", "name": "Bashkir", "nativeName": "башҡорт теле" },
  { "code": "eu", "name": "Basque", "nativeName": "euskara, euskera" },
  { "code": "be", "name": "Belarusian", "nativeName": "Беларуская" },
  { "code": "bn", "name": "Bengali", "nativeName": "বাংলা" },
  { "code": "bh", "name": "Bihari", "nativeName": "भोजपुरी" },
  { "code": "bi", "name": "Bislama", "nativeName": "Bislama" },
  { "code": "bs", "name": "Bosnian", "nativeName": "bosanski jezik" },
  { "code": "br", "name": "Breton", "nativeName": "brezhoneg" },
  { "code": "bg", "name": "Bulgarian", "nativeName": "български език" },
  { "code": "my", "name": "Burmese", "nativeName": "ဗမာစာ" },
  { "code": "ca", "name": "Catalan; Valencian", "nativeName": "Català" },
  { "code": "ch", "name": "Chamorro", "nativeName": "Chamoru" },
  { "code": "ce", "name": "Chechen", "nativeName": "нохчийн мотт" },
  { "code": "ny", "name": "Chichewa; Chewa; Nyanja", "nativeName": "chiCheŵa, chinyanja" },
  { "code": "zh", "name": "Chinese", "nativeName": "中文 (Zhōngwén), 汉语, 漢語" },
  { "code": "cv", "name": "Chuvash", "nativeName": "чӑваш чӗлхи" },
  { "code": "kw", "name": "Cornish", "nativeName": "Kernewek" },
  { "code": "co", "name": "Corsican", "nativeName": "corsu, lingua corsa" },
  { "code": "cr", "name": "Cree", "nativeName": "ᓀᐦᐃᔭᐍᐏᐣ" },
  { "code": "hr", "name": "Croatian", "nativeName": "hrvatski" },
  { "code": "cs", "name": "Czech", "nativeName": "česky, čeština" },
  { "code": "da", "name": "Danish", "nativeName": "dansk" },
  { "code": "dv", "name": "Divehi; Dhivehi; Maldivian;", "nativeName": "ދިވެހި" },
  { "code": "nl", "name": "Dutch", "nativeName": "Nederlands, Vlaams" },
  { "code": "en", "name": "English", "nativeName": "English" },
  { "code": "eo", "name": "Esperanto", "nativeName": "Esperanto" },
  { "code": "et", "name": "Estonian", "nativeName": "eesti, eesti keel" },
  { "code": "ee", "name": "Ewe", "nativeName": "Eʋegbe" },
  { "code": "fo", "name": "Faroese", "nativeName": "føroyskt" },
  { "code": "fj", "name": "Fijian", "nativeName": "vosa Vakaviti" },
  { "code": "fi", "name": "Finnish", "nativeName": "suomi, suomen kieli" },
  { "code": "fr", "name": "French", "nativeName": "français, langue française" },
  { "code": "ff", "name": "Fula; Fulah; Pulaar; Pular", "nativeName": "Fulfulde, Pulaar, Pular" },
  { "code": "gl", "name": "Galician", "nativeName": "Galego" },
  { "code": "ka", "name": "Georgian", "nativeName": "ქართული" },
  { "code": "de", "name": "German", "nativeName": "Deutsch" },
  { "code": "el", "name": "Greek, Modern", "nativeName": "Ελληνικά" },
  { "code": "gn", "name": "Guaraní", "nativeName": "Avañeẽ" },
  { "code": "gu", "name": "Gujarati", "nativeName": "ગુજરાતી" },
  { "code": "ht", "name": "Haitian; Haitian Creole", "nativeName": "Kreyòl ayisyen" },
  { "code": "ha", "name": "Hausa", "nativeName": "Hausa, هَوُسَ" },
  { "code": "he", "name": "Hebrew (modern)", "nativeName": "עברית" },
  { "code": "hz", "name": "Herero", "nativeName": "Otjiherero" },
  { "code": "hi", "name": "Hindi", "nativeName": "हिन्दी, हिंदी" },
  { "code": "ho", "name": "Hiri Motu", "nativeName": "Hiri Motu" },
  { "code": "hu", "name": "Hungarian", "nativeName": "Magyar" },
  { "code": "ia", "name": "Interlingua", "nativeName": "Interlingua" },
  { "code": "id", "name": "Indonesian", "nativeName": "Bahasa Indonesia" },
  { "code": "ie", "name": "Interlingue", "nativeName": "Originally called Occidental; then Interlingue after WWII" },
  { "code": "ga", "name": "Irish", "nativeName": "Gaeilge" },
  { "code": "ig", "name": "Igbo", "nativeName": "Asụsụ Igbo" },
  { "code": "ik", "name": "Inupiaq", "nativeName": "Iñupiaq, Iñupiatun" },
  { "code": "io", "name": "Ido", "nativeName": "Ido" },
  { "code": "is", "name": "Icelandic", "nativeName": "Íslenska" },
  { "code": "it", "name": "Italian", "nativeName": "Italiano" },
  { "code": "iu", "name": "Inuktitut", "nativeName": "ᐃᓄᒃᑎᑐᑦ" },
  { "code": "ja", "name": "Japanese", "nativeName": "日本語 (にほんご／にっぽんご)" },
  { "code": "jv", "name": "Javanese", "nativeName": "basa Jawa" },
  { "code": "kl", "name": "Kalaallisut, Greenlandic", "nativeName": "kalaallisut, kalaallit oqaasii" },
  { "code": "kn", "name": "Kannada", "nativeName": "ಕನ್ನಡ" },
  { "code": "kr", "name": "Kanuri", "nativeName": "Kanuri" },
  { "code": "ks", "name": "Kashmiri", "nativeName": "कश्मीरी, كشميري‎" },
  { "code": "kk", "name": "Kazakh", "nativeName": "Қазақ тілі" },
  { "code": "km", "name": "Khmer", "nativeName": "ភាសាខ្មែរ" },
  { "code": "ki", "name": "Kikuyu, Gikuyu", "nativeName": "Gĩkũyũ" },
  { "code": "rw", "name": "Kinyarwanda", "nativeName": "Ikinyarwanda" },
  { "code": "ky", "name": "Kirghiz, Kyrgyz", "nativeName": "кыргыз тили" },
  { "code": "kv", "name": "Komi", "nativeName": "коми кыв" },
  { "code": "kg", "name": "Kongo", "nativeName": "KiKongo" },
  { "code": "ko", "name": "Korean", "nativeName": "한국어 (韓國語), 조선말 (朝鮮語)" },
  { "code": "ku", "name": "Kurdish", "nativeName": "Kurdî, كوردی‎" },
  { "code": "kj", "name": "Kwanyama, Kuanyama", "nativeName": "Kuanyama" },
  { "code": "la", "name": "Latin", "nativeName": "latine, lingua latina" },
  { "code": "lb", "name": "Luxembourgish, Letzeburgesch", "nativeName": "Lëtzebuergesch" },
  { "code": "lg", "name": "Luganda", "nativeName": "Luganda" },
  { "code": "li", "name": "Limburgish, Limburgan, Limburger", "nativeName": "Limburgs" },
  { "code": "ln", "name": "Lingala", "nativeName": "Lingála" },
  { "code": "lo", "name": "Lao", "nativeName": "ພາສາລາວ" },
  { "code": "lt", "name": "Lithuanian", "nativeName": "lietuvių kalba" },
  { "code": "lu", "name": "Luba-Katanga", "nativeName": "" },
  { "code": "lv", "name": "Latvian", "nativeName": "latviešu valoda" },
  { "code": "gv", "name": "Manx", "nativeName": "Gaelg, Gailck" },
  { "code": "mk", "name": "Macedonian", "nativeName": "македонски јазик" },
  { "code": "mg", "name": "Malagasy", "nativeName": "Malagasy fiteny" },
  { "code": "ms", "name": "Malay", "nativeName": "bahasa Melayu, بهاس ملايو‎" },
  { "code": "ml", "name": "Malayalam", "nativeName": "മലയാളം" },
  { "code": "mt", "name": "Maltese", "nativeName": "Malti" },
  { "code": "mi", "name": "Māori", "nativeName": "te reo Māori" },
  { "code": "mr", "name": "Marathi (Marāṭhī)", "nativeName": "मराठी" },
  { "code": "mh", "name": "Marshallese", "nativeName": "Kajin M̧ajeļ" },
  { "code": "mn", "name": "Mongolian", "nativeName": "монгол" },
  { "code": "na", "name": "Nauru", "nativeName": "Ekakairũ Naoero" },
  { "code": "nv", "name": "Navajo, Navaho", "nativeName": "Diné bizaad, Dinékʼehǰí" },
  { "code": "nb", "name": "Norwegian Bokmål", "nativeName": "Norsk bokmål" },
  { "code": "nd", "name": "North Ndebele", "nativeName": "isiNdebele" },
  { "code": "ne", "name": "Nepali", "nativeName": "नेपाली" },
  { "code": "ng", "name": "Ndonga", "nativeName": "Owambo" },
  { "code": "nn", "name": "Norwegian Nynorsk", "nativeName": "Norsk nynorsk" },
  { "code": "no", "name": "Norwegian", "nativeName": "Norsk" },
  { "code": "ii", "name": "Nuosu", "nativeName": "ꆈꌠ꒿ Nuosuhxop" },
  { "code": "nr", "name": "South Ndebele", "nativeName": "isiNdebele" },
  { "code": "oc", "name": "Occitan", "nativeName": "Occitan" },
  { "code": "oj", "name": "Ojibwe, Ojibwa", "nativeName": "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  { "code": "cu", "name": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic", "nativeName": "ѩзыкъ словѣньскъ" },
  { "code": "om", "name": "Oromo", "nativeName": "Afaan Oromoo" },
  { "code": "or", "name": "Oriya", "nativeName": "ଓଡ଼ିଆ" },
  { "code": "os", "name": "Ossetian, Ossetic", "nativeName": "ирон æвзаг" },
  { "code": "pa", "name": "Panjabi, Punjabi", "nativeName": "ਪੰਜਾਬੀ, پنجابی‎" },
  { "code": "pi", "name": "Pāli", "nativeName": "पाऴि" },
  { "code": "fa", "name": "Persian", "nativeName": "فارسی" },
  { "code": "pl", "name": "Polish", "nativeName": "polski" },
  { "code": "ps", "name": "Pashto, Pushto", "nativeName": "پښتو" },
  { "code": "pt", "name": "Portuguese", "nativeName": "Português" },
  { "code": "qu", "name": "Quechua", "nativeName": "Runa Simi, Kichwa" },
  { "code": "rm", "name": "Romansh", "nativeName": "rumantsch grischun" },
  { "code": "rn", "name": "Kirundi", "nativeName": "kiRundi" },
  { "code": "ro", "name": "Romanian, Moldavian, Moldovan", "nativeName": "română" },
  { "code": "ru", "name": "Russian", "nativeName": "русский язык" },
  { "code": "sa", "name": "Sanskrit (Saṁskṛta)", "nativeName": "संस्कृतम्" },
  { "code": "sc", "name": "Sardinian", "nativeName": "sardu" },
  { "code": "sd", "name": "Sindhi", "nativeName": "सिन्धी, سنڌي، سندھی‎" },
  { "code": "se", "name": "Northern Sami", "nativeName": "Davvisámegiella" },
  { "code": "sm", "name": "Samoan", "nativeName": "gagana faa Samoa" },
  { "code": "sg", "name": "Sango", "nativeName": "yângâ tî sängö" },
  { "code": "sr", "name": "Serbian", "nativeName": "српски језик" },
  { "code": "gd", "name": "Scottish Gaelic; Gaelic", "nativeName": "Gàidhlig" },
  { "code": "sn", "name": "Shona", "nativeName": "chiShona" },
  { "code": "si", "name": "Sinhala, Sinhalese", "nativeName": "සිංහල" },
  { "code": "sk", "name": "Slovak", "nativeName": "slovenčina" },
  { "code": "sl", "name": "Slovene", "nativeName": "slovenščina" },
  { "code": "so", "name": "Somali", "nativeName": "Soomaaliga, af Soomaali" },
  { "code": "st", "name": "Southern Sotho", "nativeName": "Sesotho" },
  { "code": "es", "name": "Spanish; Castilian", "nativeName": "español, castellano" },
  { "code": "su", "name": "Sundanese", "nativeName": "Basa Sunda" },
  { "code": "sw", "name": "Swahili", "nativeName": "Kiswahili" },
  { "code": "ss", "name": "Swati", "nativeName": "SiSwati" },
  { "code": "sv", "name": "Swedish", "nativeName": "svenska" },
  { "code": "ta", "name": "Tamil", "nativeName": "தமிழ்" },
  { "code": "te", "name": "Telugu", "nativeName": "తెలుగు" },
  { "code": "tg", "name": "Tajik", "nativeName": "тоҷикӣ, toğikī, تاجیکی‎" },
  { "code": "th", "name": "Thai", "nativeName": "ไทย" },
  { "code": "ti", "name": "Tigrinya", "nativeName": "ትግርኛ" },
  { "code": "bo", "name": "Tibetan Standard, Tibetan, Central", "nativeName": "བོད་ཡིག" },
  { "code": "tk", "name": "Turkmen", "nativeName": "Türkmen, Түркмен" },
  { "code": "tl", "name": "Tagalog", "nativeName": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { "code": "tn", "name": "Tswana", "nativeName": "Setswana" },
  { "code": "to", "name": "Tonga (Tonga Islands)", "nativeName": "faka Tonga" },
  { "code": "tr", "name": "Turkish", "nativeName": "Türkçe" },
  { "code": "ts", "name": "Tsonga", "nativeName": "Xitsonga" },
  { "code": "tt", "name": "Tatar", "nativeName": "татарча, tatarça, تاتارچا‎" },
  { "code": "tw", "name": "Twi", "nativeName": "Twi" },
  { "code": "ty", "name": "Tahitian", "nativeName": "Reo Tahiti" },
  { "code": "ug", "name": "Uighur, Uyghur", "nativeName": "Uyƣurqə, ئۇيغۇرچە‎" },
  { "code": "uk", "name": "Ukrainian", "nativeName": "українська" },
  { "code": "ur", "name": "Urdu", "nativeName": "اردو" },
  { "code": "uz", "name": "Uzbek", "nativeName": "zbek, Ўзбек, أۇزبېك‎" },
  { "code": "ve", "name": "Venda", "nativeName": "Tshivenḓa" },
  { "code": "vi", "name": "Vietnamese", "nativeName": "Tiếng Việt" },
  { "code": "vo", "name": "Volapük", "nativeName": "Volapük" },
  { "code": "wa", "name": "Walloon", "nativeName": "Walon" },
  { "code": "cy", "name": "Welsh", "nativeName": "Cymraeg" },
  { "code": "wo", "name": "Wolof", "nativeName": "Wollof" },
  { "code": "fy", "name": "Western Frisian", "nativeName": "Frysk" },
  { "code": "xh", "name": "Xhosa", "nativeName": "isiXhosa" },
  { "code": "yi", "name": "Yiddish", "nativeName": "ייִדיש" },
  { "code": "yo", "name": "Yoruba", "nativeName": "Yorùbá" },
  { "code": "za", "name": "Zhuang, Chuang", "nativeName": "Saɯ cueŋƅ, Saw cuengh" }
];

export const countries = [
  { "code": "AF", "code3": "AFG", "name": "Afghanistan", "number": "004" },
  { "code": "AL", "code3": "ALB", "name": "Albania", "number": "008" },
  { "code": "DZ", "code3": "DZA", "name": "Algeria", "number": "012" },
  { "code": "AS", "code3": "ASM", "name": "American Samoa", "number": "016" },
  { "code": "AD", "code3": "AND", "name": "Andorra", "number": "020" },
  { "code": "AO", "code3": "AGO", "name": "Angola", "number": "024" },
  { "code": "AI", "code3": "AIA", "name": "Anguilla", "number": "660" },
  { "code": "AQ", "code3": "ATA", "name": "Antarctica", "number": "010" },
  { "code": "AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028" },
  { "code": "AR", "code3": "ARG", "name": "Argentina", "number": "032" },
  { "code": "AM", "code3": "ARM", "name": "Armenia", "number": "051" },
  { "code": "AW", "code3": "ABW", "name": "Aruba", "number": "533" },
  { "code": "AU", "code3": "AUS", "name": "Australia", "number": "036" },
  { "code": "AT", "code3": "AUT", "name": "Austria", "number": "040" },
  { "code": "AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031" },
  { "code": "BS", "code3": "BHS", "name": "Bahamas (the)", "number": "044" },
  { "code": "BH", "code3": "BHR", "name": "Bahrain", "number": "048" },
  { "code": "BD", "code3": "BGD", "name": "Bangladesh", "number": "050" },
  { "code": "BB", "code3": "BRB", "name": "Barbados", "number": "052" },
  { "code": "BY", "code3": "BLR", "name": "Belarus", "number": "112" },
  { "code": "BE", "code3": "BEL", "name": "Belgium", "number": "056" },
  { "code": "BZ", "code3": "BLZ", "name": "Belize", "number": "084" },
  { "code": "BJ", "code3": "BEN", "name": "Benin", "number": "204" },
  { "code": "BM", "code3": "BMU", "name": "Bermuda", "number": "060" },
  { "code": "BT", "code3": "BTN", "name": "Bhutan", "number": "064" },
  { "code": "BO", "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068" },
  { "code": "BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535" },
  { "code": "BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070" },
  { "code": "BW", "code3": "BWA", "name": "Botswana", "number": "072" },
  { "code": "BV", "code3": "BVT", "name": "Bouvet Island", "number": "074" },
  { "code": "BR", "code3": "BRA", "name": "Brazil", "number": "076" },
  { "code": "IO", "code3": "IOT", "name": "British Indian Ocean Territory (the)", "number": "086" },
  { "code": "BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096" },
  { "code": "BG", "code3": "BGR", "name": "Bulgaria", "number": "100" },
  { "code": "BF", "code3": "BFA", "name": "Burkina Faso", "number": "854" },
  { "code": "BI", "code3": "BDI", "name": "Burundi", "number": "108" },
  { "code": "CV", "code3": "CPV", "name": "Cabo Verde", "number": "132" },
  { "code": "KH", "code3": "KHM", "name": "Cambodia", "number": "116" },
  { "code": "CM", "code3": "CMR", "name": "Cameroon", "number": "120" },
  { "code": "CA", "code3": "CAN", "name": "Canada", "number": "124" },
  { "code": "KY", "code3": "CYM", "name": "Cayman Islands (the)", "number": "136" },
  { "code": "CF", "code3": "CAF", "name": "Central African Republic (the)", "number": "140" },
  { "code": "TD", "code3": "TCD", "name": "Chad", "number": "148" },
  { "code": "CL", "code3": "CHL", "name": "Chile", "number": "152" },
  { "code": "CN", "code3": "CHN", "name": "China", "number": "156" },
  { "code": "CX", "code3": "CXR", "name": "Christmas Island", "number": "162" },
  { "code": "CC", "code3": "CCK", "name": "Cocos (Keeling) Islands (the)", "number": "166" },
  { "code": "CO", "code3": "COL", "name": "Colombia", "number": "170" },
  { "code": "KM", "code3": "COM", "name": "Comoros (the)", "number": "174" },
  { "code": "CD", "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180" },
  { "code": "CG", "code3": "COG", "name": "Congo (the)", "number": "178" },
  { "code": "CK", "code3": "COK", "name": "Cook Islands (the)", "number": "184" },
  { "code": "CR", "code3": "CRI", "name": "Costa Rica", "number": "188" },
  { "code": "HR", "code3": "HRV", "name": "Croatia", "number": "191" },
  { "code": "CU", "code3": "CUB", "name": "Cuba", "number": "192" },
  { "code": "CW", "code3": "CUW", "name": "Curaçao", "number": "531" },
  { "code": "CY", "code3": "CYP", "name": "Cyprus", "number": "196" },
  { "code": "CZ", "code3": "CZE", "name": "Czechia", "number": "203" },
  { "code": "CI", "code3": "CIV", "name": "Côte d'Ivoire", "number": "384" },
  { "code": "DK", "code3": "DNK", "name": "Denmark", "number": "208" },
  { "code": "DJ", "code3": "DJI", "name": "Djibouti", "number": "262" },
  { "code": "DM", "code3": "DMA", "name": "Dominica", "number": "212" },
  { "code": "DO", "code3": "DOM", "name": "Dominican Republic (the)", "number": "214" },
  { "code": "EC", "code3": "ECU", "name": "Ecuador", "number": "218" },
  { "code": "EG", "code3": "EGY", "name": "Egypt", "number": "818" },
  { "code": "SV", "code3": "SLV", "name": "El Salvador", "number": "222" },
  { "code": "GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226" },
  { "code": "ER", "code3": "ERI", "name": "Eritrea", "number": "232" },
  { "code": "EE", "code3": "EST", "name": "Estonia", "number": "233" },
  { "code": "SZ", "code3": "SWZ", "name": "Eswatini", "number": "748" },
  { "code": "ET", "code3": "ETH", "name": "Ethiopia", "number": "231" },
  { "code": "FK", "code3": "FLK", "name": "Falkland Islands (the) [Malvinas]", "number": "238" },
  { "code": "FO", "code3": "FRO", "name": "Faroe Islands (the)", "number": "234" },
  { "code": "FJ", "code3": "FJI", "name": "Fiji", "number": "242" },
  { "code": "FI", "code3": "FIN", "name": "Finland", "number": "246" },
  { "code": "FR", "code3": "FRA", "name": "France", "number": "250" },
  { "code": "GF", "code3": "GUF", "name": "French Guiana", "number": "254" },
  { "code": "PF", "code3": "PYF", "name": "French Polynesia", "number": "258" },
  { "code": "TF", "code3": "ATF", "name": "French Southern Territories (the)", "number": "260" },
  { "code": "GA", "code3": "GAB", "name": "Gabon", "number": "266" },
  { "code": "GM", "code3": "GMB", "name": "Gambia (the)", "number": "270" },
  { "code": "GE", "code3": "GEO", "name": "Georgia", "number": "268" },
  { "code": "DE", "code3": "DEU", "name": "Germany", "number": "276" },
  { "code": "GH", "code3": "GHA", "name": "Ghana", "number": "288" },
  { "code": "GI", "code3": "GIB", "name": "Gibraltar", "number": "292" },
  { "code": "GR", "code3": "GRC", "name": "Greece", "number": "300" },
  { "code": "GL", "code3": "GRL", "name": "Greenland", "number": "304" },
  { "code": "GD", "code3": "GRD", "name": "Grenada", "number": "308" },
  { "code": "GP", "code3": "GLP", "name": "Guadeloupe", "number": "312" },
  { "code": "GU", "code3": "GUM", "name": "Guam", "number": "316" },
  { "code": "GT", "code3": "GTM", "name": "Guatemala", "number": "320" },
  { "code": "GG", "code3": "GGY", "name": "Guernsey", "number": "831" },
  { "code": "GN", "code3": "GIN", "name": "Guinea", "number": "324" },
  { "code": "GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624" },
  { "code": "GY", "code3": "GUY", "name": "Guyana", "number": "328" },
  { "code": "HT", "code3": "HTI", "name": "Haiti", "number": "332" },
  { "code": "HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334" },
  { "code": "VA", "code3": "VAT", "name": "Holy See (the)", "number": "336" },
  { "code": "HN", "code3": "HND", "name": "Honduras", "number": "340" },
  { "code": "HK", "code3": "HKG", "name": "Hong Kong", "number": "344" },
  { "code": "HU", "code3": "HUN", "name": "Hungary", "number": "348" },
  { "code": "IS", "code3": "ISL", "name": "Iceland", "number": "352" },
  { "code": "IN", "code3": "IND", "name": "India", "number": "356" },
  { "code": "ID", "code3": "IDN", "name": "Indonesia", "number": "360" },
  { "code": "IR", "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364" },
  { "code": "IQ", "code3": "IRQ", "name": "Iraq", "number": "368" },
  { "code": "IE", "code3": "IRL", "name": "Ireland", "number": "372" },
  { "code": "IM", "code3": "IMN", "name": "Isle of Man", "number": "833" },
  { "code": "IL", "code3": "ISR", "name": "Israel", "number": "376" },
  { "code": "IT", "code3": "ITA", "name": "Italy", "number": "380" },
  { "code": "JM", "code3": "JAM", "name": "Jamaica", "number": "388" },
  { "code": "JP", "code3": "JPN", "name": "Japan", "number": "392" },
  { "code": "JE", "code3": "JEY", "name": "Jersey", "number": "832" },
  { "code": "JO", "code3": "JOR", "name": "Jordan", "number": "400" },
  { "code": "KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398" },
  { "code": "KE", "code3": "KEN", "name": "Kenya", "number": "404" },
  { "code": "KI", "code3": "KIR", "name": "Kiribati", "number": "296" },
  { "code": "KP", "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408" },
  { "code": "KR", "code3": "KOR", "name": "Korea (the Republic of)", "number": "410" },
  { "code": "KW", "code3": "KWT", "name": "Kuwait", "number": "414" },
  { "code": "KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417" },
  { "code": "LA", "code3": "LAO", "name": "Lao People's Democratic Republic (the)", "number": "418" },
  { "code": "LV", "code3": "LVA", "name": "Latvia", "number": "428" },
  { "code": "LB", "code3": "LBN", "name": "Lebanon", "number": "422" },
  { "code": "LS", "code3": "LSO", "name": "Lesotho", "number": "426" },
  { "code": "LR", "code3": "LBR", "name": "Liberia", "number": "430" },
  { "code": "LY", "code3": "LBY", "name": "Libya", "number": "434" },
  { "code": "LI", "code3": "LIE", "name": "Liechtenstein", "number": "438" },
  { "code": "LT", "code3": "LTU", "name": "Lithuania", "number": "440" },
  { "code": "LU", "code3": "LUX", "name": "Luxembourg", "number": "442" },
  { "code": "MO", "code3": "MAC", "name": "Macao", "number": "446" },
  { "code": "MG", "code3": "MDG", "name": "Madagascar", "number": "450" },
  { "code": "MW", "code3": "MWI", "name": "Malawi", "number": "454" },
  { "code": "MY", "code3": "MYS", "name": "Malaysia", "number": "458" },
  { "code": "MV", "code3": "MDV", "name": "Maldives", "number": "462" },
  { "code": "ML", "code3": "MLI", "name": "Mali", "number": "466" },
  { "code": "MT", "code3": "MLT", "name": "Malta", "number": "470" },
  { "code": "MH", "code3": "MHL", "name": "Marshall Islands (the)", "number": "584" },
  { "code": "MQ", "code3": "MTQ", "name": "Martinique", "number": "474" },
  { "code": "MR", "code3": "MRT", "name": "Mauritania", "number": "478" },
  { "code": "MU", "code3": "MUS", "name": "Mauritius", "number": "480" },
  { "code": "YT", "code3": "MYT", "name": "Mayotte", "number": "175" },
  { "code": "MX", "code3": "MEX", "name": "Mexico", "number": "484" },
  { "code": "FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583" },
  { "code": "MD", "code3": "MDA", "name": "Moldova (the Republic of)", "number": "498" },
  { "code": "MC", "code3": "MCO", "name": "Monaco", "number": "492" },
  { "code": "MN", "code3": "MNG", "name": "Mongolia", "number": "496" },
  { "code": "ME", "code3": "MNE", "name": "Montenegro", "number": "499" },
  { "code": "MS", "code3": "MSR", "name": "Montserrat", "number": "500" },
  { "code": "MA", "code3": "MAR", "name": "Morocco", "number": "504" },
  { "code": "MZ", "code3": "MOZ", "name": "Mozambique", "number": "508" },
  { "code": "MM", "code3": "MMR", "name": "Myanmar", "number": "104" },
  { "code": "NA", "code3": "NAM", "name": "Namibia", "number": "516" },
  { "code": "NR", "code3": "NRU", "name": "Nauru", "number": "520" },
  { "code": "NP", "code3": "NPL", "name": "Nepal", "number": "524" },
  { "code": "NL", "code3": "NLD", "name": "Netherlands (the)", "number": "528" },
  { "code": "NC", "code3": "NCL", "name": "New Caledonia", "number": "540" },
  { "code": "NZ", "code3": "NZL", "name": "New Zealand", "number": "554" },
  { "code": "NI", "code3": "NIC", "name": "Nicaragua", "number": "558" },
  { "code": "NE", "code3": "NER", "name": "Niger (the)", "number": "562" },
  { "code": "NG", "code3": "NGA", "name": "Nigeria", "number": "566" },
  { "code": "NU", "code3": "NIU", "name": "Niue", "number": "570" },
  { "code": "NF", "code3": "NFK", "name": "Norfolk Island", "number": "574" },
  { "code": "MP", "code3": "MNP", "name": "Northern Mariana Islands (the)", "number": "580" },
  { "code": "NO", "code3": "NOR", "name": "Norway", "number": "578" },
  { "code": "OM", "code3": "OMN", "name": "Oman", "number": "512" },
  { "code": "PK", "code3": "PAK", "name": "Pakistan", "number": "586" },
  { "code": "PW", "code3": "PLW", "name": "Palau", "number": "585" },
  { "code": "PS", "code3": "PSE", "name": "Palestine, State of", "number": "275" },
  { "code": "PA", "code3": "PAN", "name": "Panama", "number": "591" },
  { "code": "PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598" },
  { "code": "PY", "code3": "PRY", "name": "Paraguay", "number": "600" },
  { "code": "PE", "code3": "PER", "name": "Peru", "number": "604" },
  { "code": "PH", "code3": "PHL", "name": "Philippines (the)", "number": "608" },
  { "code": "PN", "code3": "PCN", "name": "Pitcairn", "number": "612" },
  { "code": "PL", "code3": "POL", "name": "Poland", "number": "616" },
  { "code": "PT", "code3": "PRT", "name": "Portugal", "number": "620" },
  { "code": "PR", "code3": "PRI", "name": "Puerto Rico", "number": "630" },
  { "code": "QA", "code3": "QAT", "name": "Qatar", "number": "634" },
  { "code": "MK", "code3": "MKD", "name": "Republic of North Macedonia", "number": "807" },
  { "code": "RO", "code3": "ROU", "name": "Romania", "number": "642" },
  { "code": "RU", "code3": "RUS", "name": "Russian Federation (the)", "number": "643" },
  { "code": "RW", "code3": "RWA", "name": "Rwanda", "number": "646" },
  { "code": "RE", "code3": "REU", "name": "Réunion", "number": "638" },
  { "code": "BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652" },
  { "code": "SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654" },
  { "code": "KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659" },
  { "code": "LC", "code3": "LCA", "name": "Saint Lucia", "number": "662" },
  { "code": "MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663" },
  { "code": "PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666" },
  { "code": "VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670" },
  { "code": "WS", "code3": "WSM", "name": "Samoa", "number": "882" },
  { "code": "SM", "code3": "SMR", "name": "San Marino", "number": "674" },
  { "code": "ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678" },
  { "code": "SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682" },
  { "code": "SN", "code3": "SEN", "name": "Senegal", "number": "686" },
  { "code": "RS", "code3": "SRB", "name": "Serbia", "number": "688" },
  { "code": "SC", "code3": "SYC", "name": "Seychelles", "number": "690" },
  { "code": "SL", "code3": "SLE", "name": "Sierra Leone", "number": "694" },
  { "code": "SG", "code3": "SGP", "name": "Singapore", "number": "702" },
  { "code": "SX", "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534" },
  { "code": "SK", "code3": "SVK", "name": "Slovakia", "number": "703" },
  { "code": "SI", "code3": "SVN", "name": "Slovenia", "number": "705" },
  { "code": "SB", "code3": "SLB", "name": "Solomon Islands", "number": "090" },
  { "code": "SO", "code3": "SOM", "name": "Somalia", "number": "706" },
  { "code": "ZA", "code3": "ZAF", "name": "South Africa", "number": "710" },
  { "code": "GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239" },
  { "code": "SS", "code3": "SSD", "name": "South Sudan", "number": "728" },
  { "code": "ES", "code3": "ESP", "name": "Spain", "number": "724" },
  { "code": "LK", "code3": "LKA", "name": "Sri Lanka", "number": "144" },
  { "code": "SD", "code3": "SDN", "name": "Sudan (the)", "number": "729" },
  { "code": "SR", "code3": "SUR", "name": "Suriname", "number": "740" },
  { "code": "SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744" },
  { "code": "SE", "code3": "SWE", "name": "Sweden", "number": "752" },
  { "code": "CH", "code3": "CHE", "name": "Switzerland", "number": "756" },
  { "code": "SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760" },
  { "code": "TW", "code3": "TWN", "name": "Taiwan", "number": "158" },
  { "code": "TJ", "code3": "TJK", "name": "Tajikistan", "number": "762" },
  { "code": "TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834" },
  { "code": "TH", "code3": "THA", "name": "Thailand", "number": "764" },
  { "code": "TL", "code3": "TLS", "name": "Timor-Leste", "number": "626" },
  { "code": "TG", "code3": "TGO", "name": "Togo", "number": "768" },
  { "code": "TK", "code3": "TKL", "name": "Tokelau", "number": "772" },
  { "code": "TO", "code3": "TON", "name": "Tonga", "number": "776" },
  { "code": "TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780" },
  { "code": "TN", "code3": "TUN", "name": "Tunisia", "number": "788" },
  { "code": "TR", "code3": "TUR", "name": "Turkey", "number": "792" },
  { "code": "TM", "code3": "TKM", "name": "Turkmenistan", "number": "795" },
  { "code": "TC", "code3": "TCA", "name": "Turks and Caicos Islands (the)", "number": "796" },
  { "code": "TV", "code3": "TUV", "name": "Tuvalu", "number": "798" },
  { "code": "UG", "code3": "UGA", "name": "Uganda", "number": "800" },
  { "code": "UA", "code3": "UKR", "name": "Ukraine", "number": "804" },
  { "code": "AE", "code3": "ARE", "name": "United Arab Emirates (the)", "number": "784" },
  { "code": "GB", "code3": "GBR", "name": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826" },
  { "code": "UM", "code3": "UMI", "name": "United States Minor Outlying Islands (the)", "number": "581" },
  { "code": "US", "code3": "USA", "name": "United States", "number": "840" },
  { "code": "UY", "code3": "URY", "name": "Uruguay", "number": "858" },
  { "code": "UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860" },
  { "code": "VU", "code3": "VUT", "name": "Vanuatu", "number": "548" },
  { "code": "VE", "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862" },
  { "code": "VN", "code3": "VNM", "name": "Viet Nam", "number": "704" },
  { "code": "VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092" },
  { "code": "VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850" },
  { "code": "WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876" },
  { "code": "EH", "code3": "ESH", "name": "Western Sahara", "number": "732" },
  { "code": "YE", "code3": "YEM", "name": "Yemen", "number": "887" },
  { "code": "ZM", "code3": "ZMB", "name": "Zambia", "number": "894" },
  { "code": "ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716" },
  { "code": "AX", "code3": "ALA", "name": "Åland Islands", "number": "248" }
];

/// Enumerated Currency Codes per ISO 4217: 
/// https://www.six-group.com/en/products-services/financial-information/data-standards.html
export const currencies = [
  'AED', // United Arab Emirates dirham
  'AFN', // Afghan afghani
  'ALL', // Albanian lek
  'AMD', // Armenian dram
  'ANG', // Netherlands Antillean guilder
  'AOA', // Angolan kwanza
  'ARS', // Argentine peso
  'AUD', // Australian dollar
  'AWG', // Aruban florin
  'AZN', // Azerbaijani manat
  'BAM', // Bosnia and Herzegovina convertible mark
  'BBD', // Barbados dollar
  'BDT', // Bangladeshi taka
  'BGN', // Bulgarian lev
  'BHD', // Bahraini dinar
  'BIF', // Burundian franc
  'BMD', // Bermudian dollar
  'BND', // Brunei dollar
  'BOB', // Boliviano
  'BOV', // Bolivian Mvdol
  'BRL', // Brazilian real
  'BSD', // Bahamian dollar
  'BTN', // Bhutanese ngultrum
  'BWP', // Botswana pula
  'BYR', // Belarusian ruble
  'BZD', // Belize dollar
  'CAD', // Canadian dollar
  'CDF', // Congolese franc
  'CHE', // WIR Euro
  'CHF', // Swiss franc
  'CHW', // WIR Franc
  'CLF', // Unidad de Fomento
  'CLP', // Chilean peso
  'CNY', // Chinese yuan
  'COP', // Colombian peso
  'COU', // Unidad de Valor Real
  'CRC', // Costa Rican colon
  'CUC', // Cuban convertible peso
  'CUP', // Cuban peso
  'CVE', // Cape Verdean escudo
  'CZK', // Czech koruna
  'DJF', // Djiboutian franc
  'DKK', // Danish krone
  'DOP', // Dominican peso
  'DZD', // Algerian dinar
  'EGP', // Egyptian pound
  'ERN', // Eritrean nakfa
  'ETB', // Ethiopian birr
  'EUR', // Euro
  'FJD', // Fiji dollar
  'FKP', // Falkland Islands pound
  'GBP', // Pound sterling
  'GEL', // Georgian lari
  'GHS', // Ghanaian cedi
  'GIP', // Gibraltar pound
  'GMD', // Gambian dalasi
  'GNF', // Guinean franc
  'GTQ', // Guatemalan quetzal
  'GYD', // Guyanese dollar
  'HKD', // Hong Kong dollar
  'HNL', // Honduran lempira
  'HRK', // Croatian kuna
  'HTG', // Haitian gourde
  'HUF', // Hungarian forint
  'IDR', // Indonesian rupiah
  'ILS', // Israeli new shekel
  'INR', // Indian rupee
  'IQD', // Iraqi dinar
  'IRR', // Iranian rial
  'ISK', // Icelandic króna
  'JMD', // Jamaican dollar
  'JOD', // Jordanian dinar
  'JPY', // Japanese yen
  'KES', // Kenyan shilling
  'KGS', // Kyrgyzstani som
  'KHR', // Cambodian riel
  'KMF', // Comoro franc
  'KPW', // North Korean won
  'KRW', // South Korean won
  'KWD', // Kuwaiti dinar
  'KYD', // Cayman Islands dollar
  'KZT', // Kazakhstani tenge
  'LAK', // Lao kip
  'LBP', // Lebanese pound
  'LKR', // Sri Lankan rupee
  'LRD', // Liberian dollar
  'LSL', // Lesotho loti
  'LTL', // Lithuanian litas
  'LVL', // Latvian lats
  'LYD', // Libyan dinar
  'MAD', // Moroccan dirham
  'MDL', // Moldovan leu
  'MGA', // Malagasy ariary
  'MKD', // Macedonian denar
  'MMK', // Myanmar kyat
  'MNT', // Mongolian tögrög
  'MOP', // Macanese pataca
  'MRO', // Mauritanian ouguiya
  'MUR', // Mauritian rupee
  'MVR', // Maldivian rufiyaa
  'MWK', // Malawian kwacha
  'MXN', // Mexican peso
  'MXV', // Mexican Unidad de Inversion
  'MYR', // Malaysian ringgit
  'MZN', // Mozambican metical
  'NAD', // Namibian dollar
  'NGN', // Nigerian naira
  'NIO', // Nicaraguan córdoba
  'NOK', // Norwegian krone
  'NPR', // Nepalese rupee
  'NZD', // New Zealand dollar
  'OMR', // Omani rial
  'PAB', // Panamanian balboa
  'PEN', // Peruvian sol
  'PGK', // Papua New Guinean kina
  'PHP', // Philippine peso
  'PKR', // Pakistani rupee
  'PLN', // Polish złoty
  'PYG', // Paraguayan guaraní
  'QAR', // Qatari riyal
  'RON', // Romanian leu
  'RSD', // Serbian dinar
  'RUB', // Russian ruble
  'RWF', // Rwandan franc
  'SAR', // Saudi riyal
  'SBD', // Solomon Islands dollar
  'SCR', // Seychelles rupee
  'SDG', // Sudanese pound
  'SEK', // Swedish krona
  'SGD', // Singapore dollar
  'SHP', // Saint Helena pound
  'SLL', // Sierra Leonean leone
  'SOS', // Somali shilling
  'SRD', // Surinamese dollar
  'SSP', // South Sudanese pound
  'STD', // São Tomé and Príncipe dobra
  'SVC', // Salvadoran colón
  'SYP', // Syrian pound
  'SZL', // Swazi lilangeni
  'THB', // Thai baht
  'TJS', // Tajikstani somoni
  'TMT', // Turkmenistan manat
  'TND', // Tunisian dinar
  'TOP', // Tongan pa'anga
  'TRY', // Turkish lira
  'TTD', // Trinidad and Tobago dollar
  'TWD', // New Taiwan dollar
  'TZS', // Tanzanian shilling
  'UAH', // Ukrainian hryvnia
  'UGX', // Ugandan shilling
  'USD', // United States dollar
  'USN', // United States dollar (next day)
  'USS', // United States dollar (same day)
  'UYI', // Uruguay Peso en Unidedades Indexadas
  'UYU', // Uruguyan peso
  'UZS', // Uzbekistan som
  'VEF', // Venezuelan bolívar soberano
  'VND', // Vietnamese đồng
  'VUV', // Vanuatu vatu
  'WST', // Samoan tala
  'XAF', // CFA franc BEAC
  'XAG', // Silver
  'XAU', // Gold
  'XBA', // European Composite Unit
  'XBB', // European Monetary Unit
  'XBC', // European Unit of Account 9
  'XBD', // European Unit of Account 17
  'XCD', // East Caribbean dollar
  'XDR', // Special drawing rights (International Monetary Fund)
  'XOF', // CFA franc BCEAO
  'XPD', // Palladium
  'XPF', // CFP franc
  'XPT', // Platinum
  'XTS', // Code reserved for testing
  'YER', // Yemeni rial
  'ZAR', // South African rand
  'ZMK', // Zambian kwacha
  'ZMW', // Zambian kwacha
  'BTC'  // Bitcoin
];

export const highContrastColors = [
  'gray', 'brown', 'orange', 'cornflowerblue', 'forestgreen', 'yellowgreen', 'black', 'crimson', 'cadetblue',
  'coral', 'chocolate', 'blue', 'blueviolet', 'darkblue', 'darkcyan', 'darkorange', 'darkred', 'deepskyblue',
  'dodgerblue', 'fuchsia', 'firebrick', 'gold', 'goldenrod', 'hotpink', 'indianred', 'indigo', 'lightseagreen',
  'lightskyblue', 'lightsteelblue', 'lightblue', 'limegreen', 'maroon', 'orangered', 'violet', 'turquoise',
  'tomato', 'teal', 'tan', 'steelblue', 'slategray', 'slateblue', 'skyblue', 'silver', 'sienna', 'seagreen',
  'sandybrown', 'salmon', 'saddlebrown', 'royalblue', 'purple', 'navy', 'mediumaquamarine', 'mediumblue',
  'mediumorchid', 'mediumpurple', 'mediumseagreen', 'mediumslateblue', 'mediumsprintgreen', 'mediumturquoise',
  'mediumvioletred'
];

export const elementHeight = {
  appBar: 64,
  footer: 72,
  marketplaceSearchBar: 88
};

export const pageResizeDebounceDelay = 100;

export const undoStateType = {
  proposal: 'proposal',
  proposalChanges: 'proposalChanges',
  tabChange: 'tabChange'
};

export const undoRedoThrottleDelay = 300;

export const loginFormValidationDelay = 100;

export const backToTopThrottleDelay = 500;
