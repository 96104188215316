import Guid from "common/values/guid/guid";
import SystemNotification from "users/entities/system-notification/system-notification";

export default class SystemNotificationAPIResponse {
    id?: string;
    content?: string;

    constructor(values: Partial<SystemNotificationAPIResponse>) {
        this.id = values.id;
        this.content = values.content;
    }

    deserialize(): SystemNotification  {
        if(!this.id) throw new Error('SystemNotificationAPIResponse.deserialize(): id is required');
        if(!this.content) throw new Error('SystemNotificationAPIResponse.deserialize(): content is required');

        return new SystemNotification(new Guid(this.id), this.content);
    }
}
