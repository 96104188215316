import React, { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

type FilterNumericProps = {
  filterName: string;
  value: number;
  onChange: (filterName: string, value: number | null) => void;
};

export default function FilterNumeric(props: Readonly<FilterNumericProps>) {
  const { filterName, onChange } = props;

  const [value, setValue] = React.useState<number>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
      <NumericFormat 
        value={value}
        onValueChange={(values) => {
          setValue(values.floatValue ?? 0);
          onChange(filterName, values.floatValue ?? null);
        }}
      />
  );
}
