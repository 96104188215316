import Guid from "common/values/guid/guid";
import { Moment } from "moment";
import User from "users/entities/user/user";

export default class UserEntityMemberInvitation {
  public readonly id: Guid;
  public readonly user: User;
  public readonly entityId: Guid;
  public readonly entityName: string;
  public readonly expires: Moment;
  public readonly createClientRep: boolean;
  public readonly createVendorRep: boolean;
  public readonly isAdmin: boolean;

  constructor(
    id: Guid,
    user: User,
    entityId: Guid,
    entityName: string,
    expires: Moment,
    createClientRep: boolean,
    createVendorRep: boolean,
    isAdmin: boolean
  ) {
    this.id = id;
    this.user = user;
    this.entityId = entityId;
    this.entityName = entityName;
    this.expires = expires;
    this.createClientRep = createClientRep;
    this.createVendorRep = createVendorRep;
    this.isAdmin = isAdmin;
  }
}
