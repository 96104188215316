import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";

import SuperUserAPIService from "users/entities/super-user/api/super-user-api-service";
import UserEntityMemberInvitation from "users/values/user-entity-member-invitation/user-entity-member-invitation";
import UserEntityMemberInvitationsTable from "users/values/user-entity-member-invitation/view/user-entity-member-invitations-table";
import { useSession } from "users/session/session-context";
import { CanceledError } from "axios";


export default function ActiveUserInvitationsPage() {
  const [invitations, setInvitations] = useState<UserEntityMemberInvitation[]>([]);
  const session = useSession();

  useEffect(() => {
    let abortController = new AbortController();
    getActiveInvitations(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  async function getActiveInvitations(abortController: AbortController) {
    try {
      const superUserAPIService = new SuperUserAPIService(session);
      const returnedInvitations =
        await superUserAPIService.getActiveUserInvitations(abortController);
      setInvitations(returnedInvitations);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
      enqueueSnackbar("Failed to get open invitations", { variant: "error" });
    }
  }

  return (
    <UserEntityMemberInvitationsTable
      invitations={invitations}
    />
  );
}
