import { Change, diffLines } from "diff";
import TextChange, {
  TextChangeCollection,
} from "work/entities/proposal/redlining/_diff/text-change";
import Fee from "work/values/fee/fee";

export type FeeType = Fee | null | false;
export default class FeeRedline extends TextChangeCollection {
  private _originalEntry: FeeType;
  private _revisedEntry: FeeType;
  public _currentEntry: FeeType | undefined;

  constructor(originalFee: FeeType, revisedFee: FeeType, changes?: TextChange[]) {
    super();

    this._originalEntry = originalFee;
    this._revisedEntry = revisedFee;
    this.changes = changes ?? [];
    if(this._originalEntry === false && this._revisedEntry === false) {
      this._currentEntry = false;
    }
    if(this._originalEntry === null && this._revisedEntry === null) {
      this._currentEntry = null;
    }
    if(this._originalEntry && this._revisedEntry && this._originalEntry.isEqualTo(this._revisedEntry)) {
      this._currentEntry = revisedFee;
    }

    this.updateDiff();
  }

  public get isNewlyAdded(): boolean {
    return (
      Boolean(this._currentEntry !== undefined) &&
      Boolean(!this._revisedEntry)
    );
  }
  public get isAdded(): boolean {
    if (this._currentEntry === undefined){
      return this._originalEntry === false && this._revisedEntry !== false;
    }
    if (this._currentEntry === false) return false;
    if (this._currentEntry) return this.revisedEntry === false;
    return false;
  }
  public get isRemoved(): boolean {
    if (this._currentEntry === undefined){
      return this._originalEntry !== false && this._revisedEntry === false;
    }
    if (this._currentEntry === false) return true;
    return false;
  }
  public get isResolved(): boolean {
    return this._currentEntry !== undefined;
  }
  public get revisionAccepted(): boolean {
    if(this._revisedEntry === false) return this._currentEntry === false;
    if(this._currentEntry) {
      return this._currentEntry.isEqualTo(this._revisedEntry) ?? false;
    }
    return false;
  }
  public get revisionRejected(): boolean {
    if(this._revisedEntry === false) return this._currentEntry !== false;
    if (
      this._currentEntry === undefined ||
      (this._currentEntry === null && this._revisedEntry === null) ||
      (this._currentEntry && this._currentEntry.isEqualTo(this._revisedEntry))
    ) {
      return false;
    }
    if (
      (this._currentEntry === null && this._originalEntry === null) ||
      (this._currentEntry && this._originalEntry && this._currentEntry.isEqualTo(this._originalEntry))
    ) {
      return true;
    }
    return false;
  }
  public get originalEntry(): FeeType {
    return this._originalEntry;
  }
  public get revisedEntry(): FeeType {
    return this._revisedEntry;
  }
  public get currentEntry(): FeeType | undefined {
    return this._currentEntry;
  }

  public get wasRedlined(): boolean {
    if (
      this._originalEntry &&
      this._revisedEntry &&
      this._originalEntry.isEqualTo(this._revisedEntry)
    )
      return false;
    return this._originalEntry !== this._revisedEntry;
  }
  public get wasModified(): boolean {
    if(this._revisedEntry && this._currentEntry) return !this._revisedEntry.isEqualTo(this._currentEntry);
    if(this.revisedEntry === null) return this.currentEntry !== null;
    if(this.revisedEntry === false) return this.currentEntry !== false;
    return false;
  }
  public get canBeUndone(): boolean {
    return (
      (this.wasRedlined && this.isResolved) ||
      this.wasModified
    );
  }

  public updateFee(newFee: Fee | null | undefined | false): FeeRedline {
    const newRedline = this.clone();
    newRedline._currentEntry = newFee;
    newRedline.updateDiff();
    return newRedline;
  }

  private updateDiff() {
    const newChanges: TextChange[] = [];
    let diffResult: Change[] = [];
    let originalLine: string = "";
    let revisedLine: string = "";
    let currentLine: string = "";

    if(this._originalEntry === null){
      originalLine = "Deferred";
    } else if(this._originalEntry){
      originalLine = this._originalEntry.toString();
    }
    if(this._revisedEntry === null){
      revisedLine = "Deferred";
    } else if (this._revisedEntry){
      revisedLine = this._revisedEntry.toString();
    }
    if(this._currentEntry === null){
      currentLine = "Deferred";
    } else if (this._currentEntry){
      currentLine = this._currentEntry.toString();
    }


    if (this._currentEntry === undefined) {
      diffResult = diffLines(originalLine, revisedLine);
      diffResult.forEach((change: Change) => {
        newChanges.push(new TextChange(this, change));
      });
    } else {
      diffResult = diffLines(revisedLine, currentLine);
      diffResult.forEach((change: Change) => {
        newChanges.push(new TextChange(this, change));
      });
    }
    this.changes = newChanges;
  }

  public clone() {
    return FeeRedline.fromObject(this.toFeeRedlineJSON());
  }

  toFeeRedlineJSON() {
    return {
      changes: this.changes.map((change: TextChange) => change.toJSON()),
      _originalFee: this._originalEntry,
      _revisedFee: this._revisedEntry,
      _currentFee: this._currentEntry,
    };
  }

  public static fromObject(object: any): FeeRedline {
    const originalFee = object._originalFee ? Fee.Prototype.fromObject(object._originalFee) : object._originalFee;
    const revisedFee = object._revisedFee ? Fee.Prototype.fromObject(object._revisedFee) : object._revisedFee;
    const redline = new FeeRedline(originalFee, revisedFee);

    let currentFee: FeeType | undefined = undefined;
    if (object._currentFee === null) currentFee = null;
    else if (object._currentFee === false) currentFee = false;
    else if (object._currentFee === undefined) currentFee = undefined;
    else if (object._currentFee) currentFee = Fee.Prototype.fromObject(object._currentFee);

    redline._currentEntry = currentFee;
    redline.updateDiff();
    return redline;
  }
}
