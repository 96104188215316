import axios, { AxiosHeaders, CanceledError } from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import EntityOfficerInvitation from "legal-entities/entities/entity-officer-invitation/entity-officer-invitation";
import DisableUserAPIRequest from "users/entities/super-user/api/request-contracts/disable-user-api-request";
import EnableUserAPIRequest from "users/entities/super-user/api/request-contracts/enable-user-api-request";
import InviteEntityOfficerAPIRequest from "users/entities/super-user/api/request-contracts/invite-entity-officer-api-request";
import LoginAsUserAPIRequest from "users/entities/super-user/api/request-contracts/login-as-user-api-request";
import RegisterAPIRequest from "users/entities/super-user/api/request-contracts/register-api-request";
import UnregisterAPIRequest from "users/entities/super-user/api/request-contracts/unregister-api-request";
import SystemNotificationAPIResponse from "users/entities/super-user/api/response-contracts/system-notification-api-response";
import UserAPIResponse from "users/entities/super-user/api/response-contracts/user-api-response";
import SystemNotification from "users/entities/system-notification/system-notification";
import { AuthResponse } from "users/entities/user/api/auth-email-api-service";
import AuthInfoAPIResponse from "users/entities/user/api/response-contracts/auth-info-api-response";
import User from "users/entities/user/user";
import Session from "users/session/session";
import UserEntityMemberInvitationAPIResponse from "users/values/user-entity-member-invitation/api/user-entity-member-invitation-api-response";
import UserEntityMemberInvitation from "users/values/user-entity-member-invitation/user-entity-member-invitation";

export default class SuperUserAPIService {

  private authHeaders: AxiosHeaders = new AxiosHeaders();

  private headerWithJson(): AxiosHeaders {
    return this.authHeaders.concat(
      { "Content-Type": "application/json" }
    );
  }

  constructor(session: Readonly<Session>) {
    if (!session.authToken?.value)
      throw new Error("Session must have an authToken to create a SuperUserAPIService");
    this.authHeaders.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async register(user: User): Promise<void> {
    const url = new URL(
      "/super-user/register",
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        new RegisterAPIRequest(user),
        { 
          headers: this.headerWithJson() 
        }
      );

    } catch (error) {
      throw new SuperUserAPIServiceError("register", error);
    }
  }

  async unregister(user: User): Promise<void> {
    const url = new URL(
      "/super-user/unregister",
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        new UnregisterAPIRequest(user),
        { headers: this.headerWithJson() }
      );
    } catch (error) {
      throw new SuperUserAPIServiceError("unregister", error);
    }
  }

  async disableUser(user: User): Promise<void> {
    const url = new URL(
      "/super-user/disable-user",
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        new DisableUserAPIRequest(user),
        { headers: this.headerWithJson() }
      );
    } catch (error) {
      throw new SuperUserAPIServiceError("disableUser", error);
    }
  }

  async enableUser(user: User): Promise<void> {
    const url = new URL(
      "/super-user/enable-user",
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        new EnableUserAPIRequest(user),
        { headers: this.headerWithJson() }
      );
    } catch (error) {
      throw new SuperUserAPIServiceError("enableUser", error);
    }
  }

  async loginAsUser(userToSpoof: User): Promise<AuthResponse> {
    const url = new URL(
      "/super-user/login-as-user",
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new LoginAsUserAPIRequest(userToSpoof),
        { headers: this.headerWithJson() }
      );

      const authInfo: AuthInfoAPIResponse = Object.assign(
        new AuthInfoAPIResponse(),
        response.data);
      const user = authInfo.deserializeUser();
      const context = authInfo.deserializeContext();
      const authToken = authInfo.deserializeAuthToken();
      const legalEntities = authInfo.deserializeUserEntityInfo();
      const openEntityAgreements = authInfo.deserializeOpenEntityAgreements();
      const networkConnections = authInfo.deserializeNetworkConnections();
      const networkInvitations = authInfo.deserializeNetworkInvitations();
      const bookmarkedIndividuals = authInfo.deserializeBookmarkedIndividuals();
      const bookmarkedCompanies = authInfo.deserializeBookmarkedCompanies();
      const bookmarkedTeams = authInfo.deserializeBookmarkedTeams();
      return {
        user, context, authToken, legalEntities,
        openEntityAgreements, networkConnections,
        networkInvitations, bookmarkedIndividuals,
        bookmarkedCompanies, bookmarkedTeams
      };
    } catch (error) {
      throw new SuperUserAPIServiceError("loginAsUser", error);
    }
  }

  async inviteEntityOfficer(
    invitation: EntityOfficerInvitation):
    Promise<EntityOfficerInvitation> {
    const url = new URL(
      "/super-user/invite-entity-officer",
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new InviteEntityOfficerAPIRequest(invitation),
        { headers: this.headerWithJson() }
      );
      const returnedId: Guid = new Guid(response.data);
      invitation.id = returnedId;
      return invitation;
    }
    catch (error) {
      throw new SuperUserAPIServiceError("inviteEntityOfficer", error);
    }
  }

  async createSystemNotification(
    notificationType: string,
    notificationContent: string
  ): Promise<void> {
    const url = new URL(
      "/super-user/system-notifications",
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        { content: `${notificationContent}:${notificationType}` },
        { headers: this.headerWithJson() }
      );
    } catch (error: any) {
      throw new SuperUserAPIServiceError("createSystemNotification", error);
    }
  }

  async deleteSystemNotification(notificationId: Guid) {
    const url = new URL(
      `/super-user/system-notifications/${notificationId}`,
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        { headers: this.authHeaders }
      );
    } catch (error: any) {
      throw new SuperUserAPIServiceError("deleteSystemNotification", error);
    }
  }

  async searchUsers(query: string, abortController?: AbortController): Promise<User[]> {
    try {
      const url = new URL(
        `/super-user/search-users?query=${query}`,
        AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.get(
        url.toString(),
        { 
          headers: this.authHeaders,
          signal: abortController?.signal 
        }
      );
      const users: User[] = [];
      response.data.forEach((datum: any) => {
        try
        {
          const userData = Object.assign(new UserAPIResponse(), datum);
          users.push(userData.deserialize());  
        }
        catch(error)
        {
          console.error(`Error deserializing user data: ${error}`, datum);
        }
      });
      return users
    } catch (error: any) {
      if(error instanceof CanceledError)
        throw error;
      throw new SuperUserAPIServiceError("searchUsers", error);
    }
  }

  async validateSuperUser(id: Guid): Promise<boolean> {
    try {
      const url = new URL(`/super-user/verify/${id}`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.get(url.toString(), { headers: this.authHeaders });
      return response.data.toLowerCase() === "true";
    }
    catch (error: any) {
      throw new SuperUserAPIServiceError("validateSuperUser", error);
    }
  }


  async getAppVersion(abortController?: AbortController): Promise<string> {
    const url = new URL("/super-user/version", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(url.toString(), 
      { 
        headers: this.authHeaders,
        signal: abortController?.signal
      });
      if (!response?.data) {
        throw new Error("No data returned from server");
      }
      const version: string = response.data;
      return version;
    } catch (error: any) {
      if (error?.response?.status === 403) {
        throw new VersionInfoNotAvailableError(
          "Unable to fetch version info, caller must be a super-user"
        );
      }
      if(error instanceof CanceledError)
        throw error;
      throw new SuperUserAPIServiceError("getAppVersion", error);
    }
  }

  async getSystemNotifications(abortController?: AbortController): Promise<SystemNotification[]> {
    const url = new URL("/super-user/system-notifications", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(url.toString(), 
      { headers: this.authHeaders,
        signal: abortController?.signal 
      });
      const systemNotifications: SystemNotification[] = [];
      response.data.forEach((item: any) => {
        const systemNotificaiton = new SystemNotificationAPIResponse(item);
        systemNotifications.push(systemNotificaiton.deserialize());
      });
      return systemNotifications;
    } catch (error: any) {
      if (error?.response?.status === 403) {
        throw new SystemNotificationsNotFoundError();
      }
      if(error instanceof CanceledError)
        throw error;
      throw new SuperUserAPIServiceError("getSystemNotifications", error);
    }
  }

  async getActiveUserInvitations(abortController?: AbortController): Promise<UserEntityMemberInvitation[]> {
    const url = new URL("/super-user/active-user-invitations", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(url.toString(), 
      { 
        headers: this.authHeaders,
        signal: abortController?.signal
      });
      const invitations: UserEntityMemberInvitation[] = [];
      response.data.forEach((item: any) => {
        invitations.push(Object.assign(new UserEntityMemberInvitationAPIResponse(), item).deserialize());
      });
      return invitations;
    } catch (error: any) {
      if (error?.response?.status === 404) {
        throw new UserEntityMemberInvitationsNotFoundError();
      }
      if(error instanceof CanceledError)
        throw error;
      throw new SuperUserAPIServiceError("getActiveUserInvitations", error);
    }
  }
}

export class SuperUserAPIServiceError extends Error {
  constructor(method: string, error: any) {
    super(`Error in SuperUserAPIService.${method}: ${error}`);
  }
}

export class SystemNotificationsNotFoundError extends Error {
  constructor() {
    super(`Unable to fetch any system notifications`);
  }
}

export class UserEntityMemberInvitationsNotFoundError extends Error {
  constructor() {
    super(`Unable to retrieve open user entity member invitations`);
  }
}

export class VersionInfoNotAvailableError extends Error { }
