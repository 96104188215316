import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import AdministratorInvitation from "legal-entities/entities/administrator-invitation/administrator-invitation";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import EntityName from "legal-entities/values/entity-name/entity-name"
import { InvitationStatus } from "legal-entities/values/invitation-status"
import RegistrationInfo from "legal-entities/values/registration-info";


export default class AdministratorInvitationAPIResponse {
    id?: string;
    entityId?: string;
    entityName?: string;
    isClient?: boolean;
    isVendor?: boolean;
    emailAddress?: string;
    firstName?: string;
    lastName?: string;
    status?: string;

    deserialize(): AdministratorInvitation {
        if (!this.id || !this.entityId || !this.entityName || !this.emailAddress || !this.firstName || !this.lastName || !this.status)
            throw new Error("Invalid AdministratorInvitationAPIResponse");
        const invitation = new AdministratorInvitation(
            new LegalEntity(new Guid(this.entityId),
                new EntityName(this.entityName),
                this.isClient,
                this.isVendor
                ),
            new RegistrationInfo(new Name(this.firstName, this.lastName), new EmailAddress(this.emailAddress)),
            InvitationStatus[this.status as keyof typeof InvitationStatus]
        );
        invitation.id = new Guid(this.id);
        return invitation;
    }
}
