import EmailAddress from "common/values/email-address/email-address";
import Name from "common/values/name/name";
import Password from "common/values/password/password";

export default class RegistrationInfo {
    name: Name;
    email: EmailAddress;
    password?: Password;

    constructor(name: Name, email: EmailAddress, password?: Password) {
        this.name = name;
        this.email = email;
        this.password = password;
    }

    public toJSON() {
        return {
            firstName: this.name.firstName,
            lastName: this.name.lastName,
            emailAddress: this.email.toString(),
            password: this.password?.toString()
        }
    }
}

export class InvalidRegistrationInfoError extends Error {
    issues: any;

    constructor(issues: any) {
        super(`Invalid registration info.`);
        this.issues = issues;
    }
}