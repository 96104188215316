import Text from "common/values/text/text";

export default class FeeScheduleBillingCode extends Text {
    constructor(value: string) {
        super(value);

    }

    public isEqualTo(other: Text | null): boolean {
        if(!(other instanceof FeeScheduleBillingCode)) return false;
        return super.isEqualTo(other);
    }
    
    public override clone(): FeeScheduleBillingCode {
        return new FeeScheduleBillingCode(this.value);
    }

    public static get Prototype(): FeeScheduleBillingCode {
        return new FeeScheduleBillingCode('Prototype');
    }

    public override fromObject(obj: any): Text | null {
        return new FeeScheduleBillingCode(obj);
    }
    public toJSON(): any {
        return this.value;
    }
}