export default class ForumEntityClass {
    private _value: string;

    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: ForumEntityClass): boolean {
        return this.value === other?.value;
    }

    public get value(): string {
        return this._value;
    }

    public static get Prototype(): ForumEntityClass {
        return new ForumEntityClass("");
    }

    public fromObject(obj: any): ForumEntityClass {
        return new ForumEntityClass(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): ForumEntityClass {
        return new ForumEntityClass(this.value);
    }
}