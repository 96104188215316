import CommentIcon from "@mui/icons-material/Comment";
import { Badge, Divider, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { downloadFile } from "common/helpers/utils";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import UnavailableDocument from "documents/values/unavailable-document";
import DocumentSelector from "documents/view/document-selector";
import DocumentUploadOverlay from "documents/view/document-upload-overlay";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useSession } from "users/session/session-context";
import { ProposalField } from "work/entities/proposal/proposal";
import { FieldRedlineArray } from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import DocumentRedlineSelection from "work/entities/proposal/redlining/view/redline-field-inputs/document-redline-selection";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import { ProposalFieldName } from "work/values/constants";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import WorkDocument, {
  WorkDocumentType,
} from "work/values/work-document/work-document";

const ListContainer = styled(Grid)(({ theme }) => ({
  flexWrap: "nowrap",
  minHeight: "20rem",
  width: "100%",
}));
const DocumentSelectorContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
}));
const SelectedDocsList = styled(DocumentRedlineSelection)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
  maxHeight: "60vh",
  marginLeft: theme.spacing(2),
  overflowY: "auto",
}));
const HeadControls = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
}));
const Uploader = styled(DocumentUploadOverlay)(({ theme }) => ({
  backgroundColor: "rgba(250, 250, 250, 0.5)",
  backdropFilter: "blur(5px) saturate(200%)",
  position: "absolute",
  top: 0,
  left: 0,
  margin: "1rem",
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  zIndex: 9999,
}));

type PoliciesTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  issues?: ProposalIssues;
  activeReviewField: ProposalField | undefined;
  commentForums: Forum[];
  disableEditing?: boolean;
  onProposalRedlineChange: (
    newRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onTraverseToNewField: (fieldInfo: ProposalField | undefined) => void;
  onCommentsClicked: (field: ProposalField, title?: string) => void;
};

const PoliciesTab = (props: Readonly<PoliciesTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    commentForums,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    onCommentsClicked,
  } = props;

  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(
    null
  );
  const [documentTypeToUpload, setDocumentTypeToUpload] =
    React.useState<WorkDocumentType>();
  const [documentIdToReplace, setDocumentIdToReplace] =
    React.useState<Guid | null>(null);
  const [creatingDocumentFromTemplate, setCreatingDocumentFromTemplate] =
    React.useState<Guid | null>(null);

  const session = useSession();

  async function handleDownloadDocumentById(
    documentId?: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    if (!documentId) return;

    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const policyTemplate = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(policyTemplate);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to download policy template. Please try again", {
        variant: "error",
      });
    } finally {
      setIsDownloadingFile(null);
    }
  }

  function handleDocumentRedlineChanged(
    newRedline: FieldRedlineArray<WorkDocument>,
    traversalFieldOverride?: ProposalField | null
  ) {
    let newProposalRedline: ProposalRedline;
    if (newRedline.field.isEqualTo(ProposalField.ClientPolicies)) {
      newProposalRedline =
        proposalRedline.updateClientPolicyDocumentsRedline(newRedline);
    } else if (newRedline.field.isEqualTo(ProposalField.VendorPolicies)) {
      newProposalRedline =
        proposalRedline.updateVendorPolicyDocumentsRedline(newRedline);
    } else {
      throw new Error("Unable to determine which policy redline to update.");
    }

    onProposalRedlineChange(newProposalRedline, traversalFieldOverride);
  }

  function handleDocumentSelectionChanged(
    selectedDocs: Document[],
    deselectedDocs: Document[]
  ) {
    if (!selectedDocs.length && !deselectedDocs.length) return;
    let updatedProposalRedline = proposalRedline.clone();

    for (const selectedDoc of selectedDocs) {
      const workDocument = WorkDocument.fromDocument(
        selectedDoc,
        session.context?.viewingAsVendor
          ? WorkDocumentType.VendorPolicy
          : WorkDocumentType.ClientPolicy
      );
      if (
        !session.context?.viewingAsVendor &&
        !updatedProposalRedline.clientPolicyDocuments.redlines.some(
          (document) => document.currentEntry?.isEqualTo(workDocument)
        )
      ) {
        updatedProposalRedline =
          updatedProposalRedline.updateClientPolicyDocumentsRedline(
            updatedProposalRedline.clientPolicyDocuments.addEntry(workDocument)
          );
      }

      if (
        session.context?.viewingAsVendor &&
        !updatedProposalRedline.vendorPolicyDocuments.redlines.some(
          (document) => document.currentEntry?.isEqualTo(workDocument)
        )
      ) {
        updatedProposalRedline =
          updatedProposalRedline.updateVendorPolicyDocumentsRedline(
            updatedProposalRedline.vendorPolicyDocuments.addEntry(workDocument)
          );
      }
    }

    for (const deselectedDoc of deselectedDocs) {
      if (
        !session.context?.viewingAsVendor &&
        updatedProposalRedline.clientPolicyDocuments.redlines.some((document) =>
          document.currentEntry?.id.isEqualTo(deselectedDoc.id)
        )
      ) {
        const redlineId =
          updatedProposalRedline.clientPolicyDocuments.redlines.find(
            (documentRedline) =>
              documentRedline.currentEntry?.id?.isEqualTo(deselectedDoc.id) ||
              (!documentRedline.currentEntry &&
                documentRedline.revisedEntry?.id?.isEqualTo(deselectedDoc.id))
          )?.field.id;

        if (!redlineId) {
          console.error("Unable to determine which policy redline to update.");
          continue;
        }

        updatedProposalRedline =
          updatedProposalRedline.updateClientPolicyDocumentsRedline(
            updatedProposalRedline.clientPolicyDocuments.removeEntryByFieldId(
              redlineId
            )
          );
      }

      if (
        session.context?.viewingAsVendor &&
        updatedProposalRedline.vendorPolicyDocuments.redlines.some((document) =>
          document.currentEntry?.id.isEqualTo(deselectedDoc.id)
        )
      ) {
        const redlineId =
          updatedProposalRedline.vendorPolicyDocuments.redlines.find(
            (documentRedline) =>
              documentRedline.currentEntry?.id?.isEqualTo(deselectedDoc.id) ||
              (!documentRedline.currentEntry &&
                documentRedline.revisedEntry?.id?.isEqualTo(deselectedDoc.id))
          )?.field.id;
        if (!redlineId) {
          console.error("Unable to determine which policy redline to update.");
          continue;
        }
        updatedProposalRedline =
          updatedProposalRedline.updateVendorPolicyDocumentsRedline(
            updatedProposalRedline.vendorPolicyDocuments.removeEntryByFieldId(
              redlineId
            )
          );
      }
    }

    let updatedDocumentRedline = session.context?.viewingAsVendor
      ? updatedProposalRedline.vendorPolicyDocuments
      : updatedProposalRedline.clientPolicyDocuments;

    if (session.context?.viewingAsVendor) {
      updatedProposalRedline =
        proposalRedline.updateVendorPolicyDocumentsRedline(
          updatedDocumentRedline
        );
    } else {
      updatedProposalRedline =
        proposalRedline.updateClientPolicyDocumentsRedline(
          updatedDocumentRedline
        );
    }

    onProposalRedlineChange(updatedProposalRedline, null);
  }

  function getShouldShowBadge(): boolean {
    return !getForumForField(
      session.context?.viewingAsVendor
        ? ProposalField.VendorPolicies
        : ProposalField.ClientPolicies,
      commentForums
    );
  }

  function renderCommentButton() {
    return (
      <Tooltip title="Comments">
        <span>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked?.(
                session.context?.viewingAsVendor
                  ? ProposalField.VendorPolicies
                  : ProposalField.ClientPolicies
              );
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={getShouldShowBadge()}
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  async function handleBeginCreateDocumentFromTemplate(
    templateId: Guid | undefined | null
  ) {
    if (!templateId) return;
    setDocumentTypeToUpload(
      session.context?.viewingAsVendor
        ? WorkDocumentType.VendorPolicy
        : WorkDocumentType.ClientPolicy
    );
    setCreatingDocumentFromTemplate(templateId);
    handleDownloadDocumentById(templateId);
  }

  function renderDocumentSelector() {
    const documentsRedline = session.context?.viewingAsVendor
      ? proposalRedline.vendorPolicyDocuments
      : proposalRedline.clientPolicyDocuments;
    let selectedTemplateIds: Guid[] = [];
    documentsRedline.redlines.forEach((redline) => {
      if (redline.currentEntry?.templateIds) {
        selectedTemplateIds = selectedTemplateIds.concat(
          redline.currentEntry.templateIds
        );
      }
    });
    return (
      <DocumentSelector
        documentType="policy"
        selectedDocumentIds={documentsRedline.currentIds}
        selectedTemplateIds={selectedTemplateIds}
        onDocumentSelectionChanged={handleDocumentSelectionChanged}
        onCreateDocumentFromTemplate={handleBeginCreateDocumentFromTemplate}
      />
    );
  }

  async function handleBeginReplaceDocument(
    documentId: Guid | undefined | null,
    documentType: WorkDocumentType
  ) {
    if (!documentId) return;

    setDocumentTypeToUpload(documentType);
    setDocumentIdToReplace(documentId);
    handleDownloadDocumentById(documentId);
  }

  function handlePolicyDocumentAdded(document: Document | UnavailableDocument) {
    if (!(document instanceof Document))
      throw new Error("Document must be available");

    let updatedProposalRedline = proposalRedline;
    const workDocument = WorkDocument.fromDocument(
      document,
      documentTypeToUpload ?? session.context?.viewingAsVendor
        ? WorkDocumentType.VendorPolicy
        : WorkDocumentType.ClientPolicy,
      documentIdToReplace ?? undefined
    );
    if (
      documentIdToReplace &&
      documentTypeToUpload === WorkDocumentType.VendorPolicy
    ) {
      updatedProposalRedline =
        proposalRedline.updateVendorPolicyDocumentsRedline(
          proposalRedline.vendorPolicyDocuments.replaceEntryById(
            documentIdToReplace,
            workDocument
          )
        );
    } else if (
      documentIdToReplace &&
      documentTypeToUpload === WorkDocumentType.ClientPolicy
    ) {
      updatedProposalRedline =
        proposalRedline.updateClientPolicyDocumentsRedline(
          proposalRedline.clientPolicyDocuments.replaceEntryById(
            documentIdToReplace,
            workDocument
          )
        );
    } else if (documentTypeToUpload === WorkDocumentType.VendorPolicy) {
      updatedProposalRedline =
        proposalRedline.updateVendorPolicyDocumentsRedline(
          proposalRedline.vendorPolicyDocuments.addEntry(workDocument)
        );
    } else if (documentTypeToUpload === WorkDocumentType.ClientPolicy) {
      updatedProposalRedline =
        proposalRedline.updateClientPolicyDocumentsRedline(
          proposalRedline.clientPolicyDocuments.addEntry(workDocument)
        );
    }

    onProposalRedlineChange(updatedProposalRedline, null);
    setDocumentIdToReplace(null);
    setCreatingDocumentFromTemplate(null);
  }

  if (!isOpen) return null;

  return (
    <>
      <HeadControls>{renderCommentButton()}</HeadControls>
      <ListContainer container direction="row">
        <DocumentSelectorContainer size="grow">
          {!disableEditing && renderDocumentSelector()}
        </DocumentSelectorContainer>
        <Grid>
          <Divider orientation="vertical" />
        </Grid>
        <Grid>
          <SelectedDocsList
            workDocumentType={WorkDocumentType.ClientPolicy}
            documentsRedline={proposalRedline.clientPolicyDocuments}
            accountType={AccountType.Client}
            downloadingFileId={isDownloadingFile}
            commentForums={commentForums}
            disableEditing={disableEditing}
            activeReviewField={activeReviewField}
            onCommentsClicked={(
              documentId?: Guid,
              name?: string,
              isVendor?: boolean
            ) => {
              if (!documentId) {
                console.error("Document ID is required to view comments.");
                return;
              }
              onCommentsClicked(
                isVendor
                  ? ProposalField.VendorPolicyDocument(documentId)
                  : ProposalField.ClientPolicyDocument(documentId),
                name
              );
            }}
            onDownload={handleDownloadDocumentById}
            onDocumentRedlineChange={(newRedline, traversalFieldOverride) => {
              try {
                handleDocumentRedlineChanged(
                  newRedline,
                  traversalFieldOverride
                );
              } catch (error) {
                console.error(error);
                enqueueSnackbar("Failed to update policy redline.", {
                  variant: "error",
                });
              }
            }}
            onBeginReplaceDocument={(docId) =>
              handleBeginReplaceDocument(docId, WorkDocumentType.ClientPolicy)
            }
            onDocumentClicked={(documentId: Guid) => {
              onTraverseToNewField?.(
                ProposalField.ClientPolicyDocument(documentId)
              );
            }}
            onTraverseToNewField={onTraverseToNewField}
          />
          <Divider />
          <SelectedDocsList
            workDocumentType={WorkDocumentType.VendorPolicy}
            documentsRedline={proposalRedline.vendorPolicyDocuments}
            accountType={AccountType.Vendor}
            downloadingFileId={isDownloadingFile}
            commentForums={commentForums}
            disableEditing={disableEditing}
            activeReviewField={activeReviewField}
            onCommentsClicked={(
              documentId?: Guid,
              name?: string,
              isVendor?: boolean
            ) => {
              if (!documentId) {
                console.error("Document ID is required to view comments.");
                return;
              }
              onCommentsClicked(
                isVendor
                  ? ProposalField.VendorPolicyDocument(documentId)
                  : ProposalField.ClientPolicyDocument(documentId),
                name
              );
            }}
            onDownload={handleDownloadDocumentById}
            onDocumentRedlineChange={(newRedline, traversalFieldOverride) => {
              try {
                handleDocumentRedlineChanged(
                  newRedline,
                  traversalFieldOverride
                );
              } catch (error) {
                console.error(error);
                enqueueSnackbar("Failed to update policy redline.", {
                  variant: "error",
                });
              }
            }}
            onBeginReplaceDocument={(docId) =>
              handleBeginReplaceDocument(docId, WorkDocumentType.VendorPolicy)
            }
            onDocumentClicked={(documentId: Guid) => {
              onTraverseToNewField?.(
                ProposalField.VendorPolicyDocument(documentId)
              );
            }}
            onTraverseToNewField={onTraverseToNewField}
          />
        </Grid>
      </ListContainer>
      {documentTypeToUpload &&
        (creatingDocumentFromTemplate || documentIdToReplace) && (
          <Uploader
            session={session}
            documentType={documentTypeToUpload}
            templateId={creatingDocumentFromTemplate ?? undefined}
            onDocumentUploaded={handlePolicyDocumentAdded}
            onClose={() => {
              setCreatingDocumentFromTemplate(null);
              setDocumentIdToReplace(null);
            }}
          />
        )}
    </>
  );
};

export default PoliciesTab;
