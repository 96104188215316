import "./message-editor.css";
import * as React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import { IconButton, ToggleButton, Tooltip, styled } from "@mui/material";
import * as Constants from "common/helpers/constants";
import { enqueueSnackbar } from "notistack";
import Forum from "messaging/entities/forum/forum";
import MessagingAPIService from "messaging/api/messaging-api-service";
import Message from "messaging/entities/message/message";
import LoadingButton from "common/components/loading-button";

import { EditorState, LexicalEditor, CLEAR_EDITOR_COMMAND } from "lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { CodeNode } from "@lexical/code";
import { LinkNode, AutoLinkNode } from "@lexical/link";
import { ListNode, ListItemNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";

import moment from "moment";
import MessageFileInfo from "messaging/values/attachment/message-file-info";
import Guid from "common/values/guid/guid";
import { useSession } from "users/session/session-context";

import Attachment, {
  FileAttachment,
} from "messaging/entities/message/view/components/editor/attachment";
import {
  SEND_MESSAGE_COMMAND,
  SubmitMessagePlugin,
} from "messaging/entities/message/view/components/editor/plugins/submit-message-plugin";
import ToolbarPlugin from "messaging/entities/message/view/components/editor/plugins/toolbar-plugin";
import SubjectButton from "messaging/entities/message/view/components/subject-button";
import AutoLinkPlugin from "messaging/entities/message/view/components/editor/plugins/auto-link-plugin";

const ActionsContainer = styled("div")(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(0.5),
}));
const OptionButtons = styled("div")(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));
const OptionsButtonsContainer = styled("div")(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
}));
const EditorWrapper = styled("div")(({ theme }) => ({
  position: "relative",
}));
const SendButton = styled(LoadingButton)(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
  position: "sticky",
  top: 0,
}));
const AttachButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));
const AttachmentInput = styled("input")(({ theme }) => ({
  display: "none",
}));

const PlaceholderText = () => {
  return <div className="placeholder-text">Type your message here...</div>;
};

const lexicalConfig = {
  namespace: "lexical-editor",
  theme: {
    root: "editor-root",
    quote: "editor-quote",
    link: "editor-link",
    text: {
      bold: "editor-textBold",
      code: "editor-textCode",
      italic: "editor-textItalic",
      strikethrough: "editor-textStrikethrough",
      subscript: "editor-textSubscript",
      superscript: "editor-textSuperscript",
      underline: "editor-textUnderline",
      underlineStrikethrough: "editor-textUnderlineStrikethrough",
    },
  },
  nodes: [
    HorizontalRuleNode,
    CodeNode,
    HeadingNode,
    AutoLinkNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    LinkNode,
  ],
  onError: (error: any) => console.error(error),
};

type MessageEditorProps = {
  forum: Forum;
  autoFocus?: boolean;
  locked?: boolean;
  onFormattingToggled: () => void;
  onAttachmentAdded: () => void;
  onMessageSent: (message: Message) => void;
};

export default function MessageEditor(props: Readonly<MessageEditorProps>) {
  const [isSending, setIsSending] = React.useState(false);
  const [messageText, setMessageText] = React.useState<string>("");
  const [subject, setSubject] = React.useState<string | undefined>("");
  const [attachment, setAttachment] = React.useState<
    FileAttachment | undefined
  >();

  if (!props.forum) throw new Error("Forum is required");

  const [showToolbar, setShowToolbar] = React.useState(false);
  const editorRef = React.useRef<LexicalEditor>();
  const hiddenAttachmentFileInput = React.useRef<HTMLInputElement>(null);
  const session = useSession();

  const canSendMessage = (): boolean => {
    return (
      ((messageText && messageText.length > 0) || attachment !== undefined) &&
      !props.locked
    );
  };

  const sendMessage = async (message: Message, editor?: LexicalEditor) => {
    if (!canSendMessage() || !session.user) return;

    setIsSending(true);
    try {
      const messagingService = new MessagingAPIService(session);
      const newMessage = await messagingService.createMessage(message);
      props.onMessageSent(newMessage);
    } catch (error: any) {
      console.error(`Unable to send message: ${error}`);
      enqueueSnackbar("Failed to send message. Please try again.", {
        variant: "error",
      });
    } finally {
      editor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      setMessageText("");
      setSubject(undefined);
      setAttachment(undefined);
      setIsSending(false);
    }
  };

  const draftMessage = (messageBody?: string) => {
    if (!session.user) return;
    const attachmentList = attachment ? [attachment.file] : [];
    try {
      return Message.draft(
        props.forum,
        session.user,
        messageBody ?? messageText,
        subject,
        attachmentList
      );
    } catch (error: any) {
      console.error(`Unable to draft message: ${error}`);
    }
  };

  const handleAttachmentUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;

    setAttachment({
      file: files[0],
      fileType: files[0].type.split("/")[1],
      url: URL.createObjectURL(files[0]),
    });

    event.target.value = "";
    props?.onAttachmentAdded();
  };

  const handleSendButtonClick = () => {
    if (!canSendMessage()) return;
    const draft = draftMessage();
    if (!draft) return;
    editorRef.current?.dispatchCommand(SEND_MESSAGE_COMMAND, draft);
  };

  const constructMessage = (): Message | undefined => {
    if (!messageText || !session.user?.id) return;
    const message = new Message(
      props.forum,
      messageText,
      session.user.id,
      subject
    );

    if (!attachment) return message;
    const attachmentFile = new MessageFileInfo(
      Guid.generate(),
      attachment.file.name,
      attachment.file.size,
      attachment.fileType,
      moment()
    );
    message.attachments = [attachmentFile];

    return message;
  };

  return (
    <React.Fragment>
      <AttachmentInput
        type="file"
        hidden={true}
        multiple={false}
        ref={hiddenAttachmentFileInput}
        accept={Constants.acceptedAttachmentFormats.join(", ")}
        onChange={handleAttachmentUpload}
        style={{ display: "none" }}
      />
      <EditorWrapper>
        <LexicalComposer initialConfig={lexicalConfig}>
          <div className="editor-container">
            {showToolbar && <ToolbarPlugin />}
            <Tooltip title="Add Subject">
              <span>
                <SubjectButton
                  forceReset={subject === undefined}
                  onSubjectChanged={(subject: string) => setSubject(subject)}
                />
              </span>
            </Tooltip>
            <div className="editor-inner">
              {showToolbar ? (
                <RichTextPlugin
                  contentEditable={<ContentEditable />}
                  placeholder={<PlaceholderText />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
              ) : (
                <PlainTextPlugin
                  contentEditable={<ContentEditable />}
                  placeholder={<PlaceholderText />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
              )}
              <SubmitMessagePlugin
                message={constructMessage()}
                listenForEnterKey={!showToolbar && canSendMessage()}
                listenForSubmitButton={canSendMessage()}
                onSend={sendMessage}
              />
              <EditorRefPlugin editorRef={editorRef} />
              <HistoryPlugin />
              <AutoFocusPlugin />
              <AutoLinkPlugin />
              <ClearEditorPlugin />
              <ListPlugin />
              <LinkPlugin />
              <OnChangePlugin
                onChange={(state: EditorState, editor: LexicalEditor) => {
                  editor.update(() => {
                    const markdown = $convertToMarkdownString(TRANSFORMERS)
                    setMessageText(markdown);
                    if (markdown.length < 1 && attachment === undefined) return;
                  });
                }}
              />
              <SendButton
                color="primary"
                size="large"
                endIcon={<SendIcon />}
                loading={isSending}
                disabled={!canSendMessage()}
                onClick={handleSendButtonClick}
              >
                {`Send${isSending ? "ing..." : ""}`}
              </SendButton>
            </div>
          </div>
        </LexicalComposer>
      </EditorWrapper>
      <ActionsContainer>
        <OptionButtons>
          <OptionsButtonsContainer>
            <Tooltip title="Toggle Formatting">
              <span>
                <ToggleButton
                  size="small"
                  selected={showToolbar}
                  style={{ border: 0 }}
                  value={showToolbar}
                  onChange={async () => {
                    setShowToolbar(!showToolbar);
                    props.onFormattingToggled();
                  }}
                >
                  <TextFormatIcon />
                </ToggleButton>
              </span>
            </Tooltip>
            <Tooltip title="Attach File">
              <span>
                <AttachButton
                  size="small"
                  disabled={attachment !== undefined}
                  onClick={() => hiddenAttachmentFileInput?.current?.click()}
                >
                  <AttachFileIcon />
                </AttachButton>
              </span>
            </Tooltip>
            {attachment?.url && (
              <Attachment
                attachment={attachment}
                onAttachmentRemoved={() => setAttachment(undefined)}
              />
            )}
          </OptionsButtonsContainer>
        </OptionButtons>
      </ActionsContainer>
    </React.Fragment>
  );
}
