import { Box, CircularProgress, Container, createTheme, styled, ThemeProvider, Typography } from "@mui/material";
import AttorneyHubLogo from 'app/assets/ipah_logo.svg';
import Guid from "common/values/guid/guid";
import OpenEntityMemberInvitationAPIService from "legal-entities/entities/open-entity-member-invitation/api/open-entity-member-invitation-api-service";
import OpenEntityMemberInvitation from "legal-entities/entities/open-entity-member-invitation/open-entity-member-invitation";
import OpenEntityMemberInvitationAcceptanceForm from "legal-entities/entities/open-entity-member-invitation/view/open-entity-member-invitation-acceptance-form";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSession } from "users/session/session-context";

const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#404558',
  boxSizing: 'border-box',
  height: '100%',
  padding: theme.spacing(5, 1),
  textAlign: 'center',
  width: '100%'
}));
const Logo = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  maxWidth: '90%'
}));
const InvitationForm = styled(Container)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  position: 'relative',
  width: '100%'
}));
const InvitationFormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));
const InvitationFormInstructions = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),
  "& a": {
    color: theme.palette.common.white,
    textDecoration: 'underline'
  }
}));
const LoadingContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  margin: theme.spacing(4, 0)
}));
const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
  height: '64px',
  marginRight: theme.spacing(1.5),
  width: '64px'
}));

type OpenMemberInvitationPageProps = {
  className?: string;
  invitationId?: Guid;
}

export default function OpenEntityMemberInvitationPage(props: Readonly<OpenMemberInvitationPageProps>) {
  const { className } = props;

  const [invitation, setInvitation] = useState<OpenEntityMemberInvitation>();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ invitationId: string }>();

  const theme = createTheme();
  const session = useSession();

  async function loadInvitation() {
    setLoading(true);

    try {
      let invitationId: Guid | undefined = undefined;

      if (props.invitationId)
        invitationId = props.invitationId;
      else if (params.invitationId)
        invitationId = new Guid(params.invitationId);

      if (!invitationId) 
        throw new Error("Invitation ID was not provided");

      const service = new OpenEntityMemberInvitationAPIService(session);
      const abortController = new AbortController();
      const invitation = await service.getOpenMemberInvitationById(invitationId, abortController);
      setInvitation(invitation);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInvitation();
  }, []);

  function invitationNotFoundMessage() {
    return (
      <InvitationForm className={className}>
        <InvitationFormTitle variant='h4'>
          Invitation Not Found
        </InvitationFormTitle>
        <InvitationFormInstructions>
          The invitation couldn't be found.<br />
          If you believe this is in error please contact <a href="mailto:support@attorneyhub.com" target="_blank" rel="noreferrer">support@attorneyhub</a>.
          <p>You can close this tab</p>
        </InvitationFormInstructions>
      </InvitationForm>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <FormContainer className={className}>
        <Logo src={AttorneyHubLogo} alt="AttorneyHub Logo" />
        {loading && (
          <LoadingContainer>
            <LoadingIcon />
            <Typography variant="h4">
              Loading...
            </Typography>
          </LoadingContainer>
        )}
        {!invitation && !loading && invitationNotFoundMessage()}
        <OpenEntityMemberInvitationAcceptanceForm invitation={invitation} />
      </FormContainer>
    </ThemeProvider>
  )
}
