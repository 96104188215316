import { E164Number, parsePhoneNumber } from 'libphonenumber-js';
export default class PhoneNumber {
    private readonly _value: string;
    
    constructor(value: string | E164Number) {
        this._value = value;
    }
    public get value(): string {
        return this._value;
    }
    public static fromObject(object: any) {
        if (!object) return;
        return new PhoneNumber(object);
    }
    public toE164Number(): E164Number {
        return parsePhoneNumber(this._value).number;
    }
    public toJSON() {
        return this._value;
    }
}
