export default class Action extends String {
  static readonly AcceptOrReject = 'AcceptOrReject';
  static readonly Review = 'Review';
  static readonly DraftProposalReview = 'DraftProposalReview';
  static readonly DraftProposalReviewApproved = 'DraftProposalReviewApproved';
  static readonly ProposalReview = 'ProposalReview';
  static readonly ProposalReviewApproved = 'ProposalReviewApproved';
  static readonly ReviewOrReject = 'ReviewOrReject';
  static readonly ProposalRevisionReview = 'ProposalRevisionReview';
  static readonly HireOrCancel = 'HireOrCancel';
  static readonly Hired = 'Hired';
}
