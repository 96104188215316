import Guid from "common/values/guid/guid";
import TeamInvitation from "marketplace/entities/team-invitation/team-invitation";
import moment from "moment";

export default class MarketplaceTeamInvitationAPIResponse {
    id?: string;
    inviteeId?: string;
    teamId?: string;
    message?: string;
    acceptedAt?: string;
    closed?: boolean;

    constructor(values: Partial<MarketplaceTeamInvitationAPIResponse>) {
        Object.assign(this, values);
    }

    deserialize(): TeamInvitation {
        if (!this.id) throw new Error("Invitation ID is required, was not returned by the API");
        if (!this.inviteeId) throw new Error("Invitee ID is required, was not returned by the API");
        if (!this.teamId) throw new Error("Team ID is required, was not returned by the API");

        return new TeamInvitation(
            new Guid(this.id),
            new Guid(this.inviteeId),
            new Guid(this.teamId),
            this.message,
            this.acceptedAt ? moment(this.acceptedAt) : undefined,
            this.closed
        );
    }
}
