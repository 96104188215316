import Guid from "common/values/guid/guid";
import { Moment } from "moment";

import CompanyProfile from "marketplace/values/company-profile/company-profile";
import Bookmark from "marketplace/entities/bookmark/bookmark";
import { BookmarkType } from "marketplace/values/bookmark/bookmark";


export default class BookmarkedCompany implements Bookmark {
    vendor: CompanyProfile;
    id: Guid;
    createdDate: Moment;
    bookmarkId: Guid;
    type: BookmarkType;

    constructor(id: Guid, bookmarkId: Guid, createdDate: Moment, vendor: CompanyProfile) {
        this.vendor = vendor;
        this.bookmarkId = bookmarkId;
        this.id = id;
        this.createdDate = createdDate;
        this.type = BookmarkType.Company;
    }

}
