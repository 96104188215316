import {
  Button, Checkbox, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CanceledError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSession } from 'users/session/session-context';
import FeeScheduleTemplateAPIService from 'work/entities/fee-schedule-template/api/fee-schedule-template-api-service';
import FeeScheduleCategory from 'work/values/fee-schedule-category/fee-schedule-category';

const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: theme.spacing(50),
  maxWidth: theme.spacing(50)
}));
const LoadingContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: theme.spacing(4)
}));

type FeeScheduleExistingCategoriesProps = {
  categories?: Array<FeeScheduleCategory>;
  disabledCategories?: Array<FeeScheduleCategory>;
  onCategoriesSelected: (categories: FeeScheduleCategory[]) => void
};

export default function FeeScheduleExistingCategories(props: Readonly<FeeScheduleExistingCategoriesProps>) {
  const {
    categories,
    disabledCategories,
    onCategoriesSelected
  } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [existingCategories, setExistingCategories] = React.useState<FeeScheduleCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<FeeScheduleCategory[]>([]);

  const session = useSession();

  useEffect(() => {
    let abortController = new AbortController();
    if (categories) {
      setExistingCategories(categories);
    } else {
      getExistingCategories(abortController);
      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    }
  }, []);

  async function getExistingCategories(abortController: AbortController) {
    try {
      setLoading(true);
      const existingCategories: FeeScheduleCategory[] = [];
      const entityId = session.currentEntity.entityId;
      const accountType = session.accountType;
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const templates = await feeScheduleService
        .getFeeScheduleTemplates(entityId, accountType, abortController);

      templates.forEach((template) => {
        template.categories.forEach(category => {
          existingCategories.push(category);
        });
      });

      setExistingCategories(existingCategories);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
      enqueueSnackbar('Error getting existing fee schedule categories', { variant: 'error' });
    }
    setLoading(false);
  }

  const isFormValid = () => selectedCategories?.length > 0;

  function handleSelectClicked(category: FeeScheduleCategory) {
    const selected = [...selectedCategories];
    const index = selected.indexOf(category);
    if (index > -1) {
      selected.splice(index, 1);
    }
    else {
      selected.push(category);
    }
    setSelectedCategories(selected);
  }

  function isSelected(category: FeeScheduleCategory) {
    return selectedCategories?.map(c => c.name).includes(category.name);
  }

  function handleAddSelectedClicked() {
    onCategoriesSelected?.(selectedCategories);
  }

  return (
    <div>
      <ListContainer>
        {loading && (
          <LoadingContainer>
            <CircularProgress size={32} thickness={4} style={{ color: '#000', margin: '1rem' }} />
            <Typography>Loading...</Typography>
          </LoadingContainer>
        )}
        {!loading && (
          <>
            {(!existingCategories || existingCategories.length < 1) && (
              <Typography>No existing fee schedule categories</Typography>
            )}
            {existingCategories.map(category => {
              return (
                <ListItemButton
                  key={category.name.value}
                  alignItems="flex-start"
                  disableGutters
                  disabled={disabledCategories?.map(c => c.name?.value).includes(category.name.value)}
                  onClick={() => handleSelectClicked(category)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={isSelected(category) || disabledCategories?.map(c => c.name.value).includes(category.name.value)}
                      value={category}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'pre-line' }}
                    primary={category.name.value}
                    secondary={category.feeString}
                  />
                </ListItemButton>
              );
            })}
          </>
        )}
      </ListContainer>
      <Button
        color="primary"
        variant="contained"
        disabled={!isFormValid()}
        onClick={handleAddSelectedClicked}>
        Add Selected
      </Button>
    </div>
  )
}
