import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";

export default class SelectedRepresentative {
  public userId: Guid;
  public entityId: Guid;
  public name: Name;
  public isClientRep: boolean;

  constructor(userId: Guid, entityId: Guid, isClient: boolean, name: Name) {
    this.userId = userId;
    this.entityId = entityId;
    this.name = name;
    this.isClientRep = isClient;
  }

  public generateTargetUrl(action: "create" | "request", location: Location): URL {
    let url = new URL(`/proposals/drafts/${action}`, location.origin);
    url.searchParams.append("tUserId", this.userId.value);
    url.searchParams.append("tEntityId", this.entityId.value);
    url.searchParams.append("tIsClientRep", this.isClientRep.toString());
    url.searchParams.append("firstName", this.name.firstName?.toString() ?? "");
    url.searchParams.append("lastName", this.name.lastName?.toString() ?? "");

    return url;
  }

  public static fromURLSearchParams(params: URLSearchParams): SelectedRepresentative | undefined {
    const userIdParam = params.get("tUserId");
    const entityIdParam = params.get("tEntityId");
    const isClientRepParam = params.get("tIsClientRep");
    const firstNameParam = params.get("firstName");
    const lastNameParam = params.get("lastName");

    if (!userIdParam || !entityIdParam || !isClientRepParam || !firstNameParam || !lastNameParam) {
      return undefined;
    }

    return new SelectedRepresentative(
      new Guid(userIdParam),
      new Guid(entityIdParam),
      isClientRepParam === "true",
      new Name(firstNameParam, lastNameParam)
    );
  }
}
