import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import UserProfile from "users/values/user-profile/user-profile";

export default class UserProfileAPIResponse {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isClientRep?: boolean;
  isVendorRep?: boolean;

  deserialize(): UserProfile {
    if(!this.id) throw new Error("User profile id is required");
    if(!this.firstName) throw new Error("User profile first name is required");
    if(!this.lastName) throw new Error("User profile last name is required");
    if(this.isClientRep === undefined) throw new Error("User profile isClientRep is required");
    if(this.isVendorRep === undefined) throw new Error("User profile isVendorRep is required");

    return new UserProfile(
      new Guid(this.id), 
      new Name(this.firstName, this.lastName), 
      this.email ? new EmailAddress(this.email) : null,
      this.isClientRep,
      this.isVendorRep
    );
  }
}
