import { useEffect, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { styled } from "@mui/material";
import Chat from "messaging/entities/forum/view/components/chat";
import Guid from "common/values/guid/guid";
import MessagingAPIService from "messaging/api/messaging-api-service";
import Forum from "messaging/entities/forum/forum";
import ForumSelection from "./forum-selection";
import Loader from "common/components/loader";
import { useSession } from "users/session/session-context";
import { RealtimeProvider, HubName } from "app/providers/realtime";

const ForumContainer = styled("div")(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  paddingTop: theme.spacing(1),
  width: '100%'
}));
const SelectionContainer = styled("div")(({ theme }) => ({
  display: 'flex',
  gridRowStart: 1
}));
const ChatContainer = styled("div")(({ theme }) => ({
  gridRowStart: 2,
  gridRowEnd: 'none'
}));
const LoaderContainer = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: theme.spacing(4),
  paddingTop: 0,
  width: '100%'
}));

type ChatDialogProps = {
  forumId?: Guid;
  forums?: Forum[];
  getAllForums?: boolean;
  loadedForum?: Forum;
  onLeaveConversation?: () => void;
};

export default function ChatDialog(props: Readonly<ChatDialogProps>) {
  const {
    forumId,
    forums,
    getAllForums,
    loadedForum,
    onLeaveConversation
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allForums, setAllForums] = useState<Forum[]>([]);
  const [individualForums, setIndividualForums] = useState<Forum[]>([]);
  const [teamForums, setTeamForums] = useState<Forum[]>([]);
  const [proposalForums, setProposalForums] = useState<Forum[]>([]);
  const [workForums, setWorkForums] = useState<Forum[]>([]);
  const [selectedForum, setSelectedForum] = useState<Forum | undefined>();
  const session = useSession();

  const loadChatDialog = async () => {
    try {
      setIsLoading(true);

      if (loadedForum) {
        setSelectedForum(loadedForum);
        return;
      }

      const messagingService = new MessagingAPIService(session);
      const forums = await messagingService.getForums();

      if (forumId) {
        const targetForum = forums.find((forum) => forum.id?.isEqualTo(forumId));
        if (!targetForum)
          throw new Error("Forum not found");
        setSelectedForum(targetForum);
        return;
      }

      setAllForums(forums);
      setIndividualForums(
        forums.filter(
          (forum) =>
            forum.topic?.entityClass &&
            ["UserNetworkConnection", "Marketplace.Individual"].includes(
              forum.topic.entityClass
            )
        )
      );
      setTeamForums(
        forums.filter(
          (forum) =>
            forum.topic?.entityClass &&
            ["Marketplace.Team"].includes(forum.topic.entityClass)
        )
      );
      setProposalForums(
        forums.filter(
          (forum) =>
            forum.topic?.entityClass &&
            ["Work.Proposal"].includes(forum.topic.entityClass)
        )
      );
      setWorkForums(
        forums.filter(
          (forum) =>
            forum.topic?.entityClass &&
            ["Work"].includes(forum.topic.entityClass)
        )
      );
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to get contacts", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadChatDialog();
  }, []);

  return (
    <>
      {isLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {!isLoading && (
        <RealtimeProvider hubName={HubName.Message}>
          <ForumContainer>
            {(!forums && !getAllForums && !selectedForum) && (
              <SelectionContainer>
                <ForumSelection
                  individualForums={individualForums}
                  teamForums={teamForums}
                  proposalForums={proposalForums}
                  workForums={workForums}
                  onForumSelected={setSelectedForum}
                />
              </SelectionContainer>
            )}
            {(!forums && getAllForums) && (
              <ChatContainer>
                <Chat
                  forums={allForums}
                  onLeaveConversation={onLeaveConversation}
                />
              </ChatContainer>
            )}
            {selectedForum && (
              <ChatContainer>
                <Chat
                  forums={[selectedForum]}
                  onLeaveConversation={onLeaveConversation}
                />
              </ChatContainer>
            )}
            {forums && (
              <ChatContainer>
                <Chat
                  forums={forums}
                  onLeaveConversation={onLeaveConversation}
                />
              </ChatContainer>
            )}
          </ForumContainer>
        </RealtimeProvider>
      )}
    </>
  );
}
