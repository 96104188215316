import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";
import { ProposalField } from "work/entities/proposal/proposal";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";

const FocusBorderContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  "@keyframes blinking-border": {
    "50%": {
      borderColor: "transparent",
    },
  },
  "& > :first-of-type": {
    flex: 1,
  },
}));
const TableFocusBorderContainer = styled("tbody")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  "@keyframes blinking-border": {
    "50%": {
      borderColor: "transparent",
    },
  },
  "& > :first-of-type": {
    flex: 1,
  },
}));
const FocusBorder = styled("div")(({ theme }) => ({
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),
  "&.blink": {
    animationName: "blinking-border",
    animationDuration: "1s",
    animationIterationCount: 3,
  },
}));
const NavButton = styled(Button)(({ theme }) => ({
  height: "min-content",
  "& > span": {
    alignItems: "center",
    display: "flex",
    height: theme.spacing(4),
  },
}));

type ChangeTraverserProps = {
  redline: ProposalRedline;
  currentField: ProposalField | undefined;
  onTraverseToNewField: (newField: ProposalField) => void;
};

export default function ChangeTraverser(props: Readonly<ChangeTraverserProps>) {
  const {
    redline,
    currentField,
    onTraverseToNewField,
  } = props;

  const previousField = useMemo(() => 
    currentField ? redline.getPrecedingFieldPendingReview(currentField) : undefined, 
    [redline, currentField]
  );
  const nextField = useMemo(() => 
    currentField ? redline.getFollowingFieldPendingReview(currentField) : undefined, 
    [redline, currentField]
  );

  return (
    <ButtonGroup
      size="small"
      color="primary"
      variant="outlined"
      orientation="horizontal"
      onMouseDown={(event) => event.preventDefault()}
    >
      <NavButton
        id="previous-change"
        disabled={!previousField}
        onClick={() => {
          if(!previousField) throw new Error("Field to navigate is undefined");
          onTraverseToNewField(previousField)
        }}
        >
        <Tooltip
          title="Previous Change"
          placement="bottom"
          enterDelay={0}
          leaveDelay={0}
        >
          <span>
            <KeyboardArrowUpIcon />
            prev
          </span>
        </Tooltip>
      </NavButton>
      <NavButton
        id="next-change"
        disabled={!nextField}
        onClick={() => {
          if(!nextField) throw new Error("Field to navigate is undefined");
          onTraverseToNewField(nextField)
        }}
      >
        <Tooltip
          title="Next Change"
          placement="bottom"
          enterDelay={0}
          leaveDelay={0}
        >
          <span>
            next
            <KeyboardArrowDownIcon />
          </span>
        </Tooltip>
      </NavButton>
    </ButtonGroup>
  );
}
