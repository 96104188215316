import Guid from "common/values/guid/guid";

export default class NotificationTopic {
  category?: string;
  id?: Guid | null;

  constructor(category?: string, id?: Guid | null) {
    this.category = category;
    this.id = id;
  }
}
