import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { AccountType } from 'common/values/account-type/account-type';
import { styled } from "@mui/material/styles";
import { Box } from '@mui/material';
import { useSession } from 'users/session/session-context';

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: theme.spacing(40),
  padding: '1rem'
}));
const AccountTypeButton = styled(Link)(() => ({
  cursor: 'pointer',
  fontSize: '1em'
}));

type AccountSwitcherProps = { }

export default function AccountSwitcher(props: Readonly<AccountSwitcherProps>) {
  const session = useSession();
  const otherAccountType = session.accountType === AccountType.Client ? AccountType.Vendor : AccountType.Client;
  const canSwitchContext = session.canSwitchContext;

  return (
    <MainContainer>
      <Typography><strong>Viewing As {session.accountType}</strong></Typography>
      {canSwitchContext &&
        <AccountTypeButton onClick={() => session.setAccountViewType(otherAccountType)}>
          Switch To {otherAccountType}
        </AccountTypeButton>
      }
    </MainContainer>
  )
}
