import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {
  Avatar, Badge, CircularProgress, IconButton, styled, Tooltip
} from '@mui/material';
import Session from 'users/session/session';
import Guid from 'common/values/guid/guid';
import CompanyAPIService from 'marketplace/entities/company/api/company-api-service';
import IndividualAPIService from 'marketplace/entities/individual/api/individual-api-service';
import MarketplaceTeamAPIService from 'marketplace/entities/marketplace-team/api/marketplace-team-api-service';
import React from 'react';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(10),
  width: theme.spacing(10)
}));

export interface AvatarUploadProps {
  className?: string;
  accept: string;
  id?: Guid;
  type: 'individual' | 'company' | 'team';
  avatar?: File | string;
  onUpload: (updatedAvatar: File) => void;
  session: Session;
}

export default function AvatarUpload(props: Readonly<AvatarUploadProps>) {
  const { className, session } = props;

  const hiddenAvatarFileInput = React.useRef<HTMLInputElement>(null);

  const [uploadingAvatar, setUploadingAvatar] = React.useState(false);

  async function handleAvatarChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUploadingAvatar(true);

    const files = event.target.files;

    if (!files || files.length === 0) return;

    const avatarFile = files[0];

    if (!props.id) {
      props.onUpload(avatarFile);
      setUploadingAvatar(false);
      return;
    }

    try {
      if (props.type === 'company'){
        const companyService = new CompanyAPIService(session);
        await companyService.createCompanyAvatar(props.id, avatarFile);
      }
      if (props.type === 'individual'){
        const individualService = new IndividualAPIService(session);
        await individualService.createIndividualAvatar(props.id, avatarFile);
      }
      if (props.type === 'team'){
        const teamService = new MarketplaceTeamAPIService(session);
        await teamService.createTeamAvatar(props.id, avatarFile);
      }
      props.onUpload(avatarFile);
    } catch (error: any) {
      console.error(error);
    }
    finally {
      setUploadingAvatar(false);
      hiddenAvatarFileInput.current!.value = '';
    }
  }

  function getAvatarImage(): string | undefined {
    if (props.avatar instanceof File) {
      return URL.createObjectURL(props.avatar);
    }
    return props.avatar;
  }

  return (
    <div className={className}>
      <input
        type="file"
        ref={hiddenAvatarFileInput}
        accept={props.accept}
        onChange={handleAvatarChange}
        style={{ display: 'none' }}
      />
      <Badge
        overlap="rectangular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={
          <Tooltip title="Upload avatar">
            <span>
              <IconButton
                color="primary"
                aria-label="upload avatar"
                component="span"
                onClick={() => hiddenAvatarFileInput.current?.click()}
                size="medium">
                {uploadingAvatar ? <CircularProgress size="24px" /> : <AddAPhotoIcon />}
              </IconButton>
            </span>
          </Tooltip>
        }>
        <StyledAvatar
          src={getAvatarImage()}>
        </StyledAvatar>
      </Badge>
    </div>
  );
}
