import {
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from "@mui/material/styles";
import Loader from "common/components/loader";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import DocumentTopic from "documents/values/document-topic";
import { enqueueSnackbar } from "notistack";
import React from "react";
import Session from "users/session/session";
import Document from "documents/entities/document/document";
import Guid from "common/values/guid/guid";
import { WorkDocumentType } from "work/values/work-document/work-document";

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 99999
}));
const MarchingAntsKeyframes = styled('div')(({ theme }) => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  "@keyframes marching-ants": {
    to: {
      strokeDashoffset: -30
    }
  }
}));
const DashedBorder = styled('svg')<{ isdragging: boolean }>(({ theme, isdragging }) => ({
  backgroundColor: isdragging ? 'rgba(0, 128, 255, 0.2)' : 'initial',
  borderRadius: '20px',
  '& rect.active': {
    animation: 'marching-ants 1s infinite linear',
    stroke: theme.palette.primary.main
  }
}));
const UploadOverlay = styled('div')(({ theme }) => ({
  cursor: 'copy',
  height: '100%',
  position: 'relative',
  width: '100%'
}));
const HelperTextContainer = styled('div')<{ isdragging: boolean }>(({ theme, isdragging }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  rowGap: theme.spacing(2),
  "& > svg": {
    color: isdragging ? theme.palette.primary.main : theme.palette.text.secondary,
    height: "96px",
    width: "96px"
  },
  "& > .MuiTypography-root": {
    color: isdragging ? theme.palette.primary.main : theme.palette.text.secondary
  }
}));
const LoaderPlaceholder = styled(Loader)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "2em",
  height: "100%",
  position: "absolute",
  textAlign: "center",
  width: "100%"
}));

type DocumentUploadOverlayProps = {
  className?: string;
  templateId?: Guid;
  documentType: WorkDocumentType;
  session: Session;
  onDocumentUploaded: (document: Document) => void;
  onClose: () => void;
};

export default function DocumentUploadOverlay(props: Readonly<DocumentUploadOverlayProps>) {
  const {
    className,
    templateId,
    documentType,
    session,
    onDocumentUploaded,
    onClose
  } = props;

  const documentFileUploaderRef = React.useRef<HTMLInputElement | null>(null);

  const [isUploading, setIsUploading] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);

  async function handleDocumentFileUpload(event?: React.ChangeEvent<HTMLInputElement>, files?: FileList) {
    if (!event?.currentTarget.files && !files) return;

    const file = event?.currentTarget.files?.[0] ?? files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);

      let context: string | undefined;
      context = documentType === WorkDocumentType.Conflicts ? 'Conflicts' : 'Policy';
      const service = new DocumentAPIService(session);
      const newDocument = await service.createDocument(
        file,
        file.name,
        [new DocumentTopic(undefined, undefined, context)],
        templateId ? [templateId] : undefined
      );
      if(!(newDocument instanceof Document)) {
        throw new Error("Failed to upload document");
      }
      enqueueSnackbar("Uploaded file", { variant: "success" });
      onDocumentUploaded(newDocument);
    } catch (error: any) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(error.response.data, { variant: "error" });
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar("Unsupported file type", { variant: "error" });
      } else {
        enqueueSnackbar("Unknown error occurred during upload", {
          variant: "error",
        });
      }
    } finally {
      if (event) event.target.value = "";
      setIsDragging(false);
      setIsUploading(false);
    }
  }

  return (
    <UploadOverlay
      className={className}
      onClick={() => documentFileUploaderRef.current?.click()}
      onDragOver={(event) => {
        event.preventDefault();
        setIsDragging(true);
        event.currentTarget.getElementsByTagName('rect')[0].classList.add('active');
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setIsDragging(false);
        event.currentTarget.getElementsByTagName('rect')[0].classList.remove('active');
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleDocumentFileUpload(undefined, event.dataTransfer.files);
      }}
    >
      <MarchingAntsKeyframes>
        <DashedBorder isdragging={isDragging} width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
          <rect
            id='chasing_ants'
            width='100%'
            height='100%'
            fill='none'
            stroke='#666'
            strokeWidth='10'
            strokeDasharray='10, 20'
            strokeDashoffset='0'
            strokeLinecap='square'
            rx='20'
            ry='20'
          />
        </DashedBorder>
      </MarchingAntsKeyframes>
      <Tooltip title="Close">
        <CloseButton
          size="large"
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
        >
          <CloseIcon fontSize="large" />
        </CloseButton>
      </Tooltip>
      {isUploading ? (
        <LoaderPlaceholder
          text="Uploading..."
          size={64}
          thickness={4}
        />
      ) : (
        <HelperTextContainer isdragging={isDragging}>
          {isDragging ? <UploadFileIcon /> : <FileOpenIcon /> }
          <Typography variant="h4">
            {isDragging 
              ? "Drop file here to upload" 
              : "Drop updated file here to upload or click to open file browser"
            }
          </Typography>
        </HelperTextContainer>
      )}
      <input
        type="file"
        hidden={true}
        ref={documentFileUploaderRef}
        onChange={handleDocumentFileUpload}
      />
    </UploadOverlay >
  );
}
