export default class SenderName {
    private _value: string;
  
    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: SenderName): boolean {
        return this.value === other?.value;
    }
  
    public get value(): string {
        return this._value;
    }
  
    public static get Prototype(): SenderName {
        return new SenderName("");
    }
  
    public fromObject(obj: any): SenderName {
        return new SenderName(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): SenderName {
        return new SenderName(this.value);
    }
}