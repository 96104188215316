import {
  Paper, Table, TableBody, TableCell, TableHead,
  TableRow, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import UserEntityMemberInvitation from "users/values/user-entity-member-invitation/user-entity-member-invitation";


const InvitationsTable = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2)
  },
  padding: theme.spacing(6),
  position: "relative",
}));

const InvitationsTableTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5)
}));

type UserEntityMemberInvitationsTableProps = {
  invitations: UserEntityMemberInvitation[]
}

export default function UserEntityMemberInvitationsTable(props: Readonly<UserEntityMemberInvitationsTableProps>) {
  const { invitations } = props;

  return (
    <InvitationsTable>
      <InvitationsTableTitle variant="h6">Email Invitations</InvitationsTableTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">Email Address</TableCell>
            <TableCell align="left">Entity</TableCell>
            <TableCell align="left">Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(invitations.length < 1) && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography>No Pending Invitations</Typography>
              </TableCell>
            </TableRow>
          )}
          {invitations.map((invitation: UserEntityMemberInvitation) => (
            <TableRow key={invitation.id?.value}>
              <TableCell align="left">
                <Typography className="invitation-id">
                  {invitation.id?.value ?? ''}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className="invitation-entity">
                  {invitation.user.email?.value ?? ''}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{invitation.entityName}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{invitation.isAdmin ? "Administrator" : "Member"}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </InvitationsTable>
  );
}
