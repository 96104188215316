import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";

export enum ForumInvitationStatus {
    Open,
    Accepted,
    Rejected,
    Canceled
}

export default class ForumInvitation {
    id: Guid;
    inviteeId: Guid;
    forum: Forum;
    invitationMessage?: string;
    createdDate: Date;
    status: ForumInvitationStatus;

    constructor(id: Guid, inviteeId: Guid, forum: Forum, invitationMessage: string | undefined, createdDate: Date, status: ForumInvitationStatus) {
        this.id = id;
        this.inviteeId = inviteeId;
        this.forum = forum;
        this.invitationMessage = invitationMessage;
        this.createdDate = createdDate;
        this.status = status;
    }
}

export class ForumInvitationDoesNotExistError extends Error {
    constructor(id?: Guid | string) {
        if (id instanceof Guid) {
            super(`Forum invitation ${id} does not exist`);
        } else {
            super(`Forum invitation does not exist: ${id}`);
        }
    }
}