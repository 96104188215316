import Guid from "common/values/guid/guid";
import EntityName from "legal-entities/values/entity-name/entity-name";

/** Entity member user information */
export default class UserEntityInfo {
    public readonly entityId: Guid;
    public readonly entityName: EntityName;
    public readonly isAdmin: boolean;
    public readonly isManager: boolean;
    public readonly isOfficer: boolean;
    public readonly entityVendorFlag?: boolean;
    public readonly entityClientFlag?: boolean;

    /** Returns the entity id. */
    public get id(): Guid {
        return this.entityId;
    }

    constructor(entityId: Guid, entityName: EntityName, isAdmin: boolean, isManager: boolean, isOfficer: boolean, entityVendorFlag?: boolean, entityClientFlag?: boolean) {
        this.entityId = entityId;
        this.entityName = entityName;
        this.isAdmin = isAdmin;
        this.isManager = isManager;
        this.isOfficer = isOfficer;
        this.entityVendorFlag = entityVendorFlag;
        this.entityClientFlag = entityClientFlag;
    }

    public static fromObject(obj: any): UserEntityInfo {
        if (!obj.entityId || !obj.entityName) {
            throw new InvalidUserEntityInfoError(obj);
        }
        const id = Guid.fromObject(obj.entityId);
        if(!id) { throw new InvalidUserEntityInfoError(obj); }
        return new UserEntityInfo(
            id,
            EntityName.fromObject(obj.entityName),
            obj.isAdmin,
            obj.isManager,
            obj.isOfficer,
            obj.entityVendorFlag,
            obj.entityClientFlag
        );
    }
    public toJSON(): any {
        return {
            entityId: this.entityId.toJSON(),
            entityName: this.entityName.toJSON(),
            isAdmin: this.isAdmin,
            isManager: this.isManager,
            isOfficer: this.isOfficer,
            entityVendorFlag: this.entityVendorFlag,
            entityClientFlag: this.entityClientFlag
        };
    }
}

export class InvalidUserEntityInfoError extends Error {
    issues: any;

    constructor(issues: any) {
        super(`Invalid user entity info.`);
        this.issues = issues;
    }
}
