import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import UserInfo from "work/values/user-info/user-info";

 export default class WorkCreatorInfoAPIResponse {
    userId?: string;
    individualId?: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    entityId?: string;
    companyId?: string;
    companyName?: string;

    public deserialize(): UserInfo {
        if(!this.userId || !this.individualId || !this.firstName || !this.lastName  || !this.entityId || !this.companyId || !this.companyName){
            throw new Error("WorkCreatorInfoAPIResponse is missing values.");
        }

        return new UserInfo(
            new Guid(this.userId),
            new Guid(this.individualId),
            new Name(this.firstName, this.lastName),
            new Guid(this.entityId),
            new Guid(this.companyId),
            this.companyName,
            this.avatar
        );
    }
}
