import { Rating } from '@mui/material';
import React, { useEffect } from 'react';

type FilterRatingProps = {
  filterName: string;
  value: number;
  precision?: number;
  onChange: (filterName: string, value: number | null) => void;
};

export default function FilterRating(props: Readonly<FilterRatingProps>) {
  const { filterName, precision, onChange } = props;

  const [value, setValue] = React.useState<number>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Rating
      name={filterName}
      precision={precision}
      value={value}
      onChange={(_event, newValue) => {
        onChange(filterName, newValue);
      }}
    />
  );
}
