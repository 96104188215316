import { forwardRef, useState } from "react";
import { TextField, TextFieldVariants } from "@mui/material";
import Password, {
  InvalidPasswordError, PasswordIssues,
} from "common/values/password/password";
import { styled } from "@mui/material/styles";

const InputField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    width: '100%'
  },
  marginBottom: theme.spacing(1.5),
  minWidth: theme.spacing(40),
  ":first-of-type": {
    marginRight: theme.spacing(1.5)
  }
}));

type PasswordInputProps = {
  idPrefix?: string;
  className?: string;
  variant?: TextFieldVariants;
  password?: Password;
  confirm?: boolean;
  onChange?: (password?: Password, error?: PasswordIssues) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

const PasswordInput = forwardRef((props: PasswordInputProps, ref) => {
  const { idPrefix, className, variant, password, confirm, onChange, onKeyDown } = props;

  const [fieldText, setFieldText] = useState<string>(password?.value ?? '');
  const [confirmFieldText, setConfirmFieldText] = useState<string>('');
  const [fieldError, setFieldError] = useState<PasswordIssues | undefined>();

  function buildPassword(password?: string, confirmPassword?: string): Password | undefined {
    let passwordIssues: PasswordIssues = { password: [], confirmPassword: '' };

    try {
      if (!password) {
        passwordIssues.password = ["Password is required"];
        throw new InvalidPasswordError(passwordIssues);
      }
      if (confirm === true && !confirmPassword) {
        passwordIssues.confirmPassword = "Confirm password is required";
        throw new InvalidPasswordError(passwordIssues);
      }
      return new Password(password, confirmPassword);
    } catch (error: any) {
      if (error instanceof InvalidPasswordError) {
        throw error;
      }
    }
  }

  function handlePasswordChanged(passwordText: string, confirmPasswordText?: string) {
    let newPassword: Password | undefined;
    let newIssues: PasswordIssues | undefined;

    try {
      newPassword = buildPassword(passwordText, confirmPasswordText);
      setFieldError(undefined);
    } catch (error: any) {
      if (error instanceof InvalidPasswordError) {
        newIssues = error.issues;
        setFieldError(error.issues);
      }
    } finally {
      onChange?.(newPassword, newIssues);
    }
  }

  return (
    <div>
      <InputField
        id={idPrefix ? idPrefix + "-password-input" : "password-input"}
        className={className}
        label="Password"
        onChange={(event) => {
          setFieldText(event.target.value);
          handlePasswordChanged(event.target.value, confirmFieldText);
        }}
        required
        type="password"
        autoComplete="new-password"
        value={fieldText}
        error={fieldError?.password && fieldError.password.length > 0}
        helperText={fieldError?.password?.map((issue) => <div key={issue}>{issue}</div>)}
        variant={variant}
      />
      {props.confirm && (
        <InputField
          id={idPrefix ? idPrefix + "-confirm-password-input" : "confirm-password-input"}
          className={className}
          label="Confirm Password"
          onChange={(event) => {
            setConfirmFieldText(event.target.value);
            handlePasswordChanged(fieldText, event.target.value);
          }}
          required
          type="password"
          autoComplete="new-password"
          value={confirmFieldText}
          error={!!fieldError?.confirmPassword}
          helperText={fieldError?.confirmPassword}
          variant={variant}
          onKeyDown={onKeyDown}
        />
      )}
    </div>
  );
});

export default PasswordInput;
