import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

const ProgressIcon = styled(CircularProgress)(() => ({
  color: '#999'
}));

interface Props extends ButtonProps {
  children: string;
  loading: boolean;
}

export default function LoadingButton(props: Readonly<Props>) {
  const [disabled, setDisabled] = React.useState<boolean>(props.loading || Boolean(props.disabled));
  const { loading, ...buttonProps } = props;

  useEffect(() => {
    setDisabled(!!props.loading || !!props.disabled);
  }, [props, disabled]);

  function getStartIcon() {
    if (props.loading && !props.endIcon) 
      return <ProgressIcon size={20} />;

    return  props.startIcon;
  }

  function getEndIcon() {
    if (props.loading && props.endIcon && !props.startIcon)
       return <ProgressIcon size={20} />;

    return props.endIcon;
  }

  return (
    <Button
      {...buttonProps}
      startIcon={getStartIcon()}
      endIcon={getEndIcon()}
      disabled={disabled}>
      {props.children}
    </Button>
  );
}
