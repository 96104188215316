import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { styled } from "@mui/material/styles";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { CanceledError } from 'axios';
import Guid from 'common/values/guid/guid';
import BookmarkAPIService from 'marketplace/entities/bookmark/api/bookmark-api-service';
import BookmarkedCompany from 'marketplace/entities/bookmarked-company/bookmarked-company';
import ViewCompanyProfile from 'marketplace/values/company-profile/view/view-company-profile';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'users/session/session-context';
import { PageTab } from './tabs';

const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap",
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  widtgh: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

type CompanyBookmarksProps = {
  activeTab: PageTab;
};

export default function CompanyBookmarks(props: Readonly<CompanyBookmarksProps>) {
  const {
    activeTab
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [data, setData] = React.useState<BookmarkedCompany[]>([]);
  const [rowCount, setRowCount] = React.useState(0);

  const navigate = useNavigate();
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();
  const session = useSession();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // Close any open dialogs when the URL changes
   useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    let abortController = new AbortController();

    fetchData(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [activeTab]);

  const fetchData = async (abortController?: AbortController) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      let bookmarkService = new BookmarkAPIService(session);
      const bookmarks = await bookmarkService.getAllCompanyBookmarksForUser(abortController);
      setData(bookmarks);
      setRowCount(bookmarks.length);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setErrorLoading(true);
      console.error(error);
      return;
    }
    
    setErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const columnHelper = createMRTColumnHelper<BookmarkedCompany>();

  const renderNameColumn = (bookmark: BookmarkedCompany) => {
    return (
      <RowTitle>
        {bookmark.vendor.name}
      </RowTitle>
    );
  };

  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
      id: "name",
    }),
    columnHelper.accessor((bookmark) => bookmark.createdDate.format("MM/DD/YY"), {
      header: "Bookmarked On",
      id: "created",
    }),
  ];

  async function handleDeleteBookmark(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    bookmark: BookmarkedCompany
  ) {
    event.stopPropagation();

    const response = await confirm({
      title: 'Delete Bookmark?',
      message: 'Do you want to delete?',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    });

    if (response === ConfirmResponse.Cancel) return;

    const bookmarkService = new BookmarkAPIService(session);
    try {
      await bookmarkService.deleteCompanyBookmark(bookmark.bookmarkId);
      enqueueSnackbar("Deleted bookmark", { variant: 'success' });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Couldn't delete bookmark. Please try again", { variant: 'error' });
      fetchData();
    }
  }

  function handleViewProfile(profileId?: Guid): void {
    if (!profileId) return;
    openDialog({
      component: (
        <ViewCompanyProfile
          companyId={profileId}
          confirmDialog={confirm}
        />
      ),
      titleStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: 'flex',
        overflowX: 'hidden'
      },
      MuiProps: {
        maxWidth: 'lg',
        fullWidth: false
      }
    });
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    enableColumnPinning: true,
    enableRowActions: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleViewProfile(row.original.id),
      sx: { cursor: "pointer" },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
          </ToolbarButtonsContainer>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Bookmarks
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/marketplace/bookmarks/${newValue}`)}>
              <Tab value="individual" label="Individual" />
              <Tab value="team" label="Team" />
              <Tab value="company" label="Company" />
            </Tabs>
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    renderRowActions: ({ row, table }) => (
      <Tooltip title="Delete Bookmark">
        <IconButton
          onClick={(event) => handleDeleteBookmark(event, row.original)}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Tooltip>
    ),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: errorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: errorLoading,
      showProgressBars: isRefetching,
      // sorting,
    },
  });

  return (
    <MaterialReactTable table={table} />
  );
}
