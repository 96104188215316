import Guid from "common/values/guid/guid";

import RegistrationInfo from "legal-entities/values/registration-info";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import { InvitationStatus } from "legal-entities/values/invitation-status";


export default class AdministratorInvitation {
    id?: Guid;
    entity: LegalEntity;
    registrationInfo: RegistrationInfo;
    status: InvitationStatus;

    constructor(entity: LegalEntity, registrationInfo: RegistrationInfo, status?: InvitationStatus) {     
        this.entity = entity;
        this.registrationInfo = registrationInfo;
        this.status = status ?? InvitationStatus.Open;
    }
}

export class AdministratorInvitationNotFoundError extends Error {
    public constructor(id: Guid) {
        super(`Unable to find invitation by id: ${id}`);
    }
}

export class UnauthorizedAdministratorInvitationAccessError extends Error {
    constructor(entityId: Guid) {
        super(`You are not permitted to view all administrator invitations for this entity: ${entityId}.`);
    }
}

export class InvalidAdministratorInvitationError extends Error {}