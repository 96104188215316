import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import Team from "work/values/team/team";

export default class DetailedTeam {
  leader: Individual;
  memberUserIds: Guid[];
  members: Individual[] = [];
  marketplaceId?: Guid;
  description?: string;

  constructor(leader: Individual, members: Individual[], marketplaceId?: Guid, description?: string) {
    this.leader = leader;
    this.memberUserIds = members.filter(m => m.userId).map(m => m.userId) as Guid[];
    this.members = members;
    this.marketplaceId = marketplaceId;
    this.description = description;
  }

  toTeam(): Team {
    if (!this.leader) throw new Error("Leader is required");
    if (!this.leader.userId) throw new Error("Leader userId is required");
    if (!this.leader.entityId) throw new Error("Leader entityId is required");
    
    return new Team(
      new EntityVendorRepresentative(this.leader.userId, this.leader.entityId),
      this.memberUserIds,
      this.marketplaceId
    );
  }
}
