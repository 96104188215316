import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import Topic from "messaging/values/topic";
import moment from "moment";

export default class NetworkConnectionForumAPIResponse {
    id?: string;
    name?: string;
    createdDate?: string;
    entityClass?: string;
    entityId?: string;
    subscribers?: string[];
    context?: string;

    deserialize(): Forum {
        if (!this.id) throw new Error("Forum ID is required");
        if (!this.name) throw new Error("Forum name is required");

        const topic = new Topic(this.entityClass, this.entityId ? new Guid(this.entityId) : undefined, this.context);

        return new Forum(
            this.name,
            topic,
            this.subscribers ? this.subscribers.map((subscriber) => new Guid(subscriber)) : undefined,
            new Guid(this.id),
            undefined,
            this.createdDate ? moment(this.createdDate) : undefined
        );
    }
}
