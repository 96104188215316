import moment, { Moment } from 'moment';
import Guid from "common/values/guid/guid";

import Forum from 'messaging/entities/forum/forum';
import MessageFileInfo from 'messaging/values/attachment/message-file-info';
import User from 'users/entities/user/user';
import ReadReceipt from 'messaging/values/read-receipts/read-receipt';

export default class Message {
  id?: Guid;
  senderId: Guid | null;
  forum: Forum;
  content: string;
  subject?: string;
  publishedOn?: Moment;
  attachments: MessageFileInfo[];
  readReceipts: ReadReceipt[] = [];
  issues: string[] = [];
  deletedAt?: Moment;

  markedForCreation?: boolean;
  markedForEdit?: boolean;
  markedForReadReceipt?: boolean;
  markedForUnread?: boolean;
  markedForDeletion?: boolean;

  constructor(
    forum: Forum,
    content: string,
    senderId?: Guid | null,
    subject?: string,
    publishedOn?: Moment,
    attachments?: MessageFileInfo[],
    id?: Guid,
    issues?: string[],
    readReceipts?: ReadReceipt[],
    deletedAt?: Moment
  ) {
    this.id = id;
    this.senderId = senderId ?? null;
    this.forum = forum;
    this.content = content;
    this.subject = subject;
    this.publishedOn = publishedOn;
    this.attachments = attachments?.map((attachment) => new MessageFileInfo(
      attachment.id,
      attachment.name,
      attachment.fileSize,
      attachment.mimeType,
      attachment.created,
      attachment.rawData
    )) ?? [];
    this.issues = issues ?? [];
    this.readReceipts = readReceipts ?? [];
    this.deletedAt = deletedAt;
  }

  public setDeleted(): void {
    this.deletedAt = moment();
    this.markedForDeletion = true;
  }

  public setUndeleted(): void {
    this.deletedAt = undefined;
    this.markedForDeletion = false;
  }

  public setRead(): void {
    this.markedForReadReceipt = true;
    this.markedForUnread = false;
  }
  public setUnread(): void {
    this.markedForUnread = true;
    this.markedForReadReceipt = false;
  }

  /** Soft delete flag */
  public get isDeleted() : boolean{
    return !!this.deletedAt;
  }

  public isReadByUser(userId?: Guid): boolean {
    if (!userId) return false;
    return this.readReceipts.some((receipt) => receipt.userId.isEqualTo(userId));
  }

  static draft(forum: Forum, sender: User, content: string, subject?: string, attachments?: File[], id?: Guid): Message {
    if (!content && (!attachments || attachments.length === 0)) throw new EmptyMessageError();
    if (!sender.id) throw new Error("Must be logged in to draft a message");

    let newMessage = new Message(forum, content, sender.id, subject);

    if (attachments && attachments.length > 0) {
      newMessage.attachments = attachments.map((attachment: File) => {
        return MessageFileInfo.fromFile(attachment);
      });
    }
    newMessage.id = id;

    return newMessage;
  }

  userIsSender(user: User): boolean {
      return !this.senderId ? false 
        : this.senderId?.isEqualTo(user.id);
  }
}

export class EmptyMessageError extends Error {
  constructor() {
    super(`Message cannot be empty`);
  }
}
