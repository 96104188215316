import Guid from "common/values/guid/guid";
import ReadReceipt from "messaging/values/read-receipts/read-receipt";
import moment from "moment";

export default class ReadReceiptAPIResponse {
    userId?: string;
    seenAt?: string;

    deserialize(): ReadReceipt | undefined {
        if (!this.userId) {
            console.error("Read receipt user id not returned")
            return undefined;
        }
        if (!this.seenAt) {
            console.error("Read receipt seen at not returned")
            return undefined;
        }

        return new ReadReceipt(
            new Guid(this.userId),
            moment(this.seenAt)
        );
    }
}
