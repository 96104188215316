import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ExitIcon from "@mui/icons-material/TransitEnterexit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Avatar, Box, Button, Chip, Container, Icon, IconButton, ListItemIcon, ListItemText, MenuItem, Switch, Tab, Tabs, Theme, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { PageTab } from "app/routes/marketplace/profiles/tabs";
import { AvatarMap } from "app/routes/marketplace/profiles/types";
import { CanceledError } from "axios";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import CompanyAPIService from "marketplace/entities/company/api/company-api-service";
import Company from "marketplace/entities/company/company";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import CompanyProfile from "marketplace/values/company-profile/company-profile";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import TeamProfile from "marketplace/values/team-profile/team-profile";
import { createMRTColumnHelper, MaterialReactTable, MRT_ColumnFiltersState, MRT_PaginationState, MRT_ShowHideColumnsButton, MRT_SortingState, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from 'material-react-table';
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Params, useLoaderData, useLocation, useNavigate } from "react-router";
import { useSession } from "users/session/session-context";
import { editComponentMap, viewComponentMap } from "./types";
import { useSearchParams } from "react-router-dom";

const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap",
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  widgh: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
const NewProfileButtonContainer = styled("span")<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    cursor: disabled ? "not-allowed" : "default",
  })
);
const newProfileIcon = (activeTab: PageTab) => {
  if (activeTab === PageTab.Individual) return <PersonAddIcon fontSize="large" />;
  if (activeTab === PageTab.Company) return <DomainAddIcon fontSize="large" />;
  if (activeTab === PageTab.Teams) return <GroupAddIcon fontSize="large" />;
};
const NameCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const CategoryChip = styled(Chip)(({ theme }) => ({
  color: '#777',
  marginRight: theme.spacing(0.4),
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  marginLeft: theme.spacing(1.5),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
const NewButton = styled(IconButton)(({ theme }) => ({
  padding: 0
}));

type ProfileTableData = Individual & Company & MarketplaceTeam;

type ManageProfilesProps = {};

export default function ManageProfiles(props: Readonly<ManageProfilesProps>) {
  const isDirtyRef = React.useRef<boolean>(false);

  const [individualProfiles, setIndividualProfiles] = React.useState<
    ProfileTableData[]
  >([]);
  const [companyProfiles, setCompanyProfiles] = React.useState<ProfileTableData[]>([]);
  const [teamProfiles, setTeamProfiles] = React.useState<ProfileTableData[]>([]);
  const [activeTab, setActiveTab] = React.useState(PageTab.Individual);
  const [avatars, setAvatars] = React.useState<AvatarMap>({});

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [rowCount, setRowCount] = React.useState(0);
  const [wasErrorLoading, setWasErrorLoading] = React.useState(false);
  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const confirm = useConfirmDialog();
  const { openDialog, closeDialog, closeAllDialogs } = useDialog();
  const session = useSession();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    if (!session.user?.companyEntityId) return;
    
    let abortController = new AbortController();

    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/marketplace/profiles/${PageTab.Individual}`);
    }

    if (routeParams.action === "create") {
      createProfile();
    }

    if (routeParams.id) {
      if (routeParams.action === "edit") {
        openEditProfileDialog(routeParams.tab as PageTab, new Guid(routeParams.id));
      }
      if (routeParams.action === "view") {
        openViewProfileDialog(
          routeParams.tab as PageTab,
          new Guid(routeParams.id)
        );
      }
    }

    fetchData(routeParams.tab as PageTab, abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [routeParams]);

  const fetchData = async (tab?: PageTab, abortController?: AbortController) => {
    if (!individualProfiles.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      // Only call endpoint associated with the current active tab
      let vendors: ProfileTableData[] | null = null;
      if ((tab ?? activeTab) === PageTab.Company) {
        const marketplaceCompanyService = new CompanyAPIService(session);
        vendors = (await marketplaceCompanyService.getCompanyVendors(
          abortController
        )) as ProfileTableData[];
        setCompanyProfiles(vendors);
      } else if ((tab ?? activeTab) === PageTab.Teams) {
        const marketplaceTeamService = new MarketplaceTeamAPIService(session);
        vendors = (await marketplaceTeamService.getTeamVendors(
          abortController
        )) as ProfileTableData[];
        setTeamProfiles(vendors);
      } else if ((tab ?? activeTab) === PageTab.Individual) {
        const marketplaceIndividualService = new IndividualAPIService(session);
        vendors = (await marketplaceIndividualService.getIndividualVendors(
          abortController
        )) as ProfileTableData[];
        setIndividualProfiles(vendors);
      }

      if (!vendors) {
        setIndividualProfiles([]);
        setRowCount(0);
        setWasErrorLoading(false);
        setIsLoading(false);
        setIsRefetching(false);
        return;
      }

      await fetchAvatars(vendors);
      setIndividualProfiles(vendors);
      setRowCount(vendors.length);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setWasErrorLoading(true);
      console.error(error);
      return;
    }
    setWasErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  async function fetchAvatars(rows: ProfileTableData[]) {
    const abortController = new AbortController();
    try {
      for (const row of rows) {
        const avatarId = row.profile?.avatarId?.toString();

        if (!avatarId) continue;

        const cachedAvatar = avatars[avatarId];
        if (cachedAvatar || avatarId === null) return;
        let avatarImageData: string;

        try {
          if (activeTab === PageTab.Individual) {
            const service = new IndividualAPIService(session);
            const data = await service.getIndividualAvatar(row.id, abortController);
            if (data) avatarImageData = data;

          } else if (activeTab === PageTab.Company) {
            const service = new CompanyAPIService(session);
            avatarImageData = await service.getCompanyAvatar(
              row.id,
              abortController
            );
          } else if (activeTab === PageTab.Teams) {
            avatarImageData = await marketplaceTeamService.getTeamAvatar(
              row.id,
              abortController
            );
          }
          setAvatars((prevAvatars) => ({
            ...prevAvatars,
            [avatarId]: avatarImageData,
          }));
        } catch (error) {
          if (error instanceof CanceledError) return;
          console.error(error);
        }
      }
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
      enqueueSnackbar("Couldn't get avatars for profiles. Please try again", {
        variant: "error",
      });
    }
  }

  const readonlyTab = activeTab === PageTab.Company && !session.isLoggedInAsAdmin;
  const rowProfileEditingDisabled = (profile: ProfileTableData) => readonlyTab ||
    (activeTab === PageTab.Teams && !profile?.leader?.userId?.isEqualTo(session.user?.id));
  const disableAddProfileButton = () => {
    if (readonlyTab || !session.isLoggedInAsAdmin) return true;
    if (activeTab === PageTab.Teams && individualProfiles?.length < 1)
      return true;
    if (activeTab === PageTab.Individual && individualProfiles?.length > 0)
      return true;
    if (activeTab === PageTab.Company && companyProfiles?.length > 0)
      return true;
    if (
      activeTab === PageTab.Teams &&
      session.accountType === AccountType.Client
    )
      return true;
    return false;
  };
  const addProfileDisabledMessage = () => {
    if (readonlyTab) return "You don't have permission";
    if (activeTab === PageTab.Teams && individualProfiles?.length < 1)
      return "You must create an individual profile before creating a team";
    if (activeTab === PageTab.Individual && individualProfiles?.length > 0)
      return "You can only have one individual profile";
    if (activeTab === PageTab.Company && companyProfiles?.length > 0)
      return "You can only have one company profile";
    return "";
  };

  const marketplaceTeamService = new MarketplaceTeamAPIService(session);

  const deleteMethodMap = {
    [PageTab.Company]: null,
    [PageTab.Teams]: marketplaceTeamService.deleteTeam,
    [PageTab.Individual]: null,
  };

  function createProfile() {
    const ProfileComponent = editComponentMap[activeTab];
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        titleStyle: {
          position: "absolute",
          right: 0,
          top: 0,
        },
        MuiProps: {
          maxWidth: activeTab === PageTab.Individual ? "lg" : "md",
          fullWidth: true,
          style: {
            overflowX: "hidden",
            paddingBottom: 0,
          },
        },
        component: (
          <ProfileComponent
            navigate={navigate}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            isVisible={false}
            onFormDirty={(isDirty?: boolean) => {
              isDirtyRef.current = isDirty ?? true;
            }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              fetchData();
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  function editProfile(profile: ProfileTableData) {
    const editingDisabled = rowProfileEditingDisabled(profile);

    if (editingDisabled) {
      enqueueSnackbar("You don't have permission to edit this profile. Opening as read-only.", { variant: 'warning' });
    }

    navigate(`/marketplace/profiles/${activeTab}/${!editingDisabled ? 'edit' : 'view'}/${profile.id}`);
  }

  function openEditProfileDialog(profileType: PageTab, profileId: Guid, profileData?: IndividualProfile & CompanyProfile & TeamProfile) {
    const ProfileComponent = editComponentMap[profileType];
    const parentPath = new URL(".", window.location.origin + window.location.pathname);

    openDialog(
      {
        titleStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
        MuiProps: {
          maxWidth: profileType === PageTab.Individual ? 'lg' : 'md',
          fullWidth: true,
          style: {
            overflowX: 'hidden',
            paddingBottom: 0
          }
        },
        component: (
          <ProfileComponent
            navigate={navigate}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            individualId={profileType === PageTab.Individual ? profileId : undefined}
            companyId={profileType === PageTab.Company ? profileId : undefined}
            teamId={profileType === PageTab.Teams ? profileId : undefined}
            profile={profileData}
            onFormDirty={(isDirty? : boolean) => { 
              isDirtyRef.current = isDirty ?? true; 
            }}
            onSave={() => {
              closeDialog();
              navigate(parentPath.pathname);
              fetchData();
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => isDirtyRef.current
    );
  }

  function openViewProfileDialog(
    profileType: PageTab,
    profileId: Guid,
    profileData?: IndividualProfile & CompanyProfile & TeamProfile
  ) {
    const ProfileComponent = viewComponentMap[profileType];
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        titleStyle: {
          position: "absolute",
          right: 0,
          top: 0,
        },
        MuiProps: {
          maxWidth: profileType === PageTab.Individual ? "lg" : "md",
          fullWidth: true,
          style: {
            overflowX: "hidden",
            paddingBottom: 0,
          },
        },
        component: (
          <ProfileComponent
            individualId={
              profileType === PageTab.Individual ? profileId : undefined
            }
            companyId={profileType === PageTab.Company ? profileId : undefined}
            teamId={profileType === PageTab.Teams ? profileId : undefined}
            profile={profileData}
            onNetworkStatusUpdated={fetchData}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  async function publishProfile(profile: ProfileTableData) {
    const name = profile.profile.name ?? `${profile.profile.firstName} ${profile.profile.lastName}`;

    const response = await confirm({
      title: `${profile.isVisible ? 'Hide' : 'Show'} profile ${profile.isVisible ? 'from' : 'on'} the Marketplace?`,
      message: profile.isVisible ?
        `Hide ${name}'s profile? This profile will no longer be searchable on the Marketplace.` :
        `Show ${name}'s profile? This profile will appear in searches on the Marketplace.`
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      if (profile.profile instanceof IndividualProfile) {
        const updatedIndividual = structuredClone(profile);
        updatedIndividual.isVisible = !profile.isVisible;
        const service = new IndividualAPIService(session);
        await service.updateIndividual(
          profile,
          updatedIndividual
        );
      }
      if (profile.profile instanceof CompanyProfile) {
        const service = new CompanyAPIService(session);
        await service.updateCompany(profile.id, !profile.isVisible)
      }
      if (profile.profile instanceof TeamProfile) {
        const updatedTeam = structuredClone(profile);
        updatedTeam.isVisible = !profile.isVisible;
        const service = new MarketplaceTeamAPIService(session);
        await service.updateTeam(profile, updatedTeam);
      }

      fetchData();
      enqueueSnackbar(profile.isVisible ? `${name}'s Profile now hidden from the Marketplace` :
        `${name}'s Profile now shown on the Marketplace`, { variant: 'info' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        profile.isVisible ?
          "Couldn't hide profile from marketplace. Please try again" :
          "Couldn't show profile on marketplace. Please try again",
        { variant: 'error' }
      );
    }
  }

  async function deleteProfile(profile: ProfileTableData) {
    const name = profile.profile.name ?? `${profile.profile.firstName} ${profile.profile.lastName}`;

    const response = await confirm({
      title: 'Delete profile?',
      message: `Delete ${name} profile?`,
      okButtonText: 'Delete'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      await deleteMethodMap[activeTab]?.(profile.id);
      fetchData();
      enqueueSnackbar("Deleted profile", { variant: 'success' });

    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't delete profile. Please try again", { variant: 'error' });
    }
  }

  async function leaveTeam(profile: ProfileTableData) {
    if (!profile.profile) return;

    const team = profile as MarketplaceTeam;
    if (!team.id || !team?.memberships) return;

    //search team members for current user
    const membership = team.memberships.find(m => m.userId?.isEqualTo(session.user?.id));
    if (!membership?.userId) return;

    const response = await confirm({
      title: 'Leave team?',
      message: `Leave ${team.profile.name} team?`,
      okButtonText: 'Leave'
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const marketplaceTeamService = new MarketplaceTeamAPIService(session);
      await marketplaceTeamService.removeTeamMember(team.id, membership.userId);

      fetchData();
      enqueueSnackbar("Successfully left marketplace team", { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't leave marketplace team. Please try again", { variant: 'error' });
    }
  }

  const renderNameColumn = (
    profile: ProfileTableData,
  ): JSX.Element => {
    return (
      <NameCellContainer>
        <Avatar src={avatars[profile.profile?.avatarId?.toString() ?? '']} />
        <RowTitle>
          {profile.profile.name ?? `${profile?.profile?.firstName} ${profile?.profile?.lastName}`}
        </RowTitle>
      </NameCellContainer >
    );
  }

  const renderCategoriesColumn = (profile: ProfileTableData): JSX.Element => {
    if (profile.profile?.categories.length < 1) return <Icon>remove</Icon>;
    return (
      <div>
        {profile.profile?.categories?.map((label: string) => (
          <CategoryChip
            key={label}
            label={label}
            size="small"
          />
        ))}
      </div>
    );
  }

  const renderVisibilityColumn = (profile: ProfileTableData): JSX.Element => {
    return (
      <Tooltip
        title={
          profile.isVisible ?
            'Profile is searchable on Marketplace' :
            'Profile is hidden from Marketplace'
        }>
        <span>
          <Switch
            checked={profile.isVisible}
            disabled={rowProfileEditingDisabled(profile)}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
              event.stopPropagation();
              publishProfile(profile);
            }}
          />
        </span>
      </Tooltip>
    );
  }

  const getMenuItems = (profile: ProfileTableData): React.ReactNode[] => {
    const nodes: React.ReactNode[] = [];
    if (session.context?.viewingAsVendor) {
      nodes.push(
        <MenuItem
          key="publish"
          onClick={(event) => {
            event.stopPropagation();
            publishProfile(profile);
          }}>
          <ListItemIcon>
            {profile.isVisible ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{profile.isVisible ? 'Hide From Marketplace' : 'Show On Marketplace'}</ListItemText>
        </MenuItem>
      );
    }
    nodes.push(
      [<MenuItem key="edit" onClick={() => editProfile(profile)}>
        <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Edit Profile</ListItemText>
      </MenuItem>
      ]);
    if (activeTab === PageTab.Teams) {
      const teamProfile = profile as MarketplaceTeam;
      if (teamProfile.leader?.userId?.isEqualTo(session.user?.id)) {
        nodes.push(
          <MenuItem key="delete" onClick={() => deleteProfile(profile)}>
            <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        );
      } else {
        nodes.push(
          <MenuItem key="leave" onClick={() => leaveTeam(profile)}>
            <ListItemIcon><ExitIcon fontSize="small" /></ListItemIcon>
            <ListItemText>Leave Team</ListItemText>
          </MenuItem>);
      }
    }
    return nodes;
  }

  const columnHelper = createMRTColumnHelper<ProfileTableData>();
  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
      id: "name",
    }),
    columnHelper.accessor(renderCategoriesColumn, {
      header: "Categories",
      id: "categories",
    }),
    columnHelper.accessor(renderVisibilityColumn, {
      header: "Searchable On Marketplace?",
      id: "isVisible",
    }),
  ];

  let profiles: ProfileTableData[] = [];
  if (activeTab === PageTab.Company) profiles = companyProfiles;
  if (activeTab === PageTab.Teams) profiles = teamProfiles;
  if (activeTab === PageTab.Individual) profiles = individualProfiles;

  const table = useMaterialReactTable({
    columns,
    data: profiles,
    enableRowSelection: false,
    enableTableHead: true,
    enableRowActions: false,
    enableColumnPinning: true,
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
            <Tooltip title={addProfileDisabledMessage()}>
              <span>
                <NewProfileButtonContainer
                  disabled={disableAddProfileButton()}
                >
                  <Button
                    color="primary"
                    disabled={disableAddProfileButton()}
                    startIcon={newProfileIcon(activeTab)}
                    onClick={() => navigate(`/marketplace/profiles/${activeTab}/create`)}
                  >
                    New Profile
                  </Button>
                </NewProfileButtonContainer>
              </span>
            </Tooltip>
          </ToolbarButtonsContainer>
        )}
        {isMediumDisplaySize && (
          <Tooltip title={addProfileDisabledMessage()}>
          <span>
            <NewProfileButtonContainer
              disabled={disableAddProfileButton()}
            >
              <NewButton
                color="primary"
                disabled={disableAddProfileButton()}
                onClick={() => navigate(`/marketplace/profiles/${activeTab}/create`)}
              >
                {newProfileIcon(activeTab)}
              </NewButton>
            </NewProfileButtonContainer>
          </span>
        </Tooltip>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Marketplace Profiles
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) =>
                navigate(`/marketplace/profiles/${newValue}`)
              }
            >
              <Tab value={PageTab.Individual} label="Individual Profile" />
              <Tab value={PageTab.Company} label="Company Profile" />
              {session.context?.viewingAsVendor && (
                <Tab value={PageTab.Teams} label="Team Profiles" />
              )}
            </Tabs>
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    renderRowActionMenuItems: ({ row }) => getMenuItems(row.original),
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilters: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setQuery,
    onPaginationChange: setPagination,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => editProfile(row.original),
      sx: { cursor: "pointer" },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    rowCount,
    state: {
      columnFilters,
      globalFilter: query,
      isLoading,
      pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <MaterialReactTable table={table} />
  );
}
