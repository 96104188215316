export default class Year {
    private _value: string;

    constructor(value: string | number, notInPast?: boolean, notInFuture?: boolean) {
        this._value = value.toString();
        Year.validate(value.toString(), notInPast, notInFuture);
    }

    public get value(): string {
        return this._value;
    }

    static validate(value: string, notInPast?: boolean, notInFuture?: boolean) {
        if (!value) {
            throw new InvalidYearError('Cannot be empty');
        }
        const validYearRegExp = new RegExp(/^\d{4}$/g);
        const yearIsValid = validYearRegExp.test(value);
        const yearInPast = Number(value) < new Date().getFullYear();
        const yearInFuture = Number(value) > new Date().getFullYear();

        if (!yearIsValid) {
            throw new InvalidYearError('');
        }
        if (notInPast && yearInPast) {
            throw new InvalidYearError('Cannot be in the past');
        }
        if (notInFuture && yearInFuture) {
            throw new InvalidYearError('Cannot be in the future');
        }
    }

    static isValid(value: string): boolean {
        try {
            Year.validate(value);
            return true;
        } catch (error) {
            return false;
        }
    }

    public isEqualTo(other?: Year): boolean {
        if (!other) {
            return false;
        }
        return this._value === other.value;
    }

    public toString(): string {
        return this._value;
    }

    public toNumber(): number {
        return Number(this._value);
    }
}

export class InvalidYearError extends Error {
    value: string;
    
    constructor(value: string) {
        super(`Invalid year${value !== '' ? ': ' + value : ''}`);
        this.value = value;
    }
}
