export default class InvitationMessage {
    private _value: string;

    constructor(value: string) {
        if(!value) throw new InvalidInvitationMessageError("Invitation message cannot be empty.");
        this._value = value;
    }

    public get value(): string {
        return this._value;
    }
}

export class InvalidInvitationMessageError extends Error {
    constructor(value: string) {
        super(`Invalid invitation message: ${value}`);
    }
}