import Guid from "common/values/guid/guid";
import moment from "moment";
import UserNetworkConnection from "users/entities/user-network-connection/user-network-connection";
import UserProfileAPIResponse from "users/entities/user/api/response-contracts/user-profile-api-response";

export default class NetworkConnectionAPIResponse {
    id?: string;
    createdDate?: string;
    userA?: UserProfileAPIResponse;
    userB?: UserProfileAPIResponse;

    deserialize(): UserNetworkConnection {
        if (!this.id)
            throw new Error(`Invalid network connection response: missing id.`);
        if (!this.createdDate)
            throw new Error(`Invalid network connection response: missing createdDate.`);
        if (!this.userA)
            throw new Error(`Invalid network connection response: missing userA.`);
        if (!this.userB)
            throw new Error(`Invalid network connection response: missing userB.`);

        return new UserNetworkConnection(
            new Guid(this.id),
            moment(this.createdDate),
            Object.assign(new UserProfileAPIResponse(), this.userA).deserialize(),
            Object.assign(new UserProfileAPIResponse(), this.userB).deserialize()
        );
    }
}
