import MarketplaceCompanyBookmarkAPIResponse from "marketplace/entities/bookmarked-company/api/response-contracts/marketplace-company-bookmark-api-response";
import MarketplaceIndividualBookmarkAPIResponse from "marketplace/entities/bookmarked-individual/api/response-contracts/marketplace-individual-bookmark-api-response";
import MarketplaceTeamBookmarkAPIResponse from "marketplace/entities/bookmarked-team/api/response-contracts/marketplace-team-bookmark-api-response";
import Bookmarks from "marketplace/entities/bookmark/bookmarks";

export default class MarketplaceBookmarksAPIResponse {
  company?: MarketplaceCompanyBookmarkAPIResponse[];
  individual?: MarketplaceIndividualBookmarkAPIResponse[];
  team?: MarketplaceTeamBookmarkAPIResponse[];

  deserialize(): Bookmarks {
    const bookmarks = {
      company: [],
      individual: [],
      team: [],
    } as Bookmarks;

    if (this.company) {
      this.company.forEach((companyBookmark) => {
        bookmarks.company.push(
          Object.assign(
            new MarketplaceCompanyBookmarkAPIResponse(),
            companyBookmark
          ).deserialize()
        );
      });
    }

    if (this.individual) {
      this.individual.forEach((individualBookmark) => {
        bookmarks.individual.push(
          Object.assign(
            new MarketplaceIndividualBookmarkAPIResponse(),
            individualBookmark
          ).deserializeToBookmark()
        );
      });
    }

    if (this.team) {
      this.team.forEach((teamBookmark) => {
        bookmarks.team.push(
          Object.assign(
            new MarketplaceTeamBookmarkAPIResponse(),
            teamBookmark
          ).deserializeToBookmark()
        );
      });
    }

    return bookmarks;
  }
}
