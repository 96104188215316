import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "common/components/loading-button";
import Guid from "common/values/guid/guid";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import ViewTeam from "marketplace/entities/marketplace-team/view/view-team";
import TeamInvitation from "marketplace/entities/team-invitation/team-invitation";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from "users/session/session-context";

const PageContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
}));
const LoadingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gridColumn: "1 / auto",
  gridRowStart: "1",
  justifyContent: "center",
  marginBottom: theme.spacing(5),
  position: "sticky",
  top: 0,
  width: "100%",
  zIndex: 10,
}));
const HeaderInnerContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    justifyItems: "center",
  },
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "min-content auto",
    gridTemplateRows: "min-content auto",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "min-content auto 1fr",
  },
  alignItems: "flex-start",
  gridColumnGap: theme.spacing(2),
  display: "grid",
  width: "100%",
}));
const TitleContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  marginTop: theme.spacing(1),
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: theme.spacing(7),
    width: theme.spacing(7),
  },
  height: theme.spacing(15),
  width: theme.spacing(15),
}));
const ProfileTypeChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.disabled,
  margin: theme.spacing(0.25),
}));
const NameChipContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  alignItems: "flex-start",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
}));
const DescriptionContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    gridColumn: "1 / span 2",
    gridRow: "2",
    margin: theme.spacing(2, 0),
  },
  [theme.breakpoints.up("lg")]: {
    gridColumn: "3",
    gridRow: "1",
    margin: 0,
  },
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  margin: 0,
  textAlign: "left",
  width: "100%",
}));
const DescriptionText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
  fontSize: "1.1em",
  fontStyle: "italic",
  lineHeight: "1.1em",
}));
const DescriptionDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: "100%",
}));
const AcceptButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  minWidth: theme.spacing(18),
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
  },
}));
const RejectButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  minWidth: theme.spacing(18),
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
  },
}));

type TeamInvitationProps = {
};

export default function TeamInvitationPage(
  props: Readonly<TeamInvitationProps>
) {
  const [loading, setLoading] = React.useState(false);
  const [accepting, setAccepting] = React.useState(false);
  const [rejecting, setRejecting] = React.useState(false);
  const [invitation, setInvitation] = React.useState<TeamInvitation>();
  const [team, setTeam] = React.useState<MarketplaceTeam>();
  const [avatar, setAvatar] = React.useState<string>();
  const [returnUrl, setReturnUrl] = React.useState(
    "/marketplace/profiles/teams"
  );

  const session = useSession();
  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getInvitation();
  }, []);

  async function getInvitation() {
    try {
      setLoading(true);
      if (!params.invitationId)
        throw new Error("Invitation ID was not provided");
      const teamService = new MarketplaceTeamAPIService(session);
      const abortController = new AbortController();
      const teamInvite =
        await teamService.getTeamInvitationByInvitationId(
          new Guid(params.invitationId), abortController
        );
      setInvitation(teamInvite);
      getTeam(teamInvite.teamId);
      getTeamAvatar(teamInvite.teamId);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't get invitation. Please try again", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function getTeam(teamId: Guid) {
    const teamService = new MarketplaceTeamAPIService(session);
    const abortController = new AbortController();
    try {
      
      const marketplaceTeam = await teamService.getTeamById(
        teamId, abortController
      );
      setTeam(marketplaceTeam);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't get team. Please try again", {
        variant: "error",
      });
    }
  }

  async function getTeamAvatar(teamId: Guid) {
    try {
      const teamService = new MarketplaceTeamAPIService(session);
      const abortController = new AbortController();
      const avatar = await teamService.getTeamAvatar(teamId, abortController);
      setAvatar(avatar?.toString());
    } catch (error: any) {
      console.warn(error);
    }
  }

  async function acceptInvitation() {
    try {
      setAccepting(true);
      if (!params.invitationId)
        throw new Error("Invitation ID was not provided");
      
      const teamService = new MarketplaceTeamAPIService(session);
      await teamService.acceptTeamInvitation(
        new Guid(params.invitationId)
      );
      enqueueSnackbar("Accepted invitation to team", { variant: "info" });
      navigate(returnUrl);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't accept invitation. Please try again", {
        variant: "error",
      });
    } finally {
      setAccepting(false);
    }
  }

  async function rejectInvitation() {
    try {
      setRejecting(true);
      if (!params.invitationId)
        throw new Error("Invitation ID was not provided");
      const teamService = new MarketplaceTeamAPIService(session);
      await teamService.rejectTeamInvitation(
        new Guid(params.invitationId)
      );
      enqueueSnackbar("Rejected invitation to team", { variant: "info" });
      navigate(returnUrl);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't reject invitation. Please try again", {
        variant: "error",
      });
    } finally {
      setRejecting(false);
    }
  }

  return (
    <PageContainer>
      {loading && (
        <LoadingContainer>
          <CircularProgress
            color="primary"
            size={42}
            style={{ marginRight: "1rem" }}
          />
          <Typography variant="h4">Loading...</Typography>
        </LoadingContainer>
      )}
      {!loading && (
        <>
          <HeaderContainer>
            <HeaderInnerContainer>
              <StyledAvatar src={avatar?.toString()} />
              <TitleContainer>
                <NameChipContainer>
                  {!invitation?.closed && (
                    <Typography variant="h4">
                      Join {team?.profile.name} Team?
                    </Typography>
                  )}
                  {invitation?.closed && (
                    <Typography variant="h4">
                      Invitation to join {team?.profile.name}
                    </Typography>
                  )}
                  <span>
                    {team?.profile?.categories?.map((category) => (
                      <ProfileTypeChip
                        key={category}
                        label={category}
                        size="small"
                      />
                    ))}
                  </span>
                </NameChipContainer>
                {!invitation?.closed && (
                  <ButtonContainer>
                    <AcceptButton
                      variant="contained"
                      startIcon={<CheckIcon />}
                      disabled={accepting || rejecting}
                      loading={accepting}
                      onClick={acceptInvitation}
                    >
                      Accept
                    </AcceptButton>
                    <RejectButton
                      variant="contained"
                      startIcon={<CloseIcon />}
                      disabled={accepting || rejecting}
                      loading={rejecting}
                      onClick={rejectInvitation}
                    >
                      Reject
                    </RejectButton>
                  </ButtonContainer>
                )}
                {invitation?.acceptedAt && (
                  <Typography variant="h6">
                    Accepted on {invitation?.acceptedAt.format("MM/DD/YYYY")}
                  </Typography>
                )}
                {invitation?.closed && !invitation?.acceptedAt && (
                  <Typography variant="h6">
                    Invitation no longer available.
                  </Typography>
                )}
              </TitleContainer>
              <DescriptionContainer>
                <DescriptionText variant="subtitle1">
                  {team?.profile?.description}
                </DescriptionText>
                <DescriptionDivider />
              </DescriptionContainer>
            </HeaderInnerContainer>
          </HeaderContainer>
          {invitation?.message && (
            <>
              <Typography variant="h5">
                Message from {team?.leader.getFullName()}:
              </Typography>
              <Typography>{invitation?.message}</Typography>
            </>
          )}
          <ViewTeam team={team} />
        </>
      )}
    </PageContainer>
  );
}
