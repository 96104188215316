import Guid from "common/values/guid/guid";
import moment from "moment";
import WorkMarketplaceInfoAPIResponse from "work/contracts/responses/work-marketplace-info-api-response";
import TeamTemplate from "work/entities/team-template/team-template";
import TeamName from "work/values/team-name/team-name";

export default class WorkTeamTemplateAPIResponse {
    id?: string;
    name?: string;
    leader?: WorkMarketplaceInfoAPIResponse;
    members: WorkMarketplaceInfoAPIResponse[] = [];
    marketplaceId?: string;
    avatar?: string;
    created?: string;

    deserialize(): TeamTemplate {
        if (!this.id) throw new Error('Id not provided by backend for team template');
        if (!this.name) throw new Error('Name not provided by backend for team template');
        if (!this.leader) throw new Error('Leader not provided by backend for team template');

        const leaderResponse = Object.assign(new WorkMarketplaceInfoAPIResponse(), this.leader);
        const leaderInfo = leaderResponse.deserialize();
        if (!leaderInfo.company?.entityId) throw new Error('Leader company not provided by backend for team template');
        const template = new TeamTemplate(
            new TeamName(this.name),
            leaderInfo
        );
        template.id = new Guid(this.id);
        template.created = moment(this.created);
        if (this.marketplaceId) template.marketplaceId = new Guid(this.marketplaceId);
        template.memberUserIds = this.members.map((member) => {
            if (!member.userId) throw new Error("Member user id not returned.");
            return new Guid(member.userId);
        });
        template.members = this.members.map((member) => {
            return Object.assign(new WorkMarketplaceInfoAPIResponse(), member).deserialize();
        });
        if (this.avatar) template.avatarId = new Guid(this.avatar);
        return template;
    }
}
