import Guid from "common/values/guid/guid";
import Date from "common/values/date/date";
import Individual from "marketplace/entities/individual/individual";
import Session from "users/session/session";
import EntityRepresentative from "work/entities/entity-representative/entity-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import ProposalRedline, {
  RedlinedDates,
  RedlinedDocuments,
} from "work/entities/proposal/redlining/proposal-redline";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import {
  ProposalAction,
  ProposalFieldName,
  ProposalStatus,
} from "work/values/constants";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import DetailedTeam from "work/values/team/detailed-team";
import Team from "work/values/team/team";
import User from "users/entities/user/user";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import UserInfo from "work/values/user-info/user-info";
import WorkDocument from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import FieldRedline, {
  FieldRedlineArray,
} from "work/entities/proposal/redlining/field-redline";
import AHBoolean from "common/values/boolean/boolean";
import { FeeScheduleRedline } from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";

export default class Proposal {
  private readonly _RFPId?: Guid;
  private readonly _apiService: IProposalAPIService;

  private readonly _id?: Guid;
  private readonly _name?: ProjectName;
  private readonly _description?: ProjectDescription;
  private readonly _creator?: EntityRepresentative;
  private readonly _creatorInfo?: UserInfo;
  private readonly _createdDate?: Date;
  private readonly _lastUpdated?: Date;

  private readonly _workAgreement: WorkAgreement;

  private readonly _negotiable: boolean = true;
  private readonly _responseDueBy?: Date;

  private readonly _supersedes?: Proposal;
  private readonly _supersededById: Guid | null;

  private readonly _clientReviewers: ProposalReviewer[] = [];
  private readonly _vendorReviewers: ProposalReviewer[] = [];
  private readonly _status: ProposalStatus = ProposalStatus.AwaitingSubmission;
  private readonly _availableActions: { [key in ProposalAction]: Guid[] };

  private readonly _details?: ProposalDetails;
  private readonly _detailed: boolean = false;
  private readonly _currentUser: User;

  private _redlining?: ProposalRedline;

  // Stores a reference to the instance of the proposal
  // Used because Material Table strips out methods
  readonly _instance: Proposal;

  constructor(
    apiService: IProposalAPIService,
    currentUser: User,
    spec: CompleteProposalSpec,
    metaInfo?: ProposalMetaInfo,
    details?: ProposalDetails,
    isDetailed: boolean = false
  ) {
    this._apiService = apiService;
    this._currentUser = currentUser;

    this._name = spec.name;
    this._description = spec.description;

    const workAgreement = new WorkAgreement();
    workAgreement.RFPId = metaInfo?.RFPId;
    workAgreement.client = spec.client;
    workAgreement.vendors = spec.vendors;
    workAgreement.name = spec.name;
    workAgreement.description = spec.description;
    workAgreement.startDate = spec.startDate;
    workAgreement.endDate = spec.endDate;
    workAgreement.team = spec.team;
    workAgreement.teamRestricted = spec.teamRestricted;
    workAgreement.feeSchedule = spec.feeSchedule ?? [];
    workAgreement.clientPolicyDocuments = spec.clientPolicyDocuments ?? [];
    workAgreement.vendorPolicyDocuments = spec.vendorPolicyDocuments ?? [];
    workAgreement.conflictsDocuments = spec.conflictsDocuments ?? [];
    workAgreement.clientTeamTemplateIds = spec.clientTeamTemplateIds ?? [];
    workAgreement.vendorTeamTemplateIds = spec.vendorTeamTemplateIds ?? [];
    workAgreement.clientFeeScheduleTemplateIds =
      spec.clientFeeScheduleTemplateIds ?? [];
    workAgreement.vendorFeeScheduleTemplateIds =
      spec.vendorFeeScheduleTemplateIds ?? [];
    workAgreement.conflictsCheckWaived =
      spec.conflictsCheckWaived ?? new AHBoolean(false);
    workAgreement.discount = spec.discount ?? new Percent(0);

    this._workAgreement = workAgreement;

    this._negotiable = spec.negotiable;
    this._responseDueBy = spec.responseDueBy;
    this._clientReviewers = spec.clientReviewers ?? [];
    this._vendorReviewers = spec.vendorReviewers ?? [];

    this._RFPId = metaInfo?.RFPId;
    this._id = metaInfo?.id;
    this._createdDate = metaInfo?.createdDate;
    this._lastUpdated = metaInfo?.lastUpdated;
    this._creator = metaInfo?.creator;
    this._creatorInfo = metaInfo?.creatorInfo;
    this._supersededById = metaInfo?.supersededById ?? null;
    this._status = metaInfo?.status ?? ProposalStatus.AwaitingSubmission;
    this._availableActions = metaInfo?.availableActions ?? {
      [ProposalAction.Submit]: [],
      [ProposalAction.Approve]: [],
      [ProposalAction.Reject]: [],
      [ProposalAction.Revise]: [],
      [ProposalAction.Hire]: [],
      [ProposalAction.Delete]: [],
      [ProposalAction.Cancel]: [],
      [ProposalAction.Edit]: [],
      [ProposalAction.Manage]: [],
      [ProposalAction.Review]: [],
    };
    this._supersedes = details?.supersedes;
    this._details = details;
    this._instance = this;
    this.createRedline();
    this._detailed = isDetailed;
  }

  public get RFPId(): Guid | undefined {
    return this._RFPId;
  }

  public get id(): Guid | null {
    return this._id ? this._id?.clone() : null;
  }
  public get creator(): EntityRepresentative | null {
    return this._creator?.clone() ?? null;
  }
  public get creatorInfo(): UserInfo | null {
    return this._creatorInfo?.clone() ?? null;
  }
  public get createdDate(): Date | null {
    return this._createdDate?.clone() ?? null;
  }
  public get lastUpdated(): Date | null {
    return this._lastUpdated?.clone() ?? null;
  }
  public get status(): ProposalStatus {
    return this._status;
  }
  public get name(): ProjectName | undefined {
    return this._name?.clone();
  }
  public get description(): ProjectDescription | undefined {
    return this._description?.clone();
  }
  public get negotiable(): boolean {
    return this._negotiable;
  }
  public get responseDueBy(): Date | undefined {
    return this._responseDueBy?.clone();
  }
  public get clientReviewers(): ProposalReviewer[] {
    return [...this._clientReviewers];
  }
  public get vendorReviewers(): ProposalReviewer[] {
    return [...this._vendorReviewers];
  }
  public get supersedes(): Proposal | null {
    return this._supersedes ?? null;
  }
  public get supersededById(): Guid | null {
    return this._supersededById ?? null;
  }

  public get client(): EntityClientRepresentative | null {
    return this._workAgreement.client?.clone() ?? null;
  }
  public get vendors(): EntityVendorRepresentative[] | null {
    return this._workAgreement.vendors.map((vendor) => vendor.clone()) ?? null;
  }
  public get team(): Team | null {
    return this._workAgreement.team?.clone() ?? null;
  }
  public get teamRestricted(): AHBoolean {
    return this._workAgreement.teamRestricted?.clone() ?? new AHBoolean(false);
  }
  public get feeSchedule(): FeeScheduleCategory[] {
    return (
      this._workAgreement.feeSchedule?.map((category) => category.clone()) ?? []
    );
  }
  public get startDate(): Date | undefined {
    return this._workAgreement.startDate?.clone();
  }
  public get endDate(): Date | undefined {
    return this._workAgreement.endDate?.clone();
  }
  public get discount(): Percent {
    return this._workAgreement.discount;
  }
  public get clientPolicyDocuments(): WorkDocument[] {
    return this._workAgreement.clientPolicyDocuments.map((doc) => doc.clone());
  }
  public get vendorPolicyDocuments(): WorkDocument[] {
    return this._workAgreement.vendorPolicyDocuments.map((doc) => doc.clone());
  }
  public get conflictsDocuments(): WorkDocument[] {
    return this._workAgreement.conflictsDocuments.map((doc) => doc.clone());
  }
  public get clientTeamTemplateIds(): Guid[] {
    const templates = this._workAgreement.clientTeamTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get vendorTeamTemplateIds(): Guid[] {
    const templates = this._workAgreement.vendorTeamTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get clientFeeScheduleTemplateIds(): Guid[] {
    const templates = this._workAgreement.clientFeeScheduleTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get vendorFeeScheduleTemplateIds(): Guid[] {
    const templates = this._workAgreement.vendorFeeScheduleTemplateIds;
    return templates.map((id) => id.clone());
  }
  public get conflictsCheckWaived(): AHBoolean {
    return this._workAgreement.conflictsCheckWaived ?? new AHBoolean(false);
  }
  public get availableActions(): { [key in ProposalAction]: Guid[] } {
    return this._availableActions;
  }
  public get details(): ProposalDetails | undefined {
    return this._details;
  }
  public get isDetailed(): boolean {
    return this._detailed;
  }
  public get isArchived(): boolean {
    return this._status === ProposalStatus.Archived;
  }

  public clone(): Proposal {
    return new Proposal(
      this._apiService,
      this._currentUser,
      this.spec,
      this.metaInfo,
      this.details
    );
  }

  public hasFee(category: FeeScheduleCategory): boolean {
    return (
      this._workAgreement.feeSchedule?.some((cat) =>
        cat.name?.isEqualTo(category.name)
      ) ?? false
    );
  }

  public isAssociatedWithUserId(id: Guid): boolean {
    if (this._creator?.userId.isEqualTo(id)) return true;
    if (this._clientReviewers.some((reviewer) => reviewer.userId.isEqualTo(id)))
      return true;
    if (this._vendorReviewers.some((reviewer) => reviewer.userId.isEqualTo(id)))
      return true;
    if (this._status !== ProposalStatus.AwaitingSubmission) {
      if (this._workAgreement.client?.userId.isEqualTo(id)) return true;
      if (this._workAgreement.team?.leader?.userId.isEqualTo(id)) return true;
      if (
        this._workAgreement.team?.memberUserIds.some((userId) =>
          userId.isEqualTo(id)
        )
      )
        return true;
    }
    return false;
  }

  public get spec(): CompleteProposalSpec {
    const workAgreement = this._workAgreement.clone();

    return {
      client: workAgreement.client,
      name: this._name?.clone(),
      description: this._description?.clone(),
      negotiable: this._negotiable,
      responseDueBy: this._responseDueBy?.clone() ?? undefined,
      clientReviewers: this.clientReviewers.map((reviewer) => reviewer.clone()),
      vendorReviewers: this.vendorReviewers.map((reviewer) => reviewer.clone()),
      vendors: workAgreement.vendors,
      teamRestricted:
        workAgreement.teamRestricted?.clone() ?? new AHBoolean(false),
      feeSchedule: workAgreement.feeSchedule ?? [],
      startDate: workAgreement.startDate?.clone(),
      endDate: workAgreement.endDate?.clone(),
      team: workAgreement.team,
      conflictsCheckWaived:
        workAgreement.conflictsCheckWaived?.clone() ?? new AHBoolean(false),
      conflictsDocuments: workAgreement.conflictsDocuments,
      clientPolicyDocuments: workAgreement.clientPolicyDocuments,
      vendorPolicyDocuments: workAgreement.vendorPolicyDocuments,
      clientTeamTemplateIds: workAgreement.clientTeamTemplateIds,
      vendorTeamTemplateIds: workAgreement.vendorTeamTemplateIds,
      clientFeeScheduleTemplateIds: workAgreement.clientFeeScheduleTemplateIds,
      vendorFeeScheduleTemplateIds: workAgreement.vendorFeeScheduleTemplateIds,
      discount: workAgreement.discount ?? new Percent(0),
    };
  }

  public get metaInfo(): ProposalMetaInfo {
    if (!this._id || !this._createdDate || !this._creator)
      throw new Error(
        "Cannot get meta info for a proposal that has not been saved."
      );
    return {
      RFPId: this._RFPId,
      id: this._id?.clone(),
      supersededById: this._supersededById?.clone(),
      createdDate: this._createdDate.clone(),
      lastUpdated: this._lastUpdated?.clone() ?? this._createdDate.clone(),
      creator: this._creator?.clone(),
      creatorInfo: this._creatorInfo?.clone(),
      status: this._status,
      availableActions: this._availableActions,
    };
  }

  public get redline(): ProposalRedline | undefined {
    return this._redlining ?? undefined;
  }

  public async save(
    session: Readonly<Session>,
    originalProposal?: Proposal
  ): Promise<Proposal> {
    if (this._id !== undefined) {
      if (!originalProposal) {
        throw new Error(
          "Cannot save a proposal without the original proposal."
        );
      }
      return await this._apiService.updateProposal(originalProposal, this);
    } else {
      return await this._apiService.createProposal(this, session);
    }
  }

  public async submit(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot submit a proposal without a user id.");
    }
    if (!this.creator?.userId.isEqualTo(userId)) {
      throw new Error("Cannot submit a proposal that you did not create.");
    }
    if (this._status !== ProposalStatus.AwaitingSubmission) {
      throw new Error(
        "Cannot submit a proposal that has already been submitted."
      );
    }
    if (this._workAgreement.team === undefined) {
      throw new Error("Cannot submit a proposal without a team.");
    }

    return await this._apiService.submitProposal(this);
  }

  public async approve(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot approve a proposal without a user id.");
    }
    if (!this.isAssociatedWithUserId(userId)) {
      throw new Error(
        "Cannot approve a proposal that you are not associated with."
      );
    }
    if (
      this.status !== ProposalStatus.AwaitingApprovalByClient &&
      this.status !== ProposalStatus.AwaitingApprovalByTeam &&
      this.status !== ProposalStatus.AwaitingApprovalByTeamLeader &&
      this.status !== ProposalStatus.AwaitingApprovalByVendors
    ) {
      throw new Error(
        "Cannot approve a proposal that is not awaiting approval."
      );
    }
    if (
      !this._workAgreement.feeSchedule ||
      this._workAgreement.feeSchedule.length === 0
    ) {
      throw new Error("Cannot approve a proposal without a fee schedule.");
    }
    if (this._workAgreement.feeSchedule.some((category) => !category.fee)) {
      throw new Error("Cannot approve a proposal with deferred fees.");
    }
    if (!this._workAgreement.team) {
      throw new Error("Cannot approve a proposal without a team.");
    }
    if (
      !this._workAgreement.conflictsCheckWaived &&
      this._workAgreement.conflictsDocuments.length === 0
    ) {
      throw new Error(
        "Cannot approve a proposal without a conflicts check or waiver."
      );
    }

    const updatedProposal = await this._apiService.approveProposal(this);
    return updatedProposal;
  }

  public async reject(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot reject a proposal without a user id.");
    }
    if (!this.isAssociatedWithUserId(userId)) {
      throw new Error(
        "Cannot reject a proposal that you are not associated with."
      );
    }
    if (
      this.status !== ProposalStatus.AwaitingApprovalByClient &&
      this.status !== ProposalStatus.AwaitingApprovalByTeam &&
      this.status !== ProposalStatus.AwaitingApprovalByTeamLeader &&
      this.status !== ProposalStatus.AwaitingApprovalByVendors
    ) {
      throw new Error(
        "Cannot reject a proposal that is not awaiting approval."
      );
    }

    return await this._apiService.rejectProposal(this);
  }

  public async hire(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot hire a proposal without a user id.");
    }
    if (this._workAgreement.hireDate !== undefined) {
      throw new Error("Cannot hire a proposal that has already been hired.");
    }
    if (this.status !== ProposalStatus.AwaitingHire) {
      throw new Error("Cannot hire a proposal that is not awaiting hire.");
    }

    return await this._apiService.hireProposal(this);
  }

  public async delete(userId?: Guid): Promise<void> {
    if (!userId) {
      throw new Error("Cannot delete a proposal without a user id.");
    }
    if (this.creator?.userId.value !== userId.value) {
      throw new Error("Cannot delete a proposal that you did not create.");
    }
    if (this.status !== ProposalStatus.AwaitingSubmission) {
      throw new Error(
        "Cannot delete a proposal that has already been submitted."
      );
    }

    return await this._apiService.deleteProposal(this);
  }

  public async cancel(userId?: Guid): Promise<Proposal> {
    if (!userId) {
      throw new Error("Cannot cancel a proposal without a user id.");
    }
    if (this.creator?.userId.value !== userId.value) {
      throw new Error("Cannot cancel a proposal that you did not create.");
    }

    return await this._apiService.cancelProposal(this);
  }

  private createRedline() {
    if (
      !this.id ||
      !this.name ||
      !this.description ||
      !this.details
    )
      return;

    const previousVersion = this._supersedes || this;

    const nameRedline = this.createNameRedline(
      previousVersion.name ?? null,
      this.details.redlining?.name
    );
    const descriptionRedline = this.createDescriptionRedline(
      previousVersion.description ?? null,
      this.details.redlining?.description
    );
    const teamRedline = this.createTeamRedline(
      previousVersion.details?.team,
      this.details.redlining?.team
    );
    const feeScheduleRedline = this.createFeeScheduleRedline(
      previousVersion.feeSchedule,
      this.details.redlining?.feeSchedule
    );
    const teamRestrictedRedline = this.createTeamRestrictedRedline(
      previousVersion.teamRestricted,
      this.details.redlining?.teamRestricted
    );
    const documentsRedline: RedlinedDocuments = {
      clientPolicyDocuments: this.createDocumentRedline(
        ProposalField.ClientPolicies,
        ProposalField.ClientPolicyDocument,
        this.clientPolicyDocuments,
        previousVersion.clientPolicyDocuments,
        this.details.redlining?.clientPolicyDocuments
      ),
      vendorPolicyDocuments: this.createDocumentRedline(
        ProposalField.VendorPolicies,
        ProposalField.VendorPolicyDocument,
        this.vendorPolicyDocuments,
        previousVersion.vendorPolicyDocuments,
        this.details.redlining?.vendorPolicyDocuments
      ),
      conflictsDocuments: this.createDocumentRedline(
        ProposalField.Conflicts,
        ProposalField.ConflictsDocument,
        this.conflictsDocuments,
        previousVersion.conflictsDocuments,
        this.details.redlining?.conflictsDocuments
      ),
    };

    const forceRefreshRedline =
      documentsRedline.conflictsDocuments.redlines.some(
        (conflictsDocumentRedline) => {
          return !this.details?.redlining?.conflictsDocuments.redlines.some(
            (existingConflictsDocumentRedline) => {
              return (
                conflictsDocumentRedline.field?.isEqualTo(
                  existingConflictsDocumentRedline.field
                ) &&
                conflictsDocumentRedline.revisedEntry?.isEqualTo(
                  existingConflictsDocumentRedline.revisedEntry
                )
              );
            }
          );
        }
      );

    const conflictsCheckWaivedRedline = this.createConflictsCheckWaivedRedline(
      previousVersion.conflictsCheckWaived,
      this.details.redlining?.conflictsCheckWaived,
      forceRefreshRedline
    );
    const datesRedline: RedlinedDates = {
      startDate: this.createDateRedline(
        ProposalField.StartDate,
        this.startDate,
        previousVersion.startDate,
        this.details.redlining?.startDate
      ),
      endDate: this.createDateRedline(
        ProposalField.EndDate,
        this.endDate,
        previousVersion.endDate,
        this.details.redlining?.endDate
      ),
      responseDueBy: this.createDateRedline(
        ProposalField.ResponseDueBy,
        this.responseDueBy,
        previousVersion.responseDueBy,
        this.details.redlining?.responseDueBy
      ),
    };
    const discountRedline = this.createDiscountRedline(
      previousVersion.discount,
      this.details.redlining?.discount
    );

    this._redlining = new ProposalRedline(
      nameRedline,
      descriptionRedline,
      datesRedline,
      teamRedline,
      feeScheduleRedline,
      conflictsCheckWaivedRedline,
      teamRestrictedRedline,
      documentsRedline,
      discountRedline
    );
  }

  private createNameRedline(
    previousName: ProjectName | null,
    originalNameRedline?: FieldRedline<ProjectName>
  ): FieldRedline<ProjectName> {
    const newNameRedline = new FieldRedline<ProjectName>(
      ProjectName.Prototype,
      ProposalField.Name,
      previousName,
      this.name ?? null,
      undefined,
      true
    );
    if (
      (originalNameRedline?.revisedEntry === null &&
        newNameRedline.revisedEntry === null) ||
      originalNameRedline?.revisedEntry?.isEqualTo(
        newNameRedline.revisedEntry
      ) ||
      (originalNameRedline && this.supersedes && 
        this.creator?.userId.isEqualTo(this._currentUser.id))
    ) {
      return originalNameRedline;
    }
    return newNameRedline;
  }
  private createDescriptionRedline(
    previousDescription: ProjectDescription | null,
    originalDescriptionRedline?: FieldRedline<ProjectDescription>
  ): FieldRedline<ProjectDescription> {
    const newDescriptionRedline = new FieldRedline<ProjectDescription>(
      ProjectDescription.Prototype,
      ProposalField.Description,
      previousDescription,
      this.description ?? null,
      undefined,
      true
    );
    if (
      (originalDescriptionRedline?.revisedEntry === null &&
        newDescriptionRedline.revisedEntry === null) ||
      originalDescriptionRedline?.revisedEntry?.isEqualTo(
        newDescriptionRedline.revisedEntry
      ) ||
      (originalDescriptionRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
    ) {
      return originalDescriptionRedline;
    }
    return newDescriptionRedline;
  }
  private createDateRedline(
    field: ProposalField,
    currentDate: Date | undefined,
    previousDate?: Date,
    originalRedline?: FieldRedline<Date>
  ) {
    const newDateRedline = new FieldRedline<Date>(
      Date.Prototype,
      field,
      previousDate ? previousDate.clone() : null,
      currentDate ? currentDate.clone() : null
    );
    if (
      (originalRedline?.revisedEntry === null &&
        newDateRedline.revisedEntry === null) ||
      originalRedline?.revisedEntry?.isEqualTo(newDateRedline.revisedEntry)||
      (originalRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
    ) {
      return originalRedline;
    }
    return newDateRedline;
  }
  private createTeamRedline(
    previousTeam?: DetailedTeam,
    originalRedline?: FieldRedlineArray<Individual>
  ): FieldRedlineArray<Individual> {
    let newTeamRedline = new FieldRedlineArray<Individual>(
      Individual.Prototype,
      ProposalField.Team,
      ProposalField.TeamMember,
      previousTeam?.members ?? [],
      this.details?.team?.members ?? []
    );

    originalRedline?.redlines.forEach((originalMemberRedline) => {
      const matchingNewMemberRedline = newTeamRedline.getFieldRedlineById(
        originalMemberRedline.field.id as Guid
      );
      if (
        (matchingNewMemberRedline?.revisedEntry === null &&
          originalMemberRedline.revisedEntry === null) ||
        matchingNewMemberRedline?.revisedEntry?.isEqualTo(
          originalMemberRedline.revisedEntry
        )||
        (originalMemberRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
      ) {
        newTeamRedline = newTeamRedline.updateRedline(originalMemberRedline);
      }
    });
    return newTeamRedline;
  }
  private createFeeScheduleRedline(
    previousFeeSchedule: FeeScheduleCategory[],
    originalRedline?: FeeScheduleRedline
  ) {
    let newFeeScheduleRedline = new FeeScheduleRedline(
      previousFeeSchedule,
      this.feeSchedule
    );

    originalRedline?.redlines.forEach((originalCategoryRedline) => {
      const matchingNewCategoryRedline = newFeeScheduleRedline.redlines.find(
        (newCategoryRedline) =>
          newCategoryRedline.field.isEqualTo(originalCategoryRedline.field)
      );
      if (
        (matchingNewCategoryRedline?.revisedEntry === null &&
          originalCategoryRedline.revisedEntry === null) ||
        matchingNewCategoryRedline?.revisedEntry?.isEqualTo(
          originalCategoryRedline.revisedEntry
        ) ||
        (originalCategoryRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
      ) {
        newFeeScheduleRedline = newFeeScheduleRedline.updateRedline(
          originalCategoryRedline
        );
      }
    });

    return newFeeScheduleRedline;
  }
  private createConflictsCheckWaivedRedline(
    previousConflictsCheckWaived: AHBoolean,
    originalConflictsCheckWaivedRedline?: FieldRedline<AHBoolean>,
    forceRefreshRedline: boolean = false
  ): FieldRedline<AHBoolean> {
    const newConflictsCheckWaivedRedline = new FieldRedline<AHBoolean>(
      AHBoolean.Prototype,
      ProposalField.WaiveConflictsCheck,
      new AHBoolean(previousConflictsCheckWaived.value ?? false),
      new AHBoolean(this.conflictsCheckWaived.value ?? false)
    );
    if (forceRefreshRedline) return newConflictsCheckWaivedRedline;
    if (
      (originalConflictsCheckWaivedRedline?.revisedEntry === null &&
        newConflictsCheckWaivedRedline.revisedEntry === null) ||
      originalConflictsCheckWaivedRedline?.revisedEntry?.isEqualTo(
        newConflictsCheckWaivedRedline.revisedEntry
      )||
      (originalConflictsCheckWaivedRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
    ) {
      return originalConflictsCheckWaivedRedline;
    }
    return newConflictsCheckWaivedRedline;
  }
  private createTeamRestrictedRedline(
    previousTeamRestricted: AHBoolean,
    originalTeamRestrictedRedline?: FieldRedline<AHBoolean>
  ): FieldRedline<AHBoolean> {
    const newTeamRestrictedRedline = new FieldRedline<AHBoolean>(
      AHBoolean.Prototype,
      ProposalField.TeamRestriction,
      previousTeamRestricted ?? false,
      this.teamRestricted ?? false
    );
    if (
      (originalTeamRestrictedRedline?.revisedEntry === null &&
        newTeamRestrictedRedline.revisedEntry === null) ||
      originalTeamRestrictedRedline?.revisedEntry?.isEqualTo(
        newTeamRestrictedRedline.revisedEntry
      )||
      (originalTeamRestrictedRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
    ) {
      return originalTeamRestrictedRedline;
    }
    return newTeamRestrictedRedline;
  }
  private createDocumentRedline(
    arrayField: ProposalField,
    arrayEntryFieldConstructor: (id: Guid) => ProposalField,
    currentDocuments: WorkDocument[],
    previousDocuments?: WorkDocument[],
    originalDocumentsRedline?: FieldRedlineArray<WorkDocument>
  ) {
    let newDocumentRedline = new FieldRedlineArray<WorkDocument>(
      WorkDocument.Prototype,
      arrayField,
      arrayEntryFieldConstructor,
      previousDocuments ?? [],
      currentDocuments
    );

    originalDocumentsRedline?.redlines.forEach((originalDocumentRedline) => {
      const matchingNewDocumentRedline = newDocumentRedline.getFieldRedlineById(
        originalDocumentRedline.field.id as Guid
      );
      if (
        (matchingNewDocumentRedline?.revisedEntry === null &&
          originalDocumentRedline.revisedEntry === null) ||
        matchingNewDocumentRedline?.revisedEntry?.isEqualTo(
          originalDocumentRedline.revisedEntry
        )||
        (originalDocumentRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
      ) {
        newDocumentRedline = newDocumentRedline.updateRedline(
          originalDocumentRedline
        );
      }
    });
    return newDocumentRedline;
  }
  private createDiscountRedline(
    previousDiscount: Percent,
    originalDiscountRedline?: FieldRedline<Percent>
  ): FieldRedline<Percent> {
    const newDiscountRedline = new FieldRedline<Percent>(
      Percent.Prototype,
      ProposalField.Discount,
      previousDiscount ?? null,
      this.discount ?? null
    );

    if (
      (originalDiscountRedline?.revisedEntry === null &&
        newDiscountRedline.revisedEntry === null) ||
      originalDiscountRedline?.revisedEntry?.isEqualTo(
        newDiscountRedline.revisedEntry
      )||
      (originalDiscountRedline && this.supersedes && this.creator?.userId.isEqualTo(this._currentUser.id))
    ) {
      return originalDiscountRedline;
    }
    return newDiscountRedline;
  }

  userCanRevise(user?: User | null): boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Revise].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanApprove(user?: User | null): boolean {
    if (!user?.id) return false;
    if (
      !this._workAgreement.conflictsCheckWaived &&
      this._workAgreement.conflictsDocuments.length === 0
    )
      return false;
    return this.availableActions[ProposalAction.Approve].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanReject(user?: User | null): boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Reject].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanHire(user?: User | null): boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Hire].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  toJSON(): object {
    return {
      name: this._name?.value,
      description: this._description?.value,
      negotiable: this._negotiable,
      workAgreement: this._workAgreement.toJSON(),
      responseDueBy: this._responseDueBy?.value,
      clientReviewers: this._clientReviewers.map((reviewer) =>
        reviewer.toJSON()
      ),
      vendorReviewers: this._vendorReviewers.map((reviewer) =>
        reviewer.toJSON()
      ),
      redlining: this._redlining?.toJSON(),
    };
  }

  public async saveRedlining(): Promise<Proposal | undefined> {
    if (!this._redlining || !this._id) return;

    const proposalId: Guid = this._id;

    function makeReplacer() {
      let isInitial = true;

      return (key: string, value: any) => {
        if (isInitial) {
          isInitial = false;
          return value;
        }
        if (key === "parent") return undefined;
        return value;
      };
    }

    const replacer = makeReplacer();
    this._redlining.clearSessionHistory();
    const redliningJSON = this._redlining.toJSON();

    const redliningJson = JSON.stringify(redliningJSON, replacer);
    this._apiService.saveRedlining(proposalId, redliningJson);
  }

  public get redlinedRevision(): Proposal {
    if (!this._redlining?.isResolved)
      throw new Error("Cannot load an unresolved redline revision.");
    const revisionSpec = this.spec;

    if (this._redlining.name.currentEntry) {
      revisionSpec.name = this._redlining.name.currentEntry;
    }

    if (this._redlining.description.currentEntry) {
      revisionSpec.description = this._redlining.description.currentEntry;
    }

    revisionSpec.responseDueBy =
      this._redlining.responseDueBy.currentEntry?.clone();
    revisionSpec.startDate = this._redlining.startDate.currentEntry?.clone();
    revisionSpec.endDate = this._redlining.endDate.currentEntry?.clone();

    if (!this.team?.leader)
      throw new Error("Cannot load a redlined revision without a team leader.");
    const memberUserIds: Guid[] = [];
    this._redlining.team.redlines
      .filter((redlineMember) => redlineMember.currentEntry?.userId)
      .forEach((redlineMember) => {
        if (redlineMember.currentEntry?.userId)
          memberUserIds.push(redlineMember.currentEntry.userId);
      });
    const team = new Team(this.team.leader, memberUserIds);
    revisionSpec.team = team;
    revisionSpec.teamRestricted =
      this._redlining.teamRestricted.currentEntry ?? new AHBoolean(false);

    revisionSpec.feeSchedule = [];
    for (const redlineCategory of this._redlining.feeSchedule.redlines) {
      if (!redlineCategory.currentEntry) continue;
      revisionSpec.feeSchedule.push(redlineCategory.currentEntry);
    }

    revisionSpec.conflictsCheckWaived =
      this._redlining.conflictsCheckWaived.currentEntry ?? new AHBoolean(false);
    revisionSpec.conflictsDocuments =
      this._redlining.conflictsDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.clientPolicyDocuments =
      this._redlining.clientPolicyDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.vendorPolicyDocuments =
      this._redlining.vendorPolicyDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.discount =
      this._redlining.discount.currentEntry ?? new Percent(0);
    const revision = new Proposal(
      this._apiService,
      this._currentUser,
      revisionSpec,
      this.metaInfo,
      this.details
    );
    return revision;
  }

  public updateRedline(redline: ProposalRedline): Proposal {
    const newProposal = this.clone();
    newProposal._redlining = redline;
    return newProposal;
  }

  public async requestRevision(session: Readonly<Session>): Promise<Proposal> {
    const newRevision = await this._apiService.requestRevision(this, session);
    return newRevision;
  }
}

export interface IProposalAPIService {
  saveRedlining(id: Guid, redliningJson: string): Promise<Proposal>;
  getProposalRevisions(
    id: Guid,
    abortController?: AbortController
  ): Promise<Proposal[]>;
  createProposal(
    proposal: Proposal,
    session: Readonly<Session>
  ): Promise<Proposal>;
  updateProposal(
    originalProposal: Proposal,
    updatedProposal: Proposal
  ): Promise<Proposal>;
  deleteProposal(proposal: Proposal): Promise<void>;
  hireProposal(proposal: Proposal): Promise<Proposal>;
  cancelProposal(proposal: Proposal): Promise<Proposal>;
  submitProposal(proposal: Proposal): Promise<Proposal>;
  approveProposal(proposal: Proposal): Promise<Proposal>;
  rejectProposal(proposal: Proposal): Promise<Proposal>;
  requestRevision(
    proposal: Proposal,
    session: Readonly<Session>
  ): Promise<Proposal>;
}

export type CompleteProposalSpec = {
  RFPId?: Guid;
  vendors: EntityVendorRepresentative[];
  teamRestricted: AHBoolean;
  feeSchedule: FeeScheduleCategory[];
  conflictsCheckWaived: AHBoolean;
  conflictsDocuments: WorkDocument[];
  clientPolicyDocuments: WorkDocument[];
  vendorPolicyDocuments: WorkDocument[];
  clientTeamTemplateIds: Guid[];
  vendorTeamTemplateIds: Guid[];
  clientFeeScheduleTemplateIds: Guid[];
  vendorFeeScheduleTemplateIds: Guid[];
  discount: Percent;
  team?: Team;
  client?: EntityClientRepresentative;
  name?: ProjectName;
  description?: ProjectDescription;
  negotiable: boolean;
  responseDueBy?: Date;
  startDate?: Date;
  endDate?: Date;
  clientReviewers?: ProposalReviewer[];
  vendorReviewers?: ProposalReviewer[];
};

export type ProposalMetaInfo = {
  RFPId?: Guid;
  id: Guid;
  createdDate: Date;
  lastUpdated: Date;
  creator: EntityRepresentative;
  creatorInfo?: UserInfo;
  status: ProposalStatus;
  supersededById?: Guid;
  availableActions: { [key in ProposalAction]: Guid[] };
};

export type ProposalDetails = {
  client: Individual;
  team?: DetailedTeam;
  redlining?: ProposalRedline;
  supersedes?: Proposal;
  supersededById?: Guid;
};

export type FreelyPatchableFields = {
  reviewers: ProposalReviewer[];
  teamTemplateIds: Guid[];
  feeScheduleTemplateIds: Guid[];
};

export enum ProposalFieldCategory {
  Details = "Details",
  Team = "Team",
  FeeSchedule = "Fee Schedule",
  Conflicts = "Conflicts",
  Policies = "Policies",
  Discount = "Discount",
}

export class ProposalField {
  static readonly General: ProposalField = new ProposalField(
    ProposalFieldName.General,
    ProposalFieldCategory.Details
  );
  static readonly Client: ProposalField = new ProposalField(
    ProposalFieldName.Client,
    ProposalFieldCategory.Details
  );
  static readonly Name: ProposalField = new ProposalField(
    ProposalFieldName.Name,
    ProposalFieldCategory.Details
  );
  static readonly Description: ProposalField = new ProposalField(
    ProposalFieldName.Description,
    ProposalFieldCategory.Details
  );
  static readonly Negotiable: ProposalField = new ProposalField(
    ProposalFieldName.Negotiable,
    ProposalFieldCategory.Details
  );
  static readonly ResponseDueBy: ProposalField = new ProposalField(
    ProposalFieldName.ResponseDueBy,
    ProposalFieldCategory.Details
  );
  static readonly StartDate: ProposalField = new ProposalField(
    ProposalFieldName.StartDate,
    ProposalFieldCategory.Details
  );
  static readonly EndDate: ProposalField = new ProposalField(
    ProposalFieldName.EndDate,
    ProposalFieldCategory.Details
  );

  static readonly TeamRestriction: ProposalField = new ProposalField(
    ProposalFieldName.TeamRestriction,
    ProposalFieldCategory.Team
  );
  static readonly Team: ProposalField = new ProposalField(
    ProposalFieldName.Team,
    ProposalFieldCategory.Team
  );
  static readonly TeamLeader: ProposalField = new ProposalField(
    ProposalFieldName.TeamLeader,
    ProposalFieldCategory.Team
  );
  static TeamMember(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.Team,
      ProposalFieldCategory.Team,
      id
    );
  }

  static readonly FeeSchedule: ProposalField = new ProposalField(
    ProposalFieldName.FeeSchedule,
    ProposalFieldCategory.FeeSchedule
  );
  static FeeScheduleCategory(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.FeeSchedule,
      ProposalFieldCategory.FeeSchedule,
      id
    );
  }

  static readonly WaiveConflictsCheck: ProposalField = new ProposalField(
    ProposalFieldName.WaiveConflictsCheck,
    ProposalFieldCategory.Conflicts
  );
  static readonly Conflicts: ProposalField = new ProposalField(
    ProposalFieldName.Conflicts,
    ProposalFieldCategory.Conflicts
  );
  static ConflictsDocument(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.Conflicts,
      ProposalFieldCategory.Conflicts,
      id
    );
  }

  static readonly ClientPolicies: ProposalField = new ProposalField(
    ProposalFieldName.ClientPolicies,
    ProposalFieldCategory.Policies
  );
  static ClientPolicyDocument(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.ClientPolicies,
      ProposalFieldCategory.Policies,
      id
    );
  }
  static readonly VendorPolicies: ProposalField = new ProposalField(
    ProposalFieldName.VendorPolicies,
    ProposalFieldCategory.Policies
  );
  static VendorPolicyDocument(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.VendorPolicies,
      ProposalFieldCategory.Policies,
      id
    );
  }

  static readonly Discount: ProposalField = new ProposalField(
    ProposalFieldName.Discount,
    ProposalFieldCategory.Discount
  );

  private constructor(
    public readonly name: ProposalFieldName,
    public readonly category: ProposalFieldCategory,
    public readonly id?: Guid
  ) {}

  public isEqualTo(field?: ProposalField): boolean {
    if (!field) return false;
    return (
      this.name === field.name &&
      (this.id?.isEqualTo(field.id) || (!this.id && !field.id))
    );
  }
  public toJSON(): object {
    return {
      name: this.name,
      category: this.category.toString(),
      id: this.id?.value,
    };
  }

  public static fromObject(obj: any): ProposalField {
    return new ProposalField(
      obj.name,
      obj.category,
      obj.id ? new Guid(obj.id) : undefined
    );
  }

  public toString(): string {
    return this.name.toString();
  }
}
