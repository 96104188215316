import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import Session from "users/session/session";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import MarketplaceSearchInfoAPIResponse from "marketplace/api/response-contracts/marketplace-search-info-api-response";
import MarketplaceSearchAPIRequest from "marketplace/api/request-contracts/marketplace-search-api-request";
import SearchResults from "common/contracts/search-results";
import MarketplaceForumSearchAPIRequest from "marketplace/api/request-contracts/marketplace-forum-search-api-request";
import MarketplaceForumResultAPIResponse from "marketplace/api/response-contracts/marketplace-forum-result-api-response";
import Forum from "messaging/entities/forum/forum";

export default class MarketplaceAPIService {
  private readonly headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken) 
      throw new Error("Cannot create MarketplaceAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async searchAll(search: MarketplaceSearchAPIRequest, abortController: AbortController): 
    Promise<SearchResults<MarketplaceSearchInfoAPIResponse>> {
    let params = new URLSearchParams();
    for (const [key, value] of Object.entries(search)) {
      if (value) {
        params.append(key, value.toString());
      }
    }
    const url = new URL(`/marketplace/all?${params.toString()}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const result = new SearchResults<MarketplaceSearchInfoAPIResponse>(response.data);
      return result;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new MarketplaceSearchError(search, error);
    }
  }

  async getMarketplaceForumsAsync(search: MarketplaceForumSearchAPIRequest[], abortController: AbortController): Promise<Forum[]> {
    const url = new URL(`/marketplace/forums/search`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {
          headers: this.headers
            .concat({'Content-Type': 'application/json'}),
          signal: abortController?.signal,
          data: search
        }
      );
      const result = Array<Forum>();
      response.data.forEach((forum: MarketplaceForumResultAPIResponse) => {
        result.push(forum.deserialize());
      });
      return result;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 400:
        case 403: throw new Error(`Unable to get marketplace forums. ${error.response.data}`);
        default: throw error;
      }
    }
  }
}

export class MarketplaceSearchError extends Error {
  search: MarketplaceSearchAPIRequest;
  error: any;
  constructor(search: MarketplaceSearchAPIRequest, error: any) {
    super(`Unable to search marketplace with ${JSON.stringify(search)}.`);
    this.search = search;
    this.error = error;
  }
}