import Forum from "messaging/entities/forum/forum";

export default class CreateForumAPIRequest {
    name?: string;
    entityClass?: string;
    entityId?: string;
    context?: string;
    subscribers: string[];

    constructor(forum: Forum) {
        this.name = forum.name?.toString();
        this.entityClass = forum.topic?.entityClass?.toString();
        this.entityId = forum.topic?.entityId?.toString();
        this.context = forum.topic?.context?.toString();
        this.subscribers = forum.subscriberIds ?
            forum.subscriberIds.map((s) => s.toString()) :
            [];
    }
}
