import { FormControl, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { NumberFormatValues, NumericFormat, SourceInfo } from "react-number-format";

const Container = styled(FormControl)(({ theme }) => ({
  maxWidth: '100%',
  minWidth: '33%'
}));

type CompanyProfileNumEmployeesInputProps = {
  className?: string;
  numEmployees?: string;
  onChange: (numEmployees: string) => void;
};

export default function CompanyProfileNumEmployeesInput(props: Readonly<CompanyProfileNumEmployeesInputProps>) {
  const { className, onChange } = props;

  const [numEmployees, setNumEmployees] = useState<string>(props.numEmployees ?? '');

  const hasInvalidNumEmployees = (): boolean => {
    return numEmployees.length > 0 && (isNaN(Number(numEmployees)) || Number(numEmployees) < 0);
  }

  return (
    <Container className={className}>
      <NumericFormat
        id="numEmployees"
        variant="outlined"
        customInput={TextField}
        label="Number of Employees"
        value={numEmployees}
        type='text'
        thousandSeparator
        error={hasInvalidNumEmployees()}
        helperText={hasInvalidNumEmployees() ? "Invalid number of employees" : ""}
        onValueChange={(values: NumberFormatValues, sourceInfo: SourceInfo) => {
          setNumEmployees(values.value);
          onChange(values.value);
        }}
      />
    </Container>
  );
}
