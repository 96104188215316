import Guid from "common/values/guid/guid";
import UserOpenEntityAgreement from "legal-entities/entities/entity-agreement/user-open-entity-agreement";
import EntityName from "legal-entities/values/entity-name/entity-name";

export default class UserOpenEntityAgreementAPIResponse {
    id?: string;
    entityName?: string;

    deserialize(): UserOpenEntityAgreement {
        if(!this.id || !this.entityName) throw new Error("Invalid user open entity agreement response.");
        return new UserOpenEntityAgreement(
            new Guid(this.id), 
            new EntityName(this.entityName)
        );
    }

}
