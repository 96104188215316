import Guid from "common/values/guid/guid";

import EntityName from "legal-entities/values/entity-name/entity-name";
import User from "users/entities/user/user";

export default class LegalEntity {
    id: Guid;
    name?: EntityName;
    _managerIds: Guid[] = [];
    isVendor?: boolean;
    isClient?: boolean;

    constructor(id: Guid, name?: EntityName, isClient?: boolean, isVendor?: boolean) {
        this.id = id;
        this.name = name;
        this.isClient = isClient;
        this.isVendor = isVendor;
    }

    public hasManager(user: User | null): boolean {
        if(!user?.id) return false;
        return this._managerIds.some(id => id.isEqualTo(user.id));
    }

}

export class LegalEntityNotFoundError extends Error {
    constructor(id: Guid) {
        super(`Legal entity with id ${id} not found.`);
    }
}