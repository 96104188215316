import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";

export default class UserProfile {
    public readonly id: Guid;
    public readonly fullName: Name;
    public readonly email?: EmailAddress | null | undefined;
    public readonly isClientRep: boolean;
    public readonly isVendorRep: boolean;

    constructor(id: Guid, fullName: Name, email: EmailAddress | null | undefined, isClientRep: boolean, isVendorRep: boolean) {
        this.id = id;
        this.fullName = fullName;
        this.email = email;
        this.isClientRep = isClientRep;
        this.isVendorRep = isVendorRep;
    }

    public static fromObject(object: any) {
        if (!object.id)
            throw new Error("User profile id is required");
        if (!object.fullName) {
            if (!object.firstName)
                throw new Error("User profile first name is required");
            if (!object.lastName)
                throw new Error("User profile last name is required");
        }

        const id = Guid.fromObject(object.id);
        const fullName = Name.fromObject(object.fullName || { firstName: object.firstName, lastName: object.lastName });
        const email = object.email ? EmailAddress.fromObject(object.email) : undefined;
        const isClientRep = object.isClientRep;
        const isVendorRep = object.isVendorRep;

        if (!id)
            throw new Error("User profile id is required");
        if (!fullName)
            throw new Error("User profile full name is required");

        return new UserProfile(id, fullName, email ?? null, isClientRep, isVendorRep);
    }

    public toJSON() {
        return {
            id: this.id.toJSON(),
            fullName: this.fullName.toJSON(),
            email: this.email?.toJSON(),
            isClientRep: this.isClientRep,
            isVendorRep: this.isVendorRep
        };
    }
}
