import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import { Moment } from "moment";
import TeamName from "work/values/team-name/team-name";

export default class TeamTemplate {
  id?: Guid;
  created?: Moment;
  name: TeamName;
  leader: Individual;
  memberUserIds: Guid[] = [];
  marketplaceId?: Guid;
  avatarId?: Guid;
  isExpanded: boolean = false;
  members: Individual[] = [];

  constructor(name: TeamName, leader: Individual) {
    this.name = name;
    this.leader = leader;
  }

  public userIsReferenced(userId?: Guid): boolean {
    if (!userId) {
      return false;
    }
    return (
      this.leader.userId.isEqualTo(userId) ||
      this.memberUserIds.some((memberUserId) => memberUserId.isEqualTo(userId))
    );
  }
}
