import { FileCopy, SettingsAccessibility } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group";
import InboxIcon from "@mui/icons-material/Inbox";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TodayIcon from "@mui/icons-material/Today";
import WorkIcon from "@mui/icons-material/Work";
import { useMediaQuery } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { Theme } from "@mui/system";
import ipahMarketplaceLogo from "app/assets/ipah_marketplace_logo.svg";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSession } from "users/session/session-context";

const NavDrawer = styled(Drawer)(({ theme }) => ({
  width: "17rem",
  "& .MuiDrawer-paper": {
    paddingTop: theme.spacing(2),
    width: "17rem",
  },
}));
const NavContainer = styled("div")<{ temporary: boolean }>((props) => ({
  height: "100%",
  overflow: "auto",
  marginTop: props.temporary ? props.theme.spacing(6) : props.theme.spacing(8),
  position: "relative",
}));
const MarketplaceLink = styled(Link)((props) => ({
  opacity: 0.6,
  textAlign: "center",
  transition: "opacity 0.5s ease-out",
  "&:hover": {
    opacity: 1.0,
  },
  "& img": {
    margin: props.theme.spacing(2, "auto"),
    width: "75%",
    [props.theme.breakpoints.down("sm")]: {
      width: "66%",
    },
  },
}));

type SideNavProps = {
  showNav: boolean;
  navMenuClosed: () => void;
}

export default function SideNav(props: Readonly<SideNavProps>) {
  const [navOpen, setNavOpen] = React.useState(props.showNav);
  const [invoiceMenuOpen, setInvoiceMenuOpen] = React.useState(false);
  const [marketplaceMenuOpen, setMarketplaceMenuOpen] = React.useState(false);

  const location = useLocation();
  const session = useSession();
  const showTemporaryDrawer = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  function handleMenuExpanderClick(menuName: string) {
    if (menuName === "invoicing") setInvoiceMenuOpen(!invoiceMenuOpen);
    if (menuName === "marketplace")
      setMarketplaceMenuOpen(!marketplaceMenuOpen);
  }

  useEffect(() => {
    setNavOpen(props.showNav);
  }, [props.showNav]);

  useEffect(() => {
    const showInvoiceMenu = location.pathname.startsWith("/invoicing");
    setInvoiceMenuOpen(showInvoiceMenu);

    const showMarketplaceMenu = location.pathname.startsWith("/marketplace");
    setMarketplaceMenuOpen(showMarketplaceMenu);
  }, [location.pathname]);

  function handleNavClose() {
    setNavOpen(false);
    props.navMenuClosed();
  }

  function drawer(temporary: boolean) {
    return (
      <NavDrawer
        variant={temporary ? "temporary" : "permanent"}
        anchor={temporary ? "right" : "left"}
        open={navOpen}
        onClose={() => {
          setNavOpen(false);
          props.navMenuClosed();
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
      >
        <NavContainer temporary={temporary}>
          <List>
            <ListItemButton
              to="/dashboard"
              selected={location.pathname === "/dashboard"}
              component={Link}
              onClick={handleNavClose}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            {session.user?.companyEntityId && (
              <>
                <ListItemButton
                  to="/communications"
                  selected={location.pathname.startsWith("/communications")}
                  component={Link}
                  onClick={handleNavClose}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Communications" />
                </ListItemButton>
                <ListItemButton
                  to="/work"
                  selected={location.pathname.startsWith("/work")}
                  component={Link}
                  onClick={handleNavClose}
                >
                  <ListItemIcon>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Work" />
                </ListItemButton>
                <ListItemButton
                  to="/proposals"
                  selected={location.pathname.startsWith("/proposals")}
                  component={Link}
                  onClick={handleNavClose}
                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Proposals" />
                </ListItemButton>
                {session.user?.isCompanyManager && (
                  <>
                    <ListItemButton
                      to="/teams"
                      selected={location.pathname.startsWith("/teams")}
                      component={Link}
                      onClick={handleNavClose}
                    >
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Teams" />
                    </ListItemButton>
                    <ListItemButton
                      to="/fee-schedules"
                      selected={location.pathname.startsWith("/fee-schedules")}
                      component={Link}
                      onClick={handleNavClose}
                    >
                      <ListItemIcon>
                        <AccountBalanceIcon />
                      </ListItemIcon>
                      <ListItemText primary="Fee Schedules" />
                    </ListItemButton>
                  </>
                )}
                <ListItemButton
                  to="/contracts"
                  selected={location.pathname.startsWith("/contracts")}
                  component={Link}
                  onClick={handleNavClose}
                >
                  <ListItemIcon>
                    <FileCopy />
                  </ListItemIcon>
                  <ListItemText primary="Contracts" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => handleMenuExpanderClick("marketplace")}
                >
                  <ListItemIcon>
                    <StorefrontIcon />
                  </ListItemIcon>
                  <ListItemText primary="Marketplace" />
                  {marketplaceMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={marketplaceMenuOpen}
                  timeout="auto"
                  unmountOnExit
                  sx={{ backgroundColor: "#FAFAFA", pl: 2 }}
                >
                  <List component="div" disablePadding>
                    <ListItemButton
                      to="/marketplace"
                      selected={
                        RegExp(/\/marketplace[^/]*$/).exec(
                          location.pathname
                        ) !== null
                      }
                      component={Link}
                      onClick={handleNavClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary="Search Listings" />
                    </ListItemButton>
                    <ListItemButton
                      to="/marketplace/bookmarks"
                      selected={location.pathname.startsWith(
                        "/marketplace/bookmarks"
                      )}
                      component={Link}
                      onClick={handleNavClose}
                    >
                      <ListItemIcon>
                        <BookmarksOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Bookmarks" />
                    </ListItemButton>
                    <ListItemButton
                      to="/marketplace/profiles"
                      selected={location.pathname.startsWith(
                        "/marketplace/profiles"
                      )}
                      component={Link}
                      onClick={handleNavClose}
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Your Profiles" />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton
                  to="/network"
                  selected={location.pathname.startsWith("/network")}
                  component={Link}
                  onClick={handleNavClose}
                >
                  <ListItemIcon>
                    <AccountTreeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Network" />
                </ListItemButton>
                <ListItemButton
                  to="/calendar"
                  selected={location.pathname.startsWith("/calendar")}
                  component={Link}
                  onClick={handleNavClose}
                >
                  <ListItemIcon>
                    <TodayIcon />
                  </ListItemIcon>
                  <ListItemText primary="Calendar" />
                </ListItemButton>
              </>
            )}
            {session.user?.isCompanyManager && (
              <ListItemButton
                to="/administration"
                selected={location.pathname.startsWith("/administration")}
                component={Link}
                onClick={handleNavClose}
              >
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Administration" />
              </ListItemButton>
            )}
            {session?.user?.isSuperUser && (
              <ListItemButton
                to="/super-user"
                selected={location.pathname.startsWith("/super-user")}
                component={Link}
                onClick={handleNavClose}
              >
                <ListItemIcon>
                  <SettingsAccessibility />
                </ListItemIcon>
                <ListItemText primary="Superuser Dashboard" />
              </ListItemButton>
            )}
            <ListItemButton
              href="mailto:support@attorneyhub.com"
              target="_top"
              component="a"
            >
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItemButton>
          </List>
        </NavContainer>
        <MarketplaceLink to="/marketplace">
          <img src={ipahMarketplaceLogo} alt="Attorney Hub Marketplace" />
        </MarketplaceLink>
      </NavDrawer>
    );
  }

  return <React.Fragment>{drawer(showTemporaryDrawer)}</React.Fragment>;
}
