import React, { useEffect } from "react";
import { Box, Icon, Link, styled, Typography } from "@mui/material";
import UserNetworkConnectionAPIService from "users/entities/user-network-connection/api/user-network-connection-api-service";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import { useSession } from "users/session/session-context";
import { CanceledError } from "axios";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import IndividualAvatar from "marketplace/view/individual-avatar";
import Guid from "common/values/guid/guid";

const NameCellContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  marginLeft: theme.spacing(1.5),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

type NetworkedIndividualsProps = {
  selectedTeamMemberUserIds?: Guid[];
  onSelected: (rowData: NetworkedUserInfo) => void;
};

export default function NetworkedIndividuals(
  props: Readonly<NetworkedIndividualsProps>
) {
  const { 
    selectedTeamMemberUserIds,
    onSelected 
  } = props;

  const columnHelper = createMRTColumnHelper<NetworkedUserInfo>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [data, setData] = React.useState<NetworkedUserInfo[]>([]);
  const [rowCount, setRowCount] = React.useState(0);

  const session = useSession();

  function renderNameColumn(networkConnection: NetworkedUserInfo) {
    if (!networkConnection.name) return <Icon>remove</Icon>;
    return (
      <NameCellContainer>
        <IndividualAvatar
          avatarId={networkConnection.avatarId}
          individualId={networkConnection.individualId}
          session={session}
        />
        <RowTitle>{networkConnection.name.toString()}</RowTitle>
      </NameCellContainer>
    );
  }

  function renderEmailColumn(networkConnection: NetworkedUserInfo) {
    if (!networkConnection.email) return <Icon>remove</Icon>;
    const email = networkConnection.email.toString();
    return <Link href={`mailto:${email}`}>{email}</Link>;
  }

  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
      id: "name",
    }),
    columnHelper.accessor(renderEmailColumn, {
      header: "Email",
      id: "email",
    }),
  ];

  useEffect(() => {
    let abortController = new AbortController();
    fetchData(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  const fetchData = async (abortController: AbortController) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const userNetworkService = new UserNetworkConnectionAPIService(session);
      const connections = await userNetworkService.getUserConnectionsInfo(
        abortController
      );

      setData(connections);
      setRowCount(connections.length);
      session.updateNetworkConnections(connections);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setErrorLoading(true);
      console.error(error);
      return;
    }
    setErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onSelected(row.original),
      pointerEvents: selectedTeamMemberUserIds?.some(id => row.original?.userId && id.isEqualTo(row.original.userId)) ? "none" : "auto",
      sx: { cursor: "pointer" },
    }),
    enableColumnPinning: true,
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: errorLoading
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: errorLoading,
      showProgressBars: isRefetching,
      // sorting,
    },
  });

  return <MaterialReactTable table={table} />;
}
