import axios, { AxiosRequestConfig, AxiosResponse, Method, ResponseType } from "axios";
import * as Constants from "common/helpers/constants";

export type JsonPatchOperation = {
  op: string;
  path: string;
  value: any;
};

export default class AttorneyHubAPIService {
  static config: any = {
    headers: {
      'Content-Type': 'application/json'
    },
  };
  // ----------------------------------------------------------------------------
  // Getters
  // ----------------------------------------------------------------------------

  /** @returns {string} Returns the base URL to use for API calls */
  static get apiBaseUrl(): string {
    return Constants.apiBaseUrl;
  }

  // ----------------------------------------------------------------------------
  // Methods
  // ----------------------------------------------------------------------------

  /**
   * Sets the Authorization header for API calls with the given token
   * @param {string} authToken The token to use for the Authorization header
   */
  static setAuthHeader(authToken: string | null |undefined) {
    if (authToken) {
      AttorneyHubAPIService.addHeader("Authorization", `Bearer ${authToken}`);
    } else {
      AttorneyHubAPIService.removeHeader("Authorization");
    }
  }

  /**
   * Adds a header to the headers object
   * @param   {string} name   The name of the header to add
   * @param   {string} value  The value for the header
   * @returns {any} Returns the updated headers object
   */
  static addHeader(name: string, value: string): any {
    AttorneyHubAPIService.config.headers[name] = value;
  }

  /**
   * Adds a header to the headers object
   * @param   {string} name   The name of the header to add
   * @param   {string} value  The value for the header
   * @returns {any} Returns the updated headers object
   */
  static removeHeader(name: string): void {
    delete AttorneyHubAPIService.config.headers[name];
  }

  /**@deprecated service calls should use axios*/
  static apiCall(
    verb: Method,
    url: string,
    payload: any = {},
    headers: any = AttorneyHubAPIService.config.headers,
    responseType: ResponseType = "json"
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      method: verb,
      url: AttorneyHubAPIService.apiBaseUrl + url,
      data: payload,
      headers: headers,
      responseType: responseType
    };
    return axios(config);
  }
}
