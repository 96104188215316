import Money from "common/values/money/money";

export default class Fee {
    rate: Money;
    unit: string | null;

    constructor(rate: Money, unit: string | null) {
        this.rate = rate;
        this.unit = unit;
    }

    public isEqualTo(other: Fee | null): boolean {
        if (!other) return false;
        return (
            ((!this.rate && !other.rate) || !!this.rate?.isEqualTo(other.rate)) &&
            (this.unit === other.unit)
        );
    }

    public clone(): Fee {
        return new Fee(this.rate?.clone(), this.unit);
    }

    public fromObject(obj: any): Fee {
        if(!obj) throw new Error('Cannot create Fee from null object');
        return new Fee(
            Money.Prototype.fromObject(obj.rate),
            obj.unit,
        );
    }

    public static get Prototype(): Fee {
        return new Fee(Money.Prototype, '');
    }

    toString(): string {
        if (!this.rate) return '';
        return `${this.rate?.amount?.toString()} ${this.rate?.currency?.toString()} ${this.unit ? '/ ' + this.unit : 'fixed'}`;
    }

    public toJSON(): object {
        return {
            rate: this.rate?.toJSON(),
            unit: this.unit
        };
    }


}
