import { TextField, TextFieldVariants } from "@mui/material";
import { styled } from "@mui/material/styles";
import Name, { InvalidNameError } from "common/values/name/name";
import { forwardRef, useState, useEffect } from "react";

const InputField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    width: '100%'
  },
  marginBottom: theme.spacing(1.5),
  minWidth: theme.spacing(40),
  ":first-of-type": {
    marginRight: theme.spacing(1.5)
  }
}));

export interface NameInputError extends String { };

type NameInputProps = {
  idPrefix?: string;
  className?: string;
  variant?: TextFieldVariants;
  name?: Name;
  onChange?: (name?: Name, error?: NameInputError) => void;
};

const NameInput = forwardRef((props: NameInputProps, ref) => {
  const { idPrefix, className, variant, name, onChange } = props;
  
  const [firstNameFieldText, setFirstNameFieldText] = useState<string>(name?.firstName ?? '');
  const [lastNameFieldText, setLastNameFieldText] = useState<string>(name?.lastName ?? '');
  const [nameFieldError, setNameFieldError] = useState<NameInputError>('');

  useEffect(() => {
    setFirstNameFieldText(name?.firstName ?? '');
    setLastNameFieldText(name?.lastName ?? '');
  }, [name]);

  function buildName(firstName?: string, lastName?: string): Name | undefined {
    try {
      if (!firstName && !lastName) {
        throw new InvalidNameError(
          "First name and last name are required",
          firstName ?? '',
          lastName ?? ''
        );
      }
      return new Name(firstName, lastName);
    } catch (error: any) {
      if (error instanceof InvalidNameError) {
        throw error;
      }
    }
  }

  function handleNameChanged(firstName?: string, lastName?: string) {
    let newName: Name | undefined;
    let newErrors: NameInputError = '';

    try {
      newName = buildName(firstName, lastName);
      setNameFieldError('');
    } catch (error: any) {
      newErrors = error.message;
      setNameFieldError(newErrors);
    } finally {
      onChange?.(newName, newErrors);
    }
  }

  return (
    <div>
      <InputField
        id={idPrefix ? idPrefix + "-first-name-input" : "first-name-input"}
        className={className}
        label="First Name"
        onChange={(event) => {
          setFirstNameFieldText(event.target.value);
          handleNameChanged(event.target.value, lastNameFieldText);
        }}
        required
        value={firstNameFieldText}
        error={!!nameFieldError}
        helperText={nameFieldError}
        variant={variant}
        autoComplete="given-name"
      />
      <InputField
        id={idPrefix ? idPrefix + "-last-name-input" : "last-name-input"}
        className={className}
        label="Last Name"
        onChange={(event) => {
          setLastNameFieldText(event.target.value);
          handleNameChanged(firstNameFieldText, event.target.value);
        }}
        required
        value={lastNameFieldText}
        error={!!nameFieldError}
        helperText={nameFieldError}
        variant={variant}
        autoComplete="family-name"
      />
    </div>
  );
});

export default NameInput;
