import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import InfoIcon from "@mui/icons-material/Info";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import {
  Badge,
  Button,
  ButtonGroup,
  Drawer,
  IconButton,
  Portal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { HubName, useRealtime } from "app/providers/realtime";
import { CanceledError } from "axios";
import Loader from "common/components/loader";
import LoadingButton from "common/components/loading-button";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import MessagingAPIService from "messaging/api/messaging-api-service";
import Forum from "messaging/entities/forum/forum";
import MessageAPIResponse from "messaging/entities/message/api/response-contracts/message-api-response";
import Message from "messaging/entities/message/message";
import Topic from "messaging/values/topic";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";
import Session from "users/session/session";
import { useSession } from "users/session/session-context";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import ProposalAPIService, {
  ProposalUpdateError,
} from "work/entities/proposal/api/proposal-api-service";
import ConflictsTab from "work/entities/proposal/draft/view/tabs/conflicts-tab";
import DetailsTab from "work/entities/proposal/draft/view/tabs/details-tab";
import DiscountTab from "work/entities/proposal/draft/view/tabs/discount-tab";
import FeeScheduleTab from "work/entities/proposal/draft/view/tabs/fee-schedule-tab";
import PoliciesTab from "work/entities/proposal/draft/view/tabs/policies-tab";
import TeamTab from "work/entities/proposal/draft/view/tabs/team-tab";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import {
  Audience,
  TopicContext,
} from "work/entities/proposal/proposal-forum-topic-context";

import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import Comments from "work/entities/proposal/view/comments/comments";
import { HumanReadableProposalFieldName, ProposalFieldName } from "work/values/constants";
import ProjectName from "work/values/project-name/project-name";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import ProposalIssuesBadge from "work/values/proposal-issues/view/proposal-issues-badge";
import ProposalReviewer from "work/values/proposal-reviewer";
import SelectedRepresentative from "work/values/selected-representative/selected-representative";
import SelectedTeam from "work/values/selected-team/selected-team";
import Team from "work/values/team/team";
import ReviewerSelector from "work/view/components/reviewer-selector";

const Header = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(1),
  position: "sticky",
  top: "0px",
  zIndex: 10,
}));
const TabsContainer = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    overflow: "hidden",
    "& .MuiTabs-scroller": {
      paddingTop: theme.spacing(0.5),
    },
  },
}));
const Content = styled("section")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
}));
const TabContent = styled("section")(({ theme }) => ({
  alignItems: "stretch",
  flexDirection: "column",
  display: "flex",
  flex: 1,
  margin: theme.spacing(2, 0),
  overflow: "visible",
  width: "100%",
}));
const ActionsContainer = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  backgroundColor: theme.palette.background.default,
  bottom: "0px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  paddingBottom: theme.spacing(2.5),
  position: "sticky",
  width: "100%",
  zIndex: 10,
}));
const SecondaryActions = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  width: "100%",
}));
const ProposalActions = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexWrap: "nowrap",
  },
  alignContent: "end",
  alignItems: "center",
  display: "grid",
  flex: 1,
  gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))",
  gap: theme.spacing(1),
  minHeight: "64px",
}));
const NavButtons = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  width: "initial",
  "& > Button": {
    width: "100%",
  },
}));
const ProposalActionSpan = styled("span")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  minWidth: theme.spacing(24),
}));
const ProposalActionButton = styled(LoadingButton)(({ theme }) => ({
  width: "100%",
  whiteSpace: "nowrap",
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26) !important",
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
  },
}));
const DialogTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    overflow: "visible",
  },
}));
const MessageButtons = styled("section")(({ theme }) => ({
  alignContent: "center",
  marginLeft: theme.spacing(2),
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
}));
const MessageButton = styled(IconButton)(({ theme }) => ({
  paddingBottom: 0,
}));
const MessageButtonLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.6em",
}));
const SidePanel = styled(Drawer)(({ theme }) => ({
  position: "fixed",
  zIndex: theme.zIndex.modal + 1,
}));
const SidePanelContainer = styled("div")(({ theme }) => ({
  flex: 1,
  height: "100%",
}));
const SidePanelContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(2),
  width: "400px",
}));
const TitleBar = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  paddingBottom: theme.spacing(1),
}));

type ProposalDraftDialogProps = {
  proposal?: Proposal;
  tab?: ProposalFieldCategory;
  disableEditing?: boolean;
  isReviewing?: boolean;
  preSelectedRep?: SelectedRepresentative;
  preSelectedTeam?: SelectedTeam;
  onDirtyChange?: (isDirty: boolean) => void;
  onProposalSaved?: (proposal: Proposal) => void;
  onProposalSubmitted?: (proposal: Proposal) => void;
  onRefreshStaleProposalDialog?: (revisedDraft: Proposal) => void;
};

export default function ProposalDraftDialog(
  props: Readonly<ProposalDraftDialogProps>
) {
  const {
    tab,
    isReviewing,
    preSelectedRep,
    preSelectedTeam,
    onDirtyChange,
    onProposalSaved,
    onProposalSubmitted,
    onRefreshStaleProposalDialog,
  } = props;

  const [proposal, setProposal] = React.useState<Proposal | undefined>(
    props.proposal
  );

  const [disableEditing, setDisableEditing] = React.useState<boolean>(
    props.disableEditing ?? false
  );
  const [activeTab, setActiveTab] = React.useState<ProposalFieldCategory>(
    ProposalFieldCategory.Details
  );
  const [currentCommentField, setCurrentCommentField] = React.useState<
    ProposalField | undefined
  >();

  const [sidePanelTitle, setSidePanelTitle] = React.useState<
    string | undefined
  >();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingComments, setIsLoadingComments] =
    React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isCommentSaving, setIsCommentSaving] = React.useState<boolean>(false);
  const [isManagingReviewers, setIsManagingReviewers] =
    React.useState<boolean>(false);
  const [isApprovingReview, setIsApprovingReview] =
    React.useState<boolean>(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState<boolean>(false);
  const [isSelectingClient, setIsSelectingClient] =
    React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [reviewerSelectorAnchor, setReviewerSelectorAnchor] =
    React.useState<HTMLButtonElement>();

  const [commentForums, setCommentForums] = React.useState<Forum[]>([]);
  const [currentComments, setCurrentComments] = React.useState<Message[]>([]);
  const [pendingComments, setPendingComments] = React.useState<Message[]>([]);
  const [currentCommenters, setCurrentCommenters] = React.useState<
    Individual[]
  >([]);

  const [isStale, setIsStale] = React.useState<boolean>(false);
  const [revisedProposal, setRevisedProposal] = React.useState<Proposal>();
  const session = useSession();
  const individualAPIService = useMemo(() => {
    return new IndividualAPIService(session)
  }, [
    session,
  ]);
  const { openDialog, closeAllDialogs } = useDialog();
  const confirm = useConfirmDialog();
  const {
    connection: proposalHubConnection,
    startConnection: startProposalHubConnection,
  } = useRealtime(HubName.Proposal);
  const {
    connection: messageHubConnection,
    startConnection: startMessageHubConnection,
  } = useRealtime(HubName.Message);

  if(!session.user){
    console.warn("User not found in session");
    return null;
  }
  const proposalBuilder = React.useRef<ProposalBuilder>(
    new ProposalBuilder(session.user, props.proposal?._instance.spec)
  );
  const isDirty: boolean = (function () {
    onDirtyChange?.(proposalBuilder.current.isModified || pendingComments.length > 0);
    return proposalBuilder.current.isModified || pendingComments.length > 0;
  })();

  useEffect(() => {
    let abortController = new AbortController();

    initProposal(abortController);
    setActiveTab(tab ?? ProposalFieldCategory.Details);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  useEffect(() => {
    if (!proposalHubConnection) return;

    startProposalHubConnection()
      .then(() => {
        proposalHubConnection.on(
          "proposal-created",
          handleProposalCreatedNotification
        );
        proposalHubConnection.on(
          "proposal-updated",
          handleProposalUpdatedNotification
        );
        proposalHubConnection.on(
          "proposal-deleted",
          handleProposalDeletedNotification
        );
      })
      .catch((err) =>
        console.error("Realtime proposal hub connection failed: ", err)
      );
  }, [proposalHubConnection]);

  useEffect(() => {
    if (!messageHubConnection) return;

    startMessageHubConnection()
      .then(() => {
        messageHubConnection.on("message-received", handleCommentReceived);
      })
      .catch((err) =>
        console.error("Realtime message hub connection failed: ", err)
      );
  }, [messageHubConnection]);

  async function handleCommentReceived(newMessage: MessageAPIResponse) {
    if (isLoadingComments || !proposal?.id) return;

    if (newMessage.senderId?.valueOf === session.user?.id?.value) return;
    if (newMessage.forum?.entityClass !== "Work.Proposal") return;
    if (newMessage.forum?.entityId !== proposal.id.value) return;

    const comment = newMessage.deserialize();

    const forum = commentForums.find((forum) =>
      forum.topic?.isEqualTo(comment.forum.topic)
    );
    let updatedForums = [...commentForums];
    if (!forum) {
      updatedForums = [...commentForums, comment.forum];
      setCommentForums(updatedForums);
    }

    if (currentCommentField) {
      loadCommentsForField(currentCommentField, false, updatedForums);
    }
  }

  async function handleProposalCreatedNotification(newDraft: Proposal) {
    // isSaving and isSubmitting might not have been set due to race conditions...
    if (isSaving || isSubmitting) return;
    if (!newDraft.id || !proposal?.id) return;
    if (newDraft.id.value !== proposal.id.value) return;
    if (
      !moment(newDraft.lastUpdated?.value).isAfter(
        moment(proposal.lastUpdated?.value)
      )
    )
      return;

    const latestProposal = await getLatestDraftProposal();
    setDisableEditing(true);
    setRevisedProposal(latestProposal ?? proposal);
    setIsStale(true);
  }

  async function handleProposalUpdatedNotification(updatedDraft: Proposal) {
    if (!updatedDraft.id || !proposal?.id) return;
    if (updatedDraft.id.value !== proposal.id.value) return;
    if (
      !moment(updatedDraft.lastUpdated?.value).isAfter(
        moment(proposal.lastUpdated?.value)
      )
    )
      return;

    const latestProposal = await getLatestDraftProposal();
    setDisableEditing(true);
    setRevisedProposal(latestProposal ?? proposal);
    setIsStale(true);
  }

  async function handleProposalDeletedNotification(deletedDraft: Proposal) {
    if (!deletedDraft.id || !proposal?.id) return;
    if (deletedDraft.id.value !== proposal.id.value) return;

    setRevisedProposal(undefined);
    setIsStale(true);
  }

  async function getLatestDraftProposal(): Promise<Proposal | undefined> {
    if (!proposal?.id) return;
    try {
      const proposalService = new ProposalAPIService(session);
      const latestProposal = await proposalService.getProposalById(proposal.id);
      return latestProposal;
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Error getting latest draft proposal", {
        variant: "error",
      });
    }
  }

  async function initProposal(abortController: AbortController) {
    if (!proposal?.id && preSelectedRep) {
      buildProposalWithPreselectedRep(preSelectedRep);
      return;
    }

    try {
      setIsLoading(true);

      if (!proposal?.id && preSelectedTeam) {
        await buildProposalWithPreselectedTeam(
          preSelectedTeam,
          abortController
        );
        return;
      }
      if (!proposal?.id) return;

      let forumProposal: Proposal = proposal;
      if (!proposal?.isDetailed) {
        const proposalService = new ProposalAPIService(session);
        const detailedProposal = await proposalService.getProposalById(
          proposal.id,
          abortController
        );

        forumProposal = detailedProposal;
        setProposal(detailedProposal);
      }

      if (!forumProposal.id) {
        console.warn("Proposal does not have an ID");
        return;
      }

      initForums(forumProposal.id, abortController);
    } catch (error: any) {
      if (console instanceof CanceledError) return;
      console.error(error);
    } finally {
      if (!abortController.signal.aborted) {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (!session?.canSwitchContext || !proposal) {
      return;
    }

    if (
      session.context?.viewingAsVendor &&
      proposal.client?.userId.isEqualTo(session.user?.id)
    ) {
      session.setAccountViewType(AccountType.Client);
    } else if (
      proposal.team?.leader?.userId.isEqualTo(session.user?.id) ||
      proposal.team?.memberUserIds?.some((member) =>
        member.isEqualTo(session.user?.id)
      )
    ) {
      session.setAccountViewType(AccountType.Vendor);
    }
  }, [proposal]);


  function buildProposalWithPreselectedRep(
    preSelectedRep: SelectedRepresentative
  ) {
    if (preSelectedRep.isClientRep) {
      const clientRep = new EntityClientRepresentative(
        preSelectedRep.userId,
        preSelectedRep.entityId,
        preSelectedRep.name
      );
      proposalBuilder.current.setClient(clientRep);
      proposalBuilder.current = proposalBuilder.current.clone();
    } else {
      const leader = new EntityVendorRepresentative(
        preSelectedRep.userId,
        preSelectedRep.entityId
      );
      proposalBuilder.current.setName(new ProjectName("New Proposal"));
      proposalBuilder.current.setTeam(new Team(leader, []));
      proposalBuilder.current = proposalBuilder.current.clone();
    }
  }

  async function buildProposalWithPreselectedTeam(
    preSelectedTeam: SelectedTeam,
    abortController: AbortController
  ) {
    try {
      const marketplaceTeamAPIService = new MarketplaceTeamAPIService(session);
      const team = await marketplaceTeamAPIService.getTeamById(
        preSelectedTeam.targetTeamId,
        abortController
      );
      if (!team.leader?.userId || !team.leader?.entityId) return;
      let updatedProposalBuilder = proposalBuilder.current.clone();
      updatedProposalBuilder.setName(new ProjectName("New Proposal"));
      const leader = new EntityVendorRepresentative(
        team.leader.userId,
        team.leader.entityId
      );
      const members: Guid[] = [];
      for (const member of team.memberships) {
        if (member.userId) {
          members.push(member.userId);
        }
      }
      updatedProposalBuilder.setTeam(new Team(leader, members, team.id));
      proposalBuilder.current = updatedProposalBuilder;
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
  }

  async function initForums(
    proposalId: Guid,
    abortController: AbortController
  ) {
    try {
      const messageService = new MessagingAPIService(session);
      const forums = await messageService.getForums(
        "Work.Proposal",
        proposalId,
        "Review",
        abortController
      );
      setCommentForums(forums);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
  }

  async function saveProposal(): Promise<Proposal> {
    let draftProposal: Proposal | undefined;
    setIsSaving(true);

    try {
      if (!proposal?.id) {
        draftProposal = proposalBuilder.current.buildDraft(session);
        draftProposal = await draftProposal?.save(session);
        return draftProposal;
      }
      draftProposal = proposalBuilder.current?.updateProposal(
        proposal,
        session
      );
      draftProposal = await draftProposal?.save(session, proposal);
    } catch (error: any) {
      if (error instanceof ProposalUpdateError) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Failed to save draft", { variant: "error" });
      }
      console.error("Failed to save proposal draft: ", error);
    } finally {
      setIsSaving(false);
    }

    setProposal(draftProposal);
    if(!session.user){
      throw new Error("User not found in session");
    }
    proposalBuilder.current = new ProposalBuilder(
      session.user,
      draftProposal?._instance.spec
    );
    onDirtyChange?.(proposalBuilder.current.isModified || pendingComments.length > 0);
    if (!draftProposal) throw new Error("Failed to save draft proposal");
    return draftProposal;
  }

  function handleTabChange(
    _event: React.ChangeEvent<{}> | null,
    newTab: ProposalFieldCategory
  ) {
    setActiveTab(newTab);
  }

  async function handlePrevNextClicked(direction: "previous" | "next") {
    const tabKeys = Object.keys(ProposalFieldCategory);
    const activeTabIndex = tabKeys.indexOf(activeTab);
    const targetTabKey =
      direction === "previous"
        ? tabKeys[activeTabIndex - 1]
        : tabKeys[activeTabIndex + 1];
    handleTabChange(
      null,
      ProposalFieldCategory[
        targetTabKey as keyof typeof ProposalFieldCategory
      ] ?? activeTab
    );
  }

  async function handleSaveClicked(): Promise<void> {
    try {
      setIsSaving(true);
      const dialogIsStale = !proposal?.id;

      const draftProposal = await saveProposal();
      onProposalSaved?.(draftProposal);

      await saveComments();

      if (dialogIsStale) {
        onRefreshStaleProposalDialog?.(draftProposal);
      }
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to save draft", { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  }

  function handleManageReviewersClicked(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    setReviewerSelectorAnchor(event.currentTarget);
    setIsManagingReviewers(true);
  }

  async function handleSubmitClicked() {
    try {
      const response = await confirm({
        title: "Submit Proposal",
        message: "Are you sure you want to submit this proposal?",
        okButtonText: "Submit",
      });

      if (response === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);

      const draftProposal = await saveProposal();
      if (!draftProposal) {
        throw new Error("Failed to save draft proposal prior to submission");
      }
      await saveComments();
      await draftProposal.submit(session.user?.id);
      onProposalSubmitted?.(draftProposal);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to submit proposal", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  function shouldDisableTabs(): boolean {
    return isLoading || isSaving || isManagingReviewers || isSelectingClient;
  }

  async function handleProposalSpecChanged() {
    await generateChangeAutoComments();
  }

  async function generateChangeAutoComments() {
    if (!proposalBuilder.current) return;

    let updatedPendingComments = [];
    let updatedCurrentComments = [...currentComments];
    updatedCurrentComments = updatedCurrentComments.filter((currentComment) => {
      return !pendingComments.some(
        (pendingComment) =>
          pendingComment.id?.value === currentComment.id?.value
      );
    });

    for (let specChange of proposalBuilder.current.sessionHistory) {
      if (!specChange.action) continue;
      if (!specChange.field) continue;
      if (!specChange.value) continue;

      const action = specChange.action;
      let originalValue: string | undefined = specChange.value;

      if ((specChange.field.name === ProposalFieldName.Team && specChange.field.id) || 
          specChange.field.name === ProposalFieldName.TeamLeader ) {
        originalValue = await getTeamMemberNameByUserId(
          new Guid(specChange.value)
        );
        if (!originalValue) continue;
      }

      const commentText = `
        <span className="action ${action?.actionDescription.toLowerCase()}">${
        action?.actionDescription
      }</span>: ${originalValue}
      `;

      const comment = postComment(commentText, false, specChange.autoCommentField ?? specChange.field, specChange.timeStamp);
      if (!comment) continue;
      if (currentCommentField?.isEqualTo(specChange.autoCommentField ?? specChange.field)) {
        updatedCurrentComments.push(comment);
      }
      updatedPendingComments.push(comment);
    }

    setPendingComments(updatedPendingComments);
    setCurrentComments(updatedCurrentComments);
  }

  async function getTeamMemberNameByUserId(userId: Guid) {
    try {
      const memberInfo = await individualAPIService.getUsersProfileInfo(
        [userId],
        new AbortController()
      );
      return memberInfo[0].getFullName();
    } catch (error: any) {
      console.error(error);
    }
  }

  async function handleToggleSidePanel(field?: ProposalField, name?: string) {
    setCurrentCommentField(field);
    setSidePanelTitle(name);

    if (!field || (isSidePanelOpen && field.isEqualTo(currentCommentField))) {
      setIsSidePanelOpen(false);
      adjustDialogPosition(true);
      return;
    }

    setIsSidePanelOpen(true);
    adjustDialogPosition(false);
    await loadCommentsForField(field, true, commentForums);
  }

  async function loadCommentsForField(
    field?: ProposalField,
    showLoader: boolean = true,
    forums?: Forum[]
  ) {
    setIsLoadingComments(showLoader);

    const matchingForums =
      forums?.filter((forum) => {
        if (!forum.topic?.context) return false;
        const context = TopicContext.fromObject(
          JSON.parse(forum.topic.context)
        );
        return context.field.isEqualTo(field);
      }) ?? [];

    const forumComments = await loadCommentsForForums(matchingForums);
    const forumCommenters = await loadCommenterInfoForForums(matchingForums);

    for (const pendingComment of pendingComments) {
      if (!pendingComment.forum.topic?.context) continue;

      try {
        const context = TopicContext.fromObject(
          JSON.parse(pendingComment.forum.topic.context)
        );

        if (context.field.isEqualTo(field)) {
          forumComments.push(pendingComment);
        }
      } catch (error: any) {
        continue;
      }
    }

    setCurrentComments(forumComments);
    setCurrentCommenters(forumCommenters);
    setIsLoadingComments(false);
  }

  async function loadCommentsForForums(forums: Forum[]): Promise<Message[]> {
    const comments: Message[] = [];
    const messageService = new MessagingAPIService(session);
    for (const forum of forums) {
      try {
        comments.push(...(await messageService.getMessagesByForum(forum)));
      } catch (error) {
        console.error(error);
        continue;
      }
    }

    return comments.sort((a, b) => {
      if (!a.publishedOn && !b.publishedOn) return 0;
      if (!a.publishedOn) return -1;
      if (!b.publishedOn) return 1;
      return a.publishedOn.diff(b.publishedOn);
    });
  }

  async function loadCommenterInfoForForums(
    forums: Forum[]
  ): Promise<Individual[]> {
    const commenters: Individual[] = [];
    const messageService = new MessagingAPIService(session);
    for (const forum of forums) {
      try {
        commenters.push(
          ...(await messageService.getForumSubscriberInfo(forum))
        );
      } catch (error) {
        console.error(error);
        continue;
      }
    }

    return commenters;
  }

  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName("MuiDialog-root")) {
      dialog.setAttribute(
        "style",
        `padding-right: ${
          panelOpen ? "0px" : "400px"
        }; transition: padding-right 225ms;`
      );
    }
  }

  function postComment(
    commentText: string,
    isExternal: boolean,
    field?: ProposalField,
    timeStamp?: moment.Moment
  ): Message | undefined {
    if (!proposal?.id || !session.user) return;

    const { audience, subscriberIds } = getCommentAudienceAndSubscriberIds(
      isExternal,
      proposal,
      session
    );
    const context = new TopicContext(
      audience,
      field ?? currentCommentField ?? ProposalField.General
    );
    let forum = getCommentForumFromContext(context);

    if (!forum) {
      forum = createForum(proposal.id, context, subscriberIds);
      if (!forum) {
        console.error("Failed to create forum");
        return;
      }
    }

    const newComment = Message.draft(
      forum,
      session.user,
      commentText,
      undefined,
      undefined,
      Guid.generate()
    );
    newComment.publishedOn = timeStamp ?? moment();
    newComment.markedForCreation = true;
    onDirtyChange?.(true);

    return newComment;
  }

  function handleUserCommentPosted(
    commentText: string,
    isExternal: boolean,
    field?: ProposalField,
  ) {
    const newComment = postComment(commentText, isExternal, field);
    if (!newComment) return;

    const updatedCurrentComments = [...currentComments];
    if (currentCommentField === field) updatedCurrentComments.push(newComment);
    setCurrentComments(updatedCurrentComments);

    const updatedPendingComments = [...pendingComments];
    updatedPendingComments.push(newComment);
    setPendingComments(updatedPendingComments);
  }

  function handleCommentEdited(
    messageId: Guid,
    editedText: string,
    isExternal: boolean,
    publishedOn?: moment.Moment
  ) {
    if (!proposal?.id || !session.user) return;

    const { audience, subscriberIds } = getCommentAudienceAndSubscriberIds(
      isExternal,
      proposal,
      session
    );
    const context = new TopicContext(
      audience,
      currentCommentField ?? ProposalField.General
    );
    let forum = getCommentForumFromContext(context);

    if (!forum) {
      forum = createForum(proposal.id, context, subscriberIds);
      if (!forum) {
        console.error("Failed to create forum for edited comment");
        return;
      }
    }

    const editedComment = Message.draft(
      forum,
      session.user,
      editedText,
      undefined,
      undefined,
      messageId
    );
    editedComment.publishedOn = publishedOn ?? moment();
    editedComment.markedForEdit = true;

    // Add or replace the edited comment in the pending comments
    let updatedPendingComments = [...pendingComments];
    const existingCommentIndex = updatedPendingComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (existingCommentIndex >= 0) {
      updatedPendingComments[existingCommentIndex] = editedComment;
    } else {
      updatedPendingComments.push(editedComment);
    }
    setPendingComments(updatedPendingComments);

    //update the matching current comment with the edited comment
    let updatedCurrentComments = [...currentComments];
    const existingCurrentCommentIndex = updatedCurrentComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (existingCurrentCommentIndex >= 0) {
      updatedCurrentComments[existingCurrentCommentIndex] = editedComment;
      setCurrentComments(updatedCurrentComments);
    }
  }

  async function handleCommentReadToggled(messageId: Guid) {
    if (!proposal?.id || !session.user) return;

    let updatedPendingComments = [...pendingComments];
    let updatedCurrentComments = [...currentComments];

    const commentCurrentIndex = updatedCurrentComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (commentCurrentIndex < 0) {
      console.error("Comment not found in current comments");
      return;
    }

    const comment = updatedCurrentComments[commentCurrentIndex];
    if (comment.senderId?.isEqualTo(session.user.id)) {
      console.warn("User cannot mark their own comment as read/unread");
      return;
    }

    if (
      (!comment.markedForReadReceipt &&
        !comment.isReadByUser(session.user?.id)) ||
      comment.markedForUnread
    ) {
      comment.setRead();
    } else {
      comment.setUnread();
    }

    updatedCurrentComments[commentCurrentIndex] = comment;
    setCurrentComments(updatedCurrentComments);

    const messagePendingIndex = pendingComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (messagePendingIndex >= 0) {
      updatedPendingComments = updatedPendingComments.filter(
        (c) => !c.id?.isEqualTo(messageId)
      );
    } else {
      updatedPendingComments.push(comment);
    }
    setPendingComments(updatedPendingComments);
  }

  async function handleCommentDeleted(messageId: Guid) {
    if (!proposal?.id || !session.user) return;

    let updatedPendingComments = [...pendingComments];
    let updatedCurrentComments = [...currentComments];

    const deletedCurrentIndex = updatedCurrentComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (deletedCurrentIndex < 0) return;

    const deletedComment = updatedCurrentComments[deletedCurrentIndex];

    if (deletedComment.isDeleted) {
      deletedComment.setUndeleted();
    } else {
      deletedComment.setDeleted();
    }

    const deletedPendingIndex = updatedPendingComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (deletedPendingIndex >= 0) {
      if (deletedComment.markedForCreation) {
        updatedPendingComments = updatedPendingComments.filter(
          (c) => !c.id?.isEqualTo(messageId)
        );
        setPendingComments(updatedPendingComments);
        updatedCurrentComments = updatedCurrentComments.filter(
          (c) => !c.id?.isEqualTo(messageId)
        );
        setCurrentComments(updatedCurrentComments);
        return;
      }
      updatedPendingComments[deletedPendingIndex] = deletedComment;
    } else {
      updatedPendingComments.push(deletedComment);
    }

    setPendingComments(updatedPendingComments);
    updatedCurrentComments[deletedCurrentIndex] = deletedComment;
    setCurrentComments(updatedCurrentComments);
  }

  async function saveComments(): Promise<void> {
    if (!session.user) return;

    try {
      setIsCommentSaving(true);
      const messageService = new MessagingAPIService(session);
      const response = await messageService.createBulkMessages(pendingComments);
      const numFailedComments = response.reduce(
        (acc, res) => acc + (res.issues.length > 0 ? 1 : 0),
        0
      );
      if (numFailedComments > 0) {
        enqueueSnackbar(`Failed to save ${numFailedComments} comment(s)`, {
          variant: "error",
        });
      }

      // Update the comments and forums with the newly-created ones
      let updatedCommentForums = commentForums.filter((f) => f.id);
      let updatedCurrentComments = [...currentComments];
      for (const comment of response) {
        const existingCommentForum = updatedCommentForums.find((f) =>
          f.topic?.isEqualTo(comment.forum.topic)
        );
        if (!existingCommentForum) {
          updatedCommentForums.push(comment.forum);
        }
        const currentCommentIndex = updatedCurrentComments.findIndex((c) =>
          c.id?.isEqualTo(comment.id)
        );
        if (currentCommentIndex >= 0) {
          updatedCurrentComments[currentCommentIndex] = comment;
        }
      }

      setCommentForums(updatedCommentForums);

      updatedCurrentComments = updatedCurrentComments.filter(
        (c) => !c.markedForDeletion
      );
      setCurrentComments(updatedCurrentComments);

      // Remove pending comments that were successfully saved and those locally deleted
      let updatedPendingComments = [
        ...pendingComments.filter((c) => !c.markedForDeletion && !c.isDeleted),
      ];
      for (const comment of pendingComments) {
        const responseComment = response.find((res) =>
          res.id?.isEqualTo(comment.id)
        );
        if (responseComment?.issues.length === 0) {
          updatedPendingComments = updatedPendingComments.filter(
            (c) => !c.id?.isEqualTo(comment.id)
          );
        }
      }

      setPendingComments(updatedPendingComments);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to save comments", { variant: "error" });
    } finally {
      setIsCommentSaving(false);
    }
  }

  function getCommentAudienceAndSubscriberIds(
    isExternal: boolean,
    proposal: Proposal,
    session: Session
  ): { audience: Audience; subscriberIds: Guid[] } {
    let audience: Audience;
    const subscriberIds: Guid[] = [];
    if (session.user?.id) subscriberIds.push(session.user.id);

    if (isExternal) {
      audience = Audience.AllReviewers;

      if (proposal.creator?.userId) subscriberIds.push(proposal.creator.userId);

      if (proposal.client?.userId) subscriberIds.push(proposal.client.userId);
      subscriberIds.push(
        ...proposal.clientReviewers.map((reviewer) => reviewer.userId)
      );

      if (proposal.team?.leader?.userId)
        subscriberIds.push(proposal.team.leader.userId);
      subscriberIds.push(
        ...proposal.vendorReviewers.map((reviewer) => reviewer.userId)
      );
    } else if (session.context?.viewingAsVendor) {
      audience = Audience.VendorReviewers;

      if (proposal.team?.leader?.userId)
        subscriberIds.push(proposal.team.leader.userId);
      subscriberIds.push(
        ...proposal.vendorReviewers.map((reviewer) => reviewer.userId)
      );
    } else {
      audience = Audience.ClientReviewers;

      if (proposal.client?.userId) subscriberIds.push(proposal.client.userId);
      subscriberIds.push(
        ...proposal.clientReviewers.map((reviewer) => reviewer.userId)
      );
    }
    return {
      audience,
      subscriberIds: _.uniqBy(subscriberIds, (id) => id.value),
    };
  }

  function getCommentForumFromContext(
    context: TopicContext
  ): Forum | undefined {
    for (const forum of commentForums) {
      if (!forum.topic?.context) continue;
      try {
        const forumContext = TopicContext.fromObject(JSON.parse(forum.topic.context));
        if (forumContext.isEqualTo(context)) return forum;
      } catch (error) {
        continue;
      }
    }
  }

  function createForum(
    proposalId: Guid,
    context: TopicContext,
    subscriberIds: Guid[]
  ): Forum | undefined {
    if (!session.user) return;

    const topic = new Topic(
      "Work.Proposal",
      proposalId,
      JSON.stringify(context.toJSON())
    );
    return new Forum(
      `Proposal ${
        context.audience
      } ${context.field.name.toString()} comment thread`,
      topic,
      subscriberIds
    );
  }

  function handleViewCommenterProfile(individualId?: Guid) {
    if (!individualId) return;

    openDialog({
      component: <ViewIndividualProfile individualId={individualId} />,
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
    setTimeout(() => adjustDialogPosition(false), 500);
  }

  async function handleApproveReviewClicked() {
    if (!proposal) throw new Error("No proposal to approve");

    try {
      const response = await confirm({
        title: "Approve Review",
        message: "Are you sure you want to approve this review?",
        okButtonText: "Approve",
      });

      if (response === ConfirmResponse.Cancel) return;

      setIsApprovingReview(true);
      const proposalService = new ProposalAPIService(session);
      await proposalService.giveReviewerApproval(proposal);
      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to approve review", { variant: "error" });
    } finally {
      setIsApprovingReview(false);
    }
  }

  async function handleProposalReviewersChanged(reviewers: ProposalReviewer[]) {
    const updatedProposalBuilder = proposalBuilder.current.clone();
    if (session.context?.viewingAsVendor) {
      updatedProposalBuilder.setVendorReviewers(reviewers);
    } else {
      updatedProposalBuilder.setClientReviewers(reviewers);
    }
    proposalBuilder.current = updatedProposalBuilder;
    await saveProposal();
  }

  function hasUserApprovedReview(): boolean {
    let builderReviewers: ProposalReviewer[] = [];
    let proposalReviewers: ProposalReviewer[] = [];
    let reviewers: ProposalReviewer[] = [];

    if (session.context?.viewingAsVendor) {
      builderReviewers =
        proposalBuilder.current.currentSpec.vendorReviewers ?? [];
      proposalReviewers = proposal?.vendorReviewers ?? [];
    } else {
      builderReviewers =
        proposalBuilder.current.currentSpec.clientReviewers ?? [];
      proposalReviewers = proposal?.clientReviewers ?? [];
    }

    reviewers = builderReviewers.concat(proposalReviewers);

    return reviewers.some(
      (reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id) && reviewer.dateApproved
    );
  }

  function getSidePanelTitle(): string {
    if (sidePanelTitle) return `${sidePanelTitle} Comments`;
    return `${
      HumanReadableProposalFieldName[
        currentCommentField?.name ?? ProposalField.General.name
      ]
    } Comments`;
  }

  function handleRefreshClicked() {
    if (!proposal?.id) return;
    if (!revisedProposal?.id) {
      closeAllDialogs();
      return;
    }

    onRefreshStaleProposalDialog?.(revisedProposal);
  }

  const issues = ProposalIssues.fromBuilder(proposalBuilder.current);
  return (
    <>
      {isStale && (
        <ActionsContainer>
          <ProposalActionButton
            variant="contained"
            color={revisedProposal ? "info" : "error"}
            startIcon={<InfoIcon />}
            loading={false}
            onClick={handleRefreshClicked}
          >
            {`This draft proposal has been ${
              revisedProposal ? "updated" : "deleted"
            }, click to ${revisedProposal ? "refresh" : "exit"}`}
          </ProposalActionButton>
        </ActionsContainer>
      )}
      <Header>
        <TabsContainer
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}
        >
          <DialogTab
            value={ProposalFieldCategory.Details}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Details}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Team}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Team}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.FeeSchedule}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.FeeSchedule}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Conflicts}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Conflicts}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Policies}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Policies}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Discount}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Discount}
              />
            }
          />
        </TabsContainer>
      </Header>
      <Content>
        <TabContent>
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <DetailsTab
                commentForums={commentForums}
                activeTab={activeTab}
                proposalBuilder={proposalBuilder.current}
                issues={issues}
                disableCommenting={!proposal?.id}
                disableEditing={disableEditing}
                onSelectingClientChange={(isSelecting: boolean) =>
                  setIsSelectingClient(isSelecting)
                }
                onChange={handleProposalSpecChanged}
                onCommentsClicked={(field: ProposalField, name?: string) => {
                  handleToggleSidePanel(field, name);
                }}
              />
              <TeamTab
                entityId={session.currentEntity.entityId}
                proposalBuilder={proposalBuilder.current}
                issues={issues}
                disableCommenting={!proposal?.id}
                disableEditing={disableEditing}
                proposal={proposal}
                activeTab={activeTab}
                commentForums={commentForums}
                onChange={handleProposalSpecChanged}
                onCommentsClicked={(field: ProposalField, name?: string) => {
                  handleToggleSidePanel(field, name);
                }}
              />
              <FeeScheduleTab
                entityId={session.currentEntity.entityId}
                proposalBuilder={proposalBuilder.current}
                issues={issues}
                disableCommenting={!proposal?.id}
                commentForums={commentForums}
                disableEditing={disableEditing}
                proposal={proposal}
                activeTab={activeTab}
                onChange={handleProposalSpecChanged}
                onCommentsClicked={(field: ProposalField, name?: string) => {
                  handleToggleSidePanel(field, name);
                }}
              />
              <ConflictsTab
                proposalBuilder={proposalBuilder.current}
                issues={issues}
                disableCommenting={!proposal?.id}
                commentForums={commentForums}
                disableEditing={disableEditing}
                proposal={proposal}
                activeTab={activeTab}
                onChange={handleProposalSpecChanged}
                onCommentsClicked={(fieldId?: Guid, name?: string) => {
                  handleToggleSidePanel(
                    fieldId
                      ? ProposalField.ConflictsDocument(fieldId)
                      : ProposalField.Conflicts,
                    name
                  );
                }}
              />
              <PoliciesTab
                proposalBuilder={proposalBuilder.current}
                issues={issues}
                disableCommenting={!proposal?.id}
                commentForums={commentForums}
                disableEditing={disableEditing}
                activeTab={activeTab}
                onChange={handleProposalSpecChanged}
                onCommentsClicked={(fieldId?: Guid, name?: string) => {
                  let field: ProposalField;
                  if (session.context?.viewingAsVendor && fieldId) {
                    field = ProposalField.VendorPolicyDocument(fieldId);
                  } else if (session.context?.viewingAsVendor) {
                    field = ProposalField.VendorPolicies;
                  } else if (fieldId) {
                    field = ProposalField.ClientPolicyDocument(fieldId);
                  } else {
                    field = ProposalField.ClientPolicies;
                  }

                  handleToggleSidePanel(field, name);
                }}
              />
              <DiscountTab
                proposalBuilder={proposalBuilder.current}
                issues={issues}
                disableCommenting={!proposal?.id}
                disableEditing={disableEditing}
                proposal={proposal}
                activeTab={activeTab}
                commentForums={commentForums}
                onChange={handleProposalSpecChanged}
                onCommentsClicked={() => {
                  handleToggleSidePanel(ProposalField.Discount);
                }}
              />
            </>
          )}
        </TabContent>
      </Content>
      <ActionsContainer>
        <SecondaryActions>
          <NavButtons
            disabled={
              isSaving || isSelectingClient || isManagingReviewers || isStale
            }
          >
            <Button
              startIcon={<NavigateBeforeIcon />}
              disabled={
                shouldDisableTabs() ||
                activeTab === ProposalFieldCategory.Details
              }
              onClick={async () => await handlePrevNextClicked("previous")}
            >
              prev
            </Button>
            <Button
              endIcon={<NavigateNextIcon />}
              disabled={
                shouldDisableTabs() ||
                activeTab === ProposalFieldCategory.Discount
              }
              onClick={async () => await handlePrevNextClicked("next")}
            >
              next
            </Button>
          </NavButtons>
        </SecondaryActions>

        <ProposalActions>
          {(!disableEditing || isReviewing) && (
            <Tooltip
              title={
                !issues.canSubmit
                  ? "Fill out required fields to save."
                  : ""
              }
            >
              <ProposalActionSpan>
                <ProposalActionButton
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  loading={isSaving}
                  disabled={
                    isLoading ||
                    isSaving ||
                    isSelectingClient ||
                    isManagingReviewers ||
                    !isDirty ||
                    !issues.canSubmit ||
                    isStale
                  }
                  onClick={handleSaveClicked}
                >
                  Save
                </ProposalActionButton>
              </ProposalActionSpan>
            </Tooltip>
          )}
          {isReviewing && (
            <ProposalActionButton
              variant="contained"
              color="success"
              startIcon={<CheckIcon />}
              loading={isSaving}
              disabled={
                isLoading ||
                isSaving ||
                isApprovingReview ||
                isSelectingClient ||
                isManagingReviewers ||
                !issues.canSubmit ||
                hasUserApprovedReview() ||
                isStale
              }
              onClick={handleApproveReviewClicked}
            >
              {hasUserApprovedReview() ? "Approved" : "Approve"}
            </ProposalActionButton>
          )}
          {!disableEditing && (
            <>
              <Tooltip
                title={!proposal?.id ? "Save proposal to add reviewers." : ""}
              >
                <ProposalActionSpan>
                  <ProposalActionButton
                    variant="contained"
                    color="primary"
                    startIcon={<RateReviewIcon />}
                    loading={false}
                    disabled={
                      isLoading ||
                      isSaving ||
                      isSelectingClient ||
                      isSelectingClient ||
                      isManagingReviewers ||
                      reviewerSelectorAnchor !== undefined ||
                      !issues.canSubmit ||
                      !proposal?.id ||
                      disableEditing ||
                      isStale
                    }
                    onClick={handleManageReviewersClicked}
                  >
                    Manage Reviewers
                  </ProposalActionButton>
                </ProposalActionSpan>
              </Tooltip>
              <ReviewerSelector
                proposalBuilder={proposalBuilder.current}
                popoverAnchor={reviewerSelectorAnchor}
                onPopoverClose={() => {
                  setReviewerSelectorAnchor(undefined);
                  setIsManagingReviewers(false);
                }}
                onChange={handleProposalReviewersChanged}
              />
              {!disableEditing && (
                <Tooltip
                  title={
                    !issues.canSubmit
                      ? "Fill out required fields to submit."
                      : "Submit proposal to other party"
                  }
                >
                  <ProposalActionSpan>
                    <ProposalActionButton
                      variant="contained"
                      color="primary"
                      startIcon={<SendIcon />}
                      loading={isSubmitting}
                      disabled={
                        isLoading ||
                        isSaving ||
                        isSelectingClient ||
                        isManagingReviewers ||
                        !issues.canSubmit ||
                        disableEditing ||
                        isReviewing ||
                        isStale
                      }
                      onClick={handleSubmitClicked}
                    >
                      Submit
                    </ProposalActionButton>
                  </ProposalActionSpan>
                </Tooltip>
              )}
            </>
          )}
        </ProposalActions>
        <MessageButtons>
          <Tooltip
            title={!proposal?.id ? "Save proposal to enable commenting" : ""}
          >
            <ButtonContainer>
              <MessageButton
                size="medium"
                color="primary"
                disabled={!proposal?.id}
                onClick={() => handleToggleSidePanel(ProposalField.General)}
              >
                <Badge
                  variant="dot"
                  color="secondary"
                  overlap="circular"
                  invisible={
                    !getForumForField(ProposalField.General, commentForums)
                  }
                >
                  <CommentIcon fontSize="medium" />
                </Badge>
              </MessageButton>
              <MessageButtonLabel
                variant="button"
                color={proposal?.id ? "primary" : "darkgray"}
              >
                Comments
              </MessageButtonLabel>
            </ButtonContainer>
          </Tooltip>
        </MessageButtons>
        <Portal>
          <SidePanel open={isSidePanelOpen} anchor="right" variant="persistent">
            <SidePanelContainer>
              <SidePanelContent>
                <TitleBar>
                  <Typography variant="h5">{getSidePanelTitle()}</Typography>
                  <IconButton onClick={() => handleToggleSidePanel()}>
                    <CloseIcon />
                  </IconButton>
                </TitleBar>
                {isLoadingComments ? (
                  <Loader />
                ) : (
                  <Comments
                    proposal={proposal}
                    field={currentCommentField}
                    activeComments={currentComments}
                    pendingComments={pendingComments}
                    commenters={currentCommenters}
                    isSaving={isCommentSaving}
                    onCommentPosted={handleUserCommentPosted}
                    onCommentEdited={handleCommentEdited}
                    onCommentReadToggled={handleCommentReadToggled}
                    onCommentDeleted={handleCommentDeleted}
                    onViewProfile={handleViewCommenterProfile}
                  />
                )}
              </SidePanelContent>
            </SidePanelContainer>
          </SidePanel>
        </Portal>
      </ActionsContainer>
    </>
  );
}
