import Guid from "common/values/guid/guid";
import CompanyProfile from "marketplace/values/company-profile/company-profile";

export default class Company {
    id: Guid;
    entityId: Guid;
    profile?: CompanyProfile;
    contactId?: Guid;
    isVisible: boolean;

    constructor(id: Guid, entityId: Guid, profile?: CompanyProfile, contactId?: Guid, isVisible?: boolean) {
        this.id = id;
        this.entityId = entityId;
        this.profile = profile;
        this.contactId = contactId;
        this.isVisible = isVisible ?? false;
    }

    public static fromObject(object: any) {
        if (!object) return;
        
        const { profile, contactId, isVisible } = object
        
        const id = Guid.fromObject(object.id);
        const entityId = Guid.fromObject(object.entityId);

        if (!id) throw new Error("Company Id is required");
        if (!entityId) throw new Error("Entity Id is required");

        return new Company(
            id,
            entityId,
            CompanyProfile.fromObject(profile),
            Guid.fromObject(contactId),
            isVisible
        );
    }

    public toJSON() {
        return {
            id: this.id.value,
            entityId: this.entityId.value,
            profile: this.profile?.toJSON(),
            contactId: this.contactId?.toString(),
            isVisible: this.isVisible
        }
    }
}
