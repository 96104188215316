import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Container, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { useSession } from 'users/session/session-context';

const PageContainer  = styled('div')(({ theme }) => ({
  backgroundColor: '#fafafa',
  height: '100%'
}));
const CalendarContainer = styled('div')(({ theme }) => ({
  height: '100%',
  margin: 'auto',
  [theme.breakpoints.down('xl')]: {
    padding: 0
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
  },
}));

type CalendarProps = {};

export default function Calendar(props: Readonly<CalendarProps>) {
  const session = useSession();

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <PageContainer>
      <CalendarContainer>
        <FullCalendar
          height="100%"
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={[]}
        />
      </CalendarContainer>
    </PageContainer>
  )
}
