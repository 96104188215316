import VendorRepresentativeAPIRequest from "work/entities/entity-vendor-representative/api/request-contracts/vendor-representative-api-request";

export default class WorkTeamAPIRequest {
    leader?: VendorRepresentativeAPIRequest;
    memberUserIds?: string[];
    marketplaceId?: string;

    public get payload(): object {
        return {
            leader: this.leader?.payload,
            memberUserIds: this.memberUserIds,
            marketplaceId: this.marketplaceId
        };
    }
}
