import React, { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@mui/material';

import Guid from 'common/values/guid/guid';
import { styled } from '@mui/material/styles';
import SystemNotification from 'users/entities/system-notification/system-notification';
import SuperUserAPIService from 'users/entities/super-user/api/super-user-api-service';
import { useSession } from 'users/session/session-context';
import { CanceledError } from 'axios';

const NotificationPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2)
  },
  padding: theme.spacing(6),
  position: 'relative'
}));
const NotificationForm = styled(Box)(({ theme }) => ({
  maxWidth: '15rem'
}));
const NotificationFormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5)
}));
const NotificationInput = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  minWidth: '20rem'
}));
const NotificationSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  minWidth: '20rem'
}));

interface Props { }

export default function SystemNotifications(props: Readonly<Props>) {
  const [notificationType, setNotificationType] = React.useState<string>('');
  const [notificationContent, setNotificationContent] = React.useState('');
  const [formValid, setFormValid] = React.useState(false);
  const [systemNotifications, setSystemNotifications] = React.useState<SystemNotification[]>([]);
  const [saving, setSaving] = React.useState(false);

  const session = useSession();

  useEffect(() => {
    let abortController = new AbortController();
    getNotifications(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };

  });

  async function createNotification() {
    setSaving(true);

    try {
      const superUserAPIService = new SuperUserAPIService(session);
      await superUserAPIService.createSystemNotification(notificationType, notificationContent);
      clearForm();
      await getNotifications();
    }
    catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to create system notification", { variant: 'error' });
    } finally {
      setSaving(false);
    }
  }

  async function getNotifications(abortController?: AbortController) {
    try {
      const superUserAPIService = new SuperUserAPIService(session);
      let notifications = await superUserAPIService.getSystemNotifications(abortController);
      setSystemNotifications(notifications);
    }
    catch (error: any) {
      if (error instanceof CanceledError) return;
      console.error(error);
      enqueueSnackbar("Unable to fetch system notifications...", { variant: 'error' });
    } 
  }

  async function deleteNotification(notificationId: Guid) {
    try {
      const superUserAPIService = new SuperUserAPIService(session);
      await superUserAPIService.deleteSystemNotification(notificationId);
      await getNotifications();
    }
    catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to delete system notification", { variant: 'error' });
    } finally {
      setSaving(false);
    }
  }

  function validateForm() {
    setFormValid(notificationType !== '' && notificationContent !== '');
  }

  function clearForm() {
    setNotificationType('');
    setNotificationContent('');
    setFormValid(false);
  }

  return (
    <React.Fragment>
      <NotificationPaper>
        <NotificationForm>
          <NotificationFormTitle variant='h6'>
            New System Notification
          </NotificationFormTitle>
          <InputLabel id="notification-type-select-label">Notification Type</InputLabel>
          <NotificationSelect
            labelId="notification-type-select-label"
            id="notification-type-select"
            value={notificationType}
            label="Select the notification type..."
            onChange={(event) => {
              setNotificationType(event.target.value as string);
              validateForm();
            }}>
            <MenuItem value={'info'}>Info</MenuItem>
            <MenuItem value={'warning'}>Warning</MenuItem>
            <MenuItem value={'alert'}>Alert</MenuItem>
          </NotificationSelect>
          <NotificationInput
            label="Notification"
            minRows={5}
            multiline
            value={notificationContent}
            variant="outlined"
            onChange={(event) => {
              setNotificationContent(event.target.value);
              validateForm();
            }}
          />
          <Button
            color="primary"
            variant="contained"
            disabled={saving || !formValid}
            onClick={createNotification}>
            Send Notification
          </Button>
        </NotificationForm>
      </NotificationPaper>
      <NotificationPaper>
        <NotificationForm>
          <NotificationFormTitle variant='h6'>
            Current System Notifications
          </NotificationFormTitle>
        </NotificationForm>
        {systemNotifications &&
          <Table aria-label="notification table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Notification</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {systemNotifications.map((row) => (
                <TableRow key={row.id.value}>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.content}</TableCell>
                  <TableCell align='right'>
                    <Button
                      onClick={() => deleteNotification(row.id)}
                      color="primary">
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </NotificationPaper>
    </React.Fragment>
  )
}
