import { AxiosResponse } from "axios";
import Money from "common/values/money/money";
import _ from "lodash";

export type ObjectDiffResults = {
  added: object,
  updated: object,
  removed: object,
  unchanged: object
};
/**
 * @description - Get the difference between two objects
 * @param {object} object1 - The first object to compare
 * @param {object} object2 - The second object to compare
 * @returns {ObjectDiffResults} - An object containing the differences between the two objects
 * @example - diffObjects({ a: 1, b: 2 }, { a: 1, b: 3 }) // { updated: { b: { newValue: 3, oldValue: 2 } } }
 * @example - diffObjects({ a: 1, b: 2 }, { a: 1, b: 2 }) // { unchanged: { a: 1, b: 2 } }
 * @example - diffObjects({ a: 1, b: 2 }, { a: 1 }) // { removed: { b: 2 } }
 */
export function diffObjects(object1: object = {}, object2: object = {}): ObjectDiffResults {
  let added: object = {};
  let updated: object = {};
  let removed: object = {};
  let unchanged: object = {};

  for (const prop in object1) {
    if (object1.hasOwnProperty(prop)) {
      if (object2.hasOwnProperty(prop)) {
        if (_.isEqual(object2[prop as keyof object], object1[prop as keyof object]))
          unchanged[prop as keyof object] = object1[prop as keyof object];
        else
          Object.defineProperty(updated, 'newValue', {
            value: object2[prop as keyof object]
          });
      } else {
        removed[prop as keyof object] = object1[prop as keyof object];
      }
    }
  }
  for (const prop in object2) {
    if (object2.hasOwnProperty(prop)) {
      if (object1.hasOwnProperty(prop)) {
        if (!_.isEqual(object1[prop as keyof object], object2[prop as keyof object]))
          Object.defineProperty(updated, 'oldValue', {
            value: object1[prop as keyof object]
          });
      } else {
        added[prop as keyof object] = object2[prop as keyof object];
      }
    }
  }

  return { added, updated, removed, unchanged } as ObjectDiffResults;
}

/**
 * @description - Formats a number to a currency string
 * @param {string} money - The money object to format
 * @returns {string} - The formatted currency string
 */
export function formatCurrency(money: Money|undefined): string {
  if (!money) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: money.currency.toString()
  }).format(money.amount);
}

/**
 * @description - Returns a native type from a string
 * @param {string} string - The string to parse
 * @returns {any} - The native type
 * @example - parseStringToNativeType('true') // true
 * @example - parseStringToNativeType('false') // false
 * @example - parseStringToNativeType('1.1') // 1.1
 */
export function parseStringToNativeType(string: string): any {
  if (!_.isString(string)) return string;

  if (isNaN(parseFloat(string))) {
    if (string?.toLowerCase() === 'true') return true;
    if (string?.toLowerCase() === 'false') return false;
    return string;
  }

  return parseFloat(string);
}

/**
 * @description - Initiates a file download from the given response
 * @param {object} response - The response object from the axios request
 * @param {string} fileName - (optional) The name of the downloaded file (defaults to the file name from the response header)
 * @returns {Promise} - A promise that resolves when the file download is initiated
 */
export async function downloadFile(response: AxiosResponse, fileName?: string): Promise<void> {
  if (!response) return Promise.reject(new Error('No response provided'));

  const fileType = response.headers['content-type'];
  const contentDisposition = response.request.getResponseHeader('Content-Disposition') as string;
  const matches = RegExp(/(?<=filename(?:=|\*=(?:[\w-]+'')))["']?(?<filename>[^"';\n]+)["']?/).exec(contentDisposition);
  const blob = new Blob([response.data], { type: fileType });
  const link = document.createElement('a');
  link.setAttribute("href", window.URL.createObjectURL(blob));
  if (fileName) {
    link.setAttribute("download", fileName);
  } else if (matches?.groups?.filename) {
    link.setAttribute("download", matches.groups?.filename);
  }
  link.setAttribute("target", "_blank");
  link.click();
}

/**
 * @description - Gets the keys of an enum
 * @param {object} enumObject - The enum object
 * @returns {number[]} - An array of the enum keys
 * @example - getEnumKeys(MyEnum) // [0, 1, 2]
 */
export function getEnumKeys(enumObject: object): number[] {
  return Object.keys(enumObject).filter((v) => !isNaN(Number(v))).map((v) => Number(v));
}
